import { STEP_GIFT, STEP_COMPLETE } from 'commons/constants/cart'

export const checkBagItems = (state, action) => {
  const {
    hasBag,
    showRecoveryBagModal,
    orderDate,
    hasPreSales,
    cycleCode
  } = action.payload
  return {
    ...state,
    hasBag,
    showRecoveryBagModal,
    orderDate,
    hasPreSales,
    cycleCode
  }
}

export const clearModalBag = state => ({
  ...state,
  hasBag: false,
  showRecoveryBagModal: false,
  isFetchingItems: false
})

export const getBagItems = (state, action) => {
  const {
    products,
    gifts,
    selectedGifts,
    totalPrice,
    quantityTotal,
    cartUid,
    minimumOrderSize,
    quantityDone,
    cartTotalWithTaxes,
    taxAmount,
    shippingAmountWoDiscount,
    shippingAmountDiscount,
    shippingAmount,
    discountProfitability
  } = action.payload
  return {
    ...state,
    cartUid,
    products,
    gifts,
    selectedGifts,
    quantityDone,
    totalPrice,
    quantityTotal,
    isFetchingItems: false,
    minimumOrderSize,
    cartTotalWithTaxes,
    taxAmount,
    shippingAmountWoDiscount,
    shippingAmountDiscount,
    shippingAmount,
    discountProfitability
  }
}

export const bagIsStepComplete = getSate => {
  const {
    cartReducer: { steps }
  } = getSate()

  const { list, stepSelected } = steps
  const itemStep = list[stepSelected]
  return itemStep && itemStep.type === STEP_COMPLETE
}

export const cartIsClear = getSate => {
  const {
    cartReducer: { isClear }
  } = getSate()
  return isClear
}

export const nextStepIsGift = state => {
  const {
    cartReducer: { steps }
  } = state
  const { list, stepSelected } = steps
  const itemStep = list[stepSelected]
  return itemStep.type === STEP_GIFT
}

export const formatSelectedGifts = (selectedGifts = []) => {
  const formattedGifts = [];
  selectedGifts.forEach((gift) => {
    formattedGifts.push({
      code: gift.productCode,
      image: gift.itemDetails[0].image,
      name: gift.description,
      cartItemUid: gift.cartItemUid,
      quantity: gift.quantity,
      itemTypeId: gift.itemTypeId,
      unitPoints: null,
      totalPoints: null
    });
  })
  return formattedGifts;
}

const cleanGift = (gift) => ({
  ...gift,
  options: gift.options.map(op => ({
    ...op,
    included: false,
    quantity: 0,
    selected: false
  }))
})

export const renewGifts = state => {
  let { gifts } = state
  if (gifts && gifts.length > 0) {
    gifts = gifts.map(cleanGift);

    return {
      ...state,
      gifts,
      quantityDone: false,
      selectedGifts: undefined,
      included: false
    }
  }
  return {
    ...state
  }
}