import {
  PERSON_DATA,
  COMMERCIAL_DATA,
  VIRTUAL_PAGE_VIEW,
  CART_CONTENT,
  PRE_SALES,
  CHECKOUT_DATA,
  PURCHASE_DATA,
  CART_ADDITION_ORIGIN,
  VTR_NAVIGATION,
  CURRENT_LIST,
} from 'redux/actions/constants'

export const setPersonData = (payload) => ({
  type: PERSON_DATA,
  payload
})

export const setPreSalesData = (payload) => ({
  type: PRE_SALES,
  payload
})

export const getSiteLoadData = () => (_, getState) => {
  const { dataLayerReducer: { siteLoad } } = getState()
  return siteLoad
}

export const setCommercialData = (payload) => ({
  type: COMMERCIAL_DATA,
  payload
})

export const getVirtualPageViewData = fakePage => (dispatch, getState) => {
  const { dataLayerReducer: { currentPage } } = getState()
  let updatedVirtualPageView = {
    event: 'virtualPageView',
    page: global.document.location.href,
    previousPage: currentPage || global.document.referrer,
    pageTitle: global.document.title
  }
  if (fakePage) {
    let splitedURL = global.document.location.href.split('/')
    splitedURL[splitedURL.length-1] = fakePage
    updatedVirtualPageView = {
      event: 'virtualPageView',
      page: splitedURL.join('/') || global.document.location.href,
      previousPage: currentPage || global.document.referrer,
      pageTitle: global.document.title
    }
  }

  dispatch({
    type: VIRTUAL_PAGE_VIEW,
    payload: updatedVirtualPageView.page
  })

  return updatedVirtualPageView
}

export const setCartContent = (payload) => ({
  type: CART_CONTENT,
  payload
})

export const setCheckoutEventData = (payload) => ({
  type: CHECKOUT_DATA,
  payload
})

export const getCheckoutEventData = () => (_, getState) => {
  const { dataLayerReducer: { checkoutEvent } } = getState()
  return checkoutEvent
}

export const setPurchaseEventData = (payload) => ({
  type: PURCHASE_DATA,
  payload
})

export const setCartAdditionOrigin = (payload) => ({
  type: CART_ADDITION_ORIGIN,
  payload
})

export const setVtrNavigation = (payload) => ({
  type: VTR_NAVIGATION,
  payload
})

export const setCurrentList = (payload) => ({
  type: CURRENT_LIST,
  payload
})