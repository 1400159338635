import { createSaga } from 'natura-commons/redux'
import { saga as i18n } from '../modules/i18n'
import { saga as userSession } from '../modules/userSession';
import { saga as responsiveness } from '../modules/responsiveness';

export default createSaga(
  userSession,
  i18n,
  responsiveness,
)
