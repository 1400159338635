export const INCORPORATIONS_MENU_ID = 99996
export const LOGOUT_MENU_ID = 99997
export const LOGOUT_IMPERSON_MENU_ID = 99990
export const HOMEPAGE_MENU_ID = 89999
export const MAKE_ORDER_MENU_ID = 99999
export const MAGAZINE_MENU_ID = 99970
export const ORDERS_MENU_ID = 99960
export const MENU_HOME = '/home'
export const MENU_INCORPORATIONS = '/incorporations'
export const MENU_MAGAZINE = '/magazine'
export const MENU_ORDER = '/order'
export const MENU_PROMOTIONS = '/order/promotions'
export const ROOT = '/'
