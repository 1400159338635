import { initialState } from 'commons/interactor/environments/mapper'
import {
  setSectorReducer
} from 'commons/interactor/environments/interactor'

import {
  SET_SECTOR,
  CLEAR_ENVIRONMENT
} from '../../actions/constants'

const environmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SECTOR:
      return setSectorReducer(state, action.payload)
    case CLEAR_ENVIRONMENT:
      return initialState
    default:
      return state
  }
}

export default environmentReducer
