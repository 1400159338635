export const initialState = {
  hasPreSales: false,
  hasBag: false,
  cycleCode: null,
  orderDate: null,
  showRecoveryBagModal: false,
  products: [],
  gifts: [],
  selectedGifts: [],
  promotions: [],
  quantityDone: false,
  cartUid: null,
  totalPrice: 0.0,
  quantityTotal: 0,
  isFetchingItems: false,
  isAddingItem: false,
  addItemError: false,
  allowAutomaticGetBag: false,
  discountProfitability: 0,
  paymentConditions: [],
  paymentConditionSelected: [],
  pendingPayments: [],
  hasPendingPayments: null,
}
