import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import componentLoader from '../../commons/helpers/componentLoader'

const Modal = React.lazy(() => componentLoader(() => import('../../components/modal'), 3))

export const ModalProvider = React.memo(({ children }) => {
  const alerts = useSelector(state => (state.AlertsReducer.alerts || []), shallowEqual)
  const result = alerts.find(alert => alert && !!alert.show)

  return (
    <>
      {result && (
        <Modal
          origin={result.origin}
          title={result.title}
          body={result.body}
          type={result.type}
          show={result.show}
          typeAction={result.typeAction}
          typeModal={result.typeModal}
          changedRadioID={result.changedRadioID}
          contact={result.contact}
          titleButtonConfirm={result.titleButtonConfirm}
          titleButtonOne={result.titleButtonOne}
          titleButtonTwo={result.titleButtonTwo}
          radios={result.radio}
          image={result.image}
          increment={result.increment}
          products={result.products}
          interestedProduct={result.interestedProduct}
          kitProduct={result.kitProduct}
          damagedProduct={result.damagedProduct}
          dataObject={result.dataObject}
          onPressConfirm={result.handleConfirm}
          onPressOne={result.onPressOne}
          onPressTwo={result.onPressTwo}
          onValueChangeRadio={result.onChangeValueProfile}
        />
      )}
      {children}
    </>
  )
})
