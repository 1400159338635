import types from './split.types';
import Profile from 'natura-commons/service/Profile';
import Cart from 'natura-commons/service/Cart';
import Parameter from 'natura-commons/service/Parameter';
import { selectCustomerErrors } from '../views/SelectCustomer/SelectCustomerDefaultError'
import { getBag } from 'redux/actions/bag/bagAction'
import ENUMS from '../enums'

const cartApi = new Cart();
const profileApi = new Profile();
const parameterApi = new Parameter();

const concatGeoLevels = ({ addresses = [] }) => addresses
  .reduce((acc, { geographicalLevels = [] }) => acc.concat(geographicalLevels), []);

/** Geographical Level.
 * @typedef {Object} GeographicalLevel
 * @property {number} code The code of the geographical level.
 * @property {string} description - The description of the geographical level.
 */

/**  Check if two Geographical Levels has the same code or description
 * @param {GeographicalLevel} geoLevel1
 * @param {GeographicalLevel} geoLevel2
 */
const validateDeliveryGeoStructures = (geoLevel1, geoLevel2) => {
  if (!(geoLevel1 && geoLevel2)) {
    return false
  }

  if (geoLevel1.code && geoLevel2.code) {
    return geoLevel1.code === geoLevel2.code
  }

  if (geoLevel1.description && geoLevel2.description) {
    return geoLevel1.description === geoLevel2.description
  }

  return false
}

export const setField = (field, value) => (dispatch) => dispatch({
  type: types.SET_FIELD,
  payload: { field, value }
});

export const fetchCustomers = (searchInput) => (dispatch) => {
  const params = {};
  const input = parseInt(searchInput);

  input ? params.number = input : params.name = searchInput;

  profileApi.resolver(
    (res) => {
      dispatch({
        type: types.FETCH_CUSTOMERS,
        payload: { customers: res.data.profileGetCustomers.customers },
      });
    },
    (err) => {
      dispatch({
        type: types.FETCH_CUSTOMERS,
        payload: { customers: [] },
      });
    },
    profileApi.profileGetCustomers(params)
  )
};

export const addCustomer = (customerPersonId) => (dispatch, getState) => {
  try {
    const { splitReducer, parameterReducer, orderShippingReducer } = getState();
    if (splitReducer.lists.customers.length >= parameterReducer.split.maxItems) {
      dispatch({
        type: types.SET_SPLIT_ERROR,
        payload: { errorName: selectCustomerErrors.SPLIT_MAX_CUSTOMERS }
      })
      return;
    }

    const currentCustomer = splitReducer.lists.searchResults.find(searchResult => searchResult.personId === customerPersonId)
    const [customerGeoLevels, consultantGeoLevels] = [currentCustomer, orderShippingReducer].map(concatGeoLevels)

    if (!customerGeoLevels || !customerGeoLevels.length) {
      dispatch({
        type: types.SET_SPLIT_ERROR,
        payload: { errorName: selectCustomerErrors.CUSTOMER_DELIVERY_MODES_INVALID }
      })
      return;
    }
    const deliveryGeoStructureLevel = parameterReducer.split ? parameterReducer.split.deliveryGeoStructureLevel : null

    if (deliveryGeoStructureLevel !== null) {
      const findDeliveryGeoLevel = list => list.find(({ level }) => level === deliveryGeoStructureLevel)

      const [consumerDeliveryGeoLevel, consultantDeliveryGeoLevel] = [customerGeoLevels, consultantGeoLevels].map(findDeliveryGeoLevel);

      const splitDeliveryGeoStructuresIsValid = validateDeliveryGeoStructures(consumerDeliveryGeoLevel, consultantDeliveryGeoLevel)

      if (!splitDeliveryGeoStructuresIsValid) {
        dispatch({
          type: types.SET_SPLIT_ERROR,
          payload: { errorName: selectCustomerErrors.DELIVERY_GEO_LEVEL_INVALID }
        })
        return
      }
    }

    parameterApi.resolver(
      (deliveryModes) => {
        if (!deliveryModes || !deliveryModes.length) {
          dispatch({
            type: types.SET_SPLIT_ERROR,
            payload: { errorName: selectCustomerErrors.CUSTOMER_DELIVERY_MODES_INVALID }
          })
        } else {
          dispatch({
            type: types.ADD_CUSTOMER,
            payload: {
              customerPersonId,
              deliveryModes: deliveryModes
            },
          })
        }
      },
      (err) => {
        dispatch({
          type: types.SET_SPLIT_ERROR,
          payload: { errorName: selectCustomerErrors.CUSTOMER_DELIVERY_MODES_INVALID }
        })
      }, parameterApi.postCustomerDeliveryModes({ parameterInput: { geographicalLevels: customerGeoLevels }}))
  } catch (error) {
    dispatch({
      type: types.ADD_CUSTOMER,
      payload: {},
    })
  }
}

export const readSplitError = (errorName) => dispatch => dispatch({
  type: types.READ_SPLIT_ERROR,
  payload: { errorName }
})

export const deleteCustomer = (customerPersonId, productList, minValue) => (dispatch) => {
  if(productList.length === 0) {
    return  dispatch({
      type: types.DELETE_EMPTY_CUSTOMER,
      payload: { customerPersonId },
    });
  }
  
  cartApi.resolver(
    (res) => {
      const { data: { cartDeleteItemSplit } } = res;
      dispatch({
        type: types.DELETE_CUSTOMER,
        payload: {
          customerPersonId,
          splitItems: [
            ...cartDeleteItemSplit.purchasedItems.itemsCf,
            ...cartDeleteItemSplit.giftItems.itemsCf,
          ],
          minValue
        }
      });

      dispatch(getBag())
    },
    (err) => {
      dispatch({
        type: types.DELETE_CUSTOMER,
        payload: { customerPersonId, splitItems: [] },
      });
    },
    cartApi.deleteItemSplit({ customerUid: customerPersonId, splitItems: productList })
  )
};

export const setSelectedProduct = (customerPersonId, productSelected) => (dispatch) => dispatch({
  type: types.SET_SELECTED_PRODUCT,
  payload: { customerPersonId, productSelected }
});

export const addProduct = (productSelected, params, products) => (dispatch) => {
  cartApi.resolver(
    (res) => {
      const { data: { cartUpdateItemSplit } } = res;
      dispatch({
        type: types.ADD_PRODUCT,
        payload: {
          splitItems: [
            ...cartUpdateItemSplit.purchasedItems.itemsCf,
            ...cartUpdateItemSplit.giftItems.itemsCf,
          ],
          minValue: params.minimumScore,
          products,
        }
      });
    },
    (err) => {
      dispatch({
        type: types.ADD_PRODUCT,
        payload: { splitItems: [], minValue: params.minimumScore, products },
      });
    },
    cartApi.updateItemSplit({ cartItemUid: productSelected, params })
  )
};

export const updateProduct = (productSelected, params, products) => (dispatch) => {
  cartApi.resolver(
    (res) => {
      const { data: { cartUpdateItemSplit } } = res;

      dispatch({
        type: types.UPDATE_PRODUCT_QUANTITY,
        payload: {
          splitItems: [
            ...cartUpdateItemSplit.purchasedItems.itemsCf,
            ...cartUpdateItemSplit.giftItems.itemsCf,
          ],
          minValue: params.minimumScore,
          products,
        }
      });
    },
    (err) => {
      dispatch({
        type: types.UPDATE_PRODUCT_QUANTITY,
        payload: { splitItems: [], minValue: params.minimumScore, products },
      });
    },
    cartApi.updateItemSplit({ cartItemUid: productSelected, params })
  )
};

export const removeProduct = (customerPersonId, cartItem, minValue, products) => (dispatch) => {
  cartApi.resolver(
    (res) => {
      const { data: { cartDeleteItemSplit } } = res;
      dispatch({
        type: types.REMOVE_PRODUCT,
        payload: {
          splitItems: [
            ...cartDeleteItemSplit.purchasedItems.itemsCf,
            ...cartDeleteItemSplit.giftItems.itemsCf,
          ],
          minValue,
          products,
        }
      });
    },
    (err) => {
      dispatch({
        type: types.REMOVE_PRODUCT,
        payload: { splitItems: [], minValue, products },
      });
    },
    cartApi.deleteItemSplit({ customerUid: customerPersonId, splitItems: [cartItem] })
  )
};

export const enableSplit = (enable) => (dispatch) => {
  cartApi.resolver(
    (res) => {
      dispatch({
        type: types.ENABLE_SPLIT,
        payload: { enable }
      });
    },
    (err) => {
      dispatch({
        type: types.ENABLE_SPLIT,
        payload: { enable: false },
      });
    },
    cartApi.enableSplit({ enable })
  )
};

export const getSplit = (minValue, products = []) => (dispatch) => {
  cartApi.resolver(
    (res) => {
      const { data: { cartGetSplit } } = res;
      dispatch({
        type: types.GET_SPLIT,
        payload: { 
          splitItems: [...cartGetSplit.purchasedItems.itemsCf, ...cartGetSplit.giftItems.itemsCf],
          minValue,
          products,
        }
      });
    },
    (err) => {
      dispatch({
        type: types.GET_SPLIT,
        payload: { splitItems: [], minValue, products },
      });
    },
    cartApi.getSplit()
  )
};

export const setItemTypeId = (items, value) => items.map(item => { return { ...item, itemTypeId: value }})
  
export const checkSplitAllowGift = (gifts, allowGift) => (!allowGift ? [] : setItemTypeId(gifts, ENUMS.itemTypeId.GIFT));
