import React from 'react'
import { useSelector } from 'react-redux'
import Loading from 'components/loading'

export const ApplicationLoaderProvider = React.memo(({ children }) => {
  const isLoading = useSelector(state => !!state.loadReducer.show)

  return (
    <React.Suspense fallback={<Loading visible />}>
      <Loading visible={isLoading} />
      {children}
    </React.Suspense>
  )
})
