export function mapConsultant(userImpersonate, recent = false) {
  const result = []
  if (userImpersonate) {
    for (const consultant of userImpersonate) {
      if (consultant) {
        const name = consultant.personName.trim()
        const consultantResult = {
          name,
          firstName: name.split(' ')[0],
          lastName: name.replace(/^\S+\s*/g, ''),
          code: consultant.personCode,
          recent,
          level: consultant.personLevel,
          profileImage: consultant.picture,
          personID: consultant.personID
        }
        result.push(consultantResult)
      }
    }
  }
  return result
}

export function mapSingleConsultant(consultant) {
  if (consultant) {
    const name = consultant.personName.trim()
    return {
      name,
      firstName: name.split(' ')[0],
      lastName: name.replace(/^\S+\s*/g, ''),
      code: consultant.personCode,
      recent: false,
      level: consultant.personLevel,
      profileImage: consultant.picture,
      personID: consultant.personID
    }
  }
  return null
}
