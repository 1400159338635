import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import Translate from 'natura-commons/translate'

const translate = new Translate()

const Loading = ({ visible, background, zIndex }) => {
  const styles = {
    wrapper: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: visible ? zIndex : -1,
      opacity: visible ? 1 : 0,
      transition: 'all 0.2s',
      background,
      display: 'flex',
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    wrapperText: {
      color: '#ffffff',
      fontFamily: 'var(--primary-font-family)',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.29',
      letterSpacing: 'normal',
      textAlign: 'center',
      paddingTop: '16px',
      display: 'block'
    },
    loading: {
      width: '68px',
      height: '68px'
    }
  }

  return (
    <div style={styles.wrapper}>
      <div>
        <div style={styles.loading}>
          <div className="element" />
        </div>
        <div className="text-center">
          <span style={styles.wrapperText}>{translate.byKey('loading')}</span>
        </div>
      </div>
    </div>
  )
}

Loading.defaultProps = {
  visible: true,
  background: 'rgba(0, 0, 0, 0.7)',
  zIndex: 999999999
}

Loading.propTypes = {
  visible: PropTypes.bool,
  background: PropTypes.string,
  zIndex: PropTypes.number
}

export default Loading
