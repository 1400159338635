export const types = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning'
}

export const initialState = {
  title: '',
  icon: '',
  svgIcon: null,
  text: '',
  textConfirm: '',
  type: '',
  show: false,
  autoHide: true,
  allowHide: true,
  clickDismmiss: true,
  timeout: null,
  hideIcon: false,
  footer: false,
}
