import Translate from 'natura-commons/translate'

import { isEmpty, sumBy, orderBy, get, union } from 'lodash'

import Cart from 'natura-commons/service/Cart'
import Profile from 'natura-commons/service/Profile'
import Product from 'natura-commons/service/Product'

import {
  PLAN_B_TYPE_ID,
  PRODUCT_UNAVAILABLE_INVENTORY,
  PRODUCT_UNAVAILABLE_QUOTA_CN,

  PRODUCT_UNAVAILABLE_MESSAGE,
  PRODUCT_NOT_FOUND_MESSAGE
} from 'commons/constants/product'

import {
  ITEM_TYPE_ID_GIFT,
  STATUS_CART_PENDING_PROFITABILITY,
  STEP_SHIPPING,
  GIFT_OPTION_QUANTITY,
  STATUS_CART_CALCULATED,
  TIMEOUT_ALERT_TOAST,
  CART_SEARCH_TAG,
  TIMEOUT_HIDE_CALCULATED_TOAST,
  TIMEOUT_SHOW_ALERT_TOST,
} from 'commons/constants/cart'

import { getAllExclusiveTitles, setUpdatingItemsInExclusiveStep } from 'redux/actions/promotion/promotionAction'
import { listProducts } from 'redux/actions/products/productsActions'
import {
  SET_PICK_UP_ADDRESS,
  UPDATE_GIFT_QUANTITY,
  SELECT_GIFT_OPTION,
  SET_AUTOMATIC_GET_BAG,
  ADD_ITEM_START,
  ADD_ITEM_DONE,
  PAYMENT_CONDITION,
  SELECT_PAYMENT_CONDITION,
  SET_PENDING_PAYMENTS,
  SET_HAS_PENDING_PAYMENTS
} from 'redux/actions/constants'

import {
  updateStatusIdCart,
  clearUnavailableProducts,
  disbaleNextButton
} from 'redux/actions/cart/cartAction'

import {
  mapGiftsProduct,
  mapCartProduct,
  mapUnavailableProducts,
  mapIncorporatedGifts,
} from 'commons/interactor/products/mapper'

import { verifyError, setAlerts } from 'redux/actions/alerts/alertsAction'
import { mapCartResume } from 'commons/interactor/cart/mapper'
import { showLoad } from 'redux/actions/load/loadAction'

import { quantityDone } from 'commons/interactor/cart/interactor'

import { navigateCart } from 'redux/actions/router/routerAction'
import { getNotFoundProductAlert } from 'commons/presenter/order/home/presenter'
import {
  FAIL_CART_RECOVERY,
  FAIL_CART_RECOVERY_USER,
  FAIL_CART_REMOVE,
  FAIL_CART_UPDATE,
  FAIL_CART_ADD,
  FAIL_ADD_ADDRESS,
  ERROR_CODE_UNAVAILABLE_GIFT,
  ERROR_CODE_UNAVAILABLE_GIFT_SIMPLE,
  ERROR_CODE_NOT_FOUND_GIFT,
  ERROR_CODE_NOT_ENOUGH_QUOTA_EC,
  ERROR_CODE_NOT_ENOUGH_QUOTA_CN,
  ERROR_CODE_INVALID_QUANTITY,
  ERROR_CODE_NOT_FOUND_INVENTORY
} from 'commons/constants/error'

import { CART } from 'redux/actions/router/constants'

import {
  diffAddItem,
  getArrayItemsUIDs,
  checkStock
} from 'commons/interactor/bag/diffAddItem'
import { verifyErrorResponse } from 'commons/presenter/errors/responseErros'
import { getAlertMinOrderSize } from 'commons/presenter/errors/modalAlertMinOrderSize'

import { isAllUnavailable } from 'commons/interactor/products/interactor'

import { showUnavailabilityAlternatives } from 'commons/presenter/unavailability/presenter'
import { setAddressesAndDeliveryModes } from 'redux/actions/cart/orderShippingAction'

import {
  setCartContent,
  setCheckoutEventData
} from 'redux/actions/dataLayer/dataLayerAction'

import BenefitType from 'natura-commons/enums/BenefitType'

import Order from 'natura-commons/service/Order/index'

import {
  START_FETCHING_ITEMS,
  GET_BAG_ITEMS,
  CLEAR_MODAL_BAG,
  ADD_ITEM_ERROR,
  UPDATE_CART_PROMOTIONS,
  UPDATE_CART_RESUME,
  SET_MINE,
  SELECT_GIFT_CART,
  RENEW_GIFTS,
  RENEW_GIFTS_BAG,
  UPDATE_SELECTED_GIFTS,
  REPLACE_UNAVAILABLE_GIFT_OPTIONS
} from '../constants'

import {
  checkCartIsReady,
  checkCurrentStepCartShouldBeReady,
  checkPendingPromotionOrProfitability,
} from '../../../commons/helpers/cart'
import { setComissionDispatch } from '../parameters/parameterAction'
import { showToast, hideToast } from '../toast/toastAction'

import cartCalculatedIcon from 'commons/assets/images/ic-calculated.svg'
import IconCartMax from 'commons/assets/images/ic-cart-max.png'
import { limitCredit } from 'commons/helpers/credit'

export const cancelAddTimeOut = () => (dispatch) => {
  dispatch({ type: ADD_ITEM_ERROR })
}

export const setMine = mine => dispatch =>
  dispatch({
    type: SET_MINE,
    mine
  })

export const blockGetBag = () => dispatch =>
  dispatch({
    type: SET_AUTOMATIC_GET_BAG,
    allowAutomaticGetBag: false
  })
export const allowGetBag = () => dispatch =>
  dispatch({
    type: SET_AUTOMATIC_GET_BAG,
    allowAutomaticGetBag: true
  })

export const applyPromotion = () => (dispatch, getState) => {
  const {
    cartReducer: {
      resume: { cartUid }
    },
    userSession: { commercialInfo }
  } = getState()
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
  if (cartUid) {
    const cart = new Cart()
    cart.resolver(
      (response) => {
        const {
          data: {
            cartApplyPromotion: { success }
          }
        } = response
        if (!success) {
          verifyError(dispatch, [{ code: 90003 }])
        }
      },
      (error) => {
        dispatch(showLoad(false))
        verifyError(dispatch, error)
      },
      cart.applyPromotion(cartUid, profileIncorporations)
    )
  }
}

export const getBag = (isShowLoad = true, isDiscreteLoad = false, showCalculated = false) => (dispatch, getState) => {
  const state = getState()

  const {
    userSession: { commercialInfo: { orderProfile } }
  } = state

  dispatch(blockGetBag())
  const cart = new Cart()
  dispatch(showLoad(isShowLoad, isDiscreteLoad))
  cart.resolver(
    (response) => {
      dispatch(allowGetBag())
      if (showCalculated && response.summary.statusId === STATUS_CART_CALCULATED) {
        showCreditAlert(response, dispatch, getState)
      }
      return refreshCartContent(response, dispatch, getState)
    },
    (error) => {
      dispatch(allowGetBag())
      dispatch(showLoad(false, isDiscreteLoad))
      const {
        router: {
          location: { pathname }
        }
      } = getState()
      if (pathname === CART) {
        return verifyError(dispatch, error, false, FAIL_CART_RECOVERY_USER)
      }
      return verifyError(dispatch, error, false, FAIL_CART_RECOVERY)
    },
    cart.getCart(checkStock(state), orderProfile)
  )
}

export const getBagShadow = (checkSteps = true) => (dispatch, getState) => {
  dispatch(blockGetBag())
  const cart = new Cart()
  const {
    cartReducer: {
      steps: { stepSelected, list }
    }
  } = getState()
  const currentStep = list[stepSelected].type
  if (currentStep === STEP_SHIPPING) {
    dispatch(showLoad(true))
  }
  cart.resolver(
    (response) => {
      dispatch(allowGetBag())
      if (response.data && response.data.cartStatus) {
        const { statusId } = response.data.cartStatus
        if (checkSteps) {
          const cartIsReady = checkCartIsReady(statusId)
          const currentStepCartShouldReady = checkCurrentStepCartShouldBeReady(currentStep)

          if (currentStepCartShouldReady && cartIsReady) {
            if (getState().cartReducer.subscribe === 0 && getState().cartReducer.summaryCount === 0) {
              dispatch(setMine({ mineStatusId: statusId }))
              dispatch(getBag(false))
            }
          }
        }

        const pendingPromotionOrProfitability = checkPendingPromotionOrProfitability(statusId)

        if (!pendingPromotionOrProfitability) {
          dispatch(setMine({ mineStatusId: statusId }))
          dispatch(getBag(false, false, true))
        }
      }
    },
    (error) => {
      dispatch(showLoad(false))
      dispatch(allowGetBag())
    },
    cart.getCartStatus()
  )
}

export const dropBag = (cartId = null) => (dispatch, getState) => {
  dispatch(blockGetBag())
  const cart = new Cart()
  const {
    cartReducer: {
      resume: { cartUid }
    }
  } = getState()

  dispatch(showLoad(true))
  cart.resolver(
    (response) => {
      refreshCartContent(response, dispatch, getState)
    },
    (error) => {
      dispatch(showLoad(false))
      dispatch(allowGetBag())
      verifyError(dispatch, error)
    },
    cart.deleteCart(cartId || cartUid)
  )
}

export const hideModalRecoveryBag = () => (dispatch) => {
  dispatch({
    type: CLEAR_MODAL_BAG
  })
}


export const addUnavailableGiftToCart = (unavailableGifts, unavailableAlternativeProducts, profileIncorporations) => {
  const cart = new Cart()
  cart.addUnavailableGifts(unavailableGifts, unavailableAlternativeProducts, profileIncorporations)
}

export const addItem = (
  item,
  checkError = true,
  showGlobalLoader = false,
  requestBag = false
) => (dispatch, getState) => {
  dispatch(blockGetBag())
  const cart = new Cart()
  const { userSession: { commercialInfo } } = getState()
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
  const isDiscreteLoad = true
  if (showGlobalLoader) {
    dispatch(showLoad(true, isDiscreteLoad))
  }
  dispatch({ type: START_FETCHING_ITEMS })
  dispatch({ type: ADD_ITEM_START })
  item &&
    item.code &&
    item.quantity &&
    cart.resolver(
      (response) => {
        dispatch({ type: ADD_ITEM_DONE })
        if (showGlobalLoader) {
          dispatch(showLoad(false, isDiscreteLoad))
        }
        dispatch(allowGetBag())
        if (response != null) {
          verifyErrorResponse(dispatch, response.errors)
        }
        if (requestBag) {
          dispatch(getBag(true, isDiscreteLoad, true))
        }
        const addedItem = response.purchasedItems.find(product => Number(product.productCode) === Number(item.code))
        if (
          addedItem.quantity > item.quantity
        ) {
          showUpdatedItemToast(dispatch)
        } else {
          showAddedItemToast(dispatch)
        }
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
        refreshCartContent(response, dispatch, getState)
      },
      (error) => {
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
        dispatch({ type: ADD_ITEM_ERROR })
        dispatch({ type: ADD_ITEM_DONE })

        if (showGlobalLoader) {
          dispatch(showLoad(false, isDiscreteLoad))
        }
        dispatch(allowGetBag())
        const { errors } = error
        const newListProducts =
          errors &&
          errors.length &&
          errors.reduce((acc, item) => {
            const [unavailability] = item.productUnavailability || []
            if (unavailability && unavailability.typeId === PLAN_B_TYPE_ID) {
              acc.push({
                code: item.productCode,
                name: item.productName
              })
            }
            return acc
          }, [])
        if (newListProducts.length) {
          newListProducts.push(...getState().productsReducer.list)
          dispatch(listProducts(newListProducts))
        }
        if (checkError) {
          try {
            showUnavailabilityAlternatives(
              error.errors,
              item,
              dispatch,
              getState
            )
          } catch (err) {
            console.log('ERROR: ', err)
            checkError && verifyError(dispatch, error)
          }
        }
      },
      cart.addProducts([{ ...item }], false, profileIncorporations)
    )
}
export const addItems = (products, showImportAlert = null) => (dispatch, getState) => {
  if (products.length > 0) {
    const {
      cartReducer: {
        resume: { totalMinOrderSize }
      }
    } = getState()

    const cartSize = totalMinOrderSize?.map(
      item => ({ companyBrand: item.companyBrandIds, value: item.totalMinOrderSizeByBrand })
    )
    const isDiscreteLoad = true
    dispatch({ type: START_FETCHING_ITEMS })
    dispatch(showLoad(true, isDiscreteLoad))
    dispatch(blockGetBag())
    const cart = new Cart()
    cart.resolver(
      (response) => {
        dispatch(allowGetBag())
        dispatch(showLoad(false, isDiscreteLoad))
        if (response != null) {
          if (response.errors.length) {
            const unavailableIds = filterUnavailableProductsIds(response.errors)
            const notFoundProducts = filterNotFoundProducts(response.errors)

            return dispatch(
              addUnavailableProductsToCart(
                unavailableIds,
                response,
                products,
                notFoundProducts,
                cartSize,
                isDiscreteLoad,
                showImportAlert
              )
            )
          }
          if (response.finished) {
            if (showImportAlert) {
              showImportAlert()
            }
            return response
          }
          if (!response.errors.length && showImportAlert) {
            showImportAlert()
          }

          return refreshCartContent(response, dispatch, getState)
        }
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
      },
      (error) => {
        dispatch(allowGetBag())
        dispatch({ type: ADD_ITEM_ERROR })
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))

        if (error && error.errors) {
          const unavailableIds = filterUnavailableProductsIds(error.errors)
          const notFoundProducts = filterNotFoundProducts(error.errors)

          return dispatch(
            addUnavailableProductsToCart(
              unavailableIds,
              error,
              products,
              notFoundProducts,
              cartSize,
              false,
              showImportAlert
            )
          )
        }

        dispatch(showLoad(false))
        verifyError(dispatch, error)
      },
      cart.addProducts(products, true)
    )
  }
}

const filterUnavailableProductsIds = errors =>
  errors
    .filter(error => (
      error.message === PRODUCT_UNAVAILABLE_MESSAGE ||
      error.message === PRODUCT_UNAVAILABLE_INVENTORY ||
      error.message === PRODUCT_UNAVAILABLE_QUOTA_CN
    ))
    .map(item => item.productCode)

const filterNotFoundProducts = errors =>
  errors
    .filter(error => error.message === PRODUCT_NOT_FOUND_MESSAGE)
    .map(error => error.productCode)

export const addUnavailableProductsToCart = (
  ids,
  addResponse,
  originalProducts,
  notFoundProducts,
  cartSize,
  isDiscreteLoad,
  showImportAlert
) => async (dispatch, getState) => {
  const products = new Product()
  products.cancel()
  products.resolver(
    (response) => {
      dispatch(showLoad(false, isDiscreteLoad))

      addResponse.purchasedItems.push(
        ...mapUnavailableToCart(response.product, originalProducts)
      )
      refreshCartContent(addResponse, dispatch, getState)
      dispatch(navigateCart())
      if (notFoundProducts.length > 0) {
        dispatch(setAlerts([getNotFoundProductAlert(notFoundProducts, showImportAlert)]))
      } else if (showImportAlert) {
        showImportAlert()
      }
    },
    (error) => {
      dispatch(showLoad(false, isDiscreteLoad))
      verifyError(dispatch, error)
    },
    products.byID({ key: ids, cartSize })
  )
}
export const mapUnavailableToCart = (productsFromSearch, originalProducts) =>
  productsFromSearch.map((product) => {
    const originalProduct = originalProducts.filter(
      item => item.code === product.productCode
    )[0]
    return {
      ...product,
      quantity: originalProduct ? originalProduct.quantity : 1,
      totalPriceWoProfitability: product.priceWoProfitability
    }
  })

export const addItemPromotion = (item, errorKey = FAIL_CART_ADD) => (
  dispatch,
  getState
) => {
  dispatch(blockGetBag())
  const cart = new Cart()
  dispatch({ type: START_FETCHING_ITEMS })
  item &&
    item.code &&
    item.quantity &&
    cart.resolver(
      (response) => {
        dispatch(allowGetBag())
        if (response != null) {
          return refreshCartContent(response, dispatch, getState)
        }
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
      },
      (error) => {
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
        dispatch(allowGetBag())
        dispatch({ type: ADD_ITEM_ERROR })
        verifyError(dispatch, error, false, errorKey)
      },
      cart.addProducts([{ code: item.code, quantity: item.quantity, tags: [CART_SEARCH_TAG] }], true)
    )
}

const showUpdatedItemToast = (dispatch) => {
  const translate = new Translate()
  dispatch(hideToast())
  setTimeout(() => {
    dispatch(
      showToast({
        text: translate.byKey('cartCalculated'),
        type: 'success',
        show: true,
        autoHide: true,
        timeout: TIMEOUT_ALERT_TOAST
      })
    )
  }, 10)
}

const showAddedItemToast = (dispatch) => {
  const translate = new Translate()
  dispatch(hideToast())
  setTimeout(() => {
    dispatch(
      showToast({
        text: translate.byKey('productAdded'),
        type: 'success',
        show: true,
        autoHide: true,
        timeout: TIMEOUT_ALERT_TOAST
      })
    )
  }, 10)
}

const showMinOrderPromoReached = (minimumOrderPromotions, summary, dispatch) => {
  const translate = new Translate()

  const [ minOrderPromotion ] = minimumOrderPromotions
  const { 
    profitability: { minimumOrderSize },
  } = summary

  const companyBrandsLabels = []

  minOrderPromotion.benefit.companyBrands.forEach(brandId => {
    const minOrder = minimumOrderSize.find(({ companyBrandIds = [] }) => companyBrandIds.length > 0 && companyBrandIds.find(({ code }) => code && +code === brandId))

    minOrder && minOrder.companyBrandIds.forEach(({ description }) => companyBrandsLabels.push(description))
  });

  const companyBrandsUniqLabels = union(companyBrandsLabels).join(` ${translate.byKey('and')} `)

  const promoMinOrderSize = minOrderPromotion.benefit.minimumOrderSize;

  dispatch(hideToast())

  setTimeout(() => {
    dispatch(
      showToast({
        title: translate.byKey('minOrderPromoReduced'),
        text: translate.byKeyAndArrayParam('minOrderPromoReducedDescription', {
          companyBrandsLabels: companyBrandsUniqLabels ? companyBrandsUniqLabels : '',
          minimumOrderSize: promoMinOrderSize,
        }),
        type: 'promoInfo',
        hideIcon: true,
        show: true,
        autoHide: true,
        timeout: TIMEOUT_ALERT_TOAST
      })
    )
  }, 1000)
}

const showCreditAlert = (cart, dispatch, getState) => {
  const {
    cartReducer: {
      steps: { stepSelected },
    },
    userSession: { commercialInfo }
  } = getState()

  if (limitCredit(commercialInfo, { resume: cart.summary })) {
    if (stepSelected === 0) {
      const translate = new Translate()

      setTimeout(() => {
        dispatch(hideToast())
      }, TIMEOUT_HIDE_CALCULATED_TOAST)
      setTimeout(() => {
        dispatch(
          showToast({
            icon: IconCartMax,
            text: translate.byKey('orderExceeded'),
            type: 'error',
            show: true,
            autoHide: true,
            clickDismmiss: true,
            timeout: TIMEOUT_ALERT_TOAST
          })
        )
      }, TIMEOUT_SHOW_ALERT_TOST)
    }
  }
}

export const updateItem = item => (dispatch, getState) => {
  if (item && item.code && item.quantity > 0) {
    dispatch(blockGetBag())
    const cart = new Cart()
    const isDiscrete = true
    dispatch(showLoad(true, isDiscrete))
    const {
      cartReducer: {
        resume: { cartUid }
      },
      userSession: { commercialInfo }
    } = getState()
    const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
    cart.resolver(
      (response) => {
        dispatch(allowGetBag())
        if (response != null) {
          verifyErrorResponse(dispatch, response.errors)
          if (response.errors.length === 0) {
            showUpdatedItemToast(dispatch)
          }
          return refreshCartContent(response, dispatch, getState, item, true)
        }
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
        return dispatch(showLoad(false, isDiscrete))
      },
      (error) => {
        dispatch(allowGetBag())
        dispatch(showLoad(false, isDiscrete))
        dispatch(updateStatusIdCart(STATUS_CART_PENDING_PROFITABILITY))
        try {
          dispatch({ type: ADD_ITEM_DONE })
          showUnavailabilityAlternatives(error.errors, item, dispatch, getState)
        } catch (err) {
          verifyError(dispatch, error, false, FAIL_CART_UPDATE)
        }
      },

      cart.updateProduct(item.cartItemUid, item.quantity, cartUid, profileIncorporations)
    )
  }
}

export const updateAddressAction = (objAddress, dispatch, getState) => {
  dispatch(blockGetBag())
  const { addressShipping, addresses } = objAddress
  if (!addressShipping) return
  const cart = new Cart()
  const { userSession: { commercialInfo } } = getState()
  const { orderProfile } = commercialInfo
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
  dispatch(showLoad(true))
  cart.resolver(
    (response) => {
      dispatch(showLoad(false))
      dispatch(allowGetBag())
      dispatch(disbaleNextButton(false))
      if (response && response.shipping) {
        dispatch({
          type: SET_PICK_UP_ADDRESS,
          payload: response.shipping
        })
        return refreshCartContent(response, dispatch, getState)
      }
    },
    (error) => {
      dispatch(disbaleNextButton(true))
      dispatch(allowGetBag())
      dispatch(showLoad(false))
      verifyError(dispatch, error, false, FAIL_ADD_ADDRESS)
    },
    cart.updateShipping({
      addressId: addressShipping.id,
      deliveryModeId: addressShipping.shippingMethodId,
      deliveryPolicyId: addressShipping.deliveryPolicyId,
      addresses,
    }, profileIncorporations, orderProfile)
  )
}

const formatSelectedGifts = (selectedGifts = []) => {
  const formattedGifts = []
  selectedGifts.forEach((gift) => {
    formattedGifts.push({
      code: gift.productCode,
      image: gift.itemDetails[0].image,
      name: gift.description,
      cartItemUid: gift.cartItemUid,
      quantity: gift.quantity,
      itemTypeId: gift.itemTypeId,
      unitPoints: 0,
      totalPoints: 0
    })
  })
  return formattedGifts
}

const availableAlternativeProducts = option => option.isAvailable === true
const unavailableAlternativeProducts = option => option.isAvailable === false

const setAvailableGiftOption = (acc, option) => {
  const alternativeProduct = option.alternateProducts.find(availableAlternativeProducts)
  const unavailableAlternative = option.alternateProducts.every(unavailableAlternativeProducts)

  if (!option.isAvailable && alternativeProduct) {
    acc[0].push(alternativeProduct)
    acc[1].push(option)
  } else if (!option.isAvailable && unavailableAlternative) {
    acc[0].push(option)
    acc[1].push(option)
  } else {
    acc[0].push(option)
  }

  return acc
}

const getUnavailableAlternativeProducts = (acc, option) => {
  if (!option.isAvailable) {
    const alternativeProduct = option.alternateProducts.find(unavailableAlternativeProducts)
    const unavailableAlternative = option.alternateProducts.every(unavailableAlternativeProducts)
    if (alternativeProduct && unavailableAlternative) {
      alternativeProduct.quantity = option.quantity
      acc.push(alternativeProduct)
    }
  }

  return acc
}

export const refreshCartContent = (
  response,
  dispatch,
  getState,
  itemAdd = null,
  isUpdate = false,
  mergeItems = true
) => {
  if (isEmpty(response)) {
    dispatch(showLoad(false))
    return
  }
  const {
    purchasedItems,
    promotions = [],
    summary,
    shipping,
    checkout,
    minimumOrderSize,
    cartUnavailableItems,
    giftItems
  } = response

  const { statusId } = summary
  const {
    userSession: {
      commercialInfo: {
        orderProfile: { starterKit, maxItemQuantity, profitabilityTable }
      }
    },
    bagReducer: { products: prevProducts, quantityDone: prevQuantityDone },
    cartReducer: { promotions: prevPromotions = [] },
    parameterReducer: { titlesPromotion, updatingItemsInExclusiveStep },
    splitReducer: { fields: { enableSplit } }
  } = getState()
  const { products, mandatory } = starterKit

  const productCodesStarterKit = []
  if (products && products.length > 0) {
    products.map(el =>
      productCodesStarterKit.push(el.productCode)
    )
  }

  // const newPromotions = promotions.filter(promotion => !prevPromotions.find(prevPromotion => prevPromotion.promotionId === promotion.promotionId))

  // if (newPromotions.length > 0) {
  //   const minimumOrderPromotions = newPromotions.filter(promotion => promotion.benefit.benefitTypeId === BenefitType.MINIMUM_ORDER)

  //   if (minimumOrderPromotions.length > 0) showMinOrderPromoReached(orderBy(minimumOrderPromotions, ['benefit.minimumOrderSize'], ['asc']), summary, dispatch)
  // }

  const cartList = mapCartProduct(purchasedItems, productCodesStarterKit, mandatory) || []
  if (cartUnavailableItems && cartUnavailableItems.product) {
    cartList.push(...mapUnavailableProducts(cartUnavailableItems.product))
  }

  const notIncorporation = cartList.filter(
    item => item.incorporationUid === null
  )

  const unavailableProducts = cartList.filter(
    item => !item.cartItemUid && !item.isAvailable
  ).filter(
    (obj, index, self) =>
      index === self.findIndex(
        item => item.code === obj.code
      )
  )
  const uniqueProducts = [...notIncorporation, ...unavailableProducts].reduce(
    (acc, item) => {
      if (acc[item.code]) {
        if (mergeItems) acc[item.code].quantity += item.quantity
      } else {
        acc[item.code] = item
      }
      return acc
    },
    {}
  )
  let finalList = Object.keys(uniqueProducts).map(key => uniqueProducts[key])
  const isIncorporation = cartList.filter(
    item => item.incorporationUid !== null && !unavailableProducts.filter(unavailableItem => Number(unavailableItem.code) === Number(item.code)).length
  )

  finalList = orderBy([...finalList, ...isIncorporation], ['index'], ['asc'])
  const giftsList =
    promotions != null
      ? mapGiftsProduct(promotions, giftItems, titlesPromotion)
      : []

  if (giftItems) {
    giftsList.push(...mapIncorporatedGifts(giftItems))
  }

  const totalItems = sumBy(
    finalList.filter(item => item.isAvailable),
    'quantity'
  )
  summary.totalItemsQuantity = totalItems
  const resume = mapCartResume(summary, shipping, giftItems, checkout)
  dispatch(setCartContent({ response, finalList }))

  dispatch(
    setCheckoutEventData({
      response,
      resume,
      giftsList,
      finalList
    })
  )

  const discountProfitability = findDiscountProfitability(promotions, finalList)

  dispatch({
    type: GET_BAG_ITEMS,
    payload: {
      cartUid: resume.cartUid,
      products: finalList,
      gifts: giftsList,
      selectedGifts: formatSelectedGifts(giftItems),
      totalPrice: summary.totalPriceWoDiscounts
        ? summary.totalPriceWoDiscounts
        : 0,
      cartTotalWithTaxes: summary.cartTotalWithTaxes
        ? summary.cartTotalWithTaxes
        : 0,
      taxAmount: summary.taxAmount ? summary.taxAmount : 0,
      quantityTotal: totalItems,
      minimumOrderSize,
      discountProfitability,
      shippingAmountWoDiscount: summary.shippingAmountWoDiscount,
      shippingAmountDiscount: shipping.deliveryMode.shippingAmountDiscount,
      shippingAmount: summary.shippingAmount,
      quantityDone: statusId < STATUS_CART_CALCULATED ? prevQuantityDone : quantityDone(giftsList)
    }
  })

  dispatch(replaceUnavailableGiftsOptions(getState()))

  dispatch({ type: UPDATE_CART_RESUME, payload: { resume } })
  dispatch({ type: UPDATE_CART_PROMOTIONS, payload: { promotions } })
  dispatch(setMine({ mineStatusId: summary.statusId }))
  dispatch(setComissionDispatch({
    discountProfitability,
    profitabilityTable,
    totalPoints: resume.totalPoints
  }))

  dispatch({
    type: UPDATE_SELECTED_GIFTS,
    payload: {
      giftItems
    }
  })

  dispatch(showLoad(false))
  if (itemAdd) {
    diffAddItem(
      dispatch,
      finalList,
      prevProducts,
      itemAdd,
      maxItemQuantity,
      isUpdate
    )
  }

  if (updatingItemsInExclusiveStep) {
    dispatch(getAllExclusiveTitles({ onlySarrafos: true }))
    dispatch(setUpdatingItemsInExclusiveStep(false))
  }
}

export const selectGiftCart = (promotionId, code, giftIndex) => (
  dispatch,
  getState
) => {
  const {
    bagReducer: { gifts }
  } = getState()

  dispatch({
    type: SELECT_GIFT_CART,
    payload: {
      promotionId,
      code,
      gifts,
      giftIndex
    }
  })
}
export const selectGiftOption = (promotionId, code, giftIndex) => (
  dispatch,
  getState
) => {
  const {
    bagReducer: { gifts }
  } = getState()

  dispatch({
    type: SELECT_GIFT_OPTION,
    payload: {
      promotionId,
      optionCode: code,
      gifts,
      giftIndex
    }
  })
}
export const updateGiftQuantity = giftOption => (
  dispatch,
  getState
) => {
  const {
    bagReducer: { gifts }
  } = getState()
  dispatch({
    type: UPDATE_GIFT_QUANTITY,
    payload: { ...giftOption, gifts }
  })
}

export const checkoutGiftCart = func => (dispatch, getState) => {
  dispatch(blockGetBag())
  const cart = new Cart()
  const {
    bagReducer: { gifts: giftsToCheckout }
  } = getState()
  const giftsNotAvailable = giftsToCheckout
    .filter(gift => !gift.isAvailable)
    .map(gift => ({
      code: gift.code,
      isAvailable: gift.isAvailable,
      alternateProducts: null,
      quantity: gift.quantity,
      pendingPromotion: gift.pendingPromotion,
      promotionId: gift.promotionId,
      promotionStepNumber: gift.promotionStepNumber,
      promotionVersionId: gift.promotionVersionId,
      itemTypeId: ITEM_TYPE_ID_GIFT
    }))
  if (!giftsNotAvailable.length) {
    return dispatch(addGiftsAvailable(func))
  }
  dispatch(showLoad(true))
  cart.resolver(
    () => {
      dispatch(showLoad(false))
      dispatch(addGiftsAvailable(func))
    },
    () => {
      dispatch(showLoad(false))
      dispatch(addGiftsAvailable(func))
    },
    cart.addGifts(giftsNotAvailable)
  )
}

const addGiftsAvailable = func => (dispatch, getState) => {
  const cart = new Cart()
  const {
    bagReducer: { gifts: giftsToCheckout },
    userSession: { commercialInfo }
  } = getState()
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
  const gifts = giftsToCheckout
    .filter(gift => !gift.fromIncorporation)
    .map(gift => getAlternativeGift(gift))
  const listGift = []
  for (const gift of gifts) {
    const {
      code,
      chooseFromList,
      isAvailable,
      pendingPromotion,
      promotionId,
      promotionStepNumber,
      promotionVersionId,
      alternateProducts,
      quantity,
      selected,
      options,
      distincts
    } = gift
    if (chooseFromList && options && options.length > 0) {
      if (distincts) {
        const option = options[selected]
        for (let i = 0; i < option.quantity; i++) {
          listGift.push({
            code: option.code,
            isAvailable: option.isAvailable,
            alternateProducts: option.alternateProducts,
            quantity: GIFT_OPTION_QUANTITY,
            pendingPromotion,
            promotionId,
            promotionStepNumber,
            promotionVersionId,
            itemTypeId: ITEM_TYPE_ID_GIFT
          })
        }
      } else {
        let giftOptions = options
        const allUnavailable = isAllUnavailable(options)
        if (allUnavailable) {
          const quantityToDistribute =
            options.length >= 1 ? options[0].quantity : options[1].quantity
          giftOptions = options.map(option => ({
            ...option,
            quantity: quantityToDistribute
          }))
        }
        const listOptions = giftOptions
          .filter(op => op.selected || allUnavailable)
          .map(op => ({
            code: op.code,
            isAvailable: op.isAvailable,
            alternateProducts: op.alternateProducts,
            quantity: op.quantity,
            pendingPromotion,
            promotionId,
            promotionStepNumber,
            promotionVersionId,
            itemTypeId: ITEM_TYPE_ID_GIFT
          }))
          .reduce((acc, item) => {
            const items = new Array(item.quantity)
            items.fill(item)
            acc.push(...items)
            return acc
          }, [])
          .map(gift => ({ ...gift, quantity: GIFT_OPTION_QUANTITY }))
        listGift.push(...listOptions)
      }
    } else {
      listGift.push({
        code,
        isAvailable,
        alternateProducts,
        quantity,
        pendingPromotion,
        promotionId,
        promotionStepNumber,
        promotionVersionId,
        itemTypeId: ITEM_TYPE_ID_GIFT
      })
    }
  }
  const listTemp = listGift.reduce((acc, gift) => {
    const filteredPromo = listGift.reduce((sum, giftFilter) => {
      if (
        giftFilter.code === gift.code &&
        giftFilter.promotionId === gift.promotionId &&
        giftFilter.promotionStepNumber === gift.promotionStepNumber &&
        giftFilter.promotionVersionId === gift.promotionVersionId
      ) {
        return giftFilter.quantity + sum
      }
      return sum
    }, 0)
    acc.push({
      ...gift,
      quantity: filteredPromo
    })
    return acc
  }, [])
  const resultGifts = []
  listTemp.forEach((gift) => {
    const temp = resultGifts.find(
      giftTemp =>
        giftTemp.code === gift.code &&
        giftTemp.promotionId === gift.promotionId &&
        giftTemp.promotionStepNumber === gift.promotionStepNumber &&
        giftTemp.promotionVersionId === gift.promotionVersionId
    )
    !temp && resultGifts.push(gift)
  })

  if (!resultGifts.length) {
    dispatch(showLoad(false))
    dispatch(allowGetBag())
    func()
    return
  }

  dispatch(showLoad(true))

  cart.resolver(
    (response) => {
      if (!response) {
        dispatch(showLoad(false))
        func()
        return
      }
      refreshCartContent(response, dispatch, getState)
      dispatch(allowGetBag())
      dispatch(showLoad(false))
      func()
    },
    (error) => {
      dispatch(allowGetBag())
      dispatch(showLoad(false))

      if (cotasError(error.errors) && !hasPendingPromotion(error)) {
        return func()
      }
      if (
        error.errors &&
        error.errors.length > 0 &&
        allKnownErrors(error.errors)
      ) {
        func()
      } else {
        verifyError(dispatch, error)
      }
    },
    cart.addGifts(resultGifts, profileIncorporations)
  )
}
function allKnownErrors(errors) {
  const knownErrors = errors.filter(
    error =>
      error.code === ERROR_CODE_UNAVAILABLE_GIFT ||
      error.code === ERROR_CODE_INVALID_QUANTITY ||
      error.code === ERROR_CODE_NOT_FOUND_INVENTORY ||
      error.code === ERROR_CODE_UNAVAILABLE_GIFT_SIMPLE ||
      error.code === ERROR_CODE_NOT_FOUND_GIFT
  )
  return knownErrors.length === errors.length
}

const cotasError = (errors) => {
  const knownErrors = errors.filter(
    error =>
      error.code === ERROR_CODE_NOT_ENOUGH_QUOTA_CN ||
      error.code === ERROR_CODE_NOT_ENOUGH_QUOTA_EC
  )
  return knownErrors.length
}
const hasPendingPromotion = (response) => {
  const { promotions } = response
  if (promotions.length) {
    const hasPending = promotions.find(item => item.pendingPromotion === true)
    return hasPending != null
  }
  return false
}

function getAlternativeGift(gift) {
  if (
    !gift.isAvailable &&
    gift.alternateProducts &&
    gift.alternateProducts.length > 0
  ) {
    const availableAlternatives = gift.alternateProducts.filter(
      product => product.isAvailable
    )
    if (availableAlternatives.length > 0) {
      return {
        ...availableAlternatives[0],
        pendingPromotion: gift.pendingPromotion,
        promotionId: gift.promotionId,
        promotionStepNumber: gift.promotionStepNumber,
        promotionVersionId: gift.promotionVersionId,
        cartItemUid: gift.cartItemUid,
        quantity: gift.quantity
      }
    }
  }
  return gift
}

export const removeItem = item => (dispatch, getState) => {
  const isDiscreteLoad = true
  dispatch(blockGetBag())
  dispatch(showLoad(true, isDiscreteLoad))
  const { userSession: { commercialInfo } } = getState()
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
  const cart = new Cart()
  item &&
    item.cartItemUid &&
    cart.resolver(
      (response) => {
        dispatch(allowGetBag())
        refreshCartContent(response, dispatch, getState)
        showUpdatedItemToast(dispatch)
      },
      (error) => {
        dispatch(allowGetBag())
        dispatch(showLoad(false, isDiscreteLoad))
        verifyError(dispatch, error, false, FAIL_CART_REMOVE)
      },
      cart.deleteProduct(null, [item.cartItemUid], profileIncorporations)
    )
}

export const removeAllItems = () => (dispatch, getState) => {
  const ids = getArrayItemsUIDs(getState())
  if (ids && ids.length > 0) {
    const { userSession: { commercialInfo } } = getState()
    const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
    dispatch(blockGetBag())
    dispatch(showLoad(true))
    const cart = new Cart()
    cart.resolver(
      (response) => {
        dispatch(clearUnavailableProducts())
        dispatch(allowGetBag())
        refreshCartContent(response, dispatch, getState)
      },
      (error) => {
        dispatch(allowGetBag())
        dispatch(showLoad(false))
        verifyError(dispatch, error, false)
      },
      cart.deleteProduct(null, ids, profileIncorporations)
    )
  }
}

export const replaceUnavailableGiftsOptions = () => (dispatch, getState) => {
  const {
    bagReducer: { gifts },
  } = getState()

  const replacedGiftList = gifts.map((row) => {
    if (row.chooseFromList) {
      const [newOptions, unavailableProducts] = row.options.reduce(setAvailableGiftOption, [[], []])
      const unavailableAlternativeProducts = row.options.reduce(getUnavailableAlternativeProducts, [])

      return {
        ...row,
        options: newOptions,
        unavailableProducts,
        unavailableAlternativeProducts
      }
    }
    return row
  })

  dispatch({
    type: REPLACE_UNAVAILABLE_GIFT_OPTIONS,
    payload: replacedGiftList
  })
}

export const verifyUnavailableGiftsAndAddToCart = () => (dispatch, getState) => {
  const {
    bagReducer: { gifts },
    userSession: { commercialInfo },
  } = getState()
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)

  if (gifts && gifts.length > 0) {
    const [unavailableGiftsList, unavailableAlternativeProducts] = gifts.reduce((acc, gift) => {
      if (gift.unavailableProducts) {
        acc[0].push(...gift.unavailableProducts)
      }

      if (gift.unavailableProducts) {
        acc[1].push(...gift.unavailableAlternativeProducts)
      }

      return acc
    }, [[], []])

    if (unavailableGiftsList.length > 0) {
      addUnavailableGiftToCart(unavailableGiftsList, unavailableAlternativeProducts, profileIncorporations) // 1
    }
  }
}

export const clearGifts = () => (dispatch, getState) => {
  const cart = new Cart()
  dispatch(showLoad(true))
  const { userSession: { commercialInfo } } = getState()
  const profileIncorporations = commercialInfo.incorporations.map(({ productItem, ...incorporation }) => incorporation)
  cart.resolver(
    () => {
      dispatch(showLoad(false))
      dispatch(getBag())
      dispatch({ type: RENEW_GIFTS })
      dispatch({ type: RENEW_GIFTS_BAG })
    },
    (error) => {
      dispatch(showLoad(false))
      verifyError(dispatch, error)
    },
    cart.clearGiftItems(profileIncorporations)
  )
}

const findDiscountProfitability = (promotions, finalList) => {
  const filtered = promotions.filter(
    item => item.benefit.benefitTypeId === BenefitType.DIAMOND
  )
  if (!filtered || filtered.length === 0 || finalList.length === 0) return 0
  const discounts = []
  finalList.map((prodBag) => {
    filtered.map((promo) => {
      promo.benefit.productCodes.map((products) => {
        if (prodBag.code == products.productCode) {
          discounts.push(products.discount)
        }
      })
    })
  })
  return 0
}

export const profileGetAddress = () => (dispatch, getState) => {
  const {
    userSession: { commercialInfo: { orderProfile } },
    taxesReducer: { taxParams: { taxesParameters } },
  } = getState()

  const taxContext = { orderProfile, taxesParameters }

  const profile = new Profile()
  profile.resolver(
    (response) => {
      if (response.data && response.data.profileGetAddress) {
        dispatch(setAddressesAndDeliveryModes(response.data.profileGetAddress, 'HOME'))
      }
    },
    (error) => {
      verifyError(dispatch, error)
    },
    profile.profileGetAddress(taxContext)
  )
}

export const profileGetPaymentCondition = () => async (dispatch, getState) => {
  const { userSession: { commercialInfo: { orderProfile: { paymentConditions } } },
    dataLayerReducer: { cartContent: { response: { promotions } } } } = getState()

  const paymentConditionCodes = []
  paymentConditions.forEach(
    condition => condition.conditions.forEach(
      cond => paymentConditionCodes.push(cond.conditionCode.toString())
    )
  )

  const conditionCodeByPromotion = promotions.filter(promotion => promotion.benefit.paymentConditionCode)
    .map(promotion => promotion.benefit.paymentConditionCode.toString())

  const conditionCodes = [...paymentConditionCodes, ...conditionCodeByPromotion]

  if (conditionCodes.length) {
    const profile = new Profile()
    profile.resolver(
      (response) => {
        if (response.data) {
          dispatch({ type: PAYMENT_CONDITION, payload: response.data?.profileGetPaymentCondition?.paymentConditions })
        }

        if (response.errors) { verifyError(dispatch, response.errors) }
      },
      (error) => {
        verifyError(dispatch, error)
      },
      await profile.profileGetPaymentCondition(conditionCodes)
    )
  }
}

export const selectPaymentCondition = condition => dispatch =>
  dispatch({
    type: SELECT_PAYMENT_CONDITION,
    condition
  })

export const getPendingPayments = () => (dispatch) => {
  const order = new Order()
  order.resolver(
    (response) => {
      if (response) {
        dispatch(setPendingPayments(response))
      }
    },
    (error) => {
      verifyError(dispatch, error)
    },
    order.getPendingPaymentOrders()
  )
}

export const setPendingPayments = pendingPayments => dispatch =>
  dispatch({
    type: SET_PENDING_PAYMENTS,
    payload: { pendingPayments, hasPendingPayments: pendingPayments?.length > 0 }
  })

export const setHasPendingPayments = hasPendingPayments => dispatch =>
  dispatch({
    type: SET_HAS_PENDING_PAYMENTS,
    payload: hasPendingPayments
  })

export const splitProductsByCompanBrand = (productsList) => {
  const result = productsList.reduce((acc, item) => {
    const code = get(item, 'companyBrand.code')

    if (!code) return acc

    acc[code] = acc[code] || []
    acc[code].push(item)

    return acc
  }, {})

  return result
}


export const setAlertMinOrderSizeNotReached = (body) => (dispatch, getState) => {
  const translate = new Translate()

  const state = getState()
  const minOrderSizeList = get(state, 'cartReducer.resume.profitability.minimumOrderSize', [])

  const labels = []

  minOrderSizeList.forEach(item => {
    if (!item.isReached) {
      const companyBrandIds = get(item, 'companyBrandIds', []) || []
      
      companyBrandIds.forEach(({ description }) => labels.push(description))
    }
  })

  const label = labels.join(` ${translate.byKey('and')} `)

  const body = translate.byKeyAndParamValue('minOrderSizeNotReached', label)

  dispatch(setAlerts(getAlertMinOrderSize(body)))
}