import Translate from 'natura-commons/translate'

import { TEST_PROFILE_ID } from 'commons/constants/profile'
import { formatDate } from 'commons/helpers/date'

const DATE_EN_EU = 'yyyy-MM-dd'

const translate = new Translate()

export const initialState = {
  errorMessage: '',
  errorLogin: false,
  logged: false,
  goHome: false,
  profileChanged: '',
  expiresIn: null,
  detailsPerson: null,
  xAppTokenCommercialInfo: null,
  authorizationToken: null,
  userIdentifier: null,
  testProfileParameters: null,
  credentials: {
    userName: '',
    user: '',
    pass: ''
  }
}

const data = {
  token: '',
  ttl: 0,
  person_details: {
    personId: null,
    countryCode: null,
    canImpersonate: false,
    businessRelationships: [],
    isBlocked: false,
    userName: null,
    firstName: null,
    lastName: null,
    name: null,
    emails: [],
    roles: [],
    blocks: [],
    functionsRoles: [],
    roleSelect: null
  },
  person_comercial_info: {
    countryCode: null,
    companyId: null,
    userId: null,
    personId: null,
    address: [
      {
        id: null,
        zipCode: null,
        number: null,
        complement: null,
        extraInfo: null,
        referencePlace: null,
        addressType: {
          id: null,
          description: null
        }
      }
    ],
    orderProfile: {
      systemId: null,
      channelId: null,
      businessModelId: null,
      roles: [
        {
          businessModelId: null,
          roleId: null,
          functionId: null,
          beginDate: null,
          endDate: null
        }
      ],
      orderTypeId: null,
      orderSizeTypeId: null,
      roundTypeId: null,
      decimalPlaces: null,
      currencyCode: null,
      structures: [
        {
          orderLevel: null,
          structureCode: null
        }
      ],
      growthPlan: [
        {
          growthPlanId: null,
          growthPlanLevelId: null
        }
      ],
      currentCycle: {
        cycleCode: null,
        dateStart: null,
        dateEnd: null,
        subcycle: {
          subcycleCode: null,
          dateStart: null,
          dateEnd: null
        }
      },
      maxOrder: null,
      starterKit: {
        step: null,
        mandatory: false,
        show: false,
        products: []
      },
      paymentConditions: [
        {
          paymentId: null,
          paymentDescription: null,
          bankName: null,
          cardBrandName: null,
          conditions: [
            {
              conditionCode: null,
              conditionDescription: null,
              installmentsQuantity: null,
              conditionRate: null,
              allowPayDebtBlock: false,
              allowPayCreditBlock: false
            }
          ]
        }
      ],
      profitabilityTable: [
        {
          from: null,
          to: null,
          profitability: null
        }
      ],
      currentDate: null,
      orderCycle: {
        cycleCode: null,
        dateStart: null,
        dateEnd: null,
        subcycle: {
          subcycleCode: null,
          dateStart: null,
          dateEnd: null
        }
      },
      orderDate: null,
      kpiCycle: {
        cycleCode: null,
        subcycleCode: null
      },
      minimumOrderSize: null,
      maxItemQuantity: null,
      distributionCenterId: null,
      inventoryTypeId: null,
      quotaCommitmentStepId: null,
      inventoryCommitmentStepId: null,
      cartTTL: null,
      availabilityTTL: null,
      productTTL: null
    },
    incorporations: null,
    credit: {
      limit: null,
      available: null,
      additional: null,
      validateCredits: true
    },
    debit: {
      overdueDebits: null
    },
    training: [
      {
        name: null,
        blocks: false
      }
    ],
    isBlocked: false,
    needsApproval: false,
    blockReason: [
      {
        origin: null,
        code: null,
        description: null
      }
    ],
    approvalReason: [
      {
        origin: null,
        code: null,
        description: null
      }
    ]
  },
  testProfileParameters: {
    businessModels: [],
    channels: [],
    growthPlans: [],
    systems: []
  }
}

export const mapPersonData = responseProfile => {
  const { personData, userProfile, hasPrePaidPayment } = responseProfile
  const result = {
    personId: personData.personId,
    hasPrePaidPayment,
    canImpersonate: userProfile.canImpersonateOthers,
    businessRelationships: personData.businessRelationships,
    currentCycle: personData.currentCycle,
    orderCycle: personData.orderCycle,
    name: userProfile.userName && userProfile.userName,
    firstName: userProfile.userName && userProfile.userName.split(' ')[0],
    enableTextSearch: personData.enableTextSearch,
    shouldOptimize: personData.shouldOptimize,
    lastName:
      userProfile.userName && userProfile.userName.replace(/^\S+\s*/g, ''),
    isAttendant:
      userProfile.isAttendant === null ? false : userProfile.isAttendant,
    isGdn: userProfile.isGdn === null ? false : userProfile.isGdn,
    isLeader: userProfile.isLeader || false,
    emails: getEmails(personData),
    roles: getRoles(personData),
    blocks: getBlocks(personData),
    functionsRoles: getRoles(personData)
  }
  return result
}

export function mapLogin(responseProfile) {
  const { personData, userProfile, hasPrePaidPayment } = responseProfile
  sessionStorage.setItem('personCode', personData.personCode)
  const result = {
    ...data,
    person_details: {
      companyId: responseProfile.companyId,
      countryCode: responseProfile.countryCode,
      personId: personData.personId,
      personCode: personData.personCode,
      hasPrePaidPayment,
      canImpersonate: userProfile.canImpersonateOthers,
      businessRelationships: personData.businessRelationships,
      currentCycle: personData.currentCycle,
      orderCycle: personData.orderCycle,
      name: userProfile.userName && userProfile.userName,
      enableTextSearch: personData.enableTextSearch,
      shouldOptimize: personData.shouldOptimize,
      firstName: userProfile.userName && userProfile.userName.split(' ')[0],
      lastName:
        userProfile.userName && userProfile.userName.replace(/^\S+\s*/g, ''),
      isAttendant:
        userProfile.isAttendant === null ? false : userProfile.isAttendant,
      isGdn: userProfile.isGdn === null ? false : userProfile.isGdn,
      isLeader: userProfile.isLeader || false,
      emails: getEmails(personData),
      roles: getRoles({ ...personData, canTest: userProfile.canTest }),
      blocks: getBlocks(personData),
      functionsRoles: getRoles(personData)
    }
  }
  return result
}

function getEmails(response) {
  const emails = []
  if (!response.emails) {
    return
  }
  for (const email of response.emails) {
    const emailresult = {
      type: email.typeId,
      email: email.email
    }
    emails.push(emailresult)
  }
  return emails
}

function getRoles(response) {
  const rolesArray = []
  if (!response.roles) {
    return
  }
  for (const role of response.roles) {
    const { businessRelationships } = response
    if (verifyAddRoleArray(businessRelationships, rolesArray, role)) {
      rolesArray.push(getObjectRole(role))
    }
  }
  if (response.canTest) {
    rolesArray.push({
      businessModelId: TEST_PROFILE_ID,
      id: TEST_PROFILE_ID,
      description: translate.byKey('testProfile').toUpperCase(),
      functionId: TEST_PROFILE_ID,
      businessModelName: translate.byKey('testProfile').toUpperCase(),
      beginDate: null,
      endDate: null
    })
  }
  return rolesArray
}

const verifyAddRoleArray = (businessRelationships, rolesArray, role) => {
  const result =
    businessRelationships &&
    businessRelationships.find(
      item => item.businessModelId === role.businessModelId
    )
  const itensIn = rolesArray.find(
    item => item.businessModelId === role.businessModelId
  )

  return (
    result &&
    !itensIn &&
    (role.endDate == null || role.endDate > formatDate(new Date(), DATE_EN_EU))
  )
}

const getObjectRole = role => ({
  businessModelId: role.businessModelId,
  id: role.businessModelId,
  description: role.description,
  functionId: role.functionId,
  businessModelName: role.businessModelName,
  beginDate: role.startDate,
  endDate: role.endDate
})

function getBlocks(response) {
  const blocksArray = []
  if (!response.blockReason) {
    return
  }
  for (const block of response.blockReason) {
    const blockResult = {
      code: block.code,
      description: block.description,
      origin: block.origin
    }
    blocksArray.push(blockResult)
  }

  return blocksArray
}


export const mapTestProfileParameters = responseProfile => ({
  ...data.testProfileParameters,
  businessModels: mapBusinessModels(responseProfile.businessModels),
  channels: mapChannels(responseProfile.channels),
  growthPlans: mapGrowthPlans(responseProfile.growthPlans),
  systems: mapSystems(responseProfile.systems)
})

const mapBusinessModels = businessModels => {
  const result = []
  businessModels.map(businessModel => {
    result.push({
      businessModelId: businessModel.businessModelId,
      businessModelName: businessModel.businessModelName,
      functions: mapBusinessModelsFunctions(businessModel.functions),
      structureLevels: mapBusinessModelsStructureLevels(
        businessModel.structureLevels
      ),
      segmentation: businessModel.segmentations
    })
  })
  return result
}

const mapChannels = channels => {
  const result = []
  channels.map(channel => {
    result.push({
      channelDescription: channel.channelDescription,
      channelId: channel.channelId
    })
  })
  return result
}

const mapGrowthPlans = growthPlans => {
  const result = []
  growthPlans.map(growthPlan => {
    result.push({
      planId: growthPlan.planId,
      description: growthPlan.description,
      planLevelId: growthPlan.planLevelId
    })
  })
  return result
}

const mapSystems = systems => {
  const result = []
  systems.map(system => {
    result.push({
      systemDescription: system.systemDescription,
      systemId: system.systemId
    })
  })
  return result
}

const mapBusinessModelsFunctions = functions => {
  const result = []
  functions.map(functionMap => {
    result.push({
      functionId: functionMap.functionId,
      functionName: functionMap.functionName,
      role: mapBusinessModelsRoles(functionMap.role)
    })
  })
  return result
}

const mapBusinessModelsStructureLevels = structureLevels => {
  const result = []
  structureLevels.map(structureLevel => {
    result.push({
      structureLevelId: structureLevel.structureLevelId,
      structureLevelName: structureLevel.structureLevelName,
      structures: mapBusinessStructures(structureLevel.structures)
    })
  })
  return result
}

const mapBusinessModelsRoles = roles => {
  const result = []
  result.push({
    roleId: roles.roleId,
    roleName: roles.roleName
  })
  return result
}

const mapBusinessStructures = structures => {
  const result = []
  structures.map(structure => {
    result.push({
      structureId: structure.structureId,
      structureName: structure.structureName
    })
  })
  return result
}
