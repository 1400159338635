const commomTheme = (primaryColor, invertSelectedColorBrand) => ({
  shape: {
    borderRadius: 4,
    badgeBorderRadius: 100
  },
  avatarSizes: {
    tiny: {
      fontSize: '.625rem',
      size: 24
    },
    small: {
      fontSize: '.875rem',
      size: 32
    },
    standard: {
      fontSize: '1rem',
      size: 40
    },
    large: {
      fontSize: '1rem',
      size: 48
    },
    huge: {
      fontSize: '1.5rem',
      size: 80
    }
  },
  buttonSizes: {
    small: {
      paddingTop: 0,
      paddingRight: 8,
      paddingBottom: 0,
      paddingLeft: 8,
      height: 32
    },
    medium: {
      height: 40,
      paddingTop: 0,
      paddingRight: 12,
      paddingBottom: 0,
      paddingLeft: 12
    },
    large: {
      paddingTop: 0,
      paddingRight: 16,
      paddingBottom: 0,
      paddingLeft: 16,
      height: 48
    }
  },
  iconSizes: {
    micro: 16,
    tiny: 24,
    small: 32,
    standard: 48,
    medium: 64,
    large: 72,
    largex: 96,
    largeX: 96,
    largexx: 128,
    largeXX: 128,
    huge: 144,
    hugex: 192,
    hugeX: 192,
    hugexx: 256,
    hugeXX: 256
  },
  sizes: {
    semix: 40,
    mediumx: 56,
    largex: 72,
    largexx: 80,
    largexxx: 88,
    hugex: 128,
    hugexx: 144,
    hugexxx: 192,
    veryhuge: 256,
    none: 0,
    micro: 4,
    tiny: 8,
    small: 16,
    standard: 24,
    semi: 32,
    semiX: 40,
    medium: 48,
    mediumX: 56,
    large: 64,
    largeX: 72,
    largeXX: 80,
    largeXXX: 88,
    huge: 96,
    hugeX: 128,
    hugeXX: 144,
    hugeXXX: 192,
    veryHuge: 256
  },
  invertSelectedColorBrand,
  spacing: 8,
  palette: {
    action: {
      disabled: 'rgba(0, 0, 0, 0.24)'
    },
    primary: {
      main: primaryColor.main,
      light: primaryColor.light,
      dark: primaryColor.dark,
      contrastText: primaryColor.contrastText,
      color: primaryColor.color,
      default: '#F6BE0F'
    },
    secondary: {
      main: '#e9e9e9',
      light: '#ffffff',
      dark: '#b7b7b7',
      contrastText: '#333333'
    },
    error: {
      main: '#e74627',
      contrastText: '#ffffff'
    },
    success: {
      main: '#569a32',
      contrastText: '#ffffff'
    },
    background: {
      paper: '#ffffff',
      default: '#fafafa',
      paperContrastText: '#333333',
      defaultContrastText: '#333333'
    },
    text: {
      primary: '#333333',
      secondary: '#777777',
      disabled: '#bbbbbb',
      hint: '#bbbbbb'
    },
    complementary: {
      highlight: '#000000',
      link: '#227bbd',
      linkContrastText: '#ffffff',
      warning: '#f6be0f',
      warningContrastText: '#333333'
    }
  },
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontFamilyBrand1: '"Helvetica Now Display"',
    fontFamilyBrand2: '"Helvetica Now Text"',
    fontFamilyBrand3: null,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 96,
      fontWeight: 400,
      letterSpacing: 0
    },
    h2: {
      fontSize: 60,
      fontWeight: 400,
      letterSpacing: 0
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      letterSpacing: 0
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      letterSpacing: 0.27
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: 0
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.24
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0.13
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.11
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.51
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.22
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 1.23,
      lineHeight: 1.5
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.38
    },
    overline: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 1.92
    }
  },
  shadows: [
    'none',
    '0 1px 1px 0 rgba(0, 0, 0, 0.14)',
    '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    '0 3px 4px 0 rgba(0, 0, 0, 0.14)',
    '0 4px 5px 0 rgba(0, 0, 0, 0.14)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0 6px 10px 0 rgba(0, 0, 0, 0.14)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0 8px 10px 0 rgba(0, 0, 0, 0.14)',
    '0 9px 12px 0 rgba(0, 0, 0, 0.14)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0 12px 17px 0 rgba(0, 0, 0, 0.14)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0 16px 24px 0 rgba(0, 0, 0, 0.14)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0 24px 38px 0 rgba(0, 0, 0, 0.14)'
  ],
  overrides: {
    MuiButton: {
      outlinedPrimary: {
        color: '#333333'
      },
      outlinedSecondary: {
        color: '#333333',
        '&.Mui-disabled': {
          borderColor: '#0000003D'
        }
      },
      sizeSmall: {
        paddingTop: 0,
        paddingRight: 8,
        paddingBottom: 0,
        paddingLeft: 8,
        height: 32,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.11
      },
      root: {
        height: 40,
        paddingTop: 0,
        paddingRight: 12,
        paddingBottom: 0,
        paddingLeft: 12,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.11
      },
      sizeLarge: {
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 0,
        paddingLeft: 16,
        height: 48,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.11
      },
      text: {
        padding: null,
        '&.Mui-disabled': {
          color: '#0000003D',
          borderColor: '#0000003D'
        }
      },
      contained: {
        '&.Mui-disabled': {
          color: '#0000003D',
          backgroundColor: '#00000014'
        }
      },
      outlined: {
        padding: null,
        borderColor: '#0000003D',
        '&.Mui-disabled': {
          color: '#0000003D',
          borderColor: '#0000003D'
        }
      }
    },
    MuiAvatar: {
      colorDefault: {
        color: '#333333'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px',
        color: '#333333'
      },
      sizeSmall: {
        padding: '4px'
      }
    },
    MuiExpansionPanel: {
      root: {
        border: 'transparent 1px solid',
        '&$expanded': {
          borderColor: primaryColor.main
        },
        '&$disabled': {
          backgroundColor: '#ffffff',
          opacity: 0.32
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        alignItems: 'flex-start',
        marginTop: '8px',
        '&$disabled': {
          opacity: 1
        }
      }
    },
    MuiToolbar: {
      root: {
        padding: '0 16px',
        '@media (min-width: 600px)': {
          padding: '0 16px'
        }
      },
      gutters: {
        padding: '0 16px',
        '@media (min-width: 600px)': {
          padding: '0 16px'
        }
      },
      regular: {
        minHeight: '56px',
        '@media (min-width: 600px)': {
          minHeight: '56px'
        }
      },
      dense: {
        minHeight: '56px',
        '@media (min-width: 600px)': {
          minHeight: '56px'
        }
      }
    },
    MuiListItem: {
      root: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(51, 51, 51, 0.04)'
        },
        '&$selected': {
          backgroundColor: 'rgba(233, 233, 233, 0.16)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          },
          '&:focus': {
            outline: 'none'
          }
        },
        '&.Mui-disabled': {
          opacity: 0.32
        }
      },
      gutters: {
        '&:focus': {
          outline: 'none'
        }
      },
      button: {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      }
    },
    MuiBadge: {
      badge: {
        padding: '0 4px'
      }
    },
    MuiTab: {
      root: {
        '&$disabled': {
          opacity: 0.24
        }
      },
      textColorInherit: {
        '&$disabled': {
          opacity: 0.24
        }
      },
      wrapper: {
        '& > *:first-child': {
          marginBottom: '2px !important'
        }
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: '#333333',
        fontSize: 14,
        '& $avatar': {
          backgroundColor: 'rgba(0, 0, 0, 0.24)',
          color: '#333333'
        },
        '&$disabled': {
          opacity: 0.4
        }
      },
      clickableColorPrimary: {
        '&:hover': {
          backgroundColor: primaryColor.main
        }
      },
      clickableColorSecondary: {
        '&:hover': {
          backgroundColor: '#e9e9e9'
        }
      },
      outlined: {
        borderColor: 'rgba(0, 0, 0, 0.12)',
        color: '#000000'
      },
      outlinedPrimary: {
        color: '#000000'
      },
      outlinedSecondary: {
        color: '#000000'
      },
      icon: {
        fontSize: '24px'
      },
      iconSmall: {
        width: '16px',
        height: '16px',
        fontSize: '16px',
        marginTop: '1px'
      },
      deleteIcon: {
        width: '24px',
        height: '24px',
        fontSize: '24px',
        color: 'rgba(0, 0, 0, 0.8)',
        '&:hover': {
          color: '#000000'
        }
      },
      deleteIconSmall: {
        width: '16px',
        height: '16px',
        fontSize: '16px'
      },
      deleteIconColorPrimary: {
        color: 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
          color: '#ffffff'
        }
      },
      deleteIconColorSecondary: {
        color: 'rgba(51, 51, 51, 0.8)',
        '&:hover': {
          color: '#333333'
        }
      }
    },
    MuiTextField: {
      '& .MuiInputLabel': {
        marginTop: '20px'
      }
    },
    MuiInputBase: {
      root: {
        color: '#333333',
        '&:hover': {
          boxShadow: '#777777 0 0 0 1px'
        },
        '&.MuiError': {
          boxShadow: '#e74627 0 0 0 2px',
          '&:hover': {
            boxShadow: '#777777 0 0 0 1px'
          },
          '&.Mui-focused': {
            boxShadow: '#e74627 0 0 0 2px'
          }
        },
        '&.MuiSuccess': {
          boxShadow: '#569a32 0 0 0 1px',
          '&:hover': {
            boxShadow: '#777777 0 0 0 1px'
          },
          '&.Mui-focused': {
            boxShadow: '#569a32 0 0 0 1px'
          }
        },
        '&.Mui-focused:not(.MuiError):not(.MuiSuccess)': {
          boxShadow: '#6221BD 0 0 0 2px'
        },
        '&.MuiFilled:not(.Mui-focused)': {
          boxShadow: '#333333 0 0 0 1px',
          '&:hover': {
            boxShadow: '#777777 0 0 0 1px'
          }
        },
        '&.MuiInput-underline:before': {
          borderBottom: 'none'
        },
        '&.MuiInput-underline.Mui-disabled:before': {
          borderBottomStyle: 'none'
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: 'none'
        },
        '&.MuiInput-underline:after': {
          borderBottom: 'none'
        },
        '&:hover.Mui-disabled': {
          boxShadow: '#bbbbbb 0 0 0 1px'
        },
        padding: '12px',
        borderRadius: '4px',
        boxShadow: '#bbbbbb 0 0 0 1px'
      },
      input: {
        color: '#333333',
        '&.Mui-disabled': {
          opacity: 0.24
        }
      }
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        right: '0'
      },
      positionEnd: {
        marginRight: 8
      }
    },
    MuiInputLabel: {
      formControl: {
        transform: 'none',
        marginTop: '-4px'
      },
      shrink: {
        transform: 'none',
        marginTop: '-4px'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
        '&.Mui-focused': {
          fontSize: 14,
          color: '#777777'
        },
        '&.Mui-error': {
          color: '#e74627'
        }
      },
      formControl: {
        transform: 'none',
        marginTop: '-4px'
      }
    },
    MuiButtonGroup: {
      groupedContainedPrimary: {
        '&:not(:last-child)': {
          borderColor: '#693227',
          '&.Mui-disabled': {
            borderColor: '#0000003D'
          }
        }
      },
      groupedContainedSecondary: {
        '&:not(:last-child)': {
          borderColor: '#b7b7b7',
          '&.Mui-disabled': {
            borderColor: '#0000003D'
          }
        }
      },
      groupedContainedHorizontal: {
        '&:not(:last-child)': {
          borderRightColor: '#0000001F',
          '&.Mui-disabled': {
            borderColor: '#0000003D'
          }
        }
      },
      groupedTextPrimary: {
        '&:not(:last-child).Mui-disabled': {
          borderColor: '#0000003D'
        }
      },
      groupedTextSecondary: {
        '&:not(:last-child).Mui-disabled': {
          borderColor: '#0000003D'
        }
      },
      groupedTextHorizontal: {
        '&:not(:last-child)': {
          borderRightColor: '#0000001F',
          '&.Mui-disabled': {
            borderColor: '#0000003D'
          }
        }
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        },
        '&.Mui-disabled': {
          color: '#bbbbbb'
        }
      }
    },
    MuiAlert: {
      root: {
        width: 278,
        padding: 16,
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.51
      },
      icon: {
        marginRight: 8
      },
      message: {
        padding: '5px 0'
      },
      standardSuccess: {
        color: '#333333',
        backgroundColor: 'rgba(86, 154, 50, 0.16)'
      },
      outlinedSuccess: {
        color: '#333333'
      },
      standardError: {
        color: '#333333',
        backgroundColor: 'rgba(231, 70, 39, 0.16)'
      },
      outlinedError: {
        color: '#333333'
      },
      standardWarning: {
        color: '#333333',
        backgroundColor: 'rgba(252, 196, 51, 0.16)',
        '& .MuiAlert-icon': {
          color: '#f6be0f'
        }
      },
      outlinedWarning: {
        color: '#333333',
        borderColor: '#f6be0f',
        '& .MuiAlert-icon': {
          color: '#f6be0f'
        }
      },
      filledWarning: {
        color: '#333333',
        backgroundColor: '#f6be0f'
      },
      standardInfo: {
        color: '#333333',
        backgroundColor: 'rgba(34, 123, 189, 0.16)',
        '& .MuiAlert-icon': {
          color: '#227bbd'
        }
      },
      outlinedInfo: {
        color: '#333333',
        borderColor: '#227bbd',
        '& .MuiAlert-icon': {
          color: '#227bbd'
        }
      },
      filledInfo: {
        backgroundColor: '#227bbd'
      }
    },
    MuiAlertTitle: {
      root: {
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: 0.24
      }
    },
    MuiTableSortLabel: {
      icon: {
        marginLeft: 8
      }
    }
  }
})

export { commomTheme }
