import React from 'react'
import Translate from 'natura-commons/translate'

const translate = new Translate()
const context = Object.assign(translate, {
  translate,
  t: translate,
})

export const TranslateContext = React.createContext(context)

export const TranslateProvider = React.memo(props => (
  <TranslateContext.Provider {...props} value={context} />
))
