import { getLeverage } from 'redux/actions/parameters/parameterAction'
import { navigateTo } from 'redux/actions/router/routerAction'
import { HOME } from 'redux/actions/router/constants'
import { getWindowNotFoundAlert } from 'commons/presenter/order/home/presenter'
import { selectedDisplay } from 'dataLayer/dataLayerPush'
import { structureSelectedDisplay } from 'dataLayer/structuring/listData'
import { setVtrNavigation } from 'redux/actions/dataLayer/dataLayerAction'
import { setAlerts } from 'redux/actions/alerts/alertsAction'

import { SET_COMPANY_BRANDS } from 'redux/actions/constants'

import { sortBy } from 'lodash'

export const redirectToWindowLevelOne = showcase => (dispatch, getState) => {
  const { companyBrandReducer: { menu } } = getState()
  const menuItemToSelect = menu.find(item => item.id === showcase.id)

  if (menuItemToSelect) {
    dispatch(getLeverage(showcase.id, null, menuItemToSelect.subMenu, true))
    dispatch(navigateTo(HOME))
  } else {
    dispatch(setAlerts([getWindowNotFoundAlert()]))
  }

  selectedDisplay(structureSelectedDisplay('highlight', showcase.title))
  dispatch(setVtrNavigation('highlight'))
}

export const mapShowCaseToCompanyBrand = companyBrands => (dispatch, getState) => {
  const { companyBrandReducer: { menu } } = getState()

  const sortCompanyBrands = sortBy(companyBrands, brand => brand.code)

  const mappedCompanyBrand = sortCompanyBrands.map((brand) => {
    const showCase = menu.find(item => item.companyBrand === brand.code)

    return {
      ...brand,
      selected: false,
      showCase
    }
  })

  dispatch({ type: SET_COMPANY_BRANDS, companyBrands: mappedCompanyBrand })
}

export const setSelectedHomeBrand = companyBrandId => (dispatch, getState) => {
  const { companyBrandReducer: { companyBrands } } = getState()

  if (companyBrands) {
    const formattedCompanyBrands = companyBrands.map(brand => ({
      ...brand,
      selected: brand.code === companyBrandId,
      changeTheme: brand.code === companyBrandId,
    }))

    dispatch({ type: SET_COMPANY_BRANDS, companyBrands: formattedCompanyBrands })
  }
}

export const setSelectedHomeBrandByWindowLevelOneId = windowLevelOneId => (dispatch, getState) => {
  const { companyBrandReducer: { companyBrands } } = getState()

  if (companyBrands) {
    const formattedCompanyBrands = companyBrands.map(brand => ({
      ...brand,
      selected: brand.showCase && brand.showCase.id === windowLevelOneId
    }))

    dispatch({ type: SET_COMPANY_BRANDS, companyBrands: formattedCompanyBrands })
  }
}

export const setSelectedCartBrand = companyBrandId => (dispatch, getState) => {
  const { companyBrandReducer: { companyBrands } } = getState()

  if (companyBrands) {
    const formattedCompanyBrands = companyBrands.map(brand => ({
      ...brand,
      selected: brand.code === companyBrandId,
      changeTheme: false,
    }))

    dispatch({ type: SET_COMPANY_BRANDS, companyBrands: formattedCompanyBrands })
  }
}


export const getCompanyBrands = () => (dispatch, getState) => {
  const { companyBrandReducer: { companyBrands } } = getState()
  
  return companyBrands
}
