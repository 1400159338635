import Translate from 'natura-commons/translate'
import { PROMOTIONS } from 'redux/actions/router/constants'
import {
  initialState as menuInit,
  initialStateImperson
} from 'commons/interactor/router/mapper'
import { mapProduct } from 'commons/interactor/products/mapper'

const translate = new Translate()

export const initialState = {
  bannersHome: [],
  categories: [],
  products: [],
  vitrine: [],
  nextBestOffers: [],
  cartWindows: [],
  titlesPromotion: [],
  externalOrderSearchUrls: [],
  enableTextSearch: false,
  shouldOptimize: false,
  paginationSize: 6,
  split: {},
  windowsHome: [],
  comission: null,
  changePayment: [],
  enableCreditInfo: false,
  enableOrdersHistory: false,
  enablePickupPoint: false,
  interactiveMagazineLink: null,
  magazineImporterUrl: null,
  updatingItemsInExclusiveStep: false,
  parametersSearched: false
}
export const mapMenu = response => {
  const result = []
  if (response) {
    result.push(
      ...response.map(item => ({
        id: item.code,
        active: false,
        name: item.description,
        icon:
          item.icons && item.icons.length > 0 ? item.icons[0].urlImage : null,
        color:
          item.colors && item.colors.length > 0
            ? item.colors[0].hexadecimalColor
            : null,
        title: item.description,
        to: PROMOTIONS,
        subMenu: item.windowLevelTwo.map(
          (submenu) => ({...submenu, active: false})
        ),
        companyBrand: item.companyBrand,
        urlImage: item.urlImage,
        isShowCase: true
      }))
    )
  }
  return result
}

export const mapBanner = response => {
  const result = []
  if (response && response.length > 0) {
    for (const item of response) {

      const banner = {
        id: item.id,
        link: item.url,
        linkMobile: item.urlMobile,
        typeId: item.bannerTypeId,
        typeDescription: item.bannerTypeDescription,
        actionId: item.bannerActionId,
        actionDescription: item.bannerActionDescription,
        productCode: item.itemCode,
        windowLevelOneId: item.bannerWindowLevelOneId,
        windowLevelOneDescription: item.bannerWindowLevelOneDescription,
      }
      result.push(banner)
    }
  }

  return result
}

export const mapCategories = response => {
  const result = []
  if (response && response.length > 0) {
    for (const item of response) {
      const category = {
        code: item.code,
        description: item.description,
        windowLevelOneId: item.windowLevelOneId
      }
      result.push(category)
    }
  }

  return result
}

export const isRenderMenu = (state, isImperson) => {
  const { menu } = state.navigationReducer

  menu.forEach(element => {
    element.title = translate.byKey(element.title)
  });

  if (isImperson) return initialStateImperson.menu !== menu
  return menuInit.menu !== menu
}

export const mapVitrine = (response) => {
  if (response) {
    return response.map(item =>
      ({
        promotionCode: item.promotionCode,
        promotionDescription: item.promotionDescription,
        products: mapProduct(item.product.product),
        listPagined: []
      })
    )
  }
  return []
}

export const mapCartWindow = (response) => {
  if (response) {
    return response.map(item => ({
      code: item.windowCode,
      description: item.windowDescription,
      products: mapProduct(item.windowProducts),
    })
    )
  }
  return []
}


export const mapWindows = (response) => {
  if (response) {
    return response.map(item => ({
      windowCode: item.windowCode,
      windowDescription: item.windowDescription,
      windowProducts: mapProduct(item.windowProducts),
      listPagined: []
    }))
  }
  return []
}
