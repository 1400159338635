import { CLEAR_REDUCER_IMPERSONATION } from 'redux/actions/constants'

import {
  SHOW_TOAST,
  HIDE_TOAST,
  CLEAR_TOAST,
  CLEAR_REDURCERS
} from '../../actions/constants'

import { initialState } from '../../../commons/interactor/toast/mapper'
import { showToastReducer } from '../../../commons/interactor/toast/interactor'

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return showToastReducer(state, action.toast)
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_TOAST:
    case CLEAR_REDURCERS:
    case HIDE_TOAST:
      return initialState
    default:
      return state
  }
}

export default toastReducer
