import { filterCustomSearch } from 'commons/interactor/search/interactor'

import {
  SEARCH_REQUEST,
  SEARCH_RECEIVE,
  SEARCH_INVALIDATE,
  SEARCH_FILTER,
  CLEAR_REDURCERS,
  SELECT_ITEM,
  OPEN_SEARCH,
  RECENT_SEARCHED_PRODUCTS,
  SET_RECENT_SEARCHED_PRODUCTS,
  SET_LEAVERAGE_SEARCH,
  CLEAR_SELECTED_ITEM,
  CLEAR_REDUCER_IMPERSONATION
} from '../../actions/constants'

const initialState = {
  isFetching: false,
  didInvalidate: false,
  searchLabel: '',
  lastSearchLabel: null,
  isSearchByCode: false,
  isDefaultList: true,
  selectedItem: {},
  searchIsOpen: false,
  items: [],
  leaverageItems: [],
  recents: []
}
const items = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_INVALIDATE: {
      return {
        ...initialState,
        items: state.items,
        recents: state.recents,
        leaverageItems: state.leaverageItems
      }
    }
    case SEARCH_REQUEST: {
      const regex = /^\d+$/
      const isSearchByCode = action.searchLabel.startsWith(0)
        ? false
        : regex.test(action.searchLabel)
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        searchLabel: action.searchLabel,
        isSearchByCode,
        isDefaultList: false,
        items: state.isDefaultList ? [] : state.items
      }
    }
    case SEARCH_RECEIVE: {
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        searchLabel: action.searchLabel,
        lastSearchLabel: state.searchLabel,
        lastUpdated: action.recievedAt,
        isDefaultList: false
      }
    }
    case SEARCH_FILTER:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: filterCustomSearch(state.items, action.searchLabel),
        searchLabel: action.searchLabel,
        lastSearchLabel: state.searchLabel,
        lastUpdated: action.recievedAt,
        isDefaultList: false
      }
    case RECENT_SEARCHED_PRODUCTS: {
      return {
        ...initialState,
        searchIsOpen: state.searchIsOpen,
        recents: action.items,
        items: state.leaverageItems,
        leaverageItems: state.leaverageItems
      }
    }
    case SET_LEAVERAGE_SEARCH: {
      return {
        ...state,
        searchIsOpen: state.searchIsOpen,
        leaverageItems: action.leaverageItems,
        items: action.leaverageItems
      }
    }
  }
}

export const customSearch = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_INVALIDATE:
    case SEARCH_REQUEST:
    case SEARCH_RECEIVE:
    case SEARCH_FILTER:
    case RECENT_SEARCHED_PRODUCTS:
    case SET_LEAVERAGE_SEARCH:
      return { ...state, ...items(state, action) }
    case SET_RECENT_SEARCHED_PRODUCTS:
      return {
        ...state,
        recents: action.items,
        isDefaultList: true,
        searchIsOpen: true
      }
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
      return initialState
    case SELECT_ITEM:
      return { ...state, selectedItem: action.selectedItem }
    case CLEAR_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: { name: '' },
        searchLabel: '',
        isDefaultList: true,
        items: state.leaverageItems
      }
    case OPEN_SEARCH:
      return {
        ...state,
        searchIsOpen: action.searchIsOpen
      }
    default:
      return state
  }
}
