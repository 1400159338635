import React from 'react'
import { useStore } from 'react-redux'
import { push } from 'connected-react-router'
import { getCountry, setCountry, getLanguage } from 'natura-commons/utils/country/country'
import { AuthContext } from '../../contexts'
import { config } from '../../config/auth'
import { CognitoAuthService } from './services/CognitoAuthService'

const setupURLParameters = () => {
  const {
    isMobile,
    country,
    systemOrigin
  } = Object.fromEntries(new URLSearchParams(window.location.search))

  if (isMobile) {
    sessionStorage.setItem('isMobile', isMobile)
  }

  if (country) {
    setCountry(country)
  }

  if (systemOrigin) {
    sessionStorage.setItem('systemOrigin', systemOrigin)
  }
}

export const AuthProvider = React.memo(({ children }) => {
  setupURLParameters()

  const language = getLanguage()
  const store = useStore()
  const navigate = pathname => store.dispatch(push({
    pathname,
    state: {
      from: window.location.pathname
    }
  }))

  document.documentElement.lang = language

  return (
    <AuthContext.Provider
      value={{
        navigate,
        authProvider: CognitoAuthService,
        params: {
          ...config,
          country: getCountry().toLowerCase(),
          language,
          store,
        }
      }}
    >
      {children}
    </AuthContext.Provider>
  )
})
