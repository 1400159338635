import { filterSearchReducer } from 'commons/interactor/search/interactor'

import {
  FILTER_SEARCH,
  FILTERED_SEARCH,
  SEARCHING,
  CLEAR_REDURCERS,
  CLEAR_CART,
  GROUP_SELECTED,
  OPEN_FILTER_CONTROL,
  CLOSE_FILTER_CONTROL,
  OPEN_SORT_CONTROL,
  CLOSE_SORT_CONTROL,
  CLEAR_REDUCER_IMPERSONATION
} from '../../actions/constants'

import { initialState } from './products_mock_'

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCHING:
      return {
        ...state,
        searching: action.payload,
        groupSelected: null
      }
    case FILTER_SEARCH:
      return {
        ...state,
        textInput: action.key,
        filterSearch: Boolean(action.key),
        filterListSearch: filterSearchReducer(state, action)
      }
    case FILTERED_SEARCH:
      return {
        ...state,
        textInput: action.key,
        filterSearch: Boolean(action.key),
        filterListSearch: action.payload
      }
    case GROUP_SELECTED:
      return {
        ...state,
        groupSelected: action.payload
      }
    case OPEN_FILTER_CONTROL:
      return {
        ...state,
        isSearchControlsOpen: true,
        isFilterOpen: true,
        isSortOpen: false
      }
    case CLOSE_FILTER_CONTROL:
      return {
        ...state,
        isSearchControlsOpen: false,
        isFilterOpen: false,
        isSortOpen: false
      }
    case OPEN_SORT_CONTROL:
      return {
        ...state,
        isSearchControlsOpen: true,
        isFilterOpen: false,
        isSortOpen: true
      }
    case CLOSE_SORT_CONTROL:
      return {
        ...state,
        isSearchControlsOpen: false,
        isFilterOpen: false,
        isSortOpen: false
      }

    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
    case CLEAR_CART:
      return initialState
    default:
      return state
  }
}
