import { ROOT } from '../../../redux/actions/constants';
const SCENE_NAME = 'SPLIT';

export default {
  // Params from GPPV
  SET_MIN_SPLIT_VALUE_CN: `${ROOT}/${SCENE_NAME}/SET_MIN_SPLIT_VALUE_CN`,
  FETCH_SPLIT_PARAMETERS: `${ROOT}/${SCENE_NAME}/FETCH_SPLIT_PARAMETERS`,

  // Customer search and selection
  FETCH_CUSTOMERS: `${ROOT}/${SCENE_NAME}/SEARCH_CUSTOMER`,
  ADD_CUSTOMER: `${ROOT}/${SCENE_NAME}/ADD_CUSTOMER`,
  DELETE_CUSTOMER: `${ROOT}/${SCENE_NAME}/DELETE_CUSTOMER`,
  DELETE_EMPTY_CUSTOMER: `${ROOT}/${SCENE_NAME}/DELETE_EMPTY_CUSTOMER`,
  SET_SPLIT_ERROR: `${ROOT}/${SCENE_NAME}/SET_SPLIT_ERROR`,
  READ_SPLIT_ERROR: `${ROOT}/${SCENE_NAME}/READ_SPLIT_ERROR`,

  // Manage cart items in the customer's split
  ENABLE_SPLIT: `${ROOT}/${SCENE_NAME}/ENABLE_SPLIT`,
  GET_SPLIT: `${ROOT}/${SCENE_NAME}/GET_SPLIT`,
  SET_SELECTED_PRODUCT: `${ROOT}/${SCENE_NAME}/SET_SELECTED_PRODUCT`,
  ADD_PRODUCT: `${ROOT}/${SCENE_NAME}/ADD_PRODUCT`,
  REMOVE_PRODUCT: `${ROOT}/${SCENE_NAME}/REMOVE_PRODUCT`,
  UPDATE_PRODUCT_QUANTITY: `${ROOT}/${SCENE_NAME}/UPDATE_PRODUCT_QUANTITY`,

  // General
  SET_FIELD: `${ROOT}/${SCENE_NAME}/SET_FIELD`,
};
