import {
  GLOBAL_ERROR_SET,
  GLOBAL_ERROR_CLEAR,
} from '../../actions/constants'

const initialState = {
  hasError: false,
  error: null,
}

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_ERROR_SET:
      return {
        ...state,
        error: action.payload.error,
        hasError: action.payload.error !== null
      }
    case GLOBAL_ERROR_CLEAR:
      return initialState
    default:
      return state
  }
}

export default errorReducer
