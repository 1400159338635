import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CALL_GTM_SCRIPT } from "redux/actions/constants";

const GTMScript = () => {
  const callGTM = useSelector(state => state.dataLayerReducer.callGTM);
  const pendingDataLayerActions = useSelector(state => state.dataLayerReducer.pendingDataLayerActions)
  const dispatch = useDispatch();

  useEffect(() => {
    if (callGTM) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      })
      const script = document.createElement('script')
      script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-KCPH79D'
      script.async = true
      document.body.appendChild(script)
      pendingDataLayerActions && pendingDataLayerActions.length > 0 && pendingDataLayerActions.map(({ type, payload }) => dispatch({ type, payload }))
    }
  }, [callGTM, pendingDataLayerActions]);
  return null;
}

export default GTMScript
