import React, { createContext, useContext, useState, useCallback, useMemo } from 'react'
import { Provider, themes as themesDS } from '@naturacosmeticos/natds-web'

import themesExtension from './helpers/themesExtension'

const ThemeContext = createContext({})

function ThemeProvider({ children }) {
  const themes = useMemo(() => themesExtension(themesDS), [])
  const [themeSelected, setThemeSelected] = useState(themes.default)
  const [themeName, setThemeName] = useState('default')

  const setTheme = useCallback((themeName) => {
    const selected = themes[themeName] || themes.default

    setThemeName(themeName ? themeName : 'default')
    setThemeSelected(selected)
  }, [themes])

  const getPrimaryColor = useCallback((mode = 'light') => themeSelected[mode].palette.primary.main, [themeSelected])
  const getPrimaryColorByTheme = useCallback((themeName = 'default', mode = 'light') => {
    if (themes[themeName]) {
      return themes[themeName][mode].palette.primary.main
    }

    return themes.default[mode].palette.primary.main
  }, [themes])

  const getPrimaryByTheme = useCallback((themeName = 'default', mode = 'light') => {
    if (themes[themeName]) {
      return themes[themeName][mode].palette.primary
    }
    return themes.default[mode].palette.primary
  }, [themes])

  const getColorFontByTheme = useCallback((mode = 'light') => {
    return themeSelected[mode].palette.primary.color;
  }, [themeSelected])

  return (
    <ThemeContext.Provider value={{ setTheme, getPrimaryColor, getPrimaryColorByTheme, getPrimaryByTheme, getColorFontByTheme, theme: themeSelected, themeName }}>
      <Provider theme={themeSelected.light}>
        {children}
      </Provider>
    </ThemeContext.Provider>
  )
}

function useTheme() {
  const context = useContext(ThemeContext)

  return context
}

export { ThemeProvider, useTheme }
