import Translate from 'natura-commons/translate'

import {
  TYPE_ALERT_MESSAGE,
  ACTION_BUTTON,
  TYPE_MODAL_ERROR
} from 'redux/actions/constants'

import { getError } from 'commons/presenter/errors/responseErros'

import { defaultVariables } from './mapper'

const translate = new Translate()

export const getPendenciesAction = (
  onNavigationTo,
  getState,
  handleConfirm,
  onPressOne,
  onPressTwo,
  onChangeValueProfile
) => {
  const {
    userSession: {
      commercialInfo: { blockReason, isBlocked }
    }
  } = getState()
  if (isBlocked) {
    return []
  }
  const alerts = []
  blockReason.forEach(block => {
    const objAlert = {
      ...defaultVariables,
      id: block.id,
      type: TYPE_ALERT_MESSAGE,
      typeAction: ACTION_BUTTON,
      titleButtonConfirm: translate.byKey('okUnderstand'),
      title: translate.byKey('atention'),
      show: true,
      body: getError(block),
      handleConfirm,
      onPressOne,
      onPressTwo,
      onChangeValueProfile
    }
    alerts.push(objAlert)
  })
  const position = alerts.length > 0 ? alerts.length - 1 : 0
  if (alerts[position]) {
    alerts[position].handleConfirm = onNavigationTo
    alerts[position].onPressTwo = onNavigationTo
  }
  return alerts
}

export const getBlocksAction = (
  getState,
  handleConfirm,
  onPressOne,
  onPressTwo,
  onChangeValueProfile
) => {
  const {
    userSession: {
      commercialInfo: { blockReason, isBlocked }
    }
  } = getState()
  if (!isBlocked) {
    return []
  }
  const alerts = []
  blockReason.forEach(block => {
    const objAlert = {
      ...defaultVariables,
      id: block.id,
      type: TYPE_ALERT_MESSAGE,
      typeAction: ACTION_BUTTON,
      titleButtonConfirm: translate.byKey('okUnderstand'),
      title: translate.byKey('atention'),
      show: true,
      body: getError(block),
      handleConfirm,
      onPressOne,
      onPressTwo,
      onChangeValueProfile
    }
    alerts.push(objAlert)
  })
  return alerts
}

export const getAlertAddress = objAlert => {
  const alerts = []
  const alert = {
    ...defaultVariables,
    type: TYPE_ALERT_MESSAGE,
    typeAction: ACTION_BUTTON,
    typeModal: objAlert.typeAddress,
    title: objAlert.titleModal,
    titleButtonConfirm: translate.byKey('sendAddress'),
    show: true,
    dataObject: objAlert.objAddress,
    handleConfirm: objAlert.handleConfirm
  }
  alerts.push(alert)
  return alerts
}

export const getBlocksUserAction = getState => {
  const {
    userSession: {
      detailsPerson: { blocks, blockReason }
    }
  } = getState()
  const alerts = []
  if (!blocks) {
    return []
  }
  blockReason.forEach(block => {
    const objAlert = {
      ...defaultVariables,
      id: block.id,
      type: TYPE_ALERT_MESSAGE,
      typeAction: ACTION_BUTTON,
      titleButtonConfirm: translate.byKey('understood'),
      title: getError(block),
      show: true
    }
    alerts.push(objAlert)
  })

  return alerts
}

// FUNCTIONS REDUCER
export const setAlertsReducer = (state, payload) => {
  if (state.alerts.length > 0) {
    const erros = state.alerts.filter(
      alert => alert.typeModal === TYPE_MODAL_ERROR
    )
    let newAlerts = state.alerts.filter(
      alert => alert.typeModal !== TYPE_MODAL_ERROR
    )

    newAlerts = [...payload, ...newAlerts]

    erros.map((alert, index) => {
      newAlerts.splice(index, 0, alert)
    })

    return {
      ...state,
      alerts: newAlerts
    }
  }
  return {
    ...state,
    alerts: [...payload, ...state.alerts]
  }
}

export const updateAlertsReducer = (state, payload) => ({
  ...state,
  alerts: payload
})

export const setAlertsReadReducer = (state, payload) => ({
  ...state,
  reads: payload
})
