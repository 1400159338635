import {
  CLEAR_REDURCERS,
  SET_SORTER_SELECTED_SEARCH_PRODUCT,
  CLEAR_SORTER_SEARCH_PRODUCT,
  CLEAR_CART,
  CLEAR_REDUCER_IMPERSONATION,
  SET_FILTER_SELECTED_PRODUCT,
  SET_INITIAL_FILTER_AFTER_SEARCH
} from 'redux/actions/constants'
import { initialState } from 'commons/interactor/sorter/search/mapper'
import * as interactor from 'commons/interactor/sorter/search/interactor'

export default function sorterSearchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SORTER_SELECTED_SEARCH_PRODUCT:
      return interactor.setSorterSelectedReducer(state, action.payload)
    case SET_FILTER_SELECTED_PRODUCT:
      return { ...state, filterSelected: action.filter }
    case CLEAR_SORTER_SEARCH_PRODUCT:
      return { ...state, sorterSelected: null }
    case SET_INITIAL_FILTER_AFTER_SEARCH:
      return {
        ...state,
        initialFilterAfterSearch: action.initialFilterAfterSearch
      }
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
    case CLEAR_CART:
      return initialState
    default:
      return state
  }
}
