import * as constants from 'redux/actions/constants'
import { initialState } from 'commons/interactor/parameter/mapper'
import {
  syncAvailabilityHighlighted,
  syncAvailabilityPromotion,
  productListPaginedPromotionReducer,
  setPromotionsTitles,
  setPromotionsTitlesExclusive,
  setSearchingExclusive
} from 'commons/interactor/products/interactor'

const parameterReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_BANNERS_HOME:
      return { ...state, bannersHome: action.banners }
    case constants.SET_HOME_PARAMETERS_SEARCHED:
      return { ...state, parametersSearched: true }
    case constants.SET_CART_BANNER:
      return {
        ...state,
        cartBanner: {
          banner: action.cartBanner,
          recovered: true
        }
      }
    case constants.GET_BAG_INFO_TEXT:
      return { ...state,
        bagInfoText: {
          text: action.bagInfoText,
          recovered: true
        }
      }
    case constants.GET_LEAVERAGE:
      return { ...state, categories: action.categories }
    case constants.SET_SELECTED_LEAVERAGE:
      return { ...state, selected: action.selected }
    case constants.PAGINATION_SIZE:
      return { ...state, paginationSize: action.size, paginationOrderSize: action.orderSearchPageSize }
    case constants.PRODUCT_LIST_PAGINED_PROMOTION:
      return productListPaginedPromotionReducer(state, action)
    case constants.SYNC_AVAILABILITY_HIGHLIGHTED:
      return syncAvailabilityHighlighted(state, action)
    case constants.SYNC_AVAILABILITY_PROMOTION:
      return syncAvailabilityPromotion(state, action)
    case constants.SORT_INTERNAL:
      return { ...state, vitrine: action.vitrine }
    case constants.PRODUCTS_VITRINE:
      return { ...state, products: action.products }
    case constants.GET_INTERNAL:
      return {
        ...state,
        products: action.products,
        highlighted: action.highlighted,
        vitrine: action.vitrine
      }
    case constants.PRODUCTS_WINDOWS_HOME:
      return {
        ...state,
        windowsHome: action.windows,
      }
    case constants.CLEAR_LEVERAGE:
      return {
        ...state,
        selected: null,
        categories: [],
        products: [],
        highlighted: [],
        vitrine: [],
      }
    case constants.SET_INITIAL_NBO:
      return {
        ...state,
        nextBestOffers: action.products,
        nextBestOffersTitle: action.description
      }
    case constants.SET_INITIAL_CART_WINDOW:
      return {
        ...state,
        cartWindows: action.windows
      }
    case constants.ACTIVE_SEARCH_BY_DESCRIPTION:
      return { ...state, enableTextSearch: action.active }
    case constants.OPTMIZE_SEARCH:
      return { ...state, shouldOptimize: action.active }
    case constants.SET_TITLES:
      return setPromotionsTitles(state, action)
    case constants.SET_TITLES_EXCLUSIVES:
      return setPromotionsTitlesExclusive(state, action)
    case constants.SET_SEARCHING_EXCLUSIVES: 
      return setSearchingExclusive(state, action)
    case constants.SET_UPDATING_ITEMS_IN_EXCLUSIVE: 
      return { ...state, updatingItemsInExclusiveStep: action.updatingItems }
    case constants.CLEAR_REDURCERS:
    case constants.CLEAR_REDUCER_IMPERSONATION:
      return initialState
    case constants.SET_INITIAL_EXTERNAL_ORDER_SEARCH_URL:
      return { ...state, externalOrderSearchUrls: action.externalOrderSearchUrls }
    case constants.SET_BANNER_DEFAULT:
      return { ...state, bannerDefault: action.banner }
    case constants.SET_COMISSION:
      return { ...state, comission: action.comission }
    case constants.CHANGE_PAYMENT_METHOD_PARAM:
      return { ...state, changePayment: action.payload }
    case constants.GET_SPLIT_PARAMS:
      return { ...state, split: action.payload }
    case constants.SET_SHOW_ORDERS_HISTORY:
      return { ...state, enableOrdersHistory: action.enableOrdersHistory }
    case constants.SET_NEW_PROMOTION:
      return { ...state, enableNewPromotion: action.enableNewPromotion }
    case constants.SET_SHOW_PICKUP_POINT:
      return { ...state, enablePickupPoint: action.enablePickupPoint }
    case constants.SET_SHOW_CREDT_INFO:
      return { ...state, enableCreditInfo: action.enableCreditInfo }
    case constants.SET_INTERACTIVE_MAGAZINE_LINK:
      return { ...state, interactiveMagazineLink: action.interactiveMagazineLink }
    case constants.SET_IMPORTER_MAGAZINE_LINK:
      return { ...state, magazineImporterUrl: action.magazineImporterUrl }
    case constants.GEO_STRUCTURE_LEVEL:
      return { ...state, geoStructureLevel: action.geoStructureLevel }
    case constants.SHIPPING_METHOD:
      return { ...state, shippingMethod: action.shippingMethod }
    case constants.SET_SUCURSAL_FILTERS: {
      const sucursalFilters = action.sucursalFilters.reduce((acc, item) => {
        if (item.isNumeric) {
          acc.numericFilters.push(item)
        } else {
          acc.filters.push(item)
        }
        return acc
      }, { numericFilters: [], filters: [] })

      return { ...state, sucursalFilters }
    }
    case constants.SET_COUNTRY_PHONE_NUMBER:
      return { ...state, country: action.country }
    default:
      return state
  }
}

export default parameterReducer
