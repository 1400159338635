import { cloneDeep } from 'lodash'

import {
  CLEAR_REDURCERS,
  SET_SELECTED_MENU_ITEM,
  SET_INITIAL_MENU,
  SET_SALES_OPPORTUNITY,
  CLEAR_MENU,
  SET_INTERACTIVE_MAGAZINE_LINK
} from '../../actions/constants'
import {
  initialState,
  initialStateImperson,
  initialStateClean
} from '../../../commons/interactor/router/mapper'
import { setSelectedMenuItem } from '../../../commons/interactor/router/interactor'
import { MAGAZINE_MENU_ID, ORDERS_MENU_ID } from 'commons/constants/router'
import { INTERACTIVE_MAGAZINE, ORDERS, SALES_OPPORTUNITY } from 'redux/actions/router/constants'

const routerReducer = (state = initialStateClean, action) => {
  switch (action.type) {
    case SET_INITIAL_MENU: {
      const initial = cloneDeep(
        action.isImperson ? initialStateImperson : initialState
      )
      const position = action.isImperson
        ? initial.menu.length - 3
        : initial.menu.length - 2
      if(action.enableOrdersHistory) {
        const menuOrders = {
          id: ORDERS_MENU_ID,
          active: false,
          icon: 'outlined-content-itemlist',
          title: 'menuOptionOrders',
          to: ORDERS
        }
        action.menu.push(menuOrders)
      }
      initial.menu.splice(position, 0, ...action.menu)
      
      return {
        ...state,
        menu: initial.menu,
        originalMenu: initial.menu,
      }
    }
    case SET_SELECTED_MENU_ITEM: {
      const changedMenu = setSelectedMenuItem(state.menu, action.item)
      return { ...state, menu: changedMenu }
    }
    case CLEAR_REDURCERS:
    case CLEAR_MENU: {
      const initial = cloneDeep(initialState)
      return { ...state, menu: initial.menu }
    }
    case SET_SALES_OPPORTUNITY: {
      const cloneMenu = cloneDeep(state.menu)
      const salesOpportunityMenu = action.salesOpportunity.map(opportunity => ({
        id: opportunity.id,
        active: false,
        icon: 'outlined-action-newregister',
        title: opportunity.menuDescription,
        to: SALES_OPPORTUNITY
      }))

      cloneMenu.splice(2, 0, ...salesOpportunityMenu)

      return {
        ...state,
        menu: cloneMenu,
        salesOpportunity: action.salesOpportunity
      }
    }
    case SET_INTERACTIVE_MAGAZINE_LINK: {
      if (action.interactiveMagazineLink) {
        const cloneMenu = cloneDeep(state.menu)
        const interactiveMagazineMenu = {
          id: MAGAZINE_MENU_ID,
          active: false,
          icon: 'outlined-content-book',
          title: 'menuOptionMagazine',
          to: INTERACTIVE_MAGAZINE
        }

        cloneMenu.splice(cloneMenu.length - 1, 0, interactiveMagazineMenu)

        return {
          ...state,
          menu: cloneMenu,
        }
      }
      
      return state
    }
    default:
      return state
  }
}

export default routerReducer
