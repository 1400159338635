import * as constants from 'commons/constants/error'
import { setAlerts } from 'redux/actions/alerts/alertsAction'
import { getAlertError } from 'commons/presenter/order/home/presenter'
import Translate from 'natura-commons/translate'

import { arrayErrors } from './constants'
import { getAlertExcededQuantity } from './modalExcededQuantity'

const translate = new Translate()

export const verifyErrorResponse = (dispatch, errors) => {
  if (errors && errors.length > 0) {
    if (
      verifyErrorCode(errors, constants.INVALID_QUANTITY_INCORPORATION) ||
      verifyErrorCode(errors, constants.ERROR_CODE_NOT_ENOUGH_QUOTA_ADD) ||
      verifyErrorCode(errors, constants.ERROR_CODE_NOT_ENOUGH_QUOTA_EC)
    ) {
      dispatch(
        setAlerts([
          getAlertExcededQuantity(
            translate.byKeyAndParamValue(
              constants.ERROR_QUANTITY_EXCEDED,
              errors[0].productCode
            )
          )
        ])
      )
    } else {
      dispatch(setAlerts([getAlertError(errors, false, null, dispatch)]))
    }
  }
}

const verifyErrorCode = (errors, errorCode) =>
  errors.find(error => error.code === errorCode)

export const getError = (objError, isNetworkError = false) => {
  const { code, description, path, productCode, promotionsIds } = objError
  const errors = arrayErrors()
  if (!isNetworkError) {
    if (errors) {
      let error = {}
      if (path === 'cartAddItems' && description === 'token.validationFailed') {
        error = errors.find(
          item => item.message === 'cartItem.product.notFound'
        )
      } else if (description) {
        error = errors.find(item => item.message === description)
      } else if (code) {
        error = errors.find(item => item.code === code)
      }
      if (promotionsIds) {
        return translate.byKeyAndParamValue(error.key, promotionsIds.join(', '))
      }
      if (productCode && productCode > 0) {
        return translate.byKeyAndParamValue(error.key, productCode)
      }
      if (error) return translate.byKey(error.key)
    }
  }

  switch (code) {
    case constants.BAD_GATEWAY_ERROR_CODE:
      return translate.byKey(constants.ERROR_BAD_GATEWAY)
    case constants.GATEWAY_TIMEOUT_ERROR_CODE:
      return translate.byKey(constants.ERROR_GATEWAY_TIMEOUT)
    case constants.ERROR_CODE_ORDER_EXCEED:
      return translate.byKey(constants.KEY_ORDER_EXCEEED)
    case constants.ERROR_PENDING_APROVAL:
      return translate.byKey(constants.KEY_APROVAL_PENDING)
    case constants.ERROR_PENDING_BY_CREDIT:
      return translate.byKey(constants.KEY_CREDIT_PENDING)
    case constants.ERRO_BLOCK_BY_CREDIT_AVAILABLE:
      return translate.byKey(constants.KEY_CREDIT_PENDING)
    case constants.ERROR_CODE_UNAVAILABLE:
      return translate.byKey(constants.ERROR_3003)
    default:
      return `${code} - ${description}`
  }
}


export const getErrorStripe = (code) => {
  switch (code) {
    case 'parameter_invalid_empty':
      return translate.byKey(constants.ERROR_FPX_EMPTY)
    case constants.ERROR_OFFLINE_BANK:
      return translate.byKey(constants.ERROR_OFFLINE_BANK)
    case constants.ERROR_INSUFFICIENT_FUNDS: 
      return translate.byKey(constants.ERROR_INSUFFICIENT_FUNDS)
    case constants.ERROR_EXPIRED_CARD:
      return translate.byKey(constants.ERROR_EXPIRED_CARD)
    case constants.ERROR_INCORRECT_NUMBER:
      return translate.byKey(constants.ERROR_INCORRECT_NUMBER)
    case constants.ERROR_INCORRECT_CVC:
      return translate.byKey(constants.ERROR_INCORRECT_CVC)
    case constants.ERROR_INVALID_EXPIRY_YEAR:
      return translate.byKey(constants.ERROR_INVALID_EXPIRY_YEAR)
    case constants.ERROR_INVALID_NUMBER:
      return translate.byKey(constants.ERROR_INVALID_NUMBER)
    case constants.ERROR_INVALID_CVC:
      return translate.byKey(constants.ERROR_INVALID_CVC)
    case constants.ERROR_EXPIRED:
      return translate.byKey(constants.ERROR_EXPIRED)
    case constants.ERROR_INCORRECT:
      return translate.byKey(constants.ERROR_INCORRECT)
    case constants.ERROR_CVC:
      return translate.byKey(constants.ERROR_CVC)
    case constants.ERROR_MONTH:
      return translate.byKey(constants.ERROR_MONTH)
    case constants.ERROR_YEAR:
      return translate.byKey(constants.ERROR_YEAR)
    case constants.ERROR_NUMBER:
      return translate.byKey(constants.ERROR_NUMBER)
    case constants.ERROR_INVALID_CVC:
      return translate.byKey(constants.ERROR_INVALID_CVC)
    default:
      return ` Error processing your payment. Please contact customer support. Error code: - ${code}`
  }
}