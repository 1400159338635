/* eslint-disable camelcase */
export const unconditionalDataLayerPush = () => payload =>
  dataLayerPush(payload)

export const conditionalDataLayerPush = () => payload => {
  if (payload) {
    dataLayerPush(payload)
    return true
  }
  return false
}

export const presetDataLayerPush = preset => () => dataLayerPush(preset)

export const personDataResponsePush = unconditionalDataLayerPush()

export const commercialInfoResponsePush = unconditionalDataLayerPush()

export const siteLoad = conditionalDataLayerPush()

export const virtualPageView = async payload => {
  await dataLayerPush(payload)
}

export const login = presetDataLayerPush({ event: 'login' })

export const logout = async callback => {
  const payload = {
    event: 'logout'
  }

  await dataLayerPush(payload, callback)
}

export const addToCart = conditionalDataLayerPush()

export const removeFromCart = conditionalDataLayerPush()

export const checkout = unconditionalDataLayerPush()

export const purchase = conditionalDataLayerPush()

export const promoData = unconditionalDataLayerPush()

export const promoClick = unconditionalDataLayerPush()

export const listData = unconditionalDataLayerPush()

export const listView = conditionalDataLayerPush();

export const NBOListData = unconditionalDataLayerPush()

export const optionalKitView = conditionalDataLayerPush()

export const optionalKitAccept = presetDataLayerPush({
  event: 'optionalKitAccept'
})

export const optionalKitRefuse = presetDataLayerPush({
  event: 'optionalKitRefuse'
})

export const starterKitView = conditionalDataLayerPush()

export const starterKitAccept = presetDataLayerPush({
  event: 'starterKitAccept'
})

export const preSalesCart = presetDataLayerPush({ event: 'preSalesCart' })

export const preSalesAccept = presetDataLayerPush({ event: 'preSalesAccept' })

export const preSalesRefuse = presetDataLayerPush({ event: 'preSalesRefuse' })

export const draftCart = presetDataLayerPush({ event: 'draftCart' })

export const draftAccept = presetDataLayerPush({ event: 'draftAccept' })

export const draftRefuse = presetDataLayerPush({ event: 'draftRefuse' })

export const modalImpersonationConfiguration = conditionalDataLayerPush()

export const modalImpersonationAccept = conditionalDataLayerPush()

export const modalImpersonationDecline = conditionalDataLayerPush()

export const modalRolesConfiguration = conditionalDataLayerPush()

export const modalCycleConfiguration = conditionalDataLayerPush()

export const cartProductQuantityClick = conditionalDataLayerPush()

export const directDeliveryClick = conditionalDataLayerPush()

export const splitPageButtonClick = conditionalDataLayerPush()

export const clickForOldVersion = presetDataLayerPush({ event: 'selectOldVersion' })

export const clickFullShipOrder = presetDataLayerPush({ event: 'seeTotalDelivery' })

export const selectedDisplay = conditionalDataLayerPush()

export const profileClick = conditionalDataLayerPush()

export const searchCustomer = conditionalDataLayerPush()

/**
 * Promisified wrapper for dataLayer.push with wait-for-tags and callback support.
 *
 * @param {object} payload object to be sent to dataLayer.
 * @param {function} [callback] function to be run after all tags associated with this dataLayer.push have finished running.
 * @param {number} [timeout] eventTimeout. Defaults to 2000ms and must be between 1000 and 10000.
 * @return promise that resolves to the `callback` return value, or `undefined` if `callback` is not used.
 *
 * @example
 *   // if wait-for-tags is desired - version 1
 *   const appCallbackResult = await dataLayerPush({ event: 'event' }, appCallback, 3000);
 *
 *   // if wait-for-tags is desired - version 2
 *   await dataLayerPush({ event: 'event' }, undefined, 3000);
 *   const appCallbackResult = appCallback();
 *
 *   // if wait-for-tags is not desired/necessary
 *   dataLayerPush({ event: 'event' });
 *   const appCallbackResult = appCallback();
 */

// eslint-disable-next-line require-await
const dataLayerPush = async (
  payload,
  callback,
  timeout = 2000,
  showDebug = true
) => {
  window.dataLayer = window.dataLayer || []
  const dataLayer = window.dataLayer

  return new Promise((resolve, reject) => {
    const resolveCallback = () => resolve(callback ? callback() : undefined)
    // timeout must be a number greater than 1000 and lesser than 10000
    if (
      Number.isNaN(timeout) ||
      typeof timeout !== 'number' ||
      timeout < 1000 ||
      timeout > 10000
    ) {
      if (showDebug)
        console.error(
          'timeout must be a number greater than 1000 and lesser than 10000. Reseting to 2000...'
        )
      timeout = 2000
    }
    // callback must be a function, if specified
    if (callback && typeof callback !== 'function') {
      if (showDebug) console.error('callback must be a function, if specified')
      return
    }
    // payload must be a plain object
    if (
      !payload ||
      typeof payload !== 'object' ||
      Object.prototype.toString(payload) !== '[object Object]'
    ) {
      if (showDebug) console.error('payload must be a plain object')
      resolveCallback()
      return
    }
    // callback can be used only if there is an event property on payload
    if (callback && !payload.event) {
      if (showDebug)
        console.error(
          'callback can be used only if there is an event property on payload'
        )
      resolve(callback())
      return
    }
    if (!payload.event) {
      // callback was not used
      delete payload.eventTimeout
      delete payload.eventCallback
      dataLayer.push(payload)
      resolve(undefined)
    } else {
      // callback may have been used
      payload.eventTimeout = timeout
      payload.eventCallback = () => {
        try {
          resolveCallback() // if callback is a promise, it resolves even so
        } catch (error) {
          reject(error)
        }
      }
      dataLayer.push(payload)
      resolveCallback() // if callback is a promise, it resolves even so
    }
  })
}