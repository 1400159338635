import { GLOBAL_ERROR_SET, GLOBAL_ERROR_CLEAR } from '../constants'

export const setError = error => ({
  type: GLOBAL_ERROR_SET,
  payload: {
    error,
  }
})

export const clearErrors = () => ({
  type: GLOBAL_ERROR_CLEAR,
})
