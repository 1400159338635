import { ROOT } from '../../../redux/actions/constants'

const SCENE_NAME = 'CUSTOMER'

export default {
  // Customer search and selection
  FETCH_CUSTOMERS: `${ROOT}/${SCENE_NAME}/SEARCH_CUSTOMER`,
  ADD_CUSTOMER: `${ROOT}/${SCENE_NAME}/ADD_CUSTOMER`,
  GET_CUSTOMER_BY_DOCUMENT: `${ROOT}/${SCENE_NAME}/GET_CUSTOMER_BY_DOCUMENT`,
  ASSIGN_CUSTOMER_ROLE: `${ROOT}/${SCENE_NAME}/ASSIGN_CUSTOMER_ROLE`,

  // Geographic structure tree search
  FETCH_GEO_TREE: `${ROOT}/${SCENE_NAME}/FETCH_GEO_TREE`,
  FETCH_GEO_STRUCTURE: `${ROOT}/${SCENE_NAME}/FETCH_GEO_STRUCTURE`,
  SET_NEXT_GEO_STRUCTURE_LEVEL: `${ROOT}/${SCENE_NAME}/SET_NEXT_GEO_STRUCTURE_LEVEL`,

  // Document types search
  FETCH_DOCUMENTS: `${ROOT}/${SCENE_NAME}/FETCH_DOCUMENTS`,

  // General
  SET_FIELD: `${ROOT}/${SCENE_NAME}/SET_FIELD`,
  SET_ADDRESS: `${ROOT}/${SCENE_NAME}/SET_ADDRESS`,
  SET_COUNTRY_LEVEL: `${ROOT}/${SCENE_NAME}/SET_ADDRESS`,
  SET_PHONE: `${ROOT}/${SCENE_NAME}/SET_PHONE`,
  SET_NEXT_LEVEL: `${ROOT}/${SCENE_NAME}/SET_NEXT_LEVEL`,
  SET_PROVINCE: `${ROOT}/${SCENE_NAME}/SET_PROVINCE`,
  SET_DISTRICT: `${ROOT}/${SCENE_NAME}/SET_DISTRICT`,
  ADD_NEXT_LEVEL: `${ROOT}/${SCENE_NAME}/ADD_NEXT_LEVEL`,
  CLEAR_CUSTOMER: `${ROOT}/${SCENE_NAME}/CLEAR_CUSTOMER`
}
