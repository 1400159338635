// LEVEL
export const LEVEL_BRONZE = 'LEVEL_BRONZE'
export const LEVEL_SILVER = 'LEVEL_SILVER'
export const LEVEL_GOLD = 'LEVEL_GOLD'
export const LEVEL_PLATINUM = 'LEVEL_PLATINUM'
export const LEVEL_SAPPHIRE = 'LEVEL_SAPPHIRE'
export const LEVEL_DIAMOND = 'LEVEL_DIAMOND'

export const LEVEL_DIAMOND_ID = 6
export const TEST_PROFILE_ID = 99999

// IMPERSONATION
export const INDEX_MODAL_PERSON = 1
export const INDEX_MODAL_IMPERSONATION = 2

// SYSTEM
export const SYSTEM_ID_DEFAULT = 1
export const CHANNEL_WEB = 1
export const CHANNEL_APP = 2
export const CHANNEL_ATTENDANCE = 3
export const GDN_CHANNEL_WEB = 6
export const GDN_CHANNEL_APP = 7
