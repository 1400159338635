import { commomTheme } from './commom'

import eloColors from './colors/elo'
import avonColors from './colors/avon'
import fashionHomeColors from './colors/fashionHome'
import naturaColors from './colors/natura'

export default {
  eloTheme: commomTheme(eloColors),
  avonTheme: commomTheme(avonColors),
  fashionHomeTheme: commomTheme(fashionHomeColors),
  naturaTheme: commomTheme(naturaColors)
}
