import {
  START_FETCHING_ITEMS,
  REMOVE_ITEM,
  UPDATE_ITEM,
  CHECK_BAG_ITEMS,
  GET_BAG_ITEMS,
  CLEAR_REDURCERS,
  CLEAR_CART,
  CLEAR_MODAL_BAG,
  ADD_ITEM_ERROR,
  SET_AUTOMATIC_GET_BAG,
  REMOVE_ITEM_FROM_CART_STATE,
  CLEAR_UNAVAILABLE_PRODUCTS,
  ADD_ITEM_START,
  ADD_ITEM_DONE,
  CLEAR_REDUCER_IMPERSONATION,
  RENEW_GIFTS_BAG,
  UPDATE_SELECTED_GIFTS,
  UPDATE_GIFT_QUANTITY,
  PAYMENT_CONDITION,
  SELECT_PAYMENT_CONDITION,
  SET_PENDING_PAYMENTS,
  SET_HAS_PENDING_PAYMENTS,
  REPLACE_UNAVAILABLE_GIFT_OPTIONS
} from 'redux/actions/constants'
import { initialState } from 'commons/interactor/bag/mapper'
import * as interactor from 'commons/interactor/bag/interactor'
import { updateGiftQuantity } from 'commons/interactor/cart/interactor'

const bagReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING_ITEMS:
    case REMOVE_ITEM:
    case UPDATE_ITEM: {
      return {
        ...state,
        isFetchingItems: true,
        addItemError: false
      }
    }
    case ADD_ITEM_START: {
      return {
        ...state,
        isAddingItem: true
      }
    }
    case PAYMENT_CONDITION:
      return {
        ...state,
        paymentConditions: action.payload
    }
    case SELECT_PAYMENT_CONDITION:
      return {
        ...state,
        paymentConditionSelected: action.payload
    }
    case ADD_ITEM_DONE: {
      return {
        ...state,
        isAddingItem: false
      }
    }
    case REMOVE_ITEM_FROM_CART_STATE: {
      return {
        ...state,
        products: state.products.filter(
          product => product.code !== action.item.code
        )
      }
    }
    case CLEAR_UNAVAILABLE_PRODUCTS: {
      return {
        ...state,
        products: state.products.filter(product => product.isAvailable)
      }
    }

    case ADD_ITEM_ERROR: {
      return {
        ...state,
        isFetchingItems: false,
        addItemError: true
      }
    }
    case CHECK_BAG_ITEMS: {
      return interactor.checkBagItems(state, action)
    }
    case SET_AUTOMATIC_GET_BAG: {
      return {
        ...state,
        allowAutomaticGetBag: action.allowAutomaticGetBag
      }
    }
    case GET_BAG_ITEMS:
      return interactor.getBagItems(state, action)
    case CLEAR_MODAL_BAG:
      return interactor.clearModalBag(state)
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
    case CLEAR_CART:
      return initialState
    case RENEW_GIFTS_BAG:
      return interactor.renewGifts(state)
    case UPDATE_SELECTED_GIFTS:
      return {
        ...state,
        selectedGifts: interactor.formatSelectedGifts(action.payload.giftItems)
      }
    case UPDATE_GIFT_QUANTITY:
      return updateGiftQuantity(state, action)
    case SET_PENDING_PAYMENTS:
      return {
        ...state,
        pendingPayments: action.payload.pendingPayments,
        hasPendingPayments: action.payload.hasPendingPayments
      }
    case SET_HAS_PENDING_PAYMENTS:
      return {
        ...state,
        hasPendingPayments: action.payload
      }
    case REPLACE_UNAVAILABLE_GIFT_OPTIONS:
      return {
        ...state,
        gifts: action.payload
      }
    default:
      return state
  }
}

export default bagReducer
