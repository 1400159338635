export const ERROR_NETWORK = 'auth/network-request-failed'
export const STRING_ERROR_NETWORK = 'Network error'
export const STRING_ERROR_NETWORK_FIREBASE = 'Connection failed.'
export const ARRAY_NETWORK_ERRORS_MESSAGES = [
  'Network error: Failed to fetch',
  'Connection failed.'
]
export const ERROR_GRAPHQL_PROPERTY = 'graphql/cannot-read-property'
export const STRING_ERROR_GRAPHQL_PROPERTY = 'Cannot read property'
export const ERROR_GRAPHQL_SYNTAX = 'graphql/syntax-error'
export const STRING_ERROR_GRAPHQL_SYNTAX = 'Syntax Error'
export const ERROR_GRAPHQL_ENTITY = 'graphql/unprocessable-Entity'
export const STRING_ERROR_GRAPHQL_ENTITY = 'Unprocessable Entity'
export const INTERNAL_SERVER_ERROR = 'internal_server_error'
export const STRING_INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
export const CART_NOT_FOUND = 'cartNotFound'
export const STRING_CART_NOT_FOUND = 'cart.notFound'
export const STRING_CART_CONVERTING = 'cart.converting'
export const STRING_CART_CONVERTING_ERROR = 'cart.converting.error'
export const STRING_CART_PENDING_ORDER = 'cart.pendingOrder'
export const CART_CONVERTING = 'cartConverting'
export const STRING_CART_INVALID_DELIVERYMODE = 'cart.invalid.deliveryMode'
export const CART_INVALID_DELIVERYMODE = 'deliveryModesNotFound'
export const CHECKOUT_STARTER_KIT_ALREADY_BOUGHT = 'checkout.starterkit.already.bought'
export const STARTER_KIT_ALREADY_BOUGHT = 'starterKitAlreadyBought'
export const STRING_PAYMENT_CONDITION_NOT_FOUND = 'payment.condition.not.found'
export const PAYMENT_CONDITION_NOT_FOUND = 'paymentConditionNotFound'

// 502 and 504 handling
export const ERROR_BAD_GATEWAY = 'bad_gateway'
export const STRING_BAD_GATEWAY = 'Bad Gateway'
export const BAD_GATEWAY_ERROR_CODE = 502
export const ERROR_GATEWAY_TIMEOUT = 'gateway_timeout'
export const STRING_GATEWAY_TIMEOUT = 'Gateway Timeout'
export const GATEWAY_TIMEOUT_ERROR_CODE = 504

// CONSTANTS ERROR KEY
export const INFORMATION_NOT_FOUND = 'informationNotFound'
export const TEST_PROFILE_NOT_FOUND = 'profileIndisponible'
export const TEST_SUBSEGMENTS_NOT_FOUND = 'subSegmentsNotFound'
export const TEST_PROFILE_ERROR = 'errosProfileParam'
export const FAIL_LOAD_INFORMATION = 'failLoadInformation'
export const FAIL_LOAD_LEVERAGE = 'failLoadLeverage'
export const FAIL_CART_RECOVERY = 'failRecoveryCart'
export const FAIL_CART_RECOVERY_PRE_SALE = 'failRecoveryCartPreSale'
export const FAIL_CART_RECOVERY_USER = 'failRecoveryCartByUser'
export const FAIL_CART_REMOVE = 'failRemoveItemCart'
export const FAIL_CART_UPDATE = 'failUpdateItemCart'
export const FAIL_CART_ADD = 'excededQuantity'
export const FAIL_IMPERSONATION = 'failImpersonation'
export const FAIL_ADD_GIFT = 'failAddGift'
export const FAIL_ADD_ADDRESS = 'failAddAddress'
export const FAIL_CHECKOUT = 'failChekout'
export const KEY_ORDER_EXCEEED = 'orderExceeded'

export const STRING_ERROR_ITEM_ADD = 'availability.commitment.notEnough.quota'
export const STRING_OUT_STOCK = 'availability.unknownError'
export const ERROR_ITEM_ADD = 'addItem/availability.commitment.notEnough.quota'
export const ERROR_QUANTITY_EXCEDED = 'excededQuantity'

// ERROR_CODES
export const ERROR_CODE_UNAVAILABLE_GIFT = '3003'
export const ERROR_CODE_NOT_FOUND_GIFT = '1001'
export const ERROR_CODE_UNAVAILABLE_GIFT_SIMPLE = '123'
export const ERROR_CODE_NOT_ENOUGH_QUOTA_EC = '12004'
export const ERROR_CODE_NOT_ENOUGH_QUOTA_CN = '12003'
export const ERROR_CODE_NOT_ENOUGH_QUOTA_ADD = '12003'
export const ERROR_CODE_NOT_FOUND_INVENTORY = '12005'
export const ERROR_CODE_INVALID_QUANTITY = '3006'
export const INVALID_QUANTITY_INCORPORATION = '9001'
export const ERROR_CODE_ORDER_EXCEED = 2030
export const ERROR_PENDING_APROVAL = 4
export const ERROR_PENDING_BY_CREDIT = 245
export const ERRO_BLOCK_BY_CREDIT_AVAILABLE = 2053

export const ERROR_CODE_UNAVAILABLE = '3003'

// ERROR_CODES CHECKOUT
export const DEBIT_PENDING = 3
export const CREDIT_EXCEDEE = 4
export const START_KIT_PENDING = 5
export const PAYMENT_PENDING = 6
export const USER_PENDING_BY_DEBIT = 2044
export const USER_PENDING_BY_CREDIT = 2045

// ERROR_KEY CHECKOUT
export const KEY_DEBIT_PENDING = 'debitPending'
export const KEY_CREDIT_EXCEDEE = 'creditExceed'
export const KEY_START_KIT_PENDING = 'startKitPending'
export const KEY_PAYMENT_PENDING = 'paymentPending'
export const KEY_USER_PENDING_DEBIT = 'userPendingDebit'
export const KEY_USER_PENDING_CREDIT = 'userPendingCredit'

// ERROR_KEY BLOCKS
export const KEY_CREDIT_PENDING = 'pendingByCredit'
export const KEY_APROVAL_PENDING = 'orderPendingAproval'

export const ERROR_3003 = 'error3003'

// ERROS MESSAGE
export const MESSAGE_PAYMENTY_CONDITION = 'payment.condition.not.found'

export const ERROR_AVAILABILITY = '1002 - failed.to.check.availability'


//STRIPE ERRORS
export const ERROR_FPX_EMPTY = "fpx_empty"
export const ERROR_OFFLINE_BANK = "offline_bank"
export const ERROR_INSUFFICIENT_FUNDS = "insufficient_funds"
export const ERROR_EXPIRED_CARD = "expired_card"
export const ERROR_INCORRECT_NUMBER = "incorrect_number"
export const ERROR_INCORRECT_CVC = "incorrect_cvc"
export const ERROR_INVALID_EXPIRY_YEAR = "invalid_expiry_year"
export const ERROR_INVALID_NUMBER = "invalid_number"
export const ERROR_INVALID_CVC = "invalid_cvc"
export const ERROR_EXPIRED = "expired_card"
export const ERROR_INCORRECT = "incorrect_number"
export const ERROR_CVC = "incorrect_cvc"
export const ERROR_MONTH = "invalid_expiry_month"
export const ERROR_YEAR = "invalid_expiry_year"
export const ERROR_NUMBER = "invalid_number"

//ACCEPTANCE TERMS
export const ERROR_GET_ACCEPTANCE_TERM = "error_get_acceptance_term"
export const ERROR_SET_ACCEPTANCE_TERM = "error_set_acceptance_term"