import { remove } from 'lodash'

export const setHomeAddressShipping = (state, action) => {
  const { addressShipping } = action.payload
  if (!addressShipping) {
    return { ...state }
  }
  return {
    ...state,
    homeAddressShipping: addressShipping && addressShipping.address
  }
}

export const setAddressShipping = (state, action) => {
  const { deliveryMode, addressShipping, selectedIndex } = action.payload
  if (!addressShipping) {
    return { ...state }
  }
  return {
    ...state,
    addressShipping,
    selected: {
      ...state.selected,
      type: deliveryMode,
      deliveryMode,
      selectedIndex,
      address: addressShipping && addressShipping.address,
      geographicalLevels: addressShipping.geographicalLevels
    },
    deliveryMode
  }
}

export const setDeliveryModeShipping = (state, action) => {
  const { deliveryMode, selectedIndex } = action.payload
  
  return {
    ...state,
    selected: {
      ...state.selected,
      type: deliveryMode,
      deliveryMode,
      selectedIndex,
    },
    deliveryMode
  }
}

export const setTypeShippingAndPrice = (state, action) => {
  const {
    objTypeAndPrice,
    addressPickUp,
    orderShippingReducer
  } = action.payload
  const { type, index } = objTypeAndPrice
  return {
    ...state,
    selected: {
      ...state.selected,
      type,
      addressPickUp,
      selectedIndex: index,
      deliveryMode: type,
      address:
        orderShippingReducer.selected && orderShippingReducer.selected.address
    }
  }
}

export const setShippingChangingOption = (state, action) => {
  const { shippingChanging } = action.payload
  return {
    ...state,
    shippingChanging
  }
}

export const setPickUpAddress = (state, action) => {
  const { addressPickUp } = action.payload
  return {
    ...state,
    addressPickUp
  }
}

export const disableShippingAddress = (state, action) => {
  const { enableShippingAddress } = action.payload
  return {
    ...state,
    enableShippingAddress
  }
}

export const setShippingSelectedOption = (state, action) => {
  const { objSelected } = action.payload
  const option = {
    type: objSelected.type,
    selectedIndex: objSelected.index,
    selected: objSelected.selected
  }
  remove(
    state.optionsShipping,
    (obj) =>
      obj.type.id === objSelected.type.id &&
      obj.selectedIndex === objSelected.index
  )
  return {
    ...state,
    optionsShipping: [...state.optionsShipping, option]
  }
}


