import { put, takeLatest } from 'redux-saga/effects'
import { isMobile, isTablet } from 'commons/helpers/device'
import { types as userSessionTypes } from '../userSession'

export const sagas = ({ creators }) => {
  function* onLoginSetResponsiveness() {
    yield takeLatest(userSessionTypes.AUTHENTICATE_USER_REQUEST, function*() {
      yield put(creators.setMobile(isMobile()))
      yield put(creators.setTablet(isTablet()))
    })
  }

  return [onLoginSetResponsiveness]
}
