import {
    SHOW_ACCEPTANTE_TERM,
    CLOSE_ACCEPTANTE_TERM,
    SET_ACCEPTANTE_TERMS,
    CLEAR_ACCEPTANTE_TERM,
  } from '../../actions/constants'
  
  import { initialState } from '../../../commons/interactor/acceptanceTerm/mapper'
  import {
    showAcceptanceTerm,
    setAcceptanceTerms,
  } from '../../../commons/interactor/acceptanceTerm/interactor'
  
  const acceptanceTermReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_ACCEPTANTE_TERM:
        return showAcceptanceTerm(state, true)
      case CLOSE_ACCEPTANTE_TERM:
        return showAcceptanceTerm(state, false)
      case SET_ACCEPTANTE_TERMS:
        return setAcceptanceTerms(state, action.payload)
      case CLEAR_ACCEPTANTE_TERM:
        return initialState
      default:
        return state
    }
  }
  
  export default acceptanceTermReducer
  