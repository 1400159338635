export const isDuplicated = (eventName, payload) => {
    try{
        const currentEventPayload = JSON.stringify(payload)
        const lastEventPayload = sessionStorage.getItem(eventName);
        
        if (lastEventPayload === currentEventPayload){
            sessionStorage.setItem(eventName, currentEventPayload);
            return true;
        }
        sessionStorage.setItem(eventName, currentEventPayload);
        return false
    }catch (e) {
        return false;
    }
  };

  export const isListDuplicated = (listName, listID) => {
    /* listID = home || bestOffer || promotions || vtr */
    try{
        switch(listID) {
            case "home":
                clearDuplicatedLists(["bestOffer", "vtr", "promotions"])
                break;
            case "bestOffer":
                clearDuplicatedLists(["home", "vtr", "promotions", "banner"])
                break;
            case "vtr":
                clearDuplicatedLists(["home", "bestOffer", "banner"])
                break;
            case "promotions":
                clearDuplicatedLists(["home", "bestOffer", "banner"])
                break;
        }

        let pushedLists = JSON.parse(sessionStorage.getItem(listID));
        if(!pushedLists){
            pushedLists = []
        }
        
        if (pushedLists.includes(listName)){
            return true;
        }
        pushedLists.push(listName)
        sessionStorage.setItem(listID, JSON.stringify(pushedLists));
        return false
    }catch (e) {
        return false;
    }
  }

  export const clearDuplicatedLists = (listIDs) => {
      listIDs.forEach(listID => {
        sessionStorage.removeItem(listID);
      });
  }