import React from 'react'
import { ThemeProvider } from '../contexts/ThemeContext'

import { MenuProvider } from './Menu'

function AppProvider({ children }){
  return (
    <ThemeProvider>
      <MenuProvider>{children}</MenuProvider>
    </ThemeProvider>
  )
}

export { AppProvider }
export * from './useTranslate'
export * from './useCartUid'
export * from './useDebouncedEffect'
export * from './useAuth'
