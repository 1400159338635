import { initialState } from 'commons/interactor/cart/mapper'
import * as interactor from 'commons/interactor/cart/interactor'

import {
  UPDATE_CART,
  CLEAR_REDURCERS,
  GO_TO_NEXT_STEP,
  GO_TO_PREV_STEP,
  GO_TO_SPECIFIC_STEP,
  UPDATE_CART_PROMOTIONS,
  UPDATE_CART_RESUME,
  GET_ORDER_SHIPPING,
  SET_MINIMUM_ORDER_PRICE,
  CLEAR_CART,
  SELECT_GIFT_CART,
  RENEW_GIFTS,
  SET_STATUS_ID_CART,
  SELECT_GIFT_OPTION,
  ENABLE_DISABLE_NEXT,
  SET_MINE,
  SET_CLEAR,
  CART_FINISH,
  CLEAR_REDUCER_IMPERSONATION,
  CLEAR_STEP,
  CART_SUBSCRIBE,
  SET_METHOD_PAYMENT,
  SET_ERROR_STRIPE,
  PAYMENT_CONFIRM_INTENT,
  SET_MIN_SPLIT_VALUE_CN,
  SET_CHANGE_PAYMENT_ERROR,
  UPDATE_STEPS
} from '../../actions/constants'

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MIN_SPLIT_VALUE_CN:
      return interactor.setMinSplitValueCN(state, action)
    case SET_ERROR_STRIPE: 
      return interactor.setErrorStripe(state, action)
    case SET_METHOD_PAYMENT: 
      return interactor.setPaymentMethod(state, action)
    case PAYMENT_CONFIRM_INTENT:
      return interactor.setConfirmPaymentIntent(state, action)  
    case UPDATE_CART:
      return interactor.updateCartList(state, action)
    case GO_TO_NEXT_STEP:
      return interactor.goToNextStepReducer(state, action)
    case GO_TO_PREV_STEP:
      return interactor.goToPrevStepReducer(state, action)
    case GO_TO_SPECIFIC_STEP:
      return interactor.goToSpecificStepReducer(state, action)
    case UPDATE_CART_RESUME:
      return interactor.updateCartResume(state, action)
    case UPDATE_CART_PROMOTIONS:
      return interactor.updateCartPromotions(state, action)
    case GET_ORDER_SHIPPING:
      return interactor.getOrderShipping(state, action)
    case SET_MINIMUM_ORDER_PRICE:
      return interactor.setMinimumOrderPrice(state, action)
    case SELECT_GIFT_CART:
      return interactor.selectGift(state, action)
    case RENEW_GIFTS:
      return interactor.renewGifts(state)
    case SELECT_GIFT_OPTION:
      return interactor.selectGiftOption(state, action)
    case SET_STATUS_ID_CART:
      return interactor.updateStatusIdCart(state, action)
    case ENABLE_DISABLE_NEXT:
      return interactor.enableDisableNext(state, action)
    case SET_MINE:
      return interactor.setMine(state, action)
    case SET_CLEAR:
      return { ...state, isClear: action.clear }
    case CART_FINISH:
      return { ...state, isFinish: action.finish }
    case CLEAR_STEP:
      return { ...state, steps: { ...state.steps, stepSelected: 0 } }
    case UPDATE_STEPS:
      return interactor.updateSteps(state, action)
    case CART_SUBSCRIBE:
      return { ...state, subscribe: action.subscribe }
    case SET_CHANGE_PAYMENT_ERROR:
      return { ...state, changePaymentError: action.payload }
    case CLEAR_REDURCERS:
    case CLEAR_CART:
    case CLEAR_REDUCER_IMPERSONATION:
      return initialState
    default:
      return state
  }
}

export default cartReducer
