import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import ModalReducer from './modal/modalReducer'
import EnvironmentReducer from './environments/environmentReducer'
import AlertsReducer from './alerts/alertsReducer'
import bagReducer from './bag/bagReducer'
import cartReducer from './cart/cartReducer'
import searchReducer from './search/searchReducer'
import { customSearch } from './search/customSearchReducer'
import productsReducer from './products/productsReducer'
import navigationReducer from './router/routerReducer'
import toastReducer from './toast/toastReducer'
import selectConsultantReducer from './selectConsultant/selectConsultantReducer'
import orderShippingReducer from './cart/orderShippingReducer'
import sorterSearchReducer from './sorter/search/sorterSearchReducer'
import parameterReducer from './parameter/parameterReducer'
import loadReducer from './load/loadReducer'
import dataLayerReducer from './dataLayer/dataLayerReducer'
import companyBrandReducer from './companyBrand/companyBrandReducer'
import acceptanceTermReducer from './acceptanceTerm/acceptanceTermReducer'
import errorReducer from './errors/errorReducer'
import { history } from '../../history'
import splitReducer from '../../scenes/Split/actions/split.reducer';
import sucursalReducer from '../../scenes/Sucursales/actions/sucursal.reducer'
import orderSummaryReducer from '../../scenes/OrderSummary/actions/orderSummary.reducer';
import customerReducer from '../../scenes/Customer/actions/customer.reducer';
import taxesReducer from './taxes/taxesReducer'
import graphql from './graphql/graphqlReducer'
import { reducer as userSession } from '../modules/userSession'
import { reducer as i18n } from '../modules/i18n'
import { reducer as responsiveness } from '../modules/responsiveness'

const rootReducer = combineReducers({
  router: connectRouter(history),
  errors: errorReducer,
  graphql,
  ModalReducer,
  EnvironmentReducer,
  AlertsReducer,
  searchReducer,
  bagReducer,
  navigationReducer,
  toastReducer,
  productsReducer,
  loadReducer,
  selectConsultantReducer,
  orderShippingReducer,
  cartReducer,
  sorterSearchReducer,
  customSearch,
  parameterReducer,
  dataLayerReducer,
  splitReducer,
  customerReducer,
  sucursalReducer,
  orderSummaryReducer,
  i18n,
  userSession,
  responsiveness,
  companyBrandReducer,
  acceptanceTermReducer,
  taxesReducer
})

export default rootReducer
