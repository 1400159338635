import { push, goBack } from 'connected-react-router'

import { orderNavigateManagerAction } from 'commons/interactor/router/interactor'
import {
  bagIsStepComplete,
} from 'commons/interactor/bag/interactor'
import { clearStep } from 'redux/actions/cart/cartAction'
import { clearMenu, getCartMarketing } from 'redux/actions/parameters/parameterAction'
import { CLEAR_REDUCER_IMPERSONATION } from 'redux/actions/constants'
import { creators as userSession, } from 'redux/modules/userSession'


import { SET_SELECTED_MENU_ITEM, OPEN_SEARCH } from '../constants'

import {
  ROOT,
  LOGIN,
  HOME,
  ORDER,
  ORDER_SEARCH,
  SELECT_CONSULTANT,
  CART,
  TEST_PROFILE,
  SPLIT,
  SUMMARY,
  ORDERS,
  SALES_OPPORTUNITY,
} from './constants'

export const setSelectedMenu = item => (dispatch) => {
  dispatch({ type: SET_SELECTED_MENU_ITEM, item })
  dispatch(clearStep())
}

export const navigateSwitching = () => (dispatch) => {
  dispatch(push({
    pathname: ROOT,
    state: {
      from: window.location.pathname
    }
  }))
}

export const setRedirection = () => {
  const countryCode = sessionStorage.getItem('countryLanguage')
  if (countryCode === 'EC') {
    if (process.env.NODE_ENV === 'quality') {
      window.location.href = 'https://gsp-mfe.qa.naturacloud.com/'
    } else if (process.env.NODE_ENV === 'production') {
      window.location.href = 'https://gsp-mfe.prd.naturacloud.com/'
    } else if (process.env.NODE_ENV === 'homolog') {
      window.location.href = 'https://gsp-mfe.hml.naturacloud.com/'
    } else if (process.env.NODE_ENV === 'development') {
      window.location.href = 'https://gsp-mfe.qa.naturacloud.com/'
    }
  } else {
    navigateTo(HOME)
  }
}

export const navigateSelectConsultant = () => dispatch =>
  dispatch(push({
    pathname: SELECT_CONSULTANT,
    state: {
      from: window.location.pathname
    }
  }))

export const navigateLogin = () => (dispatch) => {
  dispatch(push({
    pathname: LOGIN,
    state: {
      from: window.location.pathname
    }
  }))

  dispatch(userSession.resetLogin())
}

export const navigateHome = () => dispatch => dispatch(push(HOME));

export const navigateOrder = () => dispatch => dispatch(push({
  pathname: ORDER,
  state: {
    from: window.location.pathname
  }
}))

export const navigateToSplit = () => dispatch => dispatch(push({
  pathname: SPLIT,
  state: {
    from: window.location.pathname
  }
}))

export const navigateToOrders = () => dispatch => dispatch(push({
  pathname: ORDERS,
  state: {
    from: window.location.pathname
  }
}))

export const navigateToSummary = () => dispatch => dispatch(push({
  pathname: SUMMARY,
  state: {
    from: window.location.pathname
  }
}))

export const navigateOrderSearch = () => dispatch =>
  dispatch(push({
    pathname: ORDER_SEARCH,
    state: {
      from: window.location.pathname
    }
  }))

export const navigateLogOut = () => (dispatch) => {
  dispatch(navigateSwitching())
}

export const navigateToLogOut = () => async (dispatch) => {
  if (global.authService) {
    await global.authService.logout()
  }

  sessionStorage.removeItem('cartUid')
  dispatch(navigateLogOut())
}

export const navigateCart = () => (dispatch) => {
  dispatch(getCartMarketing())
  dispatch({ type: OPEN_SEARCH, searchIsOpen: false })
  dispatch(push({
    pathname: CART,
    state: {
      from: window.location.pathname
    }
  }))
}

export const navigateTestProfile = () => dispatch => dispatch(push({
  pathname: TEST_PROFILE,
  state: {
    from: window.location.pathname
  }
}))

export const navigateGoBack = () => (dispatch, getState) => {
  const {
    cartReducer: { steps }
  } = getState()

  if (steps.stepSelected === 0) return dispatch(navigateOrder())

  dispatch(goBack())
}

export const navigateTo = path => dispatch => dispatch(navigateManager(path))

// eslint-disable-next-line require-await
const navigateManager = path => async (dispatch, getState) => {
  const clearBag = bagIsStepComplete(getState)
  switch (path) {
    case HOME:
      dispatch(orderNavigateManagerAction(getState, HOME))
      break
    case ORDER:
      dispatch(orderNavigateManagerAction(getState, ORDER))
      break
    case ROOT:
    case ORDERS:
    case SALES_OPPORTUNITY:
      dispatch(push({
        pathname: path,
        state: {
          from: window.location.pathname
        }
      }))
      break
    case SELECT_CONSULTANT:
      dispatch({ type: CLEAR_REDUCER_IMPERSONATION })
      dispatch(clearMenu())
      return dispatch(navigateSelectConsultant())
    default: {
      const countryCode = sessionStorage.getItem('countryLanguage')
      if (countryCode === 'EC') {
        setRedirection()
      } else {
        dispatch(push({
          pathname: path,
          state: {
            from: window.location.pathname
          }
        }))
      }
      break
    }
  }
}
