import { isDuplicated, isListDuplicated } from 'dataLayer/structuring/helper'

/** New version for list push*/

export const structureSelectedDisplay = (displayType, display) => {
  const eventName = "selectedDisplay"
  return {
      event: eventName,
      displayType,
      display
    }
}

export const structureListView = (listName, products, listID) => {
  try{
    const eventName = 'listView'
    if(!isListDuplicated(listName, listID)){
      return {
        event: eventName,
        listName,
        products,
      }
    }
  }catch (exception){
    console.error('Error structuring listView data: ', exception)
  }
}

export const setVtrNavigationOrigin = (state, payload) => {
  try {
    return {
      ...state,
      vtrNavigationOrigin: payload
    }
  } catch (exception) {
    console.error('Error structuring listView data: ', exception)
    return state
  }
}

export const structureSearchListView = (listName, searchTerm, products) => {
  try{
    const eventName = 'searchResults'
    if(!isDuplicated(eventName, {listName, products}) && searchTerm !== ""){
      return {
        event: eventName,
        codeOrName: Number.isNaN(Number.parseInt(searchTerm, 10)) ? "name" : "code",
        listName,
        searchedProducts: products,
        searchTerm
      }
    }
  }catch (exception){
    console.error('Error structuring listView data: ', exception)
  }
}

/** Old version for list push */
export const structureListData = (payload, products) => {
  try {
    const { description } = payload.selected.leaverage
    return {
      event: 'listData',
      listName: `VTR - ${description}`,
      listType: undefined,
      products: products
    }
  } catch (exception) {
    console.error('Error structuring listData:', exception)
    return undefined
  }
}

export const structureSearchListData = (payload, products) => {
  try {
    const { searchLabel } = payload
    return {
      event: 'listData',
      listName: 'Search',
      listType: undefined,
      searchTerm: searchLabel,
      products: products
    }
  } catch (exception) {
    console.error('Error structuring listData on search page', exception)
    return undefined
  }
}

export const structureNBOData = payload => ({
  event: 'listData',
  listType: 'NBO',
  listName: `NBO_${payload}`
})

export const structureNextCycleListData = payload => ({
  event: 'listData',
  listType: 'Next Cycle',
  listName: payload
})

export const getListingListName = (payload, isVitrine) => {
  try {
    const {
      selected: {
        leaverage: { name, description }
      }
    } = payload
    return !isVitrine
      ? `${description}`
      : `VTR - ${description}`
  } catch (exception) {
    console.error(
      'Error structuring getListingListName from the selection',
      exception
    )
    return undefined
  }
}

export const getSearchListName = payload => {
  try {
    return `Search - ${payload.searchLabel}`
  } catch (exception) {
    console.error(
      'Error structuring getSearchListName from the search term',
      exception
    )
    return undefined
  }
}
