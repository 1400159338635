import { LOGIN, SELECT_CONSULTANT, CART, HOME, INCORPORATIONS, INTERACTIVE_MAGAZINE} from 'redux/actions/router/constants'
import {
  INCORPORATIONS_MENU_ID,
  LOGOUT_MENU_ID,
  LOGOUT_IMPERSON_MENU_ID,
  HOMEPAGE_MENU_ID,
  MAKE_ORDER_MENU_ID,
} from 'commons/constants/router'

export const initialState = {
  menu: [
    {
      id: HOMEPAGE_MENU_ID,
      active: true,
      icon: 'outlined-action-closedbox',
      title: 'menuOptionHome',
      to: HOME
    },
    {
      id: INCORPORATIONS_MENU_ID,
      active: false,
      icon: 'outlined-content-agility',
      title: 'menuOptionIncorporations',
      badge: 'incorporation-size',
      to: INCORPORATIONS
    },
    {
      id: MAKE_ORDER_MENU_ID,
      active: false,
      icon: 'outlined-action-closedbox',
      title: 'menuOptionMakeOrder',
      to: CART
    },
    {
      id: LOGOUT_MENU_ID,
      active: false,
      icon: 'outlined-navigation-exit',
      title: 'menuOptionLoggout',
      to: LOGIN
    }
  ],
  bannerDefault: ""
}

export const initialStateImperson = {
  menu: [
    {
      id: HOMEPAGE_MENU_ID,
      active: true,
      icon: 'outlined-action-closedbox',
      title: 'menuOptionHome',
      to: HOME
    },
    {
      id: MAKE_ORDER_MENU_ID,
      active: false,
      icon: 'outlined-action-closedbox',
      title: 'menuOptionMakeOrder',
      to: CART
    },
    {
      id: LOGOUT_IMPERSON_MENU_ID,
      active: false,
      icon: 'outlined-action-cycle',
      title: 'menuOptionLoggoutImperson',
      to: SELECT_CONSULTANT
    },
    {
      id: LOGOUT_MENU_ID,
      active: false,
      icon: 'outlined-navigation-exit',
      title: 'menuOptionLoggout',
      to: LOGIN
    }
  ],
  bannerDefault: ""
}

export const initialStateClean = {
  menu: [
    {
      id: LOGOUT_MENU_ID,
      active: false,
      icon: 'outlined-navigation-exit',
      title: 'menuOptionLoggout',
      to: LOGIN
    }
  ],
  bannerDefault: ""
}
