import { ROOT } from '../../../redux/actions/constants'

const SCENE_NAME = 'SUCURSAL'

export default {
  // Loading
  SET_SUCURSAL_FILTER_LOADING: `${ROOT}/${SCENE_NAME}/SET_SUCURSAL_FILTER_LOADING`,
  SET_SUCURSAL_AGENCY_LOADING: `${ROOT}/${SCENE_NAME}/SET_SUCURSAL_AGENCY_LOADING`,

  // Geographic structure tree search
  FETCH_GEO_TREE: `${ROOT}/${SCENE_NAME}/FETCH_GEO_TREE`,
  FETCH_GEO_STRUCTURE: `${ROOT}/${SCENE_NAME}/FETCH_GEO_STRUCTURE`,
  SET_NEXT_GEO_STRUCTURE_LEVEL: `${ROOT}/${SCENE_NAME}/SET_NEXT_GEO_STRUCTURE_LEVEL`,
  INIT_GEO_STRUCTURE_LEVEL_BY_ADDRESS: `${ROOT}/${SCENE_NAME}/INIT_GEO_STRUCTURE_LEVEL_BY_ADDRESS`,
  SET_NEXT_GEO_STRUCTURE_LEVEL_BY_ADDRESS: `${ROOT}/${SCENE_NAME}/SET_NEXT_GEO_STRUCTURE_LEVEL_BY_ADDRESS`,
  SET_ADDRESS_FILTER_GEO_STRUCTURE_TREE: `${ROOT}/${SCENE_NAME}/SET_ADDRESS_FILTER_GEO_STRUCTURE_TREE`,
  CLEAR_GEO_STRUCTURE_TREE: `${ROOT}/${SCENE_NAME}/CLEAR_GEO_STRUCTURE_TREE`,

  // General
  SET_FIELD: `${ROOT}/${SCENE_NAME}/SET_FIELD`,
  SET_ADDRESS: `${ROOT}/${SCENE_NAME}/SET_ADDRESS`,
  CLEAR_ADDRESS: `${ROOT}/${SCENE_NAME}/CLEAR_ADDRESS`,
  CLEAR_NEXT_ADDRESSESS: `${ROOT}/${SCENE_NAME}/CLEAR_NEXT_ADDRESSESS`,

  // AGENCIES
  SET_AGENCIES: `${ROOT}/${SCENE_NAME}/SET_AGENCIES`,
  CLEAR_AGENCY_LIST: `${ROOT}/${SCENE_NAME}/CLEAR_AGENCY_LIST`,
  CLEAR_NEXT_ADDRESS_LEVEL: `${ROOT}/${SCENE_NAME}/CLEAR_NEXT_ADDRESS_LEVEL`,
  INCREMENT_NUMBER_PAGINATION: `${ROOT}/${SCENE_NAME}/INCREMENT_NUMBER_PAGINATION`,
  DECREMENT_NUMBER_PAGINATION: `${ROOT}/${SCENE_NAME}/DECREMENT_NUMBER_PAGINATION`,
  SET_CURRENT_PAGINATION: `${ROOT}/${SCENE_NAME}/SET_CURRENT_PAGINATION`,
  SET_SELECTED_AGENCY: `${ROOT}/${SCENE_NAME}/SET_SELECTED_AGENCY`
}
