import Product from 'natura-commons/service/Product'

import { mapProduct } from 'commons/interactor/products/mapper'
import { navigateTo } from 'redux/actions/router/routerAction'
import {
  listProducts,
  clearListProducts,
  clearListProductsSimilar
} from 'redux/actions/products/productsActions'
import { verifyError } from 'redux/actions/alerts/alertsAction'

import {
  SEARCH_RECEIVE,
  SEARCH_REQUEST,
  SEARCH_INVALIDATE,
  SEARCH_CANCEL_REQUEST,
  SEARCH_ERROR,
  SEARCH_FILTER,
  SELECT_ITEM,
  OPEN_SEARCH,
  RECENT_SEARCHED_PRODUCTS,
  SET_LEAVERAGE_SEARCH,
  CLEAR_SELECTED_ITEM
} from '../constants'
import { clearSorterAction } from 'redux/actions/sorter/search/sorterSearchAction'
import { paginedPromotions } from 'redux/actions/products/productsActions'
const products = new Product()

export const cancelSearchRequest = () => {
  products.cancel()
  return {
    type: SEARCH_CANCEL_REQUEST,
    receivedAt: Date.now()
  }
}
const recieveRecentItems = items => ({
  type: RECENT_SEARCHED_PRODUCTS,
  items,
  receivedAt: Date.now()
})

export const recieveLeaverageItems = items => ({
  type: SET_LEAVERAGE_SEARCH,
  leaverageItems: items,
  receivedAt: Date.now()
})

const requestItems = searchLabel => ({
  type: SEARCH_REQUEST,
  searchLabel,
  receivedAt: Date.now()
})

const searchError = error => ({
  type: SEARCH_ERROR,
  error
})
const filterItems = searchLabel => async dispatch => {
  dispatch(requestItems(searchLabel))
  dispatch({
    type: SEARCH_FILTER,
    searchLabel,
    receivedAt: Date.now()
  })
}
export const receiveItems = (searchLabel, products) => {
  return {
    type: SEARCH_RECEIVE,
    searchLabel,
    items: products,
    receivedAt: Date.now()
  }
}
const searchItems = searchLabel => async (dispatch, getState) => {
  products.cancel()
  dispatch(requestItems(searchLabel))
  const {
    userSession: {
      commercialInfo: { countryCode, companyId }
    },
    customSearch: { items, lastSearchLabel },
    cartReducer: {
      resume: { totalMinOrderSize }
    }
  } = getState()

  const cartSize = totalMinOrderSize?.map(
    item => ({ companyBrand: item.companyBrandIds, value: item.totalMinOrderSizeByBrand })
  )

  if (items && items.length && searchLabel === lastSearchLabel) {
    dispatch(receiveItems(searchLabel, items))
    dispatch(listProducts(items))
    return
  }
  products.resolver(
    response => {
      const products = mapProduct(response.product)
      dispatch(receiveItems(searchLabel, products != null ? products : []))
      dispatch(listProducts(products))
      dispatch(clearSorterAction())
    },
    error => {
      if (!error.cancellation) {
        dispatch(searchError())
      }
      verifyError(dispatch, error)
    },
    products.findProduct({ key: searchLabel, countryCode, companyId, cartSize })
  )
}

const shouldSearchItems = (state, searchLabel) => {
  const { customSearch } = state
  if (searchLabel != null && searchLabel.length >= 2) {
    return true
  }
  if (customSearch.isFetching) {
    return false
  }
  return customSearch.didInvalidate
}

const shouldFilterItems = (state, searchLabel) => {
  const { customSearch } = state
  if (
    customSearch &&
    customSearch.searchLabel &&
    customSearch.searchLabel.length > 0 &&
    searchLabel.startsWith(customSearch.searchLabel) &&
    customSearch.items &&
    customSearch.items.length > 0
  ) {
    return true
  }
  return false
}
export const clearSelection = () => dispatch => {
  dispatch({ type: CLEAR_SELECTED_ITEM })
}
export const selectSearchItem = (
  item,
  goToPage = null,
  filter = true
) => dispatch => {
  dispatch(searchItemsIfNeeded(item.name, filter))
  if (goToPage != null || item.navigateToPath != null) {
    const navToPage = goToPage != null ? goToPage : item.navigateToPath
    dispatch(navigateTo(navToPage))
  }
}

export const setSelectedItem = item => dispatch =>
  dispatch({ type: SELECT_ITEM, selectedItem: item })

export const searchItemsIfNeeded = (searchLabel, filter = true) => (
  dispatch,
  getState
) => {
  dispatch(clearListProducts())
  dispatch(clearListProductsSimilar())
  if (filter && shouldFilterItems(getState(), searchLabel)) {
    return dispatch(filterItems(searchLabel))
  }
  if (shouldSearchItems(getState(), searchLabel)) {
    return dispatch(searchItems(searchLabel))
  }
}
export const openSearch = isOpen => dispatch => {
  dispatch({ type: OPEN_SEARCH, searchIsOpen: isOpen })
}

export const searchItemsVitrine = searchLabel => dispatch => {
  if (searchLabel)
    dispatch({ type: SEARCH_FILTER, searchLabel, receivedAt: Date.now() })

  dispatch(paginedPromotions(true))
}
