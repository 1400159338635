import { parseISO } from 'date-fns'
import { uniqBy } from 'lodash'

import types from './customer.types'

const initialState = {
  fields: {
    documentType: '',
    documentId: '',
    fullName: '',
    email: '',
    address: [],
    countryGeoStructureLevel: {},
    isEmailValid: true,
    phone: {
      areaCode: '',
      countryCode: '',
      phoneNumber: '',
      type: ''
    },
    termAcceptanceCheck: false,
    birthday: new Date(),
    isTermAcceptanceOpen: false,
    isTermAcceptanceRequiredOpen: false,
    isCreatedMessageOpen: false,
    isCreateError: false,
    isFormValid: true,
    showAddCustomer: false,
    registerNewCustomer: false,
    selectedPerson: {},
  },
  lists: {
    documentTypes: [],
    geoStructureTree: [],
  }
}

const getCustomerByDocument = (state, payload) => {
  if (payload.customerUid) {
    return getCustomer(state, payload)
  }

  if (Object.keys(payload).length === 0) {
    return {
      ...state,
      fields: {
        ...state.fields,
        isFormValid: true,
        fullName: initialState.fields.fullName,
        email: initialState.fields.email,
        address: [...initialState.fields.address, state.fields.countryGeoStructureLevel],
        phone: { ...initialState.fields.phone },
        birthday: initialState.fields.birthday,
        registerNewCustomer: true,
        countryGeoStructureLevel: state.fields.countryGeoStructureLevel,
      }
    }
  }

  return {
    ...state,
    fields: {
      ...state.fields,
      showAddCustomer: true,
      registerNewCustomer: false,
      isFormValid: true,
      selectedPerson: {
        name: payload.name,
        personId: payload.personId,
      }
    }
  }
}

const getCustomer = (state, payload) => {
  const customerAddress = formatGeoStructureLevel(payload.addresses[0].geographicalLevels.sort((curr, next) => curr.level - next.level))
  const geoStructureTree = getGeoStructureLevels(customerAddress, state.lists.geoStructureTree)

  const birthday = payload.birthday ? parseISO(payload.birthday.slice(0, 10)) : new Date();

  return {
    ...state,
    fields: {
      ...state.fields,
      isFormValid: true,
      registerNewCustomer: false,
      fullName: payload.name,
      email: payload.email,
      address: customerAddress,
      birthday,
      personId: payload.personId,
      phone: {
        areaCode: payload.phoneAreaCode,
        countryCode: payload.phoneCountryCode,
        phoneNumber: payload.phoneNumber,
        type: payload.phoneType
      },
      roles: payload.rolesFunctions,
      countryGeoStructureLevel: state.fields.countryGeoStructureLevel,
    },
    lists: {
      ...state.lists,
      geoStructureTree,
    }
  }
}

const formatGeoStructureLevel = (levels = []) => {
  const newArray = []
  levels.map((level, index) => {
    const currentLevel = level.geoStructureLevelId >= 0 ? level.geoStructureLevelId : level.level

    newArray[currentLevel] = {
      code: +level.geoStructureCode || +level.code,
      description: level.geoStructureDescription || level.description,
      level: currentLevel,
      nextLevel: level.nextLevel || [],
      parentStructureCode: level.parenteGeoStructureCode || +(levels[index - 1] || {}).code || null,
    }
  })

  return newArray
}

const getGeoStructureLevels = (newStructureLevels, structureLevels = []) => (
  newStructureLevels.reduce(
    (acc, currentValue) => {
      const currentLevel = `level_${currentValue.geoStructureLevelId >= 0 ? currentValue.geoStructureLevelId : currentValue.level}`
      const value = currentValue
      if (acc[currentLevel]) {
        return {
          ...acc,
          [currentLevel]: uniqBy([
            ...acc[currentLevel],
            value
          ], 'code'),
        }
      }

      return {
        ...acc,
        [currentLevel]: [value]
      }
    },
    { ...structureLevels }
  )
)

const customerReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [payload.field]: payload.value
        },
        lists: {
          ...state.lists
        }
      }

    case types.SET_ADDRESS:
      const { level } = payload.value
      const { address } = state.fields

      address[level] = payload.value

      return {
        ...state,
        fields: {
          ...state.fields,
          address,
        },
      }

    case types.SET_PHONE:
      return {
        ...state,
        fields: {
          ...state.fields,
          phone: {
            ...state.fields.phone,
            [payload.field]: payload.value
          }
        },
        lists: {
          ...state.lists
        }
      }

    case types.FETCH_GEO_TREE:
      const geoList = !payload.value ? [] :
        payload.value.map(({
          geoStructureCode,
          geoStructureDescription,
          nextLevel,
          geoStructureLevelId
        }) => ({
          geoStructureCode,
          geoStructureDescription,
          nextLevel,
          geoStructureLevelId
        })
        )
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          [payload.list]: geoList
        }
      }

    case types.SET_NEXT_GEO_STRUCTURE_LEVEL:
      const geoStructureLevels = getGeoStructureLevels(payload.structureLevels, state.lists.geoStructureTree)

      return {
        ...state,
        fields: {
          ...state.fields,
          countryGeoStructureLevel: payload.countryGeoStructureLevel || state.fields.countryGeoStructureLevel,
        },
        lists: {
          ...state.lists,
          geoStructureTree: geoStructureLevels,
        }
      }

    case types.FETCH_DOCUMENTS:
      const documentTypesList = payload.documents.map(document => ({
        name: document.name,
        id: document.id
      }))
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          documentTypes: documentTypesList
        }
      }

    case types.ADD_CUSTOMER:
    case types.ASSIGN_CUSTOMER_ROLE:
      return {
        ...state,
        fields: {
          ...state.fields,
          isCreatedMessageOpen: payload.data && Object.keys(payload.data).length > 0,
          isCreateError: payload.error || false
        }
      }

    case types.GET_CUSTOMER_BY_DOCUMENT:
      return getCustomerByDocument(state, payload)

    case types.CLEAR_CUSTOMER:
      return {
        fields: {
          ...initialState.fields,
        },
        lists: {
          ...initialState.lists
        }
      }

    default:
      return { ...state }
  }
}

export default customerReducer
