import {
  SHOW_LOAD,
  LOAD_AVAILABILITY,
  CLEAR_REDURCERS
} from '../../actions/constants'
import { initialState } from '../../../commons/interactor/load/mapper'
import { showLoadReducer, showDiscreteLoadReducer } from '../../../commons/interactor/load/interactor'

const loadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOAD:
      if (action.discrete) {
        return showDiscreteLoadReducer(state, action.payload)
      }
      return showLoadReducer(state, action.payload)
    case LOAD_AVAILABILITY:
      return { ...state, showLoadAvailability: action.payload }
    case CLEAR_REDURCERS:
      return initialState
    default:
      return state
  }
}

export default loadReducer
