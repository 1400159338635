export const showToastReducer = (state, toast) => ({
  ...state,
  icon: toast.icon,
  svgIcon: toast.svgIcon,
  title: toast.title,
  text: toast.text,
  textConfirm: toast.textConfirm,
  type: toast.type,
  show: toast.show !== undefined ? toast.show : true,
  autoHide: toast.autoHide !== undefined ? toast.autoHide : true,
  allowHide: toast.allowHide !== undefined ? toast.allowHide : true,
  clickDismmiss: toast.clickDismmiss !== undefined ? toast.clickDismmiss : true,
  timeout: toast.timeout,
  hideIcon: toast.hideIcon || false,
  footer: toast.footer || false,
})
