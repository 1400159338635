/* eslint-disable import/no-unresolved */
import {
  getAlertError,
  errorsDescription,
  getAlertErrorStripe
} from 'commons/presenter/order/home/presenter'
import * as interactor from 'commons/interactor/alerts/interactor'
import * as constants from 'commons/constants/error'
import { getAlertExcededQuantity } from 'commons/presenter/errors/modalExcededQuantity'
import Translate from 'natura-commons/translate'

import {
  SET_ALERTS,
  SET_ALERTS_READ,
  UPDATE_ALERTS,
  ADD_ITEM_ERROR
} from '../constants'

const translate = new Translate()

export const setAlerts = arrAlerts => (dispatch, getState) => {
  if (!arrAlerts || !arrAlerts.length)
    return dispatch({ type: SET_ALERTS, payload: [] })
  const {
    AlertsReducer: { alerts }
  } = getState()

  arrAlerts.map(alert => {
    const any = alerts.find(item => item.body === alert.body)
    if (!any) {
      dispatch({ type: SET_ALERTS, payload: arrAlerts })
    }
  })
}

export const updateAlerts = alerts => ({
  type: UPDATE_ALERTS,
  payload: alerts
})

export const setAlertAddress = objAlert => ({
  type: SET_ALERTS,
  payload: interactor.getAlertAddress(objAlert)
})

export const setAlertsRead = bool => ({
  type: SET_ALERTS_READ,
  payload: bool
})

export const getBlocks = () => async (dispatch, getState) => {
  const alerts = await interactor.getBlocksUserAction(getState)
  dispatch(setAlerts(alerts))
}

export const getAlertCancelPooling = () => dispatch => {
  dispatch({ type: ADD_ITEM_ERROR })
  dispatch(setAlerts([getAlertError([{ code: 90003 }], false, null, dispatch)]))
}

export const verifyAlerts = (
  onNavigationTo,
  handleConfirm,
  onPressOne,
  onPressTwo,
  onChangeValueProfile
) => async (dispatch, getState) => {
  const pendencies = await interactor.getPendenciesAction(
    onNavigationTo,
    getState,
    handleConfirm,
    onPressOne,
    onPressTwo,
    onChangeValueProfile
  )
  const blocks = await interactor.getBlocksAction(
    getState,
    handleConfirm,
    onPressOne,
    onPressTwo,
    onChangeValueProfile
  )
  const alerts = [...new Set([...pendencies, ...blocks])]
  dispatch(setAlerts(alerts))
}

export const errorIgnore = errors => {
  const error = errorsDescription(errors)
  const errorJson = JSON.stringify(error)
  return errorJson.includes(constants.ERROR_AVAILABILITY)
}

// eslint-disable-next-line arrow-parens
export const verifyErrorCode = error => {
  let errorJson =
    error.message != null ? error.message : JSON.stringify(error)

  if (error?.extensions?.response?.body?.message) {
    errorJson = error.extensions.response.body.message
  }

  let errorCode = ''
  if (errorJson.includes(constants.STRING_BAD_GATEWAY)) {
    errorCode = constants.ERROR_BAD_GATEWAY
  } else if (errorJson.includes(constants.STRING_GATEWAY_TIMEOUT)) {
    errorCode = constants.ERROR_GATEWAY_TIMEOUT
  } else if (
    errorJson.includes(constants.STRING_ERROR_NETWORK) ||
    errorJson.includes(constants.STRING_ERROR_NETWORK_FIREBASE)
  ) {
    errorCode = constants.ERROR_NETWORK
  } else if (errorJson.includes(constants.STRING_ERROR_GRAPHQL_PROPERTY)) {
    errorCode = constants.ERROR_GRAPHQL_PROPERTY
  } else if (errorJson.includes(constants.STRING_ERROR_GRAPHQL_SYNTAX)) {
    errorCode = constants.ERROR_GRAPHQL_SYNTAX
  } else if (errorJson.includes(constants.STRING_CART_NOT_FOUND)) {
    errorCode = constants.CART_NOT_FOUND
  } else if (errorJson.includes(constants.STRING_CART_CONVERTING) ||
    errorJson.includes(constants.STRING_CART_CONVERTING_ERROR) ||
    errorJson.includes(constants.STRING_CART_PENDING_ORDER)) {
    errorCode = constants.CART_CONVERTING
  } else if (errorJson.includes(constants.STRING_CART_INVALID_DELIVERYMODE)) {
    errorCode = constants.CART_INVALID_DELIVERYMODE
  } else if (errorJson.includes(constants.STRING_PAYMENT_CONDITION_NOT_FOUND)) {
    errorCode = constants.PAYMENT_CONDITION_NOT_FOUND
  } else if (errorJson.includes(constants.STRING_INTERNAL_SERVER_ERROR)) {
    errorCode = constants.INTERNAL_SERVER_ERROR
  } else if (errorJson.includes(constants.STRING_ERROR_GRAPHQL_ENTITY)) {
    errorCode = constants.ERROR_GRAPHQL_ENTITY
  } else if (
    errorJson.includes(constants.STRING_ERROR_ITEM_ADD) ||
    errorJson.includes(constants.STRING_OUT_STOCK) ||
    errorJson.includes(constants.ERROR_CODE_UNAVAILABLE_GIFT)
  ) {
    errorCode = constants.ERROR_ITEM_ADD
  } else if (
    errorJson.includes(constants.ERROR_GET_ACCEPTANCE_TERM) ||
    errorJson.includes(constants.ERROR_SET_ACCEPTANCE_TERM)
  ) {
    errorCode = constants.ERROR_GET_ACCEPTANCE_TERM
  }
  return errorCode
}

export const verifyError = (
  dispatch,
  error,
  redirectLogin = false,
  errorKey = null,
  isTestProfile = false
) => {
  if (error && !error.cancellation) {
    const errorCode = verifyErrorCode(error)
    switch (errorCode) {
      case constants.ERROR_NETWORK:
        dispatch(
          setAlerts([
            getAlertError(
              error.errors || error,
              redirectLogin,
              isTestProfile ? constants.TEST_PROFILE_NOT_FOUND : errorKey,
              dispatch
            )
          ])
        )
        return true
      case constants.ERROR_GRAPHQL_PROPERTY:
      case constants.ERROR_GRAPHQL_SYNTAX:
      case constants.ERROR_GRAPHQL_ENTITY:
        dispatch(
          setAlerts([
            getAlertError(
              error.errors || error,
              redirectLogin,
              isTestProfile ? constants.TEST_PROFILE_NOT_FOUND : errorKey,
              dispatch
            )
          ])
        )
        return true
      case constants.ERROR_GET_ACCEPTANCE_TERM:
      case constants.ERROR_SET_ACCEPTANCE_TERM:
        dispatch(
          setAlerts([
            getAlertError(
              translate.byKey( 
                error.includes(constants.ERROR_GET_ACCEPTANCE_TERM) ? 
                'errorGetAcceptanceTerm' : 
                'errorSetAcceptanceTerm'
              ),
              redirectLogin,
              false,
              dispatch
            )
          ])
        )
        return true
      case constants.ERROR_ITEM_ADD:
        dispatch(
          setAlerts([
            getAlertExcededQuantity(translate.byKey(constants.FAIL_CART_ADD))
          ])
        )
        return true
      case constants.ERROR_BAD_GATEWAY:
      case constants.ERROR_GATEWAY_TIMEOUT:
      case constants.CART_INVALID_DELIVERYMODE:
        dispatch(
          setAlerts([
            getAlertError(error.errors || error, redirectLogin, errorCode)
          ])
        )
        return true
      case constants.CART_NOT_FOUND:
      case constants.CART_CONVERTING:
      case constants.PAYMENT_CONDITION_NOT_FOUND:
        dispatch(
          setAlerts([
            getAlertError(error.errors || error, true, errorCode)
          ])
        )
        return true
      default:
        dispatch(
          setAlerts([
            getAlertError(
              error.errors || error,
              redirectLogin,
              errorKey,
              dispatch
            )
          ])
        )
        return true
    }
  } else return error.cancellation
}



export const errorStripe = (
  dispatch,
  errors
) => {
  if (Array.isArray(errors) && errors.length > 0 && errors[0].message) {
    dispatch(
      setAlerts([
        getAlertErrorStripe(
          errors
        )
      ])
    )
    return true
  }
}
