import {
  PRODUCT_UNAVAILABLE_MESSAGE,
  PRODUCT_UNAVAILABLE_INVENTORY,
  PRODUCT_UNAVAILABLE_QUOTA_CN,
  PRODUCT_UNAVAILABLE_QUOTA_EC,
  CHANGE_AND_NOTIFY_TYPE_ID,
} from 'commons/constants/product'
import { setAlerts } from 'redux/actions/alerts/alertsAction'

import { orderSearchProductSimilarDispatch } from 'redux/actions/search/searchAction'
import { addItem as addItemAction } from 'redux/actions/bag/bagAction'
import {
  showModalChangeAndNotify,
  showModalChangeAndNotifySingle
} from 'commons/presenter/order/commons/presenter'

import { appendTags } from 'commons/interactor/products/mapper'

import {
  getAlertLimitedStockPlanBGuaranteedSale,
  getAlertLimitedStockPlanB,
  getAlertLimitedStockGuaranteedSale,
  showModalIncrementGuaranteedSale
} from '../order/home/presenter'

export const showUnavailabilityAlternatives = (
  errors,
  product,
  dispatch,
  getState
) => {
  const error = errors[0]
  const isShowing = dispatchShowAndNotifyIfNeed(errors, dispatch, product)
  if (isShowing) return
  const havePlanB =
    error.productUnavailability && error.productUnavailability.length > 0
  if (
    error.message === PRODUCT_UNAVAILABLE_MESSAGE ||
    error.message === PRODUCT_UNAVAILABLE_INVENTORY ||
    error.message === PRODUCT_UNAVAILABLE_QUOTA_CN ||
    error.message === PRODUCT_UNAVAILABLE_QUOTA_EC
  ) {
    const prod = findProductFromState(product.code, getState)
    if (!prod) throw new Error()
    if (prod.isGuaranteedSale) {
      prod.unavailabilityFix = error.productUnavailability[0].productsFix
      const alerts = [
        getAlertLimitedStockPlanBGuaranteedSale(
          prod,
          getGuaranteedSaleModalFunc(prod, dispatch),
          getShowSimilarProductsFunc(prod, dispatch)
        )
      ]
      dispatch(setAlerts(alerts))
      return
    }
    if (prod.isExchangeInformation) {
      const products = getState().productsReducer.list
      dispatch(
        setAlerts([
          showModalChangeAndNotify(products, { textInput: product.code })
        ])
      )
      return
    }
    if (!havePlanB && !product.isGuaranteedSale) {
      throw new Error()
    }
    if (havePlanB) {
      prod.unavailabilityFix = error.productUnavailability[0].productsFix
      prod.tags = appendTags(product.tags).appendTags(error.productUnavailability[0].tags).output()
      const alerts = [
        getAlertLimitedStockPlanB(null, () =>
          getShowSimilarProductsFunc(prod, dispatch)
        )
      ]
      dispatch(setAlerts(alerts))
      return
    }
    if (prod.isGuaranteedSale) {
      const alerts = [
        getAlertLimitedStockGuaranteedSale(
          prod,
          null,
          getGuaranteedSaleModalFunc(prod, dispatch)
        )
      ]
      dispatch(setAlerts(alerts))
      return
    }
    throw new Error()
  } else {
    throw new Error()
  }
}

export const getGuaranteedSaleModalFunc = (product, dispatch) => {
  dispatch(presentGuaranteedSaleModal(product))
}
export const getShowSimilarProductsFunc = (product, dispatch) => {
  dispatch(orderSearchProductSimilarDispatch(product, true))
}

export const findProductFromState = (productCode, getState) => {
  const products = getState().productsReducer.list
  return products.filter(product => Number.parseInt(product.code) === Number.parseInt(productCode))[0]
}

export const presentGuaranteedSaleModal = product => (dispatch) => {
  const alerts = [
    showModalIncrementGuaranteedSale(product, (quantity) => {
      dispatch(addItemAction({ ...product, quantity }, false, true))
    })
  ]
  dispatch(setAlerts(alerts))
}

const dispatchShowAndNotifyIfNeed = (errors, dispatch, product) => {
  const { productUnavailability, tags } = errors[0]
  const typeId =
    productUnavailability &&
    productUnavailability[0] &&
    productUnavailability[0].typeId

  if (typeId === CHANGE_AND_NOTIFY_TYPE_ID) {
    const [unavailability] = productUnavailability
    const [fix] = unavailability.productsFix
    const [showAndNotifyProduct] = fix && fix.fullProduct
    const finalTags = appendTags(product.tags).appendTags(tags).output()
    if (showAndNotifyProduct) {
      const item = {
        ...showAndNotifyProduct,
        value: {
          originalPrice: showAndNotifyProduct.price,
          oldPurchase:
            showAndNotifyProduct.price === showAndNotifyProduct.priceWoDiscounts
              ? null
              : showAndNotifyProduct.price,
          purchase: showAndNotifyProduct.priceWoProfitability,
          sell: showAndNotifyProduct.priceWoDiscounts,
          gain: showAndNotifyProduct.profitability,
        },
        isAvailable: showAndNotifyProduct.availability,
        image: showAndNotifyProduct.picture,
        code: showAndNotifyProduct.productCode,
        name: showAndNotifyProduct.defaultName,
        tags: finalTags,
      }
      dispatch(
        setAlerts([showModalChangeAndNotify([item])], { textInput: item.code })
      )
      return true
    }
  }
  return false
}
