export const getPropertys = commercialInfo => {
  const { countryCode, companyId, orderProfile, personId } = commercialInfo
  const { businessModelId } = orderProfile
  return { countryCode, companyId, businessModelId, personId }
}

export const getCartSize = state => {
  const {
    parameterReducer: { lastCartSize },
    cartReducer: { resume },
  } = state()

  const { cartUid, totalMinOrderSize } = resume

  const cartSize = totalMinOrderSize?.map(
    item => ({ companyBrand: item.companyBrandIds, value: item.totalMinOrderSizeByBrand })
  )

  return { lastCartSize, cartSize, cartUid }
}
