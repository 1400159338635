import Translate from 'natura-commons/translate'

import {
  ACTION_MULTIPLES_BUTTON,
  TYPE_MODAL_CHANGE_AND_NOTIFY,
  ACTION_BUTTON,
  TYPE_ALERT_MESSAGE
} from 'redux/actions/constants.js'

const translate = new Translate()

export const showModalChangeAndNotify = (products, item = null) => {
  let productsModal = products
  if (item) {
    productsModal = products.filter(product => product.code !== item.textInput)
  }

  return {
    id: 0,
    type: TYPE_MODAL_CHANGE_AND_NOTIFY,
    typeAction: ACTION_MULTIPLES_BUTTON,
    typeModal: TYPE_MODAL_CHANGE_AND_NOTIFY,
    title: translate.byKey('titleModalChangeAndNotify'),
    show: true,
    titleButtonOne: translate.byKey('notNow'),
    titleButtonTwo: translate.byKey('buttonAdd'),
    body: `<div class='w-100 mt-2 body-text-changed-notify'/> ${products[0] &&
      getDescription(products, item)}`,
    products: productsModal
  }
}

export const showModalChangeAndNotifySingle = product => ({
  id: 0,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_BUTTON,
  title: translate.byKey('titleModalChangeAndNotify'),
  show: true,
  titleButtonConfirm: translate.byKey('okUnderstand'),
  body: `<div class="w-100 d-flex flex-column">
        <span class='text-body-product-change-notify mb-2'>${translate.byKey(
          'bodyModalChangeAndNotifySinglePart1'
        )} <strong>${product.name}</strong> ${translate.byKey(
    'bodyModalChangeAndNotifySinglePart2'
  )}</span>
        <div class="flex-row d-flex mb-2 pb-1">
          <img src=${
            product.image
          } class="img-body-product-change-notify mr-2" />
          <div class="flex-column d-flex justify-content-center">
            <span class="product-name-body-product-change-notify">${
              product.name
            }</span>
            <span class="product-code-body-product-change-notify">cod: ${
              product.code
            }</span>
          </div>
       </div>
      </div>`
})

const getDescription = (products, item) => {
  let message
  if (item) {
    const result = products.find(product => product.code === item.textInput)
    message = result && result.unavailabilityMessage
  }
  if (!message) {
    const result = products.find(
      product => product.unavailabilityMessage !== ''
    )

    message = result && result.unavailabilityMessage
  }

  return !message ? '' : message
}
