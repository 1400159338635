import {
  ACTION_BUTTON,
  TYPE_ALERT_MESSAGE,
  TYPE_MODAL_ERROR
} from 'redux/actions/constants'
import Translate from 'natura-commons/translate'

const translate = new Translate()

export const getAlertExcededQuantity = (message, code) => ({
  id: 1,
  type: TYPE_ALERT_MESSAGE,
  typeAction: ACTION_BUTTON,
  typeModal: TYPE_MODAL_ERROR,
  title: translate.byKey('warningTitle'),
  show: true,
  titleButtonConfirm: translate.byKey('okUnderstand'),
  handleConfirm: () => {},
  body: message
})
