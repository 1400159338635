import { createModule } from 'natura-commons/redux'
import { sagas } from './sagas'

export const { creators, types, reducer, saga } = createModule({
  options: {
    id: 'i18n'
  },

  state: {
    countryCode: 'PE',
  },

  actions: {
    setCountryCode: [
      ['countryCode'],
      (state, { countryCode }) => ({
        ...state,
        countryCode,
      })
    ],
  },

  sagas,
})
