import React, { createContext, useContext, useState, useCallback } from 'react'

const MenuContext = createContext({})

function MenuProvider({ children }) {
  const [statusMenu, setStatusMenu] = useState(false)

  const setOpenMenu = useCallback(() => {
    setStatusMenu(oldStatus => !oldStatus)
  }, [])

  return (
    <MenuContext.Provider value={{ setOpenMenu, openMenu: statusMenu }}>
      {children}
    </MenuContext.Provider>
  )
}

function useMenu() {
  const context = useContext(MenuContext)

  return context
}

export { MenuProvider, useMenu }
