export const setProfileReducer = (state, payload) => ({
  ...state,
  profileChanged: payload,
  profileConfirm: true
})

export const setValueChangeReducer = (state, payload) => ({
  ...state,
  valueChange: payload.value,
  valueId: payload.id
})
