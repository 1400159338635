import { select, call, put } from 'redux-saga/effects';
import Translate from 'natura-commons/translate'
import { showLoad } from 'redux/actions/load/loadAction'

export function* getGraphQLClient() {
  return yield select(state => state.graphql.client)
}

export function* executeQuery(...args) {
  const client = yield getGraphQLClient()

  return yield call(client.query, ...args)
}

export function* executeMutation(...args) {
  const client = yield getGraphQLClient()

  return yield call(client.mutate, ...args)
}

export function* getSelectedRole() {
  return yield select(state => state.userSession.selectedRole)
}

export function* getTranslate() {
  const translate = new Translate()

  return yield translate
}

export function* setLoading(isLoading) {
  return yield put(showLoad(isLoading))
}
