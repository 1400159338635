import {
  SET_ADDRESS_SHIPPING,
  SET_HOME_ADDRESS_SHIPPING,
  SET_TYPE_SHIPPING_AND_PRICE,
  SET_PICK_UP_ADDRESS,
  SET_SHIPPING_SELECTED_OPTION,
  SET_SHIPPING_CHANGING_OPTION,
  SET_TYPE_SHIPPING_OPTIONS,
  CHECKOUT_CART,
  CLEAR_REDURCERS,
  CLEAR_CART,
  CLEAR_REDUCER_IMPERSONATION,
  DISABLE_ADDRESS_SHIPPING,
  SET_DELIVERY_OPTION,
  CLEAR_DELIVERY_MODES,
  SET_SELECTED_DELIVERY_MODE,
  CLEAR_SELECTED_DELIVERY_MODE,
} from 'redux/actions/constants'

import { initialState } from 'commons/interactor/cart/shipping/mapper'
import * as interactor from 'commons/interactor/cart/shipping/interactor'

const orderShippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADDRESS_SHIPPING: {
      return interactor.setAddressShipping(state, action)
    }
    case SET_HOME_ADDRESS_SHIPPING: {
      return interactor.setHomeAddressShipping(state, action)
    }
    case SET_SELECTED_DELIVERY_MODE: {
      return interactor.setDeliveryModeShipping(state, action)
    }
    case CLEAR_SELECTED_DELIVERY_MODE: {
      return {
        ...state,
        selected: {},
        deliveryModes: [],
      }
    }
    case SET_TYPE_SHIPPING_AND_PRICE: {
      return interactor.setTypeShippingAndPrice(state, action)
    }
    case SET_TYPE_SHIPPING_OPTIONS: {
      return { ...state, ...action.payload }
    }
    case DISABLE_ADDRESS_SHIPPING: {
      return interactor.disableShippingAddress(state, action)
    }
    case SET_DELIVERY_OPTION: {
      return { ...state, ...action.payload }
    }
    case CLEAR_DELIVERY_MODES: {
      return { ...state, deliveryModes: [] }
    }
    case SET_PICK_UP_ADDRESS:
      return interactor.setPickUpAddress(state, action)
    case SET_SHIPPING_SELECTED_OPTION:
      return interactor.setShippingSelectedOption(state, action)
    case SET_SHIPPING_CHANGING_OPTION:
      return interactor.setShippingChangingOption(state, action)
    case CHECKOUT_CART: {
      return state
    }
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
    case CLEAR_CART:
      return initialState
    default:
      return state
  }
}

export default orderShippingReducer
