import { showToast } from 'redux/actions/toast/toastAction'
import Translate from 'natura-commons/translate'
import { TIMEOUT_ALERT_TOAST } from 'commons/constants/cart'

const translate = new Translate()

export const toastMaxItemQuantity = (dispatch, requested, added, item) => {
  dispatch(
    showToast({
      text: getText(requested, added, item),
      type: 'success',
      show: true,
      autoHide: true,
      hideIcon: true,
      timeout: TIMEOUT_ALERT_TOAST
    })
  )
}

export const showAdditionalCreditToast = (dispatch) => {
  dispatch(
    showToast({
      text: translate.byKey('usingAdditionalCredit'),
      type: 'error',
      show: true,
      autoHide: true,
      timeout: TIMEOUT_ALERT_TOAST
    })
  )
}
export const showCreditLimitExceededToast = (dispatch) => {
  dispatch(
    showToast({
      text: translate.byKey('limitCredit'),
      type: 'error',
      show: true,
      autoHide: true,
      timeout: TIMEOUT_ALERT_TOAST
    })
  )
}

const getText = (requested, added, item) =>
  translate.byKeyAndArrayParam('diffAddItem', {
    requested,
    product: `${item.code ? item.code : item.productCode} - ${
      item.name ? item.name : item.description
    }`,
    added
  })
