import IconMoneyWhite from 'commons/assets/images/ic-money-white.png'
import IconMoneyGray from 'commons/assets/images/ic-money-gray.png'

import IconRegaloGrey from 'commons/assets/images/ic-regalo-grey.png'
import IconRegaloWhite from 'commons/assets/images/ic-regalo-white.png'
import IconBolsaGrey from 'commons/assets/images/ic-bolsa-grey.png'
import IconCartWhite from 'commons/assets/images/ic-carrito-white.png'
import IconShippingWhite from 'commons/assets/images/ic-shipping-white.png'
import IconShippingGray from 'commons/assets/images/ic-shipping-gray.png'
import IconCompleteWhite from 'commons/assets/images/ic-complete-white.png'
import IconCompleteGray from 'commons/assets/images/ic-complete-gray.png'

import { cloneDeep } from 'lodash';

import {
  STEP_PAYMENT,
  STEP_BAG,
  STEP_GIFT,
  STEP_SHIPPING,
  STEP_COMPLETE,
  STATUS_CART_PENDING_PROFITABILITY,
  DELIVERY_OPTION_HOME,
  STEP_EXCLUSIVE
} from 'commons/constants/cart'

export const stepExclusiveProps = {
  type: STEP_EXCLUSIVE,
  iconSelected: IconCartWhite,
  iconDeselected: IconBolsaGrey,
  label: 'exclusive'
}

export const initialState = {
  subscribe: false,
  isClear: true,
  isFinish: false,
  minimumOrderPrice: 90.9,
  // maxOrderPrice: 58000,
  disbaleNextButton: false,
  id: 0,
  mine: {
    mineStatusId: STATUS_CART_PENDING_PROFITABILITY
  },
  resume: {
    cartUid: null,
    statusId: STATUS_CART_PENDING_PROFITABILITY,
    totalPrice: 0.0,
    totalPriceReal: 0.0,
    cartTotalWithTaxes: 0.0,
    taxAmount: 0.0,
    totalPriceGain: 0.0,
    totalPriceSell: 0.0,
    totalDiscount: 0.0,
    totalFreight: 0.0,
    quantityTotal: 0,
    quantityGifts: 0,
    totalPoints: 0,
    quantityDiscounts: 0,
    totalTributes: 0,
    totalGifts: 0,
    totalDebits: 0,
    totalCredits: 200,
    shippingSelected: null,
    addressDispatch: {
      address: ''
    },
    profitability: { minimumOrderSize: [] },
    methodPayment: null,
    returnURL: null,
    errorStripe: null,
  },
  promotions: [],
  steps: {
    list: [
      {
        type: STEP_BAG,
        iconSelected: IconCartWhite,
        iconDeselected: IconBolsaGrey,
        label: 'myCart'
      },
      stepExclusiveProps,
      {
        type: STEP_GIFT,
        iconSelected: IconRegaloWhite,
        iconDeselected: IconRegaloGrey,
        label: 'mineGifts'
      },
      {
        type: STEP_SHIPPING,
        iconSelected: IconShippingWhite,
        iconDeselected: IconShippingGray,
        label: 'sendo'
      },
      {
        type: STEP_PAYMENT,
        iconSelected: IconMoneyWhite,
        iconDeselected: IconMoneyGray,
        label: 'payment'
      },
      {
        type: STEP_COMPLETE,
        iconSelected: IconCompleteWhite,
        iconDeselected: IconCompleteGray,
        label: 'complete'
      }
    ],
    stepSelected: 0
  },
  shipping: {
    typeShipping: null,
    addressShipping: null,
    addressPickUp: null,
    valueShipping: 0,
    enableShippingAddress: true,
    deliveryOption: DELIVERY_OPTION_HOME
  },
  changePaymentError: null
}

export const mapCartShipping = (shipping) => {
  const { address, deliveryMode } = shipping
  return {
    addressShipping: `${address && address.streetName}, ${address &&
      address.number} • ${address && address.city} • ${address &&
      address.zipCode}  ${address && address.state} - ${address &&
      address.country} `,
    deliveryMode
  }
}

const getTotalGits = (gifts) => {
  if (gifts && gifts.length > 0) {
    return cloneDeep(gifts).reduce((a,b) => ({ quantity: a.quantity + b.quantity })).quantity
  }

  return 0;
}

export const mapCartResume = (
  summary,
  shipping,
  giftsList = [],
  checkout = null
) => {
  const { address, deliveryMode } = shipping
  const totalGifts = getTotalGits(giftsList)
  return {
    cartUid: summary.cartUid,
    checkout,
    cartTotal: summary.cartTotal,
    displayTaxes: summary.taxesParameters
      ? summary.taxesParameters.every(tax => tax.displayOnFrontend === true)
      : true,
    profitability: summary.profitability,
    totalPrice: summary.totalPriceWoProfitability,
    totalPriceReal: summary.totalPrice,
    cartTotalWithTaxes: summary.cartTotalWithTaxes,
    taxAmount: summary.taxAmount,
    statusId: summary.statusId,
    totalCreditConsumed: summary.totalCreditConsumed,
    totalPriceGain: summary.totalProfitability,
    totalPriceSell: summary.totalPriceWoDiscounts,
    totalDiscount: summary.totalProfitability,
    totalFreight: summary.shippingAmount,
    quantityTotal: summary.totalItemsQuantity,
    quantityGifts: totalGifts,
    totalPoints: summary.totalPoints,
    totalCompanyBrand: summary.totalCompanyBrand,
    totalMinOrderSize: summary.totalMinOrderSize,
    minOrderSizeReached: summary.minOrderSizeReached,
    minOrderSizeRemaining: summary.minOrderSizeRemaining,
    quantityDiscounts: 0,
    residualAccountValue: summary.residualAccountValue,
    totalTributes: 0,
    totalGifts: 0,
    totalDebits: 0,
    totalCredits: 0,
    deliveryMode,
    addressDispatch: {
      address: `${address && address.streetName}, ${address &&
        address.number} • ${address && address.city} • ${address &&
        address.zipCode}  ${address && address.state} - ${address &&
        address.country} `
    }
  }
}
