import Promotion from 'natura-commons/service/Promotion'
import { verifyError } from 'redux/actions/alerts/alertsAction'
import { getCartSize } from 'commons/interactor/parameter/interactor'

import { 
  SET_TITLES, 
  SET_TITLES_EXCLUSIVES, 
  SET_SEARCHING_EXCLUSIVES,
  SET_UPDATING_ITEMS_IN_EXCLUSIVE
} from '../constants'


const promotion = new Promotion()

export const getAllTitles = () => (dispatch, getState) => {
  const {
    parameterReducer: { titlesPromotion = [], enableNewPromotion },
  } = getState()


  if (!titlesPromotion || titlesPromotion.length === 0) {
    promotion.resolver(
      titles => {
        const {
          data: { promotionGetPromotions }
        } = titles
        dispatch({ type: SET_TITLES, titles: promotionGetPromotions })
      },
      error => {
        verifyError(dispatch, error)
      },
      promotion.getAll(enableNewPromotion)
    )
  }
}

export const getAllExclusiveTitles = ({ onlySarrafos = false }) => async (dispatch, getState) => {
  const { lastCartSize, cartSize, cartUid } = getCartSize(getState)

  if (lastCartSize === cartSize) return

  if (!onlySarrafos) dispatch({ type: SET_SEARCHING_EXCLUSIVES, searchingExclusives: true })

  await fetchAndSetTitles(dispatch, getState, onlySarrafos, cartSize, cartUid);
};

const getTaxContext = (getState) => {
  const state = getState();
  const { taxesReducer: { taxParams = {} }, userSession: { commercialInfo, detailsPerson } } = state;

  const { displayTaxes, taxesParameters } = taxParams;
  const geographicalLevels = detailsPerson.addresses[0]?.geographicalLevels;

  const userInfo = {
    addresses: detailsPerson.addresses,
    countryCode: detailsPerson.countryCode,
    companyId: detailsPerson.companyId,
    orderProfile: commercialInfo.orderProfile
  };

  return { displayTaxes, taxesParameters, geographicalLevels, userInfo };
};

const fetchAndSetTitles = async (dispatch, getState, onlySarrafos, cartSize, cartUid) => {
  const taxContext = getTaxContext(getState);

  try {
    const titles = await promotion.getAllExclusives({ cartSize, cartUid, taxContext });

    dispatch({ type: SET_SEARCHING_EXCLUSIVES, searchingExclusives: false })
    dispatch({ type: SET_TITLES_EXCLUSIVES, onlySarrafos, titles, lastCartSize: cartSize })
  } catch (error) {
    dispatch({ type: SET_SEARCHING_EXCLUSIVES, searchingExclusives: false })
    verifyError(dispatch, error)
  }
};

export const setUpdatingItemsInExclusiveStep = (updatingItems) => (dispatch, getState) => {
  dispatch({ type: SET_UPDATING_ITEMS_IN_EXCLUSIVE, updatingItems })
}