export const ROOT = 'APP/GLOBAL'
const DATALAYER = 'APP/DATALAYER'

// GLOBAL
export const CLEAR_ERROR = `${ROOT}/CLEAR_ERROR`
export const CLEAR_REDIRECT = `${ROOT}/CLEAR_REDIRECT`
export const SET_SELECTED_MENU_ITEM = `${ROOT}/SET_SELECTED_MENU_ITEM`

// TOAST
export const SHOW_TOAST = `${ROOT}/SHOW_TOAST`
export const HIDE_TOAST = `${ROOT}/HIDE_TOAST`
export const CLEAR_TOAST = `${ROOT}/CLEAR_TOAST`

// LOGIN
export const LOGIN = `${ROOT}/LOGIN`
export const CLEAR_LOGIN = `${ROOT}/CLEAR_LOGIN`
export const LOGIN_SUCESS = `${ROOT}/LOGIN_SUCESS`
export const LOGIN_FAILURE = `${ROOT}/LOGIN_FAILURE`
export const LOGIN_GERA = `${ROOT}/LOGIN_GERA`
export const LOGIN_COGNITO = `${ROOT}/LOGIN_COGNITO`
export const FETCH_SUBSEGMENT = `${ROOT}/FETCH_SUBSEGMENT`
export const UPDATE_LOGIN_CYCLE_DATA = `${ROOT}/UPDATE_LOGIN_CYCLE_DATA`
export const LOGIN_SET_TOKEN = `${ROOT}/LOGIN_SET_TOKEN`
export const LOGIN_SET_COMERCIAL_TOKEN = `${ROOT}/SET_COMERCIAL_TOKEN`
export const LOGIN_SET_SESSION_IDENTIFIER = `${ROOT}/SET_SESSION_IDENTIFIER`

// PROFILE
export const SELECTED_ROLE = `${ROOT}/SELECTED_ROLE`
export const COMMERCIAL_INFO = `${ROOT}/COMMERCIAL_INFO`
export const TEST_PROFILE = `${ROOT}/TEST_PROFILE`
export const UPDATE_CYCLE_DATA = `${DATALAYER}/UPDATE_CYCLE_DATA`
export const SET_REFRESH_TOKEN = `${ROOT}/SET_REFRESH_TOKEN`

// MODAL
export const ACTION_BUTTON = `${ROOT}/ACTION_BUTTON`
export const ACTION_MULTIPLES_BUTTON = `${ROOT}/ACTION_MULTIPLES_BUTTON`
export const TYPE_ALERT_MESSAGE = `${ROOT}/TYPE_ALERT_MESSAGE`
export const TYPE_ALERT_INCREMENT = `${ROOT}/TYPE_ALERT_INCREMENT`
export const TYPE_ALERT_KIT_PRODUCT = `${ROOT}/TYPE_ALERT_KIT_PRODUCT`
export const TYPE_ALERT_KIT_PRODUCT_DETAILS = `${ROOT}/TYPE_ALERT_KIT_PRODUCT_DETAILS`
export const TYPE_BLOCK_MESSAGE = `${ROOT}/TYPE_BLOCK_MESSAGE`
export const TYPE_RADIO_MESSAGE = `${ROOT}/TYPE_RADIO_MESSAGE`
export const TYPE_CHANGE_MESSAGE = `${ROOT}/TYPE_CHANGE_MESSAGE`
export const TYPE_CONFIRM_IMPERSONATION = `${ROOT}/TYPE_CONFIRM_IMPERSONATION`
export const CLEAR_MODAL = `${ROOT}/CLEAR_MODAL`
export const CLEAR_REDURCERS = `${ROOT}/CLEAR_REDURCERS`
export const SET_PROFILE = `${ROOT}/SET_PROFILE`
export const SET_VALUE_CHANGE = `${ROOT}/SET_VALUE_CHANGE`
export const TYPE_MODAL_ROLES = `${ROOT}/TYPE_MODAL_ROLES`
export const TYPE_MODAL_IMPERSONATION = `${ROOT}/TYPE_MODAL_IMPERSONATION`
export const TYPE_MODAL_CYCLES = `${ROOT}/TYPE_MODAL_CYCLES`
export const TYPE_MODAL_INCREMENT = `${ROOT}/TYPE_MODAL_INCREMENT`
export const TYPE_MODAL_CHANGE_AND_NOTIFY = `${ROOT}/TYPE_MODAL_CHANGE_AND_NOTIFY`
export const TYPE_MODAL_INTERESTED_PRODUCT = `${ROOT}/TYPE_MODAL_INTERESTED_PRODUCT`
export const TYPE_MODAL_DAMAGED_PRODUCT = `${ROOT}/TYPE_MODAL_DAMAGED_PRODUCT`
export const TYPE_MODAL_ERROR = `${ROOT}/TYPE_MODAL_ERROR`
export const TYPE_MODAL_INFO = `${ROOT}/TYPE_MODAL_INFO`
export const TYPE_MODAL_DRAFT = `${ROOT}/TYPE_MODAL_DRAFT`
export const OPEN_MODAL_SARRAFOS = `${ROOT}/OPEN_MODAL_SARRAFOS`
export const OPEN_MODAL_OPPORTUNITIES = `${ROOT}/OPEN_MODAL_OPPORTUNITIES`

// ADDRESS MODAL
export const TYPE_MODAL_CHANGE_SHIPPING_ADDRESS = `${ROOT}/TYPE_MODAL_CHANGE_SHIPPING_ADDRESS`
export const TYPE_MODAL_SHIPPING_PICKUP = `${ROOT}/TYPE_MODAL_SHIPPING_PICKUP`

// ENVIRONMENT
export const SECTOR_COMMERCIAL = `${ROOT}/SECTOR_COMMERCIAL`
export const SECTOR_SELL = `${ROOT}/SECTOR_SELL`
export const SET_ENVIRONMENT = `${ROOT}/SET_ENVIRONMENT`
export const SET_SECTOR = `${ROOT}/SET_SECTOR`
export const CLEAR_ENVIRONMENT = `${ROOT}/CLEAR_ENVIRONMENT`

// ALERTS
export const SET_ALERTS = `${ROOT}/SET_ALERTS`
export const UPDATE_ALERTS = `${ROOT}/UPDATE_ALERTS`
export const SET_ALERTS_READ = `${ROOT}/SET_ALERTS_READ`
export const AVAILABLE_ALERT_KIT = `${ROOT}/AVAILABLE_ALERT_KIT`

// SEARCH
export const OPEN_SEARCH = `${ROOT}/OPEN_SEARCH `
export const SEARCHING = `${ROOT}/SEARCHING`
export const FILTER_SEARCH = `${ROOT}/FILTER_SEARCH`
export const FILTERED_SEARCH = `${ROOT}/FILTERED_SEARCH`
export const GROUP_SELECTED = `${ROOT}/GROUP_SELECTED`
export const RECENT_SEARCHED_PRODUCTS = `${ROOT}/RECENT_SEARCHED_PRODUCTS`
export const SET_RECENT_SEARCHED_PRODUCTS = `${ROOT}/SET_RECENT_SEARCHED_PRODUCTS`
export const CLEAR_SEARCH = `${ROOT}/CLEAR_SEARCH`

export const SEARCH_REQUEST = `${ROOT}/SEARCH_REQUEST`
export const SEARCH_RECEIVE = `${ROOT}/SEARCH_RECEIVE`
export const SEARCH_ERROR = `${ROOT}/SEARCH_RECEIVE`
export const SEARCH_FILTER = `${ROOT}/SEARCH_FILTER`
export const SEARCH_INVALIDATE = `${ROOT}/SEARCH_INVALIDATE`
export const SEARCH_CANCEL_REQUEST = `${ROOT}/SEARCH_INVALIDATE`
export const SELECT_ITEM = `${ROOT}/SELECT_ITEM`
export const CLEAR_SELECTED_ITEM = `${ROOT}/CLEAR_SELECTED_ITEM`

export const OPEN_FILTER_CONTROL = `${ROOT}/OPEN_FILTER_CONTROL`
export const CLOSE_FILTER_CONTROL = `${ROOT}/CLOSE_FILTER_CONTROL`
export const OPEN_SORT_CONTROL = `${ROOT}/OPEN_SORT_CONTROL`
export const CLOSE_SORT_CONTROL = `${ROOT}/CLOSE_SORT_CONTROL`

// BAG
export const START_FETCHING_ITEMS = `${ROOT}/START_FETCHING_ITEMS`
export const ADD_ITEM_START = `${ROOT}/ADD_ITEM_START`
export const ADD_ITEM_DONE = `${ROOT}/ADD_ITEM_DONE`
export const REMOVE_ITEM = `${ROOT}/REMOVE_ITEM`
export const REMOVE_ITEM_FROM_CART_STATE = `${ROOT}/REMOVE_ITEM_FROM_CART_STATE`
export const CLEAR_UNAVAILABLE_PRODUCTS = `${ROOT}/CLEAR_UNAVAILABLE_PRODUCTS`
export const UPDATE_ITEM = `${ROOT}/UPDATE_ITEM`
export const SET_AUTOMATIC_GET_BAG = `${ROOT}/SET_AUTOMATIC_GET_BAG`
export const REPLACE_UNAVAILABLE_GIFT_OPTIONS = `${ROOT}/REPLACE_UNAVAILABLE_GIFT_OPTIONS`

// ROUTER
export const SET_INITIAL_MENU = `${ROOT}/SET_INITIAL_MENU`
export const CLEAR_MENU = `${ROOT}/CLEAR_MENU`

// Product
export const PRODUCT_LIST = `${ROOT}/PRODUCT_LIST`
export const PRODUCT_LIST_PAGINED = `${ROOT}/PRODUCT_LIST_PAGINED`
export const PRODUCT_LIST_PAGINED_PROMOTION = `${ROOT}/PRODUCT_LIST_PAGINED_PROMOTION`
export const SHOW_DETAIL = `${ROOT}/SHOW_DETAIL`
export const PRODUCT_ADDED_IN_BAG = `${ROOT}/PRODUCT_ADDED_IN_BAG`
export const PRODUCT_REMOVED_FROM_BAG = `${ROOT}/PRODUCT_REMOVED_FROM_BAG`
export const CLEAR_LIST_PRODUCTS = `${ROOT}/CLEAR_LIST_PRODUCTS`
export const SYNC_AVAILABILITY = `${ROOT}/SYNC_AVAILABILITY`
export const SYNC_AVAILABILITY_HIGHLIGHTED = `${ROOT}/SYNC_AVAILABILITY_HIGHLIGHTED`
export const SYNC_AVAILABILITY_PROMOTION = `${ROOT}/SYNC_AVAILABILITY_PROMOTION`

// Select Consultant
export const SHOW_MESSAGE_CONSULTANT = `${ROOT}/SHOW_MESSAGE_CONSULTANT`

// Order
export const CLEAR_REDUCER_ORDER = `${ROOT}/CLEAR_REDUCER_ORDER`

// Impersonation
export const IS_IMPERSON = `${ROOT}/IS_IMPERSON`
export const CLEAR_REDUCER_IMPERSONATION = `${ROOT}/CLEAR_REDUCER_IMPERSONATION`

// Load
export const SHOW_LOAD = `${ROOT}/SHOW_LOAD`
export const LOAD_AVAILABILITY = `${ROOT}/LOAD_AVAILABILITY`

// CART
export const SET_CART_BANNER = `${ROOT}/SET_CART_BANNER`
export const GET_BAG_INFO_TEXT = `${ROOT}/GET_BAG_INFO_TEXT`
export const UPDATE_CART = `${ROOT}/UPDATE_CART`
export const GO_TO_NEXT_STEP = `${ROOT}/GO_TO_NEXT_STEP`
export const GO_TO_PREV_STEP = `${ROOT}/GO_TO_PREV_STEP`
export const GO_TO_SPECIFIC_STEP = `${ROOT}/GO_TO_SPECIFIC_STEP`
export const CLEAR_STEP = `${ROOT}/CLEAR_STEP`
export const UPDATE_STEPS = `${ROOT}/UPDATE_STEPS`
export const CHECK_BAG_ITEMS = `${ROOT}/CHECK_BAG_ITEMS`
export const GET_BAG_ITEMS = `${ROOT}/GET_BAG_ITEMS`
export const UPDATE_CART_RESUME = `${ROOT}/UPDATE_CART_RESUME`
export const UPDATE_CART_PROMOTIONS = `${ROOT}/UPDATE_CART_PROMOTIONS`
export const UPDATE_SELECTED_GIFTS = `${ROOT}/UPDATE_SELECTED_GIFTS`
export const SET_MINIMUM_ORDER_PRICE = `${ROOT}/SET_MINIMUM_ORDER_PRICE`
export const CLEAR_CART = `${ROOT}/CLEAR_CART`
export const CLEAR_MODAL_BAG = `${ROOT}/CLEAR_MODAL_BAG`
export const CHECKOUT_CART = `${ROOT}/CHECKOUT_CART`
export const ADD_ITEM_ERROR = `${ROOT}/ADD_ITEM_ERROR`
export const SELECT_GIFT_CART = `${ROOT}/SELECT_GIFT_CART`
export const RENEW_GIFTS = `${ROOT}/RENEW_GIFTS`
export const RENEW_GIFTS_BAG = `${ROOT}/RENEW_GIFTS_BAG`
export const SELECT_GIFT_OPTION = `${ROOT}/SELECT_GIFT_OPTION`
export const CHECKOUT_GIFT_CART = `${ROOT}/CHECKOUT_GIFT_CART`
export const SET_STATUS_ID_CART = `${ROOT}/SET_STATUS_ID_CART`
export const UPDATE_GIFT_QUANTITY = `${ROOT}/UPDATE_GIFT_QUANTITY`
export const ENABLE_DISABLE_NEXT = `${ROOT}/ENABLE_DISABLE_NEXT`
export const SET_MINE = `${ROOT}/SET_MINE`
export const SET_CLEAR = `${ROOT}/SET_CLEAR`
export const CART_FINISH = `${ROOT}/CART_FINISH`
export const CART_SUBSCRIBE = `${ROOT}/CART_SUBSCRIBE`
export const REMOVE_BOUGHT_STARTER_KIT = `${ROOT}/REMOVE_BOUGHT_STARTER_KIT`

// CART/ORDERADDRESS
export const SET_ADDRESS_SHIPPING = `${ROOT}/SET_ADDRESS_SHIPPING`
export const SET_HOME_ADDRESS_SHIPPING = `${ROOT}/SET_HOME_ADDRESS_SHIPPING`
export const SET_TYPE_SHIPPING_AND_PRICE = `${ROOT}/SET_TYPE_SHIPPING_AND_PRICE`

// SHIPPING
export const GET_ORDER_SHIPPING = `${ROOT}/GET_ORDER_SHIPPING`
export const SET_PICK_UP_ADDRESS = `${ROOT}/SET_PICK_UP_ADDRESS`
export const SET_SHIPPING_SELECTED_OPTION = `${ROOT}/SET_SHIPPING_SELECTED_OPTION`
export const SET_SHIPPING_CHANGING_OPTION = `${ROOT}/SET_SHIPPING_CHANGING_OPTION`
export const SET_TYPE_SHIPPING_OPTIONS = `${ROOT}/SET_TYPE_SHIPPING_OPTIONS`
export const DISABLE_ADDRESS_SHIPPING = `${ROOT}/DISABLE_ADDRESS_SHIPPING`
export const SET_DELIVERY_OPTION = `${ROOT}/SET_DELIVERY_OPTION`
export const CLEAR_DELIVERY_MODES = `${ROOT}/CLEAR_DELIVERY_MODES`
export const SET_SELECTED_DELIVERY_MODE = `${ROOT}/SET_SELECTED_DELIVERY_MODE`
export const CLEAR_SELECTED_DELIVERY_MODE = `${ROOT}/CLEAR_SELECTED_DELIVERY_MODE`

// SORTER SEARCH PRODUCTS
export const TYPE_MODAL_SORTER_SEARCH_PRODUCT = `${ROOT}/TYPE_MODAL_SORTER_SEARCH_PRODUCT`
export const SET_SORTER_SELECTED_SEARCH_PRODUCT = `${ROOT}/SET_SORTER_SELECTED`
export const CLEAR_SORTER_SEARCH_PRODUCT = `${ROOT}/CLEAR_SORTER_SEARCH_PRODUCT`
export const SET_FILTER_SELECTED_PRODUCT = `${ROOT}/SET_FILTER_SELECTED_PRODUCT`
export const SET_INITIAL_FILTER_AFTER_SEARCH = `${ROOT}/SET_INITIAL_FILTER_AFTER_SEARCH`

// NEXT CICLE
export const PRODUCT_LIST_NEXT_CICLE = `${ROOT}/PRODUCT_LIST_NEXT_CICLE`

// SIMILAR
export const PRODUCT_LIST_SIMILAR = `${ROOT}/PRODUCT_LIST_SIMILAR`
export const CLEAR_PRODUCT_LIST_SIMILAR = `${ROOT}/CLEAR_PRODUCT_LIST_SIMILAR`

// BANNER
export const ACTION_BANNER_PROMOTIONS = 'ACTION_BANNER_PROMOTIONS'
export const SET_BANNERS_HOME = 'SET_BANNERS_HOME'

// NBO
export const SET_INITIAL_NBO = `${ROOT}/SET_INITIAL_NBO`

// HOME SKELETON
export const SET_HOME_PARAMETERS_SEARCHED = `${ROOT}/SET_HOME_PARAMETERS_SEARCHED`

// CARTWINDOW
export const SET_INITIAL_CART_WINDOW = `${ROOT}/SET_INITIAL_CART_WINDOW`

// LEAVERAGE
export const GET_LEAVERAGE = `${ROOT}/GET_LEAVERAGE`
export const CLEAR_LEVERAGE = `${ROOT}/CLEAR_LEVERAGE`
export const SET_SELECTED_LEAVERAGE = `${ROOT}/SET_SELECTED_LEAVERAGE`
export const SET_LEAVERAGE_SEARCH = `${ROOT}/SET_LEAVERAGE_SEARCH`
export const GET_INTERNAL = `${ROOT}/GET_INTERNAL`
export const SORT_INTERNAL = `${ROOT}/SORT_INTERNAL`
export const PRODUCTS_VITRINE = `${ROOT}/PRODUCTS_VITRINE`

// EXTERNAL ORDER SEARCH
export const SET_INITIAL_EXTERNAL_ORDER_SEARCH_URL = 'SET_INITIAL_EXTERNAL_ORDER_SEARCH_URL'

// PROMOTION
export const SET_TITLES = `${ROOT}/SET_TITLES`
export const SET_TITLES_EXCLUSIVES = `${ROOT}/SET_TITLES_EXCLUSIVES`
export const SET_SEARCHING_EXCLUSIVES = `${ROOT}/SET_SEARCHING_EXCLUSIVES`
export const SET_UPDATING_ITEMS_IN_EXCLUSIVE = `${ROOT}/SET_UPDATING_ITEMS_IN_EXCLUSIVE`

// DATALAYER
export const PERSON_DATA = `${DATALAYER}/PERSON_DATA`
export const COMMERCIAL_DATA = `${DATALAYER}/COMMERCIAL_DATA`
export const VIRTUAL_PAGE_VIEW = `${DATALAYER}/VIRTUAL_PAGE_VIEW`
export const UPDATE_ITEM_DATA = `${DATALAYER}/UPDATE_ITEM_DATA`
export const CART_CONTENT = `${DATALAYER}/CART_CONTENT`
export const PRE_SALES = `${DATALAYER}/PRE_SALES`
export const CHECKOUT_DATA = `${DATALAYER}/CHECKOUT_DATA`
export const PURCHASE_DATA = `${DATALAYER}/PURCHASE_DATA`
export const CART_ADDITION_ORIGIN = `${DATALAYER}/CART_ADDITION_ORIGIN`
export const VTR_NAVIGATION = `${DATALAYER}/VTR_NAVIGATION`
export const CURRENT_LIST = `${DATALAYER}/CURRENT_LIST`

// PARAMETERS
export const ACTIVE_SEARCH_BY_DESCRIPTION = `${ROOT}/ACTIVE_SEARCH_BY_DESCRIPTION`
export const PAGINATION_SIZE = `${ROOT}/PAGINATION_SIZE`
export const OPTMIZE_SEARCH = `${ROOT}/OPTMIZE_SEARCH`
export const PRODUCTS_WINDOWS_HOME = `${ROOT}/WINDOWS_HOME`
export const SET_BANNER_DEFAULT = `${ROOT}/BANNER_DEFAULT`
export const CHANGE_PAYMENT_METHOD_PARAM = `${ROOT}/CHANGE_PAYMENT_METHOD`
export const SET_SHOW_ORDERS_HISTORY = `${ROOT}/SET_SHOW_ORDERS_HISTORY`
export const SET_NEW_PROMOTION = `${ROOT}/SET_NEW_PROMOTION`
export const SET_SHOW_PICKUP_POINT = `${ROOT}/SET_SHOW_PICKUP_POINT`
export const SET_SHOW_CREDT_INFO = `${ROOT}/SET_SHOW_CREDT_INFO`
export const SET_SUCURSAL_FILTERS = `${ROOT}/SET_SUCURSAL_FILTERS`
export const GEO_STRUCTURE_LEVEL = `${ROOT}/GEO_STRUCTURE_LEVEL`
export const SHIPPING_METHOD = `${ROOT}/SHIPPING_METHOD`
export const SET_SALES_OPPORTUNITY = `${ROOT}/SET_SALES_OPPORTUNITY`
export const SET_COUNTRY_PHONE_NUMBER = `${ROOT}/SET_COUNTRY_PHONE_NUMBER`
export const SET_INTERACTIVE_MAGAZINE_LINK = `${ROOT}/SET_INTERACTIVE_MAGAZINE_LINK`
export const SET_IMPORTER_MAGAZINE_LINK = `${ROOT}/SET_IMPORTER_MAGAZINE_LINK`

// COMPANY BRAND
export const SET_COMPANY_BRANDS = `${ROOT}/SET_COMPANY_BRANDS`
export const SET_MENU = `${ROOT}/SET_MENU`


// PAYMENT
export const SET_METHOD_PAYMENT = `${ROOT}/SET_METHOD_PAYMENT`
export const PAYMENT_CONFIRM_INTENT = `${ROOT}/PAYMENT_CONFIRM_INTENT`
export const PAYMENT_INTENT = `${ROOT}/PAYMENT_INTENT`
export const PAYMENT_CONFIG = `${ROOT}/PAYMENT_CONFIG`
export const SET_ERROR_STRIPE = `${ROOT}/SET_ERROR_STRIPE`
export const PAYMENT_CONDITION = `${ROOT}/PAYMENT_CONDITION`
export const SELECT_PAYMENT_CONDITION = `${ROOT}/SELECT_PAYMENT_CONDITION`
export const SET_PENDING_PAYMENTS = `${ROOT}/SET_PENDING_PAYMENTS`
export const SET_HAS_PENDING_PAYMENTS = `${ROOT}/SET_HAS_PENDING_PAYMENTS`
export const SET_CHANGE_PAYMENT_ERROR = `${ROOT}/SET_CHANGE_PAYMENT_ERROR`

// ERRORS
export const GLOBAL_ERROR_SET = `${ROOT}/GLOBAL_ERROR_SET`
export const GLOBAL_ERROR_CLEAR = `${ROOT}/GLOBAL_ERROR_CLEAR`

// PROFITABILITY
export const SET_COMISSION = `${ROOT}/SET_COMISSION`

// SPLIT
export const SET_MIN_SPLIT_VALUE_CN = `${ROOT}/PARAMETERS/SET_MIN_SPLIT_VALUE_CN`
export const GET_SPLIT_PARAMS = `${ROOT}/PARAMETERS/GET_SPLIT_PARAMS`
// GRAPHQL
export const GRAPHQL_SET_CLIENT = `${ROOT}/GRAPHQL_SET_CLIENT`

// ACCEPTANCE TERM
export const SHOW_ACCEPTANTE_TERM = `${ROOT}/SHOW_ACCEPTANTE_TERM`
export const CLOSE_ACCEPTANTE_TERM = `${ROOT}/CLOSE_ACCEPTANTE_TERM`
export const GET_ACCEPTANTE_TERMS = `${ROOT}/GET_ACCEPTANTE_TERMS`
export const SET_ACCEPTANTE_TERMS = `${ROOT}/SET_ACCEPTANTE_TERMS`
export const SET_CURRENT_ACCEPTANTE_TERM = `${ROOT}/SET_CURRENT_ACCEPTANTE_TERM`
export const CLEAR_ACCEPTANTE_TERM = `${ROOT}/CLEAR_ACCEPTANTE_TERM`

// TAXES
export const SET_TAXES_PARAMS = `${ROOT}/SET_TAXES_PARAMS`

// GTM
export const CALL_GTM_SCRIPT = `${ROOT}/CALL_GTM_SCRIPT`
