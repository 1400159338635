/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { UserAgentProvider } from '@quentin-sommer/react-useragent'
import { PersistGate } from 'redux-persist/integration/react'

import { CookiesProvider } from 'react-cookie'
import { configSdk } from 'natura-commons/service/Sdk'

import { AcceptanceTermProvider } from 'providers/AcceptanceTermProvider'

import { GraphQLProvider } from './providers/GraphQLProvider'
import { ErrorProvider } from './providers/ErrorProvider'
import { TranslateProvider } from './providers/TranslateProvider'
import { AuthProvider } from './providers/AuthProvider'
import { ModalProvider } from './providers/ModalProvider'
import { ApplicationLoaderProvider } from './providers/ApplicationLoaderProvider'

import App from './scenes/App'
import configureStore from './store'
import { history } from './history'

import './commons/assets/css/bootstrap.min.css'
import './commons/assets/css/app.css'
import './web.config'

import { AppProvider } from './hooks'

configSdk(process.env.REACT_APP_NODE_ENV)

const { store, persistor } = configureStore()

const renderApp = () => render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ApplicationLoaderProvider>
        <TranslateProvider>
          <ErrorProvider>
            <CookiesProvider>
              <AuthProvider>
                <ConnectedRouter history={history}>
                  <UserAgentProvider ua={window.navigator.userAgent}>
                    <GraphQLProvider>
                      <AppProvider>
                        <AcceptanceTermProvider>
                          <ModalProvider>
                            <App />
                          </ModalProvider>
                        </AcceptanceTermProvider>
                      </AppProvider>
                    </GraphQLProvider>
                  </UserAgentProvider>
                </ConnectedRouter>
              </AuthProvider>
            </CookiesProvider>
          </ErrorProvider>
        </TranslateProvider>
      </ApplicationLoaderProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

renderApp()

if (module.hot) {
  // Support hot reloading of components.
  // Whenever the App component file or one of its dependencies
  // is changed, re-import the updated component and re-render it
  module.hot.accept('./scenes/App', () => {
    setTimeout(renderApp)
  })
}
