import Translate from 'natura-commons/translate'

import { DELIVERY_OPTION_HOME } from 'commons/constants/cart'
import { formatCountryDeliveryDate } from 'commons/helpers/date'

const translate = new Translate()

export const initialState = {
  shippingChanging: false,
  typeShipping: null,
  addressShipping: null,
  addressPickUp: null,
  valueShipping: 0,
  optionsShipping: [],
  deliveryModes: [],
  enableShippingAddress: true,
  deliveryOption: DELIVERY_OPTION_HOME
}

export const mapDeliveryMode = (deliveryMode, getState) => {
  const { parameterReducer } = getState()
  const { userSession: { commercialInfo: { countryCode } } } = getState()
  const {
    cost,
    displayCost,
    deliveryDate,
    deliveryDay,
    deliveryPolicyId,
    distributionCenterCode,
    handleDays,
    scheduledDelivery,
    shippingMethodId,
  } = deliveryMode

  const { description = 'Normal' } = parameterReducer.shippingMethod.find(
    item => String(item.code) === String(shippingMethodId)
  )

  const deliveryTime = formatCountryDeliveryDate(deliveryDate, translate, countryCode)

  return {
    id: `${shippingMethodId}-${deliveryPolicyId}`,
    titleOptions: translate.byKey(description.toLowerCase()),
    optionType: 'delivery',
    valueShipping: deliveryMode && displayCost,
    deliveryTime,
    cost: deliveryMode && cost,
    deliveryDay,
    deliveryPolicyId,
    distributionCenterCode,
    handleDays,
    scheduledDelivery,
    shippingMethodId,
  }
}
