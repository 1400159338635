import IconCyclePromotion from 'commons/assets/images/ic-cycle-promotions.png'
import IconForYou from 'commons/assets/images/ic-for-you.png'
import IconPurchaseSuggestion from 'commons/assets/images/ic-purchase-suggestion.png'
import IconStock from 'commons/assets/images/ic-stock.png'

import FlagCyclePromotion from 'commons/assets/images/ic-flag-cycle-promotions.png'
import FlagForYou from 'commons/assets/images/ic-flag-for-you.png'
import FlagPurchaseSuggestion from 'commons/assets/images/ic-flag-purchase-suggestion.png'
import FlagStock from 'commons/assets/images/ic-flag-stock.png'

import { PROMOTIONS } from 'redux/actions/router/constants'

export const initialState = {
  open: false,
  filterSearch: false,
  searching: false,
  textInput: '',
  groupSelected: null,
  isSearchControlsOpen: false,
  isFilterOpen: false,
  isSortOpen: false,
  groupsList: [
    {
      code: '001',
      name: 'Promociones Del Ciclo',
      icon: IconCyclePromotion,
      flag: FlagCyclePromotion,
      color: '#8ebe2f',
      navigateToPath: PROMOTIONS
    },
    {
      code: '002',
      name: 'Sugestión de Compra',
      icon: IconPurchaseSuggestion,
      flag: FlagPurchaseSuggestion,
      color: '#572a86',
      navigateToPath: PROMOTIONS
    },
    {
      code: '003',
      name: 'Stock',
      icon: IconStock,
      flag: FlagStock,
      color: '#60afc6',
      navigateToPath: PROMOTIONS
    },
    {
      code: '004',
      name: 'Hecho para ti',
      icon: IconForYou,
      flag: FlagForYou,
      color: '#f6be0f',
      navigateToPath: PROMOTIONS
    }
  ],
  listSearch: [],
  filterListSearch: [],
  recentSearch: []
}
