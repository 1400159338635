import { isEmpty, get, pick } from 'lodash'
import { takeLatest, put, select } from 'redux-saga/effects'
import { PROFILE } from 'natura-commons/graphql/queries'
import { commercialInfoResponsePush } from 'dataLayer/dataLayerPush'
import { structureCommercialInfoResponse } from 'dataLayer/structuring/profileData'
import { executeQuery, setLoading } from 'redux/sagas/custom-effects'
import { setCommercialData } from 'redux/actions/dataLayer/dataLayerAction'
import { verifyError, setAlerts } from 'redux/actions/alerts/alertsAction'
import { getCycleOrderAlert } from 'commons/presenter/order/home/presenter'
import { INFORMATION_NOT_FOUND } from 'commons/constants/error'

export const sagas = ({ types, creators }) => {
  function* queryProfileOtherCycles() {
    const { selectedRole, xAppToken } = yield select(state => ({
      selectedRole: state.userSession.selectedRole,
      xAppToken: state.userSession.xAppTokenPersonDataImpersonate
    }))

    return yield executeQuery({
      query: PROFILE.getOtherCycles,
      variables: pick(selectedRole, [
        'roleId',
        'businessModelId',
        'functionId'
      ]),
      context: {
        headers: {
          xAppToken
        }
      }
    })
  }

  function* queryChangeCycle(cycleCode, subCycleCode, personID, orderDate) {
    const xAppTokenOtherCycles = yield select(state =>
      get(state, 'userSession.xAppTokenOtherCycles')
    )

    return yield executeQuery({
      query: PROFILE.getChangeCycle,
      variables: {
        personID,
        cycleCode,
        subCycleCode,
        orderDate,
      },
      context: {
        headers: {
          xAppToken: xAppTokenOtherCycles
        }
      }
    })
  }

  function* onProfileCyclesRequest() {
    yield takeLatest(types.PROFILE_CYCLES_REQUEST, function*() {
      const cyclesInfo = yield select(state => state.userSession.cycles)

      if (!isEmpty(cyclesInfo)) {
        return
      }

      yield setLoading(true)

      const { data, errors } = yield queryProfileOtherCycles()

      if (!errors) {
        const { payload, xAppToken } = get(data, 'profileGetOtherCycles', {})

        yield put(creators.profileCyclesSuccess(payload, xAppToken))
      } else {
        yield onGetProfileCyclesRequestFailure(errors)
      }

      yield setLoading(false)
    })
  }

  function* onGetProfileCyclesRequestFailure(errors) {
    yield put(dispatch =>
      verifyError(dispatch, { errors }, true, INFORMATION_NOT_FOUND)
    )
  }

  function* onProfileCyclesSuccess() {
    yield takeLatest(types.PROFILE_CYCLES_SUCCESS, function*(action) {
      const isImperson = yield select(
        state => state.userSession.impersonation.isImperson
      )

      if (isImperson) {
        yield put(creators.setImpersonCycleData(action.cyclesInfo))
      } else {
        yield put(creators.setCycleData(action.cyclesInfo))
      }

      if (!action.cyclesInfo) {
        const {
          cycleCode,
          dateStart,
          subcycle: { subcycleCode }
        } = yield select(state => state.userSession.detailsPerson.orderCycle)

        return yield put(creators.setCycle(cycleCode, subcycleCode, dateStart))
      }
      if (action.cyclesInfo.otherCycles.length > 1) {
        yield put(
          setAlerts([getCycleOrderAlert(action.cyclesInfo.otherCycles)])
        )
      } else {
        const {
          cycle: {
            cycleCode,
            subcycle: { subcycleCode }
          },
          orderDate,
        } = action.cyclesInfo.otherCycles[0]

        yield put(creators.setCycle(cycleCode, subcycleCode, orderDate))
      }
    })
  }

  function* onSetCycle() {
    yield takeLatest(types.SET_CYCLE, function*(action) {
      const {
        isImperson,
        personData,
        userCurrentCycle
      } = yield select(state => ({
        isImperson: state.userSession.impersonation.isImperson,
        personData: state.userSession.impersonation.person.personData,
        userCurrentCycle: state.userSession.cycles[0].cycle
      }))

      const personId = isImperson ? personData.personId : null
      const currentCycleDate = userCurrentCycle.orderDate || userCurrentCycle.dateStart

      if (action.cycleCode === userCurrentCycle.cycleCode && action.orderDate === currentCycleDate) {
        yield put(creators.commercialInfoRequest())
      } else {
        yield put(
          creators.changeCycleRequest(
            action.cycleCode,
            action.subcycleCode,
            personId,
            action.orderDate,
          )
        )
      }
    })
  }

  function* onChangeCycleRequest() {
    yield takeLatest(types.CHANGE_CYCLE_REQUEST, function*(action) {
      yield setLoading(true)

      const { data, errors } = yield queryChangeCycle(
        action.cycleCode,
        action.subcycleCode,
        action.personId,
        action.orderDate,
      )

      if (!errors) {
        const { payload, xAppToken } = get(data, 'profileGetPersonData', {})

        yield put(creators.changeCycleSuccess(payload, xAppToken))
      } else {
        yield onChangeCycleRequestFailure(errors)
      }
      yield setLoading(false)
    })
  }

  function* onChangeCycleRequestSuccess() {
    yield takeLatest(types.CHANGE_CYCLE_SUCCESS, function*() {
      yield put(creators.commercialInfoRequest())
    })
  }

  function* onChangeCycleRequestFailure(errors) {
    yield commercialInfoResponsePush(structureCommercialInfoResponse())
    yield put(setCommercialData({}))
    yield put(dispatch => verifyError(dispatch, { errors }))
  }

  return [
    onChangeCycleRequest,
    onChangeCycleRequestSuccess,
    onProfileCyclesRequest,
    onProfileCyclesSuccess,
    onSetCycle
  ]
}
