import React from 'react'
import { connect } from 'react-redux'

import { setError } from 'redux/actions/errors/errorAction'
import { setClient } from 'redux/actions/graphql/graphqlAction'

import { v4 as uuid } from 'uuid';

import { get, omitBy, isNil } from 'lodash'

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  createLoggerLink,
  createPersistedQueryLink,
  from,
  onError,
  setContext,
} from 'natura-commons/graphql'
import { IGNORED_PERSISTED_QUERIES } from 'natura-commons/graphql/constants'

import { defaultConfig } from 'natura-commons/service/Sdk'

export const GraphQLProvider = connect(state => ({
  xAppToken: get(state, 'userSession.xAppTokenCommercialInfo') || '',
  Authorization: `Bearer ${get(state, 'userSession.authenticationToken') || ''}`,
  sessionIdentifier: get(state, 'userSession.commercialInfo.sessionIdentifier') || '',
}), { setError, setClient })(({ setError, setClient, xAppToken, Authorization, sessionIdentifier, ...props }) => {
  const commonHeaders = {
    xAppToken,
    Authorization,
    sessionIdentifier,
    'correlation-id': uuid(),
    cookie: 'XXX',
  }

  const uri = defaultConfig.providers[process.env.REACT_APP_NODE_ENV]
  const links = [
    /**
     * LOGGER LINK
     */
    createLoggerLink({
      enabled: [
        'local',
        'development'
      ].includes(process.env.REACT_APP_NODE_ENV.toString().toLowerCase())
    }),
    /**
     * CONTEXT LINK
     */
    setContext(({ operationName }, { headers }) => ({
      headers: omitBy({
        ...commonHeaders,
        ...headers,
        operation: operationName || 'undefined', // used by dynatrace
      }, isNil),
    })),
    /**
     * PERSISTED QUERY LINK
     */
    createPersistedQueryLink({
      useGETForHashedQueries: true,
      ignoreQueries: [...IGNORED_PERSISTED_QUERIES],
    }),
    /**
     * ERROR LINK
     */
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (!['PersistedQueryNotFound'].includes(message)) {
            console.error(
              `[GraphQL]: Location: ${locations}, Path: ${path} ${message}`
            )
          }
        })
      }

      if (networkError) {
        setError(networkError)
      }
    }),

    /**
     * HTTP LINK
     */
    createHttpLink({
      uri,
      fetchOptions: {
        credentials: 'include',
      },
      headers: {
        ...commonHeaders,
      }
    })
  ]

  const client = new ApolloClient({
    link: from(links),
    cache: new InMemoryCache({
      addTypename: false
    }),
    defaultOptions: {
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })

  React.useEffect(() => {
    setClient(client)
  }, [links, setClient]) // eslint-disable-line react-hooks/exhaustive-deps

  return <ApolloProvider client={client} {...props} />
})
