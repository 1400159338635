import {
  STATUS_CART_CALCULATED,
  STATUS_CART_SHIPPING_READY,
  STATUS_CART_PAYMENT_READY,
  STATUS_CART_PENDING_PROMOTION,
  STATUS_CART_PENDING_PROFITABILITY,
  STEP_BAG,
  STEP_GIFT,
  STEP_SHIPPING,
  STEP_PAYMENT,
  STEP_EXCLUSIVE,
} from 'commons/constants/cart'

export const checkCurrentStepCartShouldBeReady = currentStep => [STEP_BAG, STEP_GIFT, STEP_EXCLUSIVE, STEP_SHIPPING, STEP_PAYMENT].includes(currentStep)
export const checkCartIsReady = cartStatusId => [STATUS_CART_CALCULATED, STATUS_CART_SHIPPING_READY, STATUS_CART_PAYMENT_READY].includes(cartStatusId)
export const checkPendingPromotionOrProfitability = cartStatusId => [STATUS_CART_PENDING_PROMOTION, STATUS_CART_PENDING_PROFITABILITY].includes(cartStatusId)

export const showCalculatedMessageToast = (cartStatusId, currentStep) => {
  return [STATUS_CART_CALCULATED].includes(cartStatusId) && [STEP_BAG].includes(currentStep)
}

export const statusId = (cart) => {
  const { resume } = cart
  const { stepSelected, list } = cart.steps
  const currentStep = list[stepSelected].type
  const cartIsReady = checkCartIsReady(resume.statusId)
  const currentStepCartShouldReady = checkCurrentStepCartShouldBeReady(currentStep)

  return !(currentStepCartShouldReady && cartIsReady)
}
