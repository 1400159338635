export const initialState = {
  userRoles: [{
    businessModelName: undefined
  }],
  siteLoad: {
    event: 'siteLoad',
    countryId: undefined,
    countryName: undefined,
    systemId: undefined,
    systemName: undefined,
    channelId: undefined,
    channelName: undefined,
    companyId: undefined,
    companyName: undefined,
    productsNbo: [undefined],
    isTest: undefined,
    isImpersonation: undefined,
    user: {
      cycle: {
        cycleCode: undefined,
        subcycleCode: undefined,
        orderCycleCode: undefined,
        orderSubcycleCode: undefined
      },
      addresses: undefined,
      personId: undefined,
      personCode: undefined,
      personName: undefined,
      businessModel: undefined,
      isBlocked: undefined,
      blockReason: undefined,
      growthPlanLevelCN: undefined,
      growthPlanLevelLeader: undefined,
      personCodeGDN: undefined,
      personCodeLider: undefined,
      canTest: undefined,
      canImpersonate: undefined
    },
    persona: {
      cycle: {
        cycleCode: undefined,
        subcycleCode: undefined,
        orderCycleCode: undefined,
        orderSubcycleCode: undefined
      },
      addresses: undefined,
      personId: undefined,
      personCode: undefined,
      personName: undefined,
      businessModel: undefined,
      isBlocked: undefined,
      blockReason: undefined,
      growthPlanLevelCN: undefined,
      growthPlanLevelLeader: undefined,
      personCodeGDN: undefined,
      personCodeLider: undefined,
      segmentation: undefined
    },
  },
  currentPage: undefined, // The current page URL
  cartContent: undefined, // The latest received cart content
  cartAdditionOrigin: {
    origin: undefined,
    listName: undefined,
    listPosition: undefined
  }, // The source of the latest addition to the cart
  checkoutEvent: {
    event: 'checkout',
    checkoutStep: undefined,
    cartSummary: {
      cartTotal: undefined,
      totalPriceGain: undefined,
      totalGifts: undefined,
      totalPoints: undefined,
      totalTributes: undefined,
      totalCredits: undefined,
      shippingAmount: undefined,
      shippingAmountDiscount: undefined,
      shippingAmountWoDiscount: undefined
    },
    checkoutProducts: {
      gifts: [undefined],
      products: [undefined],
      promotions: [undefined],
      hasPreSales: undefined
    }
  },
  vtrNavigationOrigin: undefined,
  currentList: undefined,
  callGTM: false,
  pendingDataLayerActions: []
}
