import React from 'react';

import {
  Button,
  Dialog,
  Typography,
} from '@naturacosmeticos/natds-web';

import {
  DialogTitleSplit,
  DialogActionsButtons,
  DialogContentCustomer
} from './styles'

export const selectCustomerErrors = {
  CUSTOMER_DELIVERY_MODES_INVALID: 'CUSTOMER_DELIVERY_MODES_INVALID',
  DELIVERY_GEO_LEVEL_INVALID: 'DELIVERY_GEO_LEVEL_INVALID',
  SPLIT_MAX_CUSTOMERS: 'SPLIT_MAX_CUSTOMERS',
}

const selectCustomerErrorsDictionary = {
  CUSTOMER_DELIVERY_MODES_INVALID: 'customerDeliveryModeInvalid',
  DELIVERY_GEO_LEVEL_INVALID: 'invalidDeliveryGeoStructure',
  SPLIT_MAX_CUSTOMERS: 'splitMaxCustomers',
}

export const getSelectCustomerError = (errors = []) => {
  const getStepError = (step = 0) => {
    const error = errors[step];
    const errorPrefix = error && error.type ? selectCustomerErrorsDictionary[error.type] : false

    if (errorPrefix) {
      return {
        errorName: error.type,
        label: errorPrefix,
        description: `${errorPrefix}Description`
      }
    }

    const nextStep = step + 1;

    if (nextStep < errors.length) {
      return getStepError(nextStep)
    }

    return null
  }

  return getStepError();
}

const SelectCustomerDefaultError = ({ splitError, handlers, translate }) => {
  return <Dialog
    open
    maxWidth="xs"
    scroll="paper"
  >
    <DialogTitleSplit>{translate.byKey(splitError.label)}</DialogTitleSplit>
    <DialogContentCustomer>
      <Typography variant="body1">{translate.byKey(splitError.description)}</Typography>
    </DialogContentCustomer>
    <DialogActionsButtons>
      <Button
        color="inherit"
        variant="text"
        onClick={() => handlers.readSplitError(splitError.errorName)}
      >
        <Typography variant="button">{translate.byKey('ok')}</Typography>
      </Button>
    </DialogActionsButtons>
  </Dialog>
}

export default SelectCustomerDefaultError;
