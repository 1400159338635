import { initialState } from 'commons/interactor/products/mapper'

import {
  productListReducer,
  productsNextCicleReducer,
  productsSimilarReducer,
  clearProductListReducer,
  productListPaginedReducer,
  syncAvailability
} from 'commons/interactor/products/interactor'

import {
  PRODUCT_LIST,
  CLEAR_REDURCERS,
  PRODUCT_LIST_NEXT_CICLE,
  PRODUCT_LIST_SIMILAR,
  CLEAR_PRODUCT_LIST_SIMILAR,
  CLEAR_CART,
  CLEAR_LIST_PRODUCTS,
  CLEAR_REDUCER_IMPERSONATION,
  PRODUCT_LIST_PAGINED,
  SYNC_AVAILABILITY
} from '../../actions/constants'

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_LIST:
      return productListReducer(state, action.list)
    case PRODUCT_LIST_PAGINED:
      return productListPaginedReducer(state, action)
    case PRODUCT_LIST_NEXT_CICLE:
      return productsNextCicleReducer(state, action.productsNextCicle)
    case PRODUCT_LIST_SIMILAR:
      return productsSimilarReducer(state, action.productsSimilar)
    case CLEAR_PRODUCT_LIST_SIMILAR:
      return productsSimilarReducer(state, null)
    case CLEAR_LIST_PRODUCTS:
      return clearProductListReducer(state)
    case SYNC_AVAILABILITY:
      return syncAvailability(state, action)
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
    case CLEAR_CART:
      return initialState
    default:
      return state
  }
}
