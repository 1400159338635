import { createModule } from 'natura-commons/redux'
import { sagas } from './sagas'

export const { creators, types, reducer, saga } = createModule({
  options: {
    id: 'responsiveness'
  },

  state: {
    isMobile: false,
    isTablet: false,
  },

  actions: {
    setMobile: [
      ['isMobile'],
      (state, { isMobile }) => ({
        ...state,
        isMobile,
      })
    ],

    setTablet: [
      ['isTablet'],
      (state, { isTablet }) => ({
        ...state,
        isTablet,
      })
    ],
  },

  sagas,
})
