import { CLEAR_ACCEPTANTE_TERM, SET_ACCEPTANTE_TERMS, SHOW_ACCEPTANTE_TERM, CLOSE_ACCEPTANTE_TERM } from '../constants'
import { showLoad } from 'redux/actions/load/loadAction'
import { verifyError } from 'redux/actions/alerts/alertsAction'
import People from 'natura-commons/service/People'
import { ERROR_GET_ACCEPTANCE_TERM, ERROR_SET_ACCEPTANCE_TERM } from 'commons/constants/error'

const peopleAPI = new People()

export const getPendingAcceptanceTerms = (personId, response) => (dispatch) => {
  const { countryCode } = response.data.profileGetPersonData.payload
  
  peopleAPI.resolver(
    (res) => {
      const { pendingAgreements = [] } = res.data.getPendingAcceptanceTerm

      if (pendingAgreements.length > 0) {
        const notAcceptingTerms = pendingAgreements.map(item => ({ ...item, accepted: false }))

        dispatch({ type: SET_ACCEPTANTE_TERMS, payload: { pendingAcceptanceTerms: notAcceptingTerms, personId, response }})
        dispatch({ type: SHOW_ACCEPTANTE_TERM })
      } else {
        dispatch({ type: SET_ACCEPTANTE_TERMS, payload: { 
          pendingAcceptanceTerms: [], 
          personId: null, 
          response }
        })
        dispatch({ type: CLOSE_ACCEPTANTE_TERM, payload: { allAccepted: true }})
      }
    },
    (err) => {
      handleErrorAcceptanceTerms(dispatch, ERROR_GET_ACCEPTANCE_TERM)
    },
    peopleAPI.getPendingAcceptanceTerms({ personId, countryCode })
  )
}

export const setAcceptPendingAcceptanceTerm = (args) => (dispatch, getState) => {
  dispatch(showLoad(true))

  peopleAPI.resolver(
    (res) => {
      const { status } = res.data.setAcceptPendingAcceptanceTerm;

      if (status === '200') {
        const { acceptanceTermReducer: { 
            pendingAcceptanceTerms,
            personId, 
            personDataResponse 
          }} = getState()

        dispatch({ type: SET_ACCEPTANTE_TERMS, payload: { 
          pendingAcceptanceTerms: pendingAcceptanceTerms.map(item => {
            if (item.agreementId === args.agreementId) {
              return { ...item, accepted: true }
            }
  
            return item;
          }), 
          personId, 
          response: personDataResponse
        }})

        dispatch(showLoad(false))
      } else if (status === '404') {
        dispatch({ type: CLOSE_ACCEPTANTE_TERM, payload: { allAccepted: true }})
        dispatch(showLoad(false))
      } else {
        throw new Error()
      }
    },
    (err) => {
      handleErrorAcceptanceTerms(dispatch, ERROR_SET_ACCEPTANCE_TERM)
      dispatch(showLoad(false))
    },
    peopleAPI.setAcceptPendingAcceptanceTerm(args)
  )
}

export const handleErrorAcceptanceTerms = (dispatch, error) => {
  verifyError(dispatch, [error], true)
  dispatch({ type: SET_ACCEPTANTE_TERMS, payload: { 
    pendingAcceptanceTerms: [], 
    personId: null, 
    response: null }
  })
  dispatch({ type: CLOSE_ACCEPTANTE_TERM })
  dispatch({ type: CLEAR_ACCEPTANTE_TERM })
}