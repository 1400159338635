import {
  SET_PROFILE,
  CLEAR_MODAL,
  CLEAR_REDURCERS,
  SET_VALUE_CHANGE,
  OPEN_MODAL_SARRAFOS
} from '../../actions/constants'

import { initialState } from '../../../commons/interactor/modal/mapper'
import {
  setProfileReducer,
  setValueChangeReducer
} from '../../../commons/interactor/modal/interactor'

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return setProfileReducer(state, action.payload)
    case SET_VALUE_CHANGE:
      return setValueChangeReducer(state, action.payload)
    case OPEN_MODAL_SARRAFOS:
      return { ...state, openModalSarrafos: action.payload }
    case CLEAR_REDURCERS:
    case CLEAR_MODAL:
      return initialState
    default:
      return state
  }
}

export default modalReducer
