import { filter, uniqBy } from 'lodash'

import types from './sucursal.types'

const initialState = {
  fields: {
    address: [],
    countryGeoStructureLevel: {},
    selectedAgency: {}
  },
  lists: {
    geoStructureTree: [],
    agencies: {
      page_1: []
    },
    pagination: {
      currentPage: 1,
      numberPage: 1
    },
    pageState: null,
  }
}

const clearStructureChildrenLevels = (structureLevels, level) => {
  let found = false
  const extractNumber = str => parseInt(str.split('_')[1])

  Object.keys(structureLevels)
    .sort((acc, next) => extractNumber(acc) - extractNumber(next))
    .forEach((key) => {
      if (found) {
        structureLevels[key] = []
      }

      if (key === level) {
        found = true
      }
    })

  return structureLevels
}

const getGeoStructureLevels = (newStructureLevels, structureLevels = [], clearChildrenLevels = true) => {
  let currentLevel

  const newStructureLevelsAux = newStructureLevels.reduce(
    (acc, currentValue) => {
      currentLevel = `level_${
        currentValue.geoStructureLevelId >= 0
          ? currentValue.geoStructureLevelId
          : currentValue.level
      }`
      const value = currentValue

      if (!acc[currentLevel]) {
        acc[currentLevel] = []
      }

      if (acc[currentLevel].some(({ parentStructureCode }) => parentStructureCode !== value.parentStructureCode)) {
        return {
          ...acc,
          [currentLevel]: [value]
        }
      }

      return {
        ...acc,
        [currentLevel]: uniqBy([...acc[currentLevel], value], 'code')
      }
    },
    { ...structureLevels }
  )

  if (!clearChildrenLevels) {
    return newStructureLevelsAux
  }

  return clearStructureChildrenLevels(newStructureLevelsAux, currentLevel)
}

const formatNextLevel = ({ nextLevel, level, geoStructureCode }) => nextLevel.map(geoStructure => ({
  code: geoStructure.code || geoStructure.geoStructureCode,
  description: geoStructure.description || geoStructure.geoStructureDescription,
  level: level + 1,
  parentStructureCode: geoStructureCode,
}))

const sucursalReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_GEO_TREE: {
      const geoList = !payload.value
        ? []
        : payload.value.map(
          ({
            geoStructureCode,
            geoStructureDescription,
            nextLevel,
            geoStructureLevelId
          }) => ({
            geoStructureCode,
            geoStructureDescription,
            nextLevel,
            geoStructureLevelId
          })
        )
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          [payload.list]: geoList
        }
      }
    }

    case types.SET_AGENCIES: {
      const { shipping, pageState, currentPage } = payload
      const { agencies, pagination } = state.lists

      const page = pageState ? pagination.numberPage - 1 : pagination.numberPage

      const newAgencies = {
        ...agencies,
        [`page_${page}`]: shipping
      }

      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          agencies: {
            ...newAgencies
          },
          pagination: {
            ...state.lists.pagination,
            currentPage
          },
          pageState,
        }
      }
    }

    case types.INCREMENT_NUMBER_PAGINATION: {
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          pagination: {
            ...state.lists.pagination,
            numberPage: state.lists.pagination.numberPage + 1
          }
        }
      }
    }

    case types.DECREMENT_NUMBER_PAGINATION:
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          pagination: {
            ...state.lists.pagination,
            numberPage: state.lists.pagination.numberPage - 1
          }
        }
      }

    case types.SET_CURRENT_PAGINATION:
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          pagination: {
            ...state.lists.pagination,
            currentPage: payload.page
          }
        }
      }

    case types.SET_ADDRESS: {
      const { level } = payload.value
      const { address } = state.fields

      const addressAux = filter(address, adrss => !adrss || adrss.level <= level)

      addressAux[level] = payload.value

      return {
        ...state,
        fields: {
          ...state.fields,
          address: addressAux,
        },
      }
    }

    case types.CLEAR_ADDRESS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          address: {
            page_1: []
          },
        },
      }
    }

    case types.CLEAR_GEO_STRUCTURE_TREE:
      return {
        ...state,
        lists: {
          ...state.lists,
          geoStructureTree: {
            level_0: state.lists.geoStructureTree.level_0,
            level_1: state.lists.geoStructureTree.level_1,
          },
        },
      }

    case types.CLEAR_NEXT_ADDRESSESS: {
      const { currentLevel } = payload
      const geoStructureTree = clearStructureChildrenLevels(state.lists.geoStructureTree, `level_${currentLevel}`)

      return {
        ...state,
        lists: {
          ...state.lists,
          geoStructureTree,
        },
      }
    }

    case types.SET_SELECTED_AGENCY: {
      return {
        ...state,
        fields: {
          ...state.fields,
          selectedAgency: payload.agency,
        },
      }
    }

    case types.CLEAR_AGENCY_LIST: {
      return {
        ...state,
        fields: state.fields,
        lists: {
          ...state.lists,
          agencies: {
            page_1: []
          },
          pagination: {
            currentPage: 1,
            numberPage: 1
          },
          pageState: null
        }
      }
    }

    case types.CLEAR_NEXT_ADDRESS_LEVEL: {
      const { level } = payload
      const { address } = state.fields

      const newAddress = address.filter(item => item === undefined || item?.level <= level)

      return {
        ...state,
        fields: {
          address: newAddress,
        }
      }
    }

    case types.SET_ADDRESS_FILTER_GEO_STRUCTURE_TREE: {
      const { address } = state.fields

      const result = payload.structureLevels.reduce((acc, geoStructureCMM) => {
        const currentLevel = `level_${geoStructureCMM.level}`

        const filterLevel = address.find(geoStructureAddress => (
          (Number(geoStructureCMM.level) === Number(geoStructureAddress?.level)) &&
          (Number(geoStructureCMM.code) === Number(geoStructureAddress?.code))))

        if (filterLevel) {
          return {
            ...acc,
            [`level_${geoStructureCMM.level + 1}`]: formatNextLevel(geoStructureCMM),
            [currentLevel]: uniqBy([...(acc[currentLevel] || []), geoStructureCMM], 'code'),
          }
        }

        return {
          ...acc,
          [currentLevel]: uniqBy([...(acc[currentLevel] || []), geoStructureCMM], 'code'),
        }
      }, {})

      return {
        ...state,
        fields: {
          ...state.fields,
          countryGeoStructureLevel:
            payload.countryGeoStructureLevel ||
            state.fields.countryGeoStructureLevel
        },
        lists: {
          ...state.lists,
          geoStructureTree: {
            ...result
          }
        }
      }
    }

    case types.SET_NEXT_GEO_STRUCTURE_LEVEL: {
      const geoStructureLevels = getGeoStructureLevels(
        payload.structureLevels,
        state.lists.geoStructureTree
      )

      return {
        ...state,
        fields: {
          ...state.fields,
          countryGeoStructureLevel:
            payload.countryGeoStructureLevel ||
            state.fields.countryGeoStructureLevel
        },
        lists: {
          ...state.lists,
          geoStructureTree: geoStructureLevels
        }
      }
    }

    case types.INIT_GEO_STRUCTURE_LEVEL_BY_ADDRESS: {
      const newStructureLevels = payload.structureLevels.reduce((acc, geoStructure) => {
        acc[`level_${geoStructure.level}`] = acc[`level_${geoStructure.level}`] || []
        acc[`level_${geoStructure.level}`].push({
          ...geoStructure,
          nextLevel: [payload.structureLevels.find(({ level }) => level === geoStructure.level + 1)],
          parentStructureCode: payload.structureLevels.find(({ level }) => level === geoStructure.level - 1)?.code || ''
        })

        return acc
      }, {})

      return {
        ...state,
        lists: {
          ...state.lists,
          geoStructureTree: newStructureLevels
        }
      }
    }

    case types.SET_SUCURSAL_FILTER_LOADING: {
      return {
        ...state,
        filterLoading: payload
      }
    }

    case types.SET_SUCURSAL_AGENCY_LOADING: {
      return {
        ...state,
        agencyLoading: payload
      }
    }

    default:
      return { ...state }
  }
}

export default sucursalReducer
