import Product from 'natura-commons/service/Product'

import {
  FILTERED_SEARCH,
  SEARCHING,
  GROUP_SELECTED,
  OPEN_FILTER_CONTROL,
  OPEN_SORT_CONTROL,
  CLOSE_SORT_CONTROL,
  CLOSE_FILTER_CONTROL,
  SYNC_AVAILABILITY,
  SYNC_AVAILABILITY_HIGHLIGHTED,
  SYNC_AVAILABILITY_PROMOTION
} from '../constants'

import {
  navigateTo,
  navigateOrderSearch
} from 'redux/actions/router/routerAction'
import {
  listProductsSimilar,
  listProducts
} from 'redux/actions/products/productsActions'
import { ORDER_SEARCH } from 'redux/actions/router/constants'
import {
  mapProduct,
  mapProductsAvailability
} from 'commons/interactor/products/mapper'
import { getArrayCodeProducts } from 'commons/interactor/products/interactor'

import { showLoad } from 'redux/actions/load/loadAction'
import { verifyError, errorIgnore } from 'redux/actions/alerts/alertsAction'

const filteredSearch = (list, key) => dispatch => {
  dispatch({ type: FILTERED_SEARCH, payload: list, key })
}
export const searching = doSearch => dispatch => {
  dispatch({ type: SEARCHING, payload: doSearch })
}

export const orderHomeSearchDispatch = (
  objSearch,
  redirect = true
) => dispatch => {
  const { item, textInput } = objSearch
  const valueInputSearch = item !== null ? item.name : textInput
  dispatch(
    filterSearchDispatch(
      item !== null ? item.code : textInput,
      valueInputSearch
    )
  )
  if (redirect) dispatch(navigateTo(ORDER_SEARCH))
}

export const orderSearchDispatch = objSearch => dispatch => {
  dispatch(orderHomeSearchDispatch(objSearch, false))
}

export const searchGroupSelectedDispatch = objSearch => dispatch => {
  dispatch({ type: GROUP_SELECTED, payload: objSearch })
}

export const orderSearchProductSimilarDispatch = (
  item,
  redirect = false
) => async (dispatch, getState) => {
  const {
    cartReducer: {
      resume: { totalMinOrderSize }
    }
  } = getState()

  const cartSize = totalMinOrderSize?.map(
    item => ({ companyBrand: item.companyBrandIds, value: item.totalMinOrderSizeByBrand })
  )

  dispatch(showLoad(true))
  const products = new Product()
  products.resolver(
    response => {
      dispatch(listProductsSimilar(item, mapProduct(response.product)))
      dispatch(searching(false))
      dispatch(showLoad(false))
      if (redirect) {
        dispatch(navigateOrderSearch())
      }
    },
    error => {
      dispatch(showLoad(false))
      verifyError(dispatch, error)
    },
    products.byID({ key: getArrayCodeProducts(item), cartSize })
  )
}

export default function filterSearchDispatch(key, valueInputSearch = null) {
  return async (dispatch, getState) => {
    dispatch(showLoad(true))
    if (key.length >= 2) {
      const {
        userSession: {
          commercialInfo: { countryCode, companyId }
        },
        cartReducer: {
          resume: { totalMinOrderSize }
        }
      } = getState()

      const cartSize = totalMinOrderSize?.map(
        item => ({ companyBrand: item.companyBrandIds, value: item.totalMinOrderSizeByBrand })
      )
      const products = new Product()
      products.resolver(
        response => {
          const newList = mapProduct(response.product)
          valueInputSearch !== null && dispatch(listProducts(newList))
          valueInputSearch = valueInputSearch !== null ? valueInputSearch : key
          dispatch(filteredSearch(newList, valueInputSearch))
          dispatch(searching(false))
          dispatch(showLoad(false))
        },
        error => {
          dispatch(showLoad(false))
          verifyError(dispatch, error)
        },
        products.findProduct({ key, countryCode, companyId, cartSize })
      )
    } else {
      dispatch(filteredSearch([], ''))
      dispatch(showLoad(false))
      dispatch(searching(false))
    }
  }
}

export const setFilterControlOpen = (isOpen = false) => dispatch => {
  if (isOpen) {
    return dispatch({ type: OPEN_FILTER_CONTROL })
  }
  return dispatch({ type: CLOSE_FILTER_CONTROL })
}
export function setSortControlOpen(isOpen = false) {
  return dispatch => {
    if (isOpen) {
      return dispatch({ type: OPEN_SORT_CONTROL })
    }
    return dispatch({ type: CLOSE_SORT_CONTROL })
  }
}

export const findAvailability = (codes, index = null) => async dispatch => {
  if (codes && codes.length > 0) {
    const products = new Product()
    products.resolver(
      response => {
        dispatch({
          type:
            index === null ? SYNC_AVAILABILITY : SYNC_AVAILABILITY_PROMOTION,
          productsCheck: response,
          index
        })
      },
      error => {
        if (errorIgnore(error.erros)) {
          const response = mapProductsAvailability(error.productCodes)
          dispatch({
            type:
              index === null ? SYNC_AVAILABILITY : SYNC_AVAILABILITY_PROMOTION,
            productsCheck: response,
            index
          })
          return
        }
        verifyError(dispatch, error)
      },
      products.checkAvailableProducts(codes)
    )
  }
}

export const findHighlightedAvailability = (codes, index) => async dispatch => {
  if (codes && codes.length > 0) {
    const products = new Product()
    products.resolver(
      response => {
        dispatch({
          type: SYNC_AVAILABILITY_HIGHLIGHTED,
          productsCheck: response,
          index
        })
      },
      error => {
        if (errorIgnore(error.erros)) {
          const response = mapProductsAvailability(error.productCodes)
          dispatch({
            type: SYNC_AVAILABILITY_HIGHLIGHTED,
            productsCheck: response,
            index
          })
          return
        }
        verifyError(dispatch, error)
      },
      products.checkAvailableProducts(codes)
    )
  }
}
