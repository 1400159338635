import {
  SET_SORTER_SELECTED_SEARCH_PRODUCT,
  CLEAR_SORTER_SEARCH_PRODUCT,
  SET_FILTER_SELECTED_PRODUCT,
  PRODUCT_LIST_PAGINED,
  SET_INITIAL_FILTER_AFTER_SEARCH
} from 'redux/actions/constants'
import {
  sortProductsPagined,
  sortPromotions,
  paginedPromotions,
  clearPaginationVitrine,
  setProductsVitrine,
  listProductsPagined
} from 'redux/actions/products/productsActions'
import { filterOptions } from 'commons/presenter/order/search/presenter'

export const setSorterSelectedAction = (
  item,
  isPromotion = false
) => dispatch => {
  dispatch({
    type: SET_SORTER_SELECTED_SEARCH_PRODUCT,
    payload: item
  })

  !isPromotion
    ? dispatch(sortProductsPagined(item))
    : dispatch(sortPromotions(item))
}

export const clearSorterAction = () => dispatch => {
  dispatch({ type: CLEAR_SORTER_SEARCH_PRODUCT })
}

export const setFilterSelected = (filter, isPromotion = false) => dispatch => {
  dispatch({ type: SET_FILTER_SELECTED_PRODUCT, filter })
  if (isPromotion) {
    dispatch(clearPaginationVitrine())
    dispatch(paginedPromotions())
    return dispatch(setProductsVitrine())
  }
  dispatch({ type: PRODUCT_LIST_PAGINED, listPagined: [] })
  dispatch(listProductsPagined())
}

export const setInitialFilterAfterSearch = (isPromotion = false) => (
  dispatch,
  getState
) => {
  const {
    parameterReducer: { products },
    productsReducer: { productsSimilar, list }
  } = getState()

  const productList = isPromotion ? products : list

  const options = filterOptions(
    (!!productsSimilar && productsSimilar && productsSimilar.listSimilar) ||
      productList
  )

  return dispatch({
    type: SET_INITIAL_FILTER_AFTER_SEARCH,
    initialFilterAfterSearch: options
  })
}
