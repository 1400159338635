import { get, isEmpty } from 'lodash'
import { takeLatest, put, select } from 'redux-saga/effects'
import { HOME, ORDER } from 'redux/actions/router/constants'
import { INFORMATION_NOT_FOUND } from 'commons/constants/error'
import {
  executeQuery,
  setLoading,
} from 'redux/sagas/custom-effects'
import { getAlertTitleBody } from 'commons/presenter/order/home/presenter'
import { orderNavigateManagerAction } from 'commons/interactor/router/interactor'
import { setAlerts, verifyError } from 'redux/actions/alerts/alertsAction'
import { mapSingleConsultant } from 'commons/interactor/selectConsultant/mapper'
import { getModalConsultant } from 'commons/presenter/consultant/presenter'

import { setImpersonationLocalStorage } from 'natura-commons/utils/profile'
import { PROFILE } from 'natura-commons/graphql/queries'

export const sagas = ({ types, creators }) => {
  function* queryImpersonation(contact) {
    const xAppToken = yield select(state =>
      get(state, 'userSession.xAppTokenPersonDataImpersonate')
    )

    return yield executeQuery({
      query: PROFILE.getPersonData,
      variables: {
        personID: contact.personID
      },
      context: {
        headers: {
          xAppToken
        }
      }
    })
  }

  function* queryFindUserImpersonation({ personCode }) {
    const { businessModelId, companyId, countryCode, xAppToken } = yield select(
      state => ({
        businessModelId: get(
          state,
          'userSession.selectedRole.businessModelId'
        ),
        companyId: get(state, 'userSession.detailsPerson.companyId'),
        countryCode: get(state, 'userSession.detailsPerson.countryCode'),
        xAppToken: get(state, 'userSession.xAppTokenPersonDataImpersonate')
      })
    )

    return yield executeQuery({
      query: PROFILE.getUserForImpersonation,
      variables: {
        businessModelId,
        companyId,
        countryCode,
        personCode
      },
      context: {
        headers: {
          xAppToken
        }
      }
    })
  }

  function* onSetImpersonationRequest() {
    yield takeLatest(types.SET_IMPERSONATION_REQUEST, function* ({ contact }) {
      const { data, errors } = yield queryImpersonation(contact)

      if (!errors) {
        const { payload, xAppToken } = get(data, 'profileGetPersonData')

        yield put(creators.setImpersonationSuccess(payload, xAppToken))
      } else {
        yield put(dispatch =>
          verifyError(dispatch, { errors }, false, INFORMATION_NOT_FOUND)
        )
      }

      yield setLoading(false)
    })
  }

  function* onSetImpersonationSuccess() {
    yield takeLatest(types.SET_IMPERSONATION_SUCCESS, function* ({
      personData
    }) {
      if (isEmpty(personData.roles)) {
        yield put(
          setAlerts([getAlertTitleBody(null, 'accountIsBlocked', false)])
        )
      } else {
        yield put((dispatch, getState) =>
          orderNavigateManagerAction(getState, HOME)(dispatch)
        )
      }
    })
  }

  function* onFindUserForImpersonationRequest() {
    yield takeLatest(types.FIND_USER_FOR_IMPERSONATION_REQUEST, function* ({
      personCode
    }) {
      yield setLoading(true)

      const { data, errors } = yield queryFindUserImpersonation({ personCode })

      if (!errors) {
        yield onFindUserForImpersonationSuccess(data.BRMGetUserImpersonate)
      } else {
        yield put(dispatch => verifyError(dispatch, errors, false))
      }

      yield setLoading(false)
    })
  }

  function* onFindUserForImpersonationSuccess(userImpersonate) {
    if (!userImpersonate || userImpersonate[0].error) {
      yield put(dispatch =>
        verifyError(
          dispatch,
          [{ message: userImpersonate[0].error.status }],
          false
        )
      )

      return
    }

    yield put(
      setAlerts(getModalConsultant(mapSingleConsultant(userImpersonate[0])))
    )
  }

  /**
   * @deprecated This is just fallback for graphql queries inside commons folder
   * which still uses localStorage for headers
   */
  function* onSetImpersonationSuccessLocalStorage() {
    yield takeLatest(
      types.SET_IMPERSONATION_SUCCESS,
      ({ personData, xAppTokenPersonDataImpersonate }) => {
        setImpersonationLocalStorage(
          personData.personId,
          xAppTokenPersonDataImpersonate
        )
        sessionStorage.setItem('impersonateCode', personData.personCode)
      }
    )
  }

  return [
    onSetImpersonationRequest,
    onSetImpersonationSuccess,
    onSetImpersonationSuccessLocalStorage,
    onFindUserForImpersonationRequest
  ]
}
