const PERSON_DATA_MESSAGES = [
  {
    code: 1027,
    message: 'blocked.for.not.finding.active.roles',
    key: 'blockedByActiveRole'
  },
  {
    code: 1009,
    message: 'blocks.not.found',
    key: 'blocksNotFound'
  },
  {
    code: 1007,
    message: 'business.model.not.found',
    key: 'businessModelNotFound'
  },
  {
    code: 1004,
    message: 'business.relation.not.found',
    key: 'businessRelationNotFound'
  },
  {
    code: 1012,
    message: 'call.center.cant.impersonate.person.from.different.country',
    key: 'callCenterCountryFailed'
  },
  {
    code: 1020,
    message: 'cycle.not.found.in.CMM',
    key: 'cycleNotFound'
  },
  {
    code: 1028,
    message: 'invalid.person',
    key: 'invalidPerson'
  },
  {
    code: 1018,
    message: 'invalid.segmentation.schema',
    key: 'invalidSegmentationSchema'
  },
  {
    code: 1006,
    message: 'multiple.business.relation.found',
    key: 'multipleBusinessRelation'
  },
  {
    code: 1001,
    message: 'not.found',
    key: 'notFound'
  },
  {
    code: 1023,
    message: 'parameter.number.of.seconds.not.found',
    key: 'numberOfSecondsParameterNotFound'
  },
  {
    code: 1016,
    message: 'other.cycles.doesnt.match.cmm.cycle',
    key: 'otherCyclesVerificationCMMFailed'
  },
  {
    code: 1015,
    message: 'cycle.and.sub.cycle.given.dont.match.other.cycles.data',
    key: 'otherCyclesVerificationFailed'
  },
  {
    code: 1016,
    message: 'other.cycles.verification.failed.for.missing.required.params',
    key: 'otherCyclesVerificationParamsMissing'
  },
  {
    code: 1010,
    message: 'pendencies.not.found',
    key: 'pendenciesNotFound'
  },
  {
    code: 1024,
    message: 'person.invalid.params.given',
    key: 'personInvalidParams'
  },
  {
    code: 1002,
    message: 'person.not.found',
    key: 'personNotFound'
  },
  {
    code: 1008,
    message: 'profile.role.not.found',
    key: 'profileRoleNotFound'
  },
  {
    code: 1017,
    message: 'user.profiles.not.found',
    key: 'profilesNotFound'
  },
  {
    code: 1019,
    message: 'token.profiles.doesnt.exist',
    key: 'tokenProfilesDoesntExist'
  },
  {
    code: 1003,
    message: 'person.data.role.not.found',
    key: 'roleNotFound'
  },
  {
    code: 2034,
    message: 'segmentation.data.missing.or.invalid',
    key: 'segmentationInvalid'
  },
  {
    code: 1005,
    message: 'person.data.structure.not.found',
    key: 'structureNotFound'
  },
  {
    code: 1014,
    message: 'user.impersonating.has.no.permission.to.impersonate.others',
    key: 'thirdOrderCantImpersonateOthers'
  },
  {
    code: 1013,
    message: 'third.order.cant.impersonate.person.with.invalid.hierarchy',
    key: 'thirdOrderInvalidHierarchy'
  },
  {
    code: 1022,
    message: 'time.zone.not.found',
    key: 'timeZoneNotFound'
  },
  {
    code: 1026,
    message: 'user.not.authorized.to.access.token',
    key: 'unauthorizedTokenAccess'
  },
  {
    code: 1011,
    message: 'auth.token.failed',
    key: 'userAuthFailed'
  },
  {
    code: 1025,
    message: 'invalid.x.app.token',
    key: 'xAppTokenFailed'
  },
  {
    code: 1026,
    message: 'authenticationData.userInfo.addresses.[0].deliveryModes',
    key: 'addressesDeliveryModes'
  },
  {
    code: 8001,
    message: 'ad.parameter.not.found',
    key: 'adParameterNotFound'
  },
  {
    code: 8002,
    message: 'invalid.given.header',
    key: 'invalidHeader'
  }
]

const OTHER_CYCLES_MESSAGES = [
  {
    code: 3004,
    message: 'unable.to.get.past.cycles.due.to.business.model.not.given',
    key: 'getPastCyclesParamsMissing'
  },
  {
    code: 3007,
    message: 'the.given.token.has.missing.data',
    key: 'invalidGivenToken'
  },
  {
    code: 3006,
    message: 'invalid.business.model.role.and.function.parameters.given',
    key: 'invalidParams'
  },
  {
    code: 3001,
    message: 'other.cycles.roles.not.found',
    key: 'otherCyclesRolesNotFound'
  },
  {
    code: 3002,
    message: 'required.params.business.model.role.function.not.given',
    key: 'paramsMissingRoles'
  },
  {
    code: 3005,
    message: 'past.cycles.not.found.for.the.role.given',
    key: 'pastCyclesNotFound'
  },
  {
    code: 3002,
    message: 'required.param.business.model.not.given',
    key: 'paramsMissingRoles'
  },
  {
    code: 3003,
    message: 'user.role.cant.impersonate.other.cycles',
    key: 'userRolesNotFound'
  }
]

const INCORPORATION = [
  {
    code: 6000,
    message: 'bad.request',
    key: 'badRequest'
  },
  {
    code: 6001,
    message: 'incorporation.successfully.created',
    key: 'incorporationCreated'
  },
  {
    code: 6002,
    message: 'incorporation.already.exists',
    key: 'incorporationDuplicated'
  },
  {
    code: 6003,
    message: 'incorporation.invalid.params.given',
    key: 'incorporationInvalidErr'
  },
  {
    code: 6004,
    message: 'incorporation.successfully.updated',
    key: 'incorporationUpdated'
  },
  {
    code: 6005,
    message: 'incorporation.update.error',
    key: 'incorporationUpdateErr'
  }
]

const DEBIT_INFO_MESSAGES = [
  {
    code: 4006,
    message: 'given.debit.is.already.created',
    key: 'debitDuplicated'
  },
  {
    code: 4003,
    message: 'debit.format.invalid',
    key: 'debitFormatInvalid'
  },
  {
    code: 4001,
    message: 'debit.not.found',
    key: 'debitNotFound'
  },
  {
    code: 4004,
    message: 'debit.payment.format.invalid',
    key: 'debitPaymentFormatInvalid'
  },
  {
    code: 4002,
    message: 'debit.payment.not.found',
    key: 'debitPaymentNotFound'
  },
  {
    code: 4012,
    message: 'debit.payment.successfully.saved',
    key: 'debitPaymentSaved'
  },
  {
    code: 4008,
    message: 'debit.pending.orders.not.found',
    key: 'debitPendingOrdersNotFound'
  },
  {
    code: 4011,
    message: 'debit.updated.but.still.blocked',
    key: 'debitStillBlocked'
  },
  {
    code: 4007,
    message: 'given.debit.is.already.updated',
    key: 'debitUpdateDuplicated'
  },
  {
    code: 4010,
    message: 'debit.update.error',
    key: 'debitUpdateError'
  },
  {
    code: 4009,
    message: 'send.message.approval.order.failed',
    key: 'publishMessageFailure'
  }
]

const CREDIT_INFO_MESSAGES = [
  {
    code: 5001,
    message: 'credit.format.invalid',
    key: 'creditFormatInvalid'
  },
  {
    code: 5003,
    message: 'credit.not.found',
    key: 'creditNotFound'
  },
  {
    code: 5005,
    message: 'credit.successfully.updated',
    key: 'creditSuccess'
  },
  {
    code: 5004,
    message: 'new.credit.is.not.the.most.recent',
    key: 'newCreditNotMostRecent'
  },
  {
    code: 5002,
    message: 'credit.update.fail.resend.message',
    key: 'creditUpdateFail'
  },
  {
    code: 5003,
    message: 'profile-debit-info-orders-approve',
    key: 'debitApproval'
  }
]

const COMMERCIALINFO = [
  {
    code: 1000,
    message: 'required.param.not.sent',
    key: 'paramNotSent'
  },
  {
    code: 2000,
    message: 'bad.request',
    key: 'badRequest'
  },
  {
    code: 2029,
    message:
      'user.cant.impersonate.for.exceeding.allowed.amount.of.simultaneous.pending.orders',
    key: 'cantImpersonatePendingOrders'
  },
  {
    code: 2043,
    message: 'cmm.cycle.not.found',
    key: 'cmmCycleNotFound'
  },
  {
    code: 2001,
    message: 'commercial.info.not.found',
    key: 'commercialInfoNotFound'
  },
  {
    code: 2046,
    message: 'commitment.moment.not.found',
    key: 'commitmentMomentNotFound'
  },
  {
    code: 2033,
    message: 'starterKit.stage.db.error',
    key: 'createStarterKitStageError'
  },
  {
    code: 2053,
    message: 'user.blocked.by.credit.available',
    key: 'creditBlock'
  },
  {
    code: 2017,
    message: 'credit.not.found',
    key: 'creditNotFound'
  },
  {
    code: 2020,
    message: 'credit.pending.order.quantity.not.found',
    key: 'creditPendingOrderQuantityNotFound'
  },
  {
    code: 2016,
    message: 'currency.system.not.found',
    key: 'currencySystemNotFound'
  },
  {
    code: 2010,
    message: 'current.cycle.not.found',
    key: 'currentCycleNotFound'
  },
  {
    code: 2009,
    message: 'cycles.not.found',
    key: 'cyclesNotFound'
  },
  {
    code: 2023,
    message: 'debit.increase.duedate.not.found',
    key: 'debitIncreaseDuedateNotFound'
  },
  {
    code: 2022,
    message: 'debit.not.found',
    key: 'debitNotFound'
  },
  {
    code: 2024,
    message: 'debit.payment.error',
    key: 'debitPaymentError'
  },
  {
    code: 2025,
    message: 'debit.pending.order.quantity.not.found',
    key: 'debitPendingOrderQuantityNotFound'
  },
  {
    code: 2036,
    message: 'decimal.places.not.found',
    key: 'decimalPlacesNotFound'
  },
  {
    code: 2051,
    message: 'delivery.modes.not.found',
    key: 'deliveryModesNotFound'
  },
  {
    code: 2031,
    message: 'distribution.center.not.found',
    key: 'distributionCenterNotFound'
  },
  {
    code: 2002,
    message: 'growth.plan.not.found',
    key: 'growthPlanNotFound'
  },
  {
    code: 2042,
    message: 'hierarchy.not.found',
    key: 'hierarchyNotFound'
  },
  {
    code: 2032,
    message: 'invalid.test.profile',
    key: 'invalidTestProfile'
  },
  {
    code: 2047,
    message: 'inventory.type.not.found',
    key: 'inventoryTypeNotFound'
  },
  {
    code: 2043,
    message: 'max.debit.quantity.overdue.not.found',
    key: 'maxDebitQuantityOverdueNotFound'
  },
  {
    code: 2036,
    message: 'max.item.quantity.not.found',
    key: 'maxItemQuantityNotFound'
  },
  {
    code: 2004,
    message: 'max.order.not.found',
    key: 'maxOrderNotFound'
  },
  {
    code: 2019,
    message: 'minimum.order.size.not.found',
    key: 'minimumOrderSizeNotFound'
  },
  {
    code: 2011,
    message: 'multiple.current.cycle.found',
    key: 'multipleCurrentCycleFound'
  },
  {
    code: 2030,
    message: 'order.exceed.day',
    key: 'orderExceedDay'
  },
  {
    code: 2034,
    message: 'there.was.an.error.trying.to.fetch.order.data',
    key: 'orderRepositoryFailed'
  },
  {
    code: 2035,
    message: 'order.size.type.invalid',
    key: 'orderSizeTypeInvalid'
  },
  {
    code: 2027,
    message: 'order.size.type.not.found',
    key: 'orderSizeTypeNotFound'
  },
  {
    code: 2036,
    message: 'order.type.not.found',
    key: 'orderTypeNotFound'
  },
  {
    code: 2021,
    message: 'params.simultaneous.pending.orders.not.found',
    key: 'paramSimultaneousPendingOrdersNotFound'
  },
  {
    code: 2008,
    message: 'parent.structure.not.found',
    key: 'parentStructureNotFound'
  },
  {
    code: 2014,
    message: 'payment.condition.not.found',
    key: 'paymentConditionNotFound'
  },
  {
    code: 2045,
    message: 'user.pending.by.credit',
    key: 'pendingByCredit'
  },
  {
    code: 2044,
    message: 'user.pending.by.debit',
    key: 'pendingByDebit'
  },
  {
    code: 2013,
    message: 'profitability.default.not.found',
    key: 'profitabilityDefaultNotFound'
  },
  {
    code: 2012,
    message: 'profitability.not.found',
    key: 'profitabilityNotFound'
  },
  {
    code: 2039,
    message: 'register.pendencies.order.quantity.not.found',
    key: 'registerPendenciesOrderNotFound'
  },
  {
    code: 2026,
    message: 'invalid.params.given',
    key: 'requiredParamsForInventoryFailed'
  },
  {
    code: 2026,
    message: 'invalid.business.model.system.and.channel.given',
    key: 'requiredParamsVerificationFailed'
  },
  {
    code: 2003,
    message: 'role.not.found',
    key: 'roleNotFound'
  },
  {
    code: 2038,
    message: 'roundType.not.found',
    key: 'roundTypeNotFound'
  },
  {
    code: 2008,
    message: 'starterKit.cycle.not.found',
    key: 'starterKitCycleNotFound'
  },
  {
    code: 2007,
    message: 'starterKit.product.not.found',
    key: 'starterKitProductNotFound'
  },
  {
    code: 2006,
    message: 'starterKit.stage.not.found',
    key: 'starterKitStageNotFound'
  },
  {
    code: 2052,
    message: 'starterKit.status.not.found',
    key: 'starterKitStatusNotFound'
  },
  {
    code: 2040,
    message: 'stockCommitment.not.found',
    key: 'stockCommitmentNotFound'
  },
  {
    code: 2015,
    message: 'structure.not.found',
    key: 'structureNotFound'
  },
  {
    code: 2018,
    message: 'transactions.not.found',
    key: 'transactionsNotFound'
  },
  {
    code: 2048,
    message: 'ttl.parameter.not.found',
    key: 'ttlParameterNotFound'
  },
  {
    code: 2044,
    message: 'user.execeed.maximum.overdue.debits',
    key: 'userBlockedByOverdueDebits'
  },
  {
    code: 2037,
    message: 'user.not.authorized.to.test',
    key: 'userNotAuthorizedToTest'
  },
  {
    code: 2041,
    message: 'user.exceeded.maximum.pending.orders.by.register',
    key: 'userOrdersPendingByRegisterBlock'
  },
  {
    code: 1,
    message: 'user.exceeded.maximum.pending.orders.by.PEOPLE',
    key: 'userOrdersPendingByPeopleBlock'
  },
  {
    code: 4,
    message: 'user.exceeded.maximum.pending.orders.by.CREDIT',
    key: 'userOrdersPendingByCreditBlock'
  },
  {
    code: 6,
    message: 'user.exceeded.maximum.pending.orders.by.PREPAID',
    key: 'userOrdersPendingByPrepaidBlock'
  },
  {
    code: 2044,
    message: 'user.exceeded.maximum.pending.orders.by.DEBIT',
    key: 'userOrdersPendingByDebitBlock'
  },
  {
    code: 2040,
    message: 'user.orders.pending.by.register.not.found',
    key: 'userOrdersPendingByRegisterNotFound'
  },
  {
    code: 2028,
    message: 'user.pending.orders.not.found',
    key: 'userPendingOrdersNotFound'
  },
  {
    code: 2049,
    message: 'no.address.with.addressUse.of.type.delivery',
    key: 'invalidAddressUse'
  },
  {
    code: 2049,
    message: 'restart.period.not.found',
    key: 'invalidAddressUse'
  },
  {
    code: 2049,
    message: 'profile-credit-info-orders-approve',
    key: 'invalidAddressUse'
  }
]

const CART = [
  {
    code: 1,
    message: 'composer.invalidContext',
    key: 'composerInvalidContext'
  },
  {
    code: 1000,
    message: 'address.notFound',
    key: 'addressNotFound'
  },
  {
    code: 1001,
    message: 'address.validationFailed',
    key: 'addressValidationFailed'
  },
  {
    code: 14001,
    message: 'status.promotionApplied.successfully',
    key: '.promotionAppliedSuccessfully'
  },
  {
    code: 14000,
    message: 'status.promotionApplied.invalid',
    key: '.promotionAppliedInvalid'
  },
  {
    code: 2000,
    message: 'cart.ok',
    key: 'cartOk'
  },
  {
    code: 2001,
    message: 'cart.badRequest',
    key: 'cartBadRequest'
  },
  {
    code: 2002,
    message: 'cart.notFound',
    key: 'cartNotFound'
  },
  {
    code: 2003,
    message: 'cart.unauthorized',
    key: 'cartUnauthorized'
  },
  {
    code: 2004,
    message: 'cart.internalServerError',
    key: 'cartInternalServerError'
  },
  {
    code: 2005,
    message: 'cart.invalidStatus',
    key: 'invalidStatus'
  },
  {
    code: 2006,
    message: 'cart.concurrencyError',
    key: 'concurrency'
  },
  {
    code: 2007,
    message: 'cart.converting',
    key: 'cartConverting'
  },
  {
    code: 2008,
    message: 'cart.invalid.coverage',
    key: 'invalidCoverage'
  },
  {
    code: 2009,
    message: 'cart.topic.error',
    key: 'topicError'
  },
  {
    code: 2012,
    message: 'cart.converting.error',
    key: 'cartConverting'
  },
  {
    code: 2013,
    message: 'cart.pendingOrder',
    key: 'cartConverting'
  },
  {
    code: 3000,
    message: 'cartItem.ok',
    key: 'cartItemOk'
  },
  {
    code: 3001,
    message: 'cartItem.invalidQuantity',
    key: 'invalidQuantity'
  },
  {
    code: 3002,
    message: 'cartItem.invalidGift',
    key: 'invalidGift'
  },
  {
    code: 3003,
    message: 'cartItem.unavailable',
    key: 'cartItemUnavailable'
  },
  {
    code: 3004,
    message: 'cartItem.exceededQuantity',
    key: 'exceededQuantity'
  },
  {
    code: 3005,
    message: 'cartItem.notFound',
    key: 'cartItemNotFound'
  },
  {
    code: 3006,
    message: 'cartItem.gift.invalidQuantity',
    key: 'invalidGiftQuantity'
  },
  {
    code: 3007,
    message: 'cartItem.productAlreadyInCart',
    key: 'productAlreadyInCart'
  },
  {
    code: 3008,
    message: 'cartItem.gift.unknown',
    key: 'unknownGiftItem'
  },
  {
    code: 3009,
    message: 'cartItem.itemType.unknown',
    key: 'unknownItemTypeId'
  },
  {
    code: 3010,
    message: 'cartItem.promotion.notFound',
    key: 'promotionNotFound'
  },
  {
    code: 3011,
    message: 'cartItem.validationFailed',
    key: 'cartItemValidationFailed'
  },
  {
    code: 3012,
    message: 'cartItem.internalServerError',
    key: 'cartItemInternalServerError'
  },
  {
    code: 3013,
    message: 'cartItem.bundle.validationFailed',
    key: 'bundleValidationFailed'
  },
  {
    code: 3014,
    message: 'cartItem.duplicatedRequest',
    key: 'duplicatedRequest'
  },
  {
    code: 3015,
    message: 'cartItem.product.notFound',
    key: 'productNotFound'
  },
  {
    code: 3019,
    message: 'cartItem.itemTypeNotAllowed',
    key: 'productRestrictSale'
  },
  {
    code: 1001,
    message: 'cartItem.product.not.found',
    key: 'productNotFound'
  },
  {
    code: 3016,
    message: 'cartItem.duplicatedProduct',
    key: 'duplicatedProduct'
  },
  {
    code: 3017,
    message: 'cartItem.duplicatedQuantity',
    key: 'duplicatedQuantity'
  },
  {
    code: 5000,
    message: 'checkout.creditBlock',
    key: 'cartCreditBlock'
  },
  {
    code: 5001,
    message: 'checkout.debitBlock',
    key: 'cartDebitBlock'
  },
  {
    code: 5019,
    message: 'checkout.cart.empty.purchased.items',
    key: 'cartEmptyPurchasedItems'
  },
  {
    code: 5002,
    message: 'checkout.personBlock',
    key: 'cartPersonBlock'
  },
  {
    code: 5003,
    message: 'checkout.payment.invalid',
    key: 'invalidPaymentAmount'
  },
  {
    code: 5004,
    message: 'checkout.deliveryMode.invalid',
    key: 'invalidDeliveryMode'
  },
  {
    code: 5005,
    message: 'checkout.gift.invalid',
    key: 'invalidGiftItem'
  },
  {
    code: 5006,
    message: 'checkout.choosableGift.distinctQuantity',
    key: 'invalidDistinctItemQuantity'
  },
  {
    code: 5007,
    message: 'checkout.gift.invalidQuantity',
    key: 'invalidGiftQuantity'
  },
  {
    code: 5008,
    message: 'checkout.choosableGift.invalidQuantity',
    key: 'invalidChoosableGiftQuantity'
  },
  {
    code: 5009,
    message: 'checkout.address.notFound',
    key: 'shippingAddressNotFound'
  },
  {
    code: 5010,
    message: 'checkout.costCenter.missing',
    key: 'pendingCostCenter'
  },
  {
    code: 5011,
    message: 'checkout.minimumOrderSize',
    key: 'pendingMinimumOrderSize'
  },
  {
    code: 5012,
    message: 'checkout.paymentCondition.missing',
    key: 'pendingPaymentCondition'
  },
  {
    code: 5013,
    message: 'checkout.promotion.pending',
    key: 'pendingPromotion'
  },
  {
    code: 5014,
    message: 'checkout.starterkit.missing',
    key: 'pendingStarterKit'
  },
  {
    code: 5015,
    message: 'checkout.starterkit.already.bought',
    key: 'starterKitAlreadyBought'
  },
  {
    code: 6,
    message: 'payment.pending',
    key: 'paymentPending'
  },
  {
    code: 4,
    message: 'credit.exceeds.available',
    key: 'creditExceedsAvailable'
  },
  {
    code: 3,
    message: 'debit.pending',
    key: 'debitPending'
  },
  {
    code: 5,
    message: 'starterkit.pending',
    key: 'pendingStarterKit'
  },
  {
    code: 5015,
    message: 'checkout.promotion.notFound',
    key: 'promotionNotFound'
  },
  {
    code: 5016,
    message: 'checkout.ok',
    key: 'checkoutOk'
  },
  {
    code: 5017,
    message: 'checkout.validatorError',
    key: 'checkoutValidatorError'
  },
  {
    code: 5018,
    message: 'checkout.validator.notFound',
    key: 'validatorNotFound'
  },
  {
    code: 6000,
    message: 'inactiveCarts.invalidParameters',
    key: 'inactiveCartsInvalidParameters'
  },
  {
    code: 6001,
    message: 'inactiveCarts.ok',
    key: 'inactiveCartsOk'
  },
  {
    code: 6002,
    message: 'inactiveCarts.failed',
    key: 'inactiveCartsFailed'
  },
  {
    code: 7000,
    message: 'costCenter.ok',
    key: 'costCenterOk'
  },
  {
    code: 7001,
    message: 'costCenter.updateError',
    key: 'costCenterUpdateError'
  },
  {
    code: 7002,
    message: 'costCenter.notFound',
    key: 'costCenterNotFound'
  },
  {
    code: 7003,
    message: 'costCenter.paymentCondition.notFound',
    key: 'paymentConditionNotFound'
  },
  {
    code: 7004,
    message: 'costCenter.notAllowed',
    key: 'costCenterNotAllowed'
  },
  {
    code: 8000,
    message: 'paymentMethod.notFound',
    key: 'paymentMethodNotFound'
  },
  {
    code: 8001,
    message: 'payment.ok',
    key: 'paymentOk'
  },
  {
    code: 8002,
    message: 'payment.costCenter.required',
    key: 'costCenterRequired'
  },
  {
    code: 8002,
    message: 'payment.paymentDueDate.notFound',
    key: 'paymentDueDateNotFound'
  },
  {
    code: 9000,
    message: 'incorporation.notFound',
    key: 'incorporationNotFound'
  },
  {
    code: 9001,
    message: 'incorporation.invalidQuantity',
    key: 'incorporationInvalidQuantity'
  },
  {
    code: 11000,
    message: 'shipping.notFound',
    key: 'shippingNotFound'
  },
  {
    code: 11001,
    message: 'shipping.ok',
    key: 'shippingOk'
  },
  {
    code: 11002,
    message: 'shipping.internalServerError',
    key: 'shippingInternalServerError'
  },
  {
    code: 11002,
    message: 'shipping.deliveryDate.notFound',
    key: 'deliveryDateNotFound'
  },

  {
    code: 12000,
    message: 'availability.unknownError',
    key: 'availabilityUnknownError'
  },
  {
    code: 12001,
    message: 'availability.timeout',
    key: 'availabilityTimeout'
  },
  {
    code: 12002,
    message: 'availability.commitmentNotFound',
    key: 'commitmentNotFound'
  },
  {
    code: 12003,
    message: 'availability.commitment.notEnough.quotaCn',
    key: 'commitmentNotEnoughQuotaCn'
  },
  {
    code: 12004,
    message: 'availability.commitment.notEnough.quotaEc',
    key: 'commitmentNotEnoughQuotaEc'
  },
  {
    code: 12005,
    message: 'availability.commitment.notFound.inventory',
    key: 'commitmentNotFoundInventory'
  },
  {
    code: 13000,
    message: 'token.validationFailed',
    key: 'tokenValidationFailed'
  },
  {
    code: 13000,
    message: 'token.countryInfo',
    key: 'tokenCountryInfo'
  },
  {
    code: 14000,
    message: 'parameters.timeout',
    key: 'parametersTimeout'
  },
  {
    code: 15000,
    message: 'acknowledge.success',
    key: 'acknowledgeSuccess'
  },
  {
    code: 15001,
    message: 'acknowledge.internalServerError',
    key: 'acknowledgeInternalServerError'
  },
  {
    code: 16000,
    message: 'batchRetry.success',
    key: 'batchRetrySuccess'
  },
  {
    code: 16001,
    message: 'batchRetry.internalServerError',
    key: 'batchRetryInternalServerError'
  },
  {
    code: 3018,
    message: 'cartItem.invalidPayload',
    key: 'invalidPayload'
  },
  {
    code: 5019,
    message: 'cart.checkout.failed',
    key: 'checkoutError'
  },
  {
    code: 5018,
    message: 'cart.inactivation.success',
    key: 'checkoutError'
  },
  {
    code: 10000,
    message: 'messageBus.internalServerError',
    key: 'messageBusInternalServerError'
  },
  {
    code: 12006,
    message: 'availability.error',
    key: 'availabilityError'
  },
  {
    code: 17000,
    message: 'request.error',
    key: 'requestError'
  },
  {
    code: 17001,
    message: 'request.timeout',
    key: 'requestTimeout'
  },
  {
    code: 17001,
    message: 'cart.mine.success',
    key: 'cartMineSuccess'
  },
  {
    code: 17002,
    message: 'get.cart.success',
    key: 'getCartSuccess'
  },
  {
    code: 17003,
    message: 'delete.items.success',
    key: 'deleteItemsSuccess'
  },
  {
    code: 13001,
    message: 'token.validationFailed.deliveryModes',
    key: 'tokenValidationFailedDeliveryModes'
  },
  {
    code: 13002,
    message: 'token.validationFailed.paymentConditions',
    key: 'tokenValidationFailedPaymentConditions'
  },
  {
    code: 13003,
    message: 'token.validationFailed.distributionCenter',
    key: 'tokenValidationFailedDistributionCenter'
  },
  {
    code: 13004,
    message: 'token.validationFailed.roles',
    key: 'tokenValidationFailedRoles'
  },
  {
    code: 13005,
    message: 'token.validationFailed.orderType',
    key: 'tokenValidationFailedOrderType'
  },
  {
    code: 13006,
    message: 'token.validationFailed.maxOrder',
    key: 'tokenValidationFailedMaxOrder'
  },
  {
    code: 13007,
    message: 'token.validationFailed.address',
    key: 'tokenValidationFailedAddress'
  },
  {
    code: 13008,
    message: 'token.validationFailed.minimumOrderSize',
    key: 'tokenValidationFailedMinimumOrderSize'
  },
  {
    code: 13009,
    message: 'token.validationFailed.profitabilityTable',
    key: 'tokenValidationFailedProfitabilityTable'
  },
  {
    code: 13010,
    message: 'token.validationFailed.inventoryType',
    key: 'tokenValidationFailedInventoryType'
  },
  {
    code: 2011,
    message: 'cart.invalid.deliveryMode',
    key: 'deliveryModesNotFound'
  }
]

const DEFAULT = [
  {
    code: 90001,
    message: 'internal.server.error',
    key: 'systemInMaintenanceBody'
  },
  { 
    code: 90002,
    message: 'no Route matched with those values',
    key: 'systemInMaintenanceBody'
  },
  {
    code: 90003,
    message: 'cancel.pooling.cart',
    key: 'cancelPooling'
  },
  {
    code: 90004,
    message: 'failed.to.retrieve.apptoken',
    key: 'retrieveApptoken'
  },
  {
    code: 90005,
    message: 'userId.dont.match.authorization.personId',
    key: 'userAuthorization'
  },
  {
    code: 90006,
    message: 'userProfile.userId.dont.match.authorization.personId',
    key: 'userProfileAuthorization'
  },
  {
    code: 90007,
    message: 'xAppToken.invalid',
    key: 'xAppTokenInvalid'
  },
  {
    code: 90008,
    message: 'xAppToken.JWT.invalid',
    key: 'JwtInvalid'
  },
  {
    code: 5002,
    message: 'residual.current.account.format.invalid',
    key: 'residualCurrentAccountFormatInvalid'
  },
  {
    code: 5001,
    message: 'residual.current.account.not.found',
    key: 'residualCurrentAccountNotFound'
  },
  {
    code: 5003,
    message: 'residual.current.account.older',
    key: 'residualCurrentAccountOlder'
  },
  {
    code: 0,
    message: 'graphql.internal.error',
    key: 'graphqlInternalError'
  },
  {
    code: 90009,
    message: 'NOT_FOUND',
    key: 'consultantNotFound'
  },
  {
    code: 90010,
    message: 'NOT_ALLOWED',
    key: 'consultantNotStructure'
  },
  {
    code: 90010,
    message: 'CEASED',
    key: 'consultantBlocked'
  }
]

const ORDER = [
  {
    code: 7008,
    message: 'no.incorporations.created',
    key: 'incorporationCreateError'
  },
  {
    code: 7007,
    message: 'no.incorporations.updated',
    key: 'incorporationUpdateError'
  },
  {
    code: 7003,
    message: 'order.successfully.cancelled',
    key: 'orderCancelled'
  },
  {
    code: 7003,
    message: 'order.successfully.created',
    key: 'orderCreated'
  },
  {
    code: 7004,
    message: 'order.create.error',
    key: 'orderCreateError'
  },
  {
    code: 7002,
    message: 'order.not.found',
    key: 'orderUpdated'
  },
  {
    code: 7005,
    message: 'order.update.error',
    key: 'orderUpdateError'
  }
]

const ACKNOWLEDGE = [
  {
    code: 102,
    message: 'acknowledge.message.id.not.found',
    key: 'messageIdNotFound'
  },
  {
    code: 201,
    message: 'acknowledge.batch.retry.success',
    key: 'batchRetrySucess'
  },
  {
    code: 202,
    message: 'failed.to.batch.retry.product.acknowledge',
    key: 'batchRetryFail'
  }
]

const BUSINESSCOVERAGE = [
  {
    code: 301,
    message: 'businessCoverage.not.found',
    key: 'batchInsertnotFound'
  },
  {
    code: 302,
    message: 'businessCoverage.not.valid',
    key: 'batchNoValidToInsert'
  }
]

const HIERARCHY = [
  {
    code: 402,
    message: 'failed.to.retrieve.hierarchy',
    key: 'hierarchyInternalError'
  },
  {
    code: 502,
    message: 'failed.to.retrieve.product.structures',
    key: 'getProductStructureFail'
  },
  {
    code: 703,
    message: 'batch.material.failed',
    key: 'materialFail'
  }
]

const PRODUCT = [
  {
    code: 702,
    message: 'product.not.found',
    key: 'productNotFound'
  },
  {
    code: 801,
    message: 'invalid.request.params',
    key: 'invalidRequestParams'
  },
  {
    code: 802,
    message: 'invalid.token',
    key: 'invalidToken'
  },
  {
    code: 805,
    message: 'failed.to.retrieve.product',
    key: 'failedRetrieveProduct'
  },
  {
    code: 806,
    message: 'invalid.apply.promotion.and.unavailability.param',
    key: 'invalidApplyPromotionAndUnavailability'
  },
  {
    code: 807,
    message: 'invalid.product.codes',
    key: 'invalidProductCodes'
  },
  {
    code: 808,
    message: 'invalid.price',
    key: 'invalidPrice'
  },
  {
    code: 809,
    message: 'invalid.outlet',
    key: 'invalidOutlet'
  },
  {
    code: 810,
    message: 'invalid.description',
    key: 'invalidDescription'
  },
  {
    code: 811,
    message: 'invalid.cart.size',
    key: 'invalidCartSize'
  }
]

const TOKEN = [
  {
    code: 901,
    message: 'invalid.company.id',
    key: 'invalidCompanyId'
  },
  {
    code: 902,
    message: 'invalid.country.code',
    key: 'invalidCountryCode'
  },
  {
    code: 903,
    message: 'invalid.business.model.id',
    key: 'invalidBusinessModelId'
  },
  {
    code: 904,
    message: 'invalid.channel.id',
    key: 'invalidChannelId'
  },
  {
    code: 905,
    message: 'invalid.cycle.code',
    key: 'invalidCycleCode'
  },
  {
    code: 906,
    message: 'invalid.sub.cycle.code',
    key: 'invalidSubCycleCode'
  },
  {
    code: 907,
    message: 'invalid.order.date',
    key: 'invalidOrderDate'
  },
  {
    code: 908,
    message: 'invalid.product.ttl',
    key: 'invalidProductTTL'
  },
  {
    code: 909,
    message: 'invalid.profitability.table',
    key: 'invalidProfitabilityTable'
  },
  {
    code: 910,
    message: 'invalid.roles',
    key: 'invalidRoles'
  },
  {
    code: 911,
    message: 'invalid.roles',
    key: 'invalidStructures'
  },
  {
    code: 912,
    message: 'invalid.system.id',
    key: 'invalidSystemId'
  },
  {
    code: 913,
    message: 'invalid.person.id',
    key: 'invalidPersonId'
  },
  {
    code: 498,
    message: 'kong.authentication.required',
    key: 'kongAuthenticationRequired'
  }
]

export const arrayErrors = () => [
  ...new Set([
    ...PERSON_DATA_MESSAGES,
    ...OTHER_CYCLES_MESSAGES,
    ...INCORPORATION,
    ...DEBIT_INFO_MESSAGES,
    ...CREDIT_INFO_MESSAGES,
    ...COMMERCIALINFO,
    ...CART,
    ...ORDER,
    ...ACKNOWLEDGE,
    ...BUSINESSCOVERAGE,
    ...HIERARCHY,
    ...PRODUCT,
    ...TOKEN,
    ...DEFAULT
  ])
]
