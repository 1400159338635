export const PRODUCT_UNAVAILABLE_MESSAGE = 'cartItem.unavailable'
export const PRODUCT_UNAVAILABLE_INVENTORY =
  'availability.commitment.notFound.inventory'
export const PRODUCT_NOT_FOUND_MESSAGE = 'cartItem.product.not.found'
export const PRODUCT_GIFT_INCORPORATION_ITEM_TYPE_ID = 4
export const PRODUCT_UNAVAILABLE_QUOTA_CN =
  'availability.commitment.notEnough.quotaCn'
export const PRODUCT_UNAVAILABLE_QUOTA_EC =
  'availability.commitment.notEnough.quotaEc'

export const CHANGE_AND_NOTIFY_TYPE_ID = 1
export const URL_IMAGE = 'https://gspstatic.natura.com/static/producto/500x500/'
export const PLAN_B_TYPE_ID = 2
export const PRODUCT_DEBOUNCE_TIMEOUT = 1000
