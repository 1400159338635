export const STEP_BAG = 'bag'
export const STEP_EXCLUSIVE = 'exclusive'
export const STEP_GIFT = 'gift'
export const STEP_SHIPPING = 'shipping'
export const STEP_PAYMENT = 'payment'
export const STEP_COMPLETE = 'complete'
export const INTERVAL_GET_CART_BAG = 500
export const INTERVAL_DELETE_CART = 1000
export const INTERVAL_GET_CART = 500
export const ATTEMPTS_GET_CART = 12
export const TIMEOUT_ALERT_TOAST = 5000
export const TIMEOUT_CREDIT_ALERT_TOAST = 3000
export const TIMEOUT_HIDE_CALCULATED_TOAST = TIMEOUT_ALERT_TOAST + 10
export const TIMEOUT_SHOW_ALERT_TOST = TIMEOUT_ALERT_TOAST + 20
export const ID_TOAST_MINIMUM_ORDER = 1
export const ID_TOAST_MAX_ORDER = 2
export const ID_TOAST_MAX_QUANTITY = 3
export const QUANTITY_OPTIONAL_KIT = 1
export const GIFT_OPTION_QUANTITY = 1
export const STATUS_CART_NEW = 0
export const STATUS_CART_PENDING_PROMOTION = 1
export const STATUS_CART_PENDING_PROFITABILITY = 2
export const STATUS_CART_CALCULATED = 3
export const STATUS_CART_SHIPPING_READY = 4
export const STATUS_CART_PAYMENT_READY = 5
export const STATUS_CART_CHECKOUT_READY = 5
export const STATUS_CART_INACTIVE = 8
export const CART_CHOICE_REACTIVATE = 'Reactivate'
export const CART_CHOICE_PRE_SALES = 'PreSales'
export const ITEM_TYPE_ID_GIFT = 2
export const ORDER_SIZE_TYPE_ID_POINTS_ZERO = 0
export const ORDER_SIZE_TYPE_ID_POINTS = 1
export const ORDER_SIZE_TYPE_ID_PRICE = 2
export const MAX_PRODUCT_QUANTITY = 99
export const MAX_PRODUCT_QUANTITY_GIFT = 999
export const SHIPPING_ADDRESS_TYPE_ID = 1
export const BILLING_ADDRESS_TYPE_ID = 2
export const DELIVERY_OPTION_HOME = 'homeDelivery'
export const DELIVERY_OPTION_AGENCY = 'agencyDelivery'
export const HOME_SEARCH_TAG = 'home.search'
export const CART_SEARCH_TAG = 'cart.search'
export const CART_EXCLUSIVE_TAG = 'cart.exclusive'
export const HOME_BANNER_TAG = 'home.banner'
export const ORDER_BANNER_TAG = 'order.banner'
export const PRODUCT_SEARCH_BY_CODE = 'product.search.by.code'
export const PRODUCT_SEARCH_BY_TEXT = 'product.search.by.text'
export const CANCEL_POOLING = 90003
export const EXCEL_IMPORT_ORDER_TAG = 'excel-imported'
export const INCORPORATION_TAG = 'incorporation'
export const MAGAZINE_TAG = 'magazine.imported.order'
