import styled, { css } from 'styled-components';

import {
  GridContainer,
  DialogTitle,
  DialogContent
} from '@naturacosmeticos/natds-web';

export const CustomerCard = styled(GridContainer)`
  margin-left: 16px;
  flex-direction: column;
  background-color: #FAFAFA;
`;

export const CustomerCardAddress = styled(CustomerCard)`
  border-bottom: none;
`

export const DialogTitleSplit = styled(DialogTitle)`
  padding: 16px 24px !important;
`

export const DialogSubTitle = styled.div`
  font-size: 14px;
  color: #777777;
  margin: 0 0 0 25px;
`

export const DialogContentCustomer = styled(DialogContent)`
  background-color: #FAFAFA;
  border-top: 1px solid #00000024;
  border-bottom: 1px solid #00000024;
`

export const DialogContentBordered = styled(DialogContent)`
  margin-top: 20px;
  border-top: 1px solid #00000024;
  border-bottom: 1px solid #00000024;
`

export const ContainerFormControl = styled.div`
  width: 100%;
  max-width: 348px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fafafa;
  padding: 0 16px;

  .MuiFormControlLabel-root {
    display: flex;
    align-items: flex-start;

    .MuiButtonBase-root {
      margin-top: 9px;
      margin-right: -15px;
    }
  }
`

export const DialogActionsButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 15px;
`

export const ButtonAction = styled.button`
  font-size: 14px;
  height: 48px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  ${({color, background}) => css`
    color: ${color};
    background-color: ${background};

    span {
      font-weight: 400;
    }
  `}
`

export const CustomerLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
`

export const CustomerValue = styled.span`
  font-size: 14px;
  color: #777777;
`
