import {
  ACTION_BUTTON,
  TYPE_ALERT_MESSAGE,
  TYPE_MODAL_INFO
} from 'redux/actions/constants'
import Translate from 'natura-commons/translate'

const translate = new Translate()

export const getAlertMinOrderSize = (body) => [
  {
    id: 99982,
    typeAction: ACTION_BUTTON,
    type: TYPE_ALERT_MESSAGE,
    typeModal: TYPE_MODAL_INFO,
    title: translate.byKey('atention'),
    show: true,
    titleButtonConfirm: translate.byKey('backCartButton'),
    handleConfirm: () => {},
    body
  }
]
