import { push } from 'connected-react-router'
import Translate from 'natura-commons/translate'
import {
  TYPE_RADIO_MESSAGE,
  ACTION_MULTIPLES_BUTTON,
  TYPE_MODAL_ROLES
} from 'redux/actions/constants'
import { HOME } from 'redux/actions/router/constants'
import { setAlerts } from 'redux/actions/alerts/alertsAction'
import { creators as userSession } from 'redux/modules/userSession'

const translate = new Translate()

const alertRoles = (detailsPerson) => {
  let functionsRolesArray = []
  const roles = detailsPerson.roles
  for (const role of roles) {
    const isTestProfile = role.id === 99999 && detailsPerson.canTest
    const roleResult = {
      Id: role.id,
      isImperson: role.isImperson,
      Value: isTestProfile ? translate.byKey('testProfile').toUpperCase() : role.businessModelName
    }
    functionsRolesArray.push(roleResult)
  }
  functionsRolesArray = functionsRolesArray.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.Value === item.Value)
  )
  const alert = {
    id: 0,
    type: TYPE_RADIO_MESSAGE,
    typeAction: ACTION_MULTIPLES_BUTTON,
    typeModal: TYPE_MODAL_ROLES,
    title: `<span class="pt-2 d-block">${translate.byKey('hello')} ${
      detailsPerson.firstName
    }!</span>`,
    show: true,
    titleButtonOne: `${translate.byKey('backButton')}`,
    titleButtonTwo: `${translate.byKey('accept')}`,
    body: `<span class="label-perfiles">${translate.byKeyAndParamValue(
      'haveMoreProfiles',
      functionsRolesArray.length
    )}</br>
          <strong>${translate.byKey('whichWantEnter')}</strong></span>`,
    radio: functionsRolesArray
  }
  return alert
}

export const orderNavigateManagerAction = (getState, path) => (dispatch) => {
  const data = getDataObjects(getState)
  const { detailsPerson, roleSelected, personData, commercialInfo } = data
  const { personId } = commercialInfo

  if (data.isImperson) {
    if (!personId || personId !== personData.personId) {
      return dispatch(managerImperson(personData, roleSelected))
    }
    return dispatch(push({
      pathname: path,
      state: {
        from: window.location.pathname
      }
    }));
  }
  if (detailsPerson.roles && !data.personId && !roleSelected) {
    return dispatch(managerRoles(detailsPerson))
  }
  return dispatch(push({
    pathname: path,
    state: {
      from: window.location.pathname
    }
  }));
}

export const setSelectedMenuItem = (menu, selectedItem) => {
  if (selectedItem.windowLevelOneId) {
    const newMenu = menu.map((item) => {
      if (selectedItem.windowLevelOneId === item.id) {
        item.active = true

        item.subMenu = item.subMenu.map((submenu) => {
          if (submenu.code === selectedItem.code) {
            submenu.active = true
          } else {
            submenu.active = false
          }

          return submenu
        })
      } else {
        item.active = false

        if (item.subMenu) {
          item.subMenu = item.subMenu.map((submenu) => ({ ...submenu, active: false }))
        }
      }

      return item
    })
    return newMenu;
  }

  const changedMenu = []

  const selectedIndex = menu.findIndex(
    (item) => item.id === (selectedItem.id || selectedItem.code)
  )

  selectedItem = menu[selectedIndex]

  if (!selectedItem) {
    selectedItem = menu[0]
  }
  selectedItem.active = true

  menu.map((item, index) => {
    if (index !== selectedIndex) {
      item.active = false
      if (item.subMenu) {
        item.subMenu = item.subMenu.map((submenu) => ({
          ...submenu,
          active: false
        }))
      }

      changedMenu.push(item)
    } else {
      selectedItem.active = selectedItem.to === '/' ? '' : selectedItem.active

      changedMenu.push(selectedItem)
    }
  })
  return changedMenu
}

const managerImperson = (personData, roleSelected) => {
  if (personData.roles) {
    if (personData.roles.length > 1) {
      if (roleSelected) return userSession.setSelectedRole(roleSelected)
      return managerRoles(personData)
    }
    if (personData.roles.length === 1) {
      return userSession.setSelectedRole(personData.roles[0].id)
    }
    return null
  }
}

const managerRoles = (detailsPerson) => {
  if (detailsPerson.roles.length > 1) {
    return setAlerts([alertRoles(detailsPerson)])
  }
  if (detailsPerson.roles.length === 1) {
    return userSession.setSelectedRole(detailsPerson.roles[0].id)
  }
  return null
}

const getDataObjects = (getState) => {
  let personId = null
  let isBlocked = null
  let comercialCurrentUser = false
  const {
    userSession: {
      detailsPerson,
      commercialInfo,
      impersonation: {
        isImperson,
        roleSelected,
        person: { personData }
      }
    },
    cartReducer
  } = getState()
  if (commercialInfo) {
    personId = commercialInfo.personId
    isBlocked = commercialInfo.isBlocked
    if (detailsPerson) {
      comercialCurrentUser = isImperson && personId === detailsPerson.personId
    }
  }
  return {
    detailsPerson,
    personId,
    isBlocked,
    isImperson,
    personData,
    cartReducer,
    roleSelected,
    commercialInfo,
    comercialCurrentUser
  }
}
