import {
  ORDER_SIZE_TYPE_ID_POINTS_ZERO,
} from 'commons/constants/cart'

export const limitCredit = (commercialInfo, cart) => {
  if (commercialInfo.credit && commercialInfo.credit.validateCredits === false) {
    return false
  }
  const available = getAvailable(commercialInfo)
  const {
    resume: { totalCreditConsumed }
  } = cart

  return available < totalCreditConsumed
}

const getAvailable = (commercialInfo) =>
  (commercialInfo && commercialInfo.credit
    ? commercialInfo.credit.available + commercialInfo.credit.additional
    : ORDER_SIZE_TYPE_ID_POINTS_ZERO)
