import { takeLatest } from 'redux-saga/effects'
import { getTranslate } from 'redux/sagas/custom-effects'

export const sagas = ({ types }) => {
  function* onChangeCountryCode() {
    yield takeLatest(types.SET_COUNTRY_CODE, function* (action) {
      const i18n = yield getTranslate()

      sessionStorage.setItem('countryLanguage', action.countryCode)
      i18n.setLocale()
    })
  }

  return [onChangeCountryCode]
}
