// FUNCSTION REDUCER
export const showLoadReducer = (state, payload) => ({
  ...state,
  show: payload,
  showDiscrete: false
})

export const showDiscreteLoadReducer = (state, payload) => ({
  ...state,
  showDiscrete: payload,
  show: false
})
