export const ROOT = '/'
export const LOGIN = `${ROOT}login`
export const HOME = ROOT
export const ORDER = `${ROOT}order`
export const INCORPORATIONS = `${ROOT}incorporations`
export const ORDER_SEARCH = `${ROOT}order/search`
export const PROMOTIONS_HOME = `${ROOT}home/promotions`
export const PROMOTIONS = `${ROOT}order/promotions`
export const PROMOTION = `${ROOT}order/promotion`
export const SELECT_CONSULTANT = `${ROOT}select-consultant`
export const CART = `${ROOT}cart`
export const TEST_PROFILE = `${ROOT}test-profile`
export const MAGAZINE = `${ROOT}magazine`
export const ORDER_UPLOAD = `${ROOT}order/upload`
export const ORDERS = `${ROOT}orders`
export const SPLIT = `${ROOT}cart/split`
export const CUSTOMER = `${ROOT}customer`
export const SUMMARY = `${ROOT}cart/summary`
export const SALES_OPPORTUNITY = `${ROOT}opportunities`
export const INTERACTIVE_MAGAZINE = `${ROOT}interactive-magazine`
export const LOGOUT = `${ROOT}logout`
