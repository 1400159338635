const options = [
  {
    id: 0,
    description: 'name',
    columnSorter: 'name',
    typeOrder: 'asc'
  },
  {
    id: 1,
    description: 'smallPrice',
    columnSorter: 'priceSell',
    typeOrder: 'asc'
  },
  {
    id: 2,
    description: 'bigPrice',
    columnSorter: 'priceSell',
    typeOrder: 'desc'
  }
]

export const initialState = {
  sorterOptions: options,
  sorterSelected: null,
  filterSelected: [],
  initialFilterAfterSearch: []
}
