import { SHOW_TOAST, HIDE_TOAST, CLEAR_TOAST } from '../constants'

export const showToast = (toast) => ({
  type: SHOW_TOAST,
  toast
})

export const hideToast = () => ({
  type: HIDE_TOAST,
})

export const clearToast = () => ({
  type: CLEAR_TOAST
})
