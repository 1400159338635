import { get, isEmpty, size } from 'lodash'
import { takeLatest, call, put, delay, select } from 'redux-saga/effects'

import { executeQuery, getTranslate, setLoading } from 'redux/sagas/custom-effects'
import { CLEAR_ACCEPTANTE_TERM, CLEAR_LOGIN, CLEAR_REDURCERS, CLOSE_ACCEPTANTE_TERM, SET_ACCEPTANTE_TERMS, SET_TITLES, CLEAR_REDUCER_IMPERSONATION } from 'redux/actions/constants'
import { setAlerts, verifyError } from 'redux/actions/alerts/alertsAction'
import { navigateSelectConsultant, navigateSwitching, navigateTestProfile } from 'redux/actions/router/routerAction'
import { orderNavigateManagerAction } from 'commons/interactor/router/interactor'
import { getAlertTitleBody, getAlertImpersonation } from 'commons/presenter/order/home/presenter'
import { INDEX_MODAL_IMPERSONATION, TEST_PROFILE_ID } from 'commons/constants/profile'
import { showToast } from 'redux/actions/toast/toastAction'
import { TIMEOUT_ALERT_TOAST } from 'commons/constants/cart'
import { mapTestProfileParameters } from 'commons/interactor/login/mapper'
import { TEST_PROFILE_NOT_FOUND } from 'commons/constants/error'
import { HOME, SELECT_CONSULTANT } from 'redux/actions/router/constants'
import { isMobile } from 'commons/helpers/device'

import { setPersonDataLocalStorage, setTokenLocalStorage } from 'natura-commons/utils/profile'
import { PROFILE } from 'natura-commons/graphql/queries'

import { CHANNEL_WEB, CHANNEL_APP } from '../../../../commons/constants/profile/index'

import { creators as i18n } from '../../i18n'
import { getPendingAcceptanceTerms } from 'redux/actions/acceptanceTerm/acceptanceTermAction'
export const sagas = ({ types, creators }) => {
  function* queryPersonData(token) {
    const channelId = isMobile() ? CHANNEL_APP : CHANNEL_WEB
    return yield executeQuery({
      query: PROFILE.getPersonData,
      variables: { personID: null, fullTokens: true, channelId },
      context: {
        headers: {
          xAppToken: '',
          Authorization: `Bearer ${token}`
        }
      },
    })
  }

  function* queryTestProfileParameters() {
    const { companyId, countryCode } = yield select(state => state.userSession.detailsPerson)
    return yield executeQuery({
      query: PROFILE.getTestProfileParameters,
      variables: {
        countryCode,
        companyId,
      }
    })
  }

  function* querySegmentationGroups(businessModelId, segmentId) {
    const { companyId, countryCode } = yield select(state => state.userSession.detailsPerson)
    return yield executeQuery({
      query: PROFILE.getSegmentationsGroups,
      variables: {
        countryCode,
        companyId,
        businessModelId,
        segmentId,
      }
    })
  }

  function* onAuthenticateUserRequest() {
    yield takeLatest([
      types.AUTHENTICATE_USER_REQUEST,
      types.RESET_LOGIN,
    ], function* (action) {
      let token;

      yield put((dispatch) => dispatch({ type: CLEAR_REDUCER_IMPERSONATION }))

      if (action.type === types.AUTHENTICATE_USER_REQUEST) {
        token = action.token
      } else {
        token = yield select(state => state.userSession.authenticationToken)
      }

      if (!token) {
        yield onAuthenticateUserFailure()

        return
      }

      yield setLoading(true)

      const response = yield queryPersonData(token)

      if (!response.errors) {
        const { payload } = get(response, 'data.profileGetPersonData') || {}

        yield put((dispatch) => getPendingAcceptanceTerms(payload.personId, response)(dispatch))
      } else {
        yield onAuthenticateUserFailure(response.errors)
        yield setLoading(false)
      }
    })
  }

  function* onSetPendingAcceptanceTerms() {
    yield takeLatest(
      [SET_ACCEPTANTE_TERMS],
      function* () {
        yield setLoading(false)
      })
  }

  function* onClosePendingAcceptanceTerms() {
    yield takeLatest([
      CLOSE_ACCEPTANTE_TERM,
    ], function* (action) {
      const response = yield select(state => state.acceptanceTermReducer.personDataResponse)

      if (action?.payload?.allAccepted && response) {
        const { payload, xAppToken, commercialInfo, otherCycles } = get(response, 'data.profileGetPersonData') || {}

        yield put(creators.authenticateUserSuccess(payload, xAppToken))
        yield put((dispatch) => dispatch({ type: CLEAR_ACCEPTANTE_TERM }))

        if (otherCycles) {
          yield put(creators.profileCyclesSuccess(otherCycles.payload, otherCycles.xAppToken))
        }

        if (commercialInfo) {
          yield put(creators.commercialInfoSuccess(commercialInfo.payload, commercialInfo.xAppToken))
        }
      }
    })
  }

  function* onAuthenticateUserSuccess() {
    yield takeLatest(types.AUTHENTICATE_USER_SUCCESS, function* ({ detailsPerson }) {
      const {
        impersonation,
        pathname,
      } = yield select(state => ({
        impersonation: state.userSession.impersonation,
        pathname: state.router.location.pathname,
      }))
      const roles = detailsPerson.roles || []
      const functionRoles = roles.filter(role => role.businessModelId !== TEST_PROFILE_ID)
      const currentRoles = impersonation.isImperson ? functionRoles : roles

      put(dispatch => dispatch({ type: SET_TITLES, titles: [] }))

      if (pathname !== SELECT_CONSULTANT) {
        const businessModels = impersonation.isImperson
          ? impersonation.person.personData.businessRelationships
          : detailsPerson.businessRelationships

        const canImpersonate = impersonation.isImperson
          ? impersonation.person.personData.canImpersonate
          : detailsPerson.canImpersonate

        if (isEmpty(businessModels) && canImpersonate) {
          yield put(navigateSelectConsultant())

          return
        }
      }

      if (isEmpty(currentRoles)) {
        yield put(setAlerts([getAlertTitleBody(null, 'accountIsBlocked', true)]))
      } else if (size(currentRoles) === 1) {
        yield put(creators.setSelectedRole(currentRoles[0].id))
      } else {
        yield put((dispatch, getState) =>
          orderNavigateManagerAction(getState, HOME)(dispatch)
        )
      }
    })
  }

  function* onAuthenticateUserFailure(errors) {
    const translate = yield getTranslate()

    yield put(creators.authenticateUserFailure(translate.byKey('checkAndTryAgain')))
    yield put(
      showToast({
        title: translate.byKey('incorrectData'),
        text: translate.byKey('verifyAndTryAgain'),
        type: 'error',
        show: true,
        timeout: TIMEOUT_ALERT_TOAST
      })
    )

    if (!isEmpty(errors)) {
      yield put(dispatch => verifyError(dispatch, { errors }, true))
    }
  }

  function* onAuthenticateUserSuccessChangeLocale() {
    yield takeLatest(types.AUTHENTICATE_USER_SUCCESS, function* (action) {
      const { detailsPerson } = action

      yield put(i18n.setCountryCode(detailsPerson.countryCode))
    })
  }

  function* onClearReducers() {
    yield takeLatest([CLEAR_REDURCERS, CLEAR_LOGIN], function* () {
      yield put(creators.clear())
    })
  }

  function* onUserSessionExpiration() {
    while (true) {
      const userSession = yield select(state => get(state, 'userSession' || {}))

      if (userSession.expiresIn) {
        const now = new Date().getTime() + 1000 * 60
        if (now >= userSession.expiresIn) {
          if (global.authService) {
            const result = yield call(() => global.authService.refresh())
            if (!result) {
              yield put({ type: CLEAR_REDURCERS })
            }
          }

          yield put(navigateSwitching())
        }
      }

      yield delay(5000)
    }
  }

  function* onSetSelectedRole() {
    yield takeLatest(types.SET_SELECTED_ROLE, function* ({ roleId }) {
      if (!roleId) {
        return
      }

      if (roleId === TEST_PROFILE_ID) {
        yield put(creators.testProfileParametersRequest())

        return
      }

      const { isImperson, detailsPerson } = yield select(state => ({
        isImperson: state.userSession.impersonation.isImperson,
        detailsPerson: state.userSession.detailsPerson,
      }))

      if (isImperson || !detailsPerson.canImpersonate) {
        yield put(creators.profileCyclesRequest())

        return
      }

      yield put(setAlerts([getAlertImpersonation(detailsPerson)]))
    })
  }

  function* onSetImpersonationType() {
    yield takeLatest(types.SET_IMPERSONATION_TYPE, function* ({ impersonationId }) {
      if (INDEX_MODAL_IMPERSONATION === impersonationId) {
        return yield put(navigateSelectConsultant())
      }

      return yield put(creators.profileCyclesRequest())
    })
  }

  /**
   * @deprecated This is just fallback for graphql queries inside commons folder
   * which still uses localStorage for headers
   */
  function* onAuthenticateUserRequestStoreToken() {
    yield takeLatest(types.AUTHENTICATE_USER_REQUEST, (action) => {
      setTokenLocalStorage(action.token)
    })
  }

  /**
   * @deprecated This is just fallback for graphql queries inside commons folder
   * which still uses localStorage for headers
   */
  function* onAuthenticateUserSuccessStorePersonData() {
    yield takeLatest(types.AUTHENTICATE_USER_SUCCESS, (action) => {
      const { xAppTokenPersonData, detailsPerson } = action

      setPersonDataLocalStorage(xAppTokenPersonData, get(detailsPerson, 'personData.personId'))
    })
  }

  /**
   * @deprecated This is just fallback for graphql queries inside commons folder
   * which still uses localStorage for headers
   */
  function* onAuthenticateUserSuccessLocalStorage() {
    yield takeLatest(types.AUTHENTICATE_USER_SUCCESS, (action) => {
      sessionStorage.setItem('personCode', action.detailsPerson.personCode)
    })
  }

  function* onUserTestProfileRequest() {
    yield takeLatest(types.TEST_PROFILE_PARAMETERS_REQUEST, function* () {
      yield setLoading(true)

      const { data, errors } = yield queryTestProfileParameters()

      if (!errors) {
        const result = mapTestProfileParameters(data.CMMGetTestProfileParameters)
        yield put(creators.testProfileParametersSuccess(result))
        yield put(navigateTestProfile())
      } else {
        yield put(dispatch => verifyError(dispatch, errors, false, TEST_PROFILE_NOT_FOUND))
      }

      yield setLoading(false)
    })
  }

  function* onSegmentationGroupRequest() {
    yield takeLatest(types.SEGMENTATION_GROUP_REQUEST, function* (action) {
      yield setLoading(true)

      const { data, errors } = yield querySegmentationGroups(action.businessModelId, action.segmentId)

      if (!errors) {
        if (data.CMMGetSegmentationGroups.length === 0) {
          const subSegmentNotFoundError = new Error()
          yield put(dispatch => verifyError(dispatch, subSegmentNotFoundError, false, TEST_PROFILE_NOT_FOUND))
        }

        const subSegments = data.CMMGetSegmentationGroups
        const { businessModels } = yield select(state => state.userSession.testProfileParameters)

        const businessModelIndex = businessModels.findIndex(
          model => model.businessModelId === action.businessModelId
        )

        if (subSegments.length === 0) {
          yield setLoading(false)
          yield put(creators.segmentationGroupSuccess())
          return
        }

        businessModels[businessModelIndex] = {
          ...businessModels[businessModelIndex],
          subSegments: {
            ...(businessModels[businessModelIndex].subSegments || {}),
            [subSegments[0].segment.segmentId]: subSegments
          }
        }
        yield setLoading(false)
        yield put(creators.segmentationGroupSuccess(businessModels))

        return
      }

      yield put(dispatch => verifyError(dispatch, errors, false, TEST_PROFILE_NOT_FOUND))
      yield setLoading(false)
    })
  }

  function* onSelectConsultantClearImpersonation() {
    yield takeLatest('@@router/LOCATION_CHANGE', function* (action) {
      if ((action.payload.location?.pathname || "").includes(SELECT_CONSULTANT) && action.payload.action === 'PUSH') {
        yield put(creators.clearImpersonation())
      }
    })
  }

  return [
    onAuthenticateUserRequest,
    onAuthenticateUserRequestStoreToken,
    onAuthenticateUserSuccessChangeLocale,
    onAuthenticateUserSuccess,
    onAuthenticateUserSuccessLocalStorage,
    onAuthenticateUserSuccessStorePersonData,
    onSelectConsultantClearImpersonation,
    onClearReducers,
    onClosePendingAcceptanceTerms,
    onSegmentationGroupRequest,
    onSetImpersonationType,
    onSetSelectedRole,
    onSetPendingAcceptanceTerms,
    onUserSessionExpiration,
    onUserTestProfileRequest,
  ]
}
