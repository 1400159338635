import {
  SET_ALERTS,
  SET_ALERTS_READ,
  CLEAR_REDURCERS,
  UPDATE_ALERTS,
  CLEAR_REDUCER_IMPERSONATION
} from '../../actions/constants'

import { initialState } from '../../../commons/interactor/alerts/mapper'
import {
  setAlertsReducer,
  setAlertsReadReducer,
  updateAlertsReducer
} from '../../../commons/interactor/alerts/interactor'

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERTS:
      return setAlertsReducer(state, action.payload)
    case UPDATE_ALERTS:
      return updateAlertsReducer(state, action.payload)
    case SET_ALERTS_READ:
      return setAlertsReadReducer(state, action.payload)
    case CLEAR_REDUCER_IMPERSONATION:
    case CLEAR_REDURCERS:
      return initialState
    default:
      return state
  }
}

export default alertsReducer
