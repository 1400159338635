export const defaultVariables = {
  id: null,
  type: null,
  typeAction: null,
  typeModal: null,
  changedRadioID: null,
  titleButtonConfirm: null,
  titleButtonOne: null,
  titleButtonTwo: null,
  radios: null,
  origin: null,
  title: null,
  show: true,
  body: null,
  increment: null,
  kitProduct: null,
  dataObject: null,
  damagedProduct: null,
  interestedProduct: null,
  handleConfirm: null,
  onPressOne: null,
  onPressTwo: null,
  onChangeValueProfile: null
}

export const objDamagedProduct = {
  item: {},
  itemDamaged: {},
  expirationDate: null
}

export const objInterestedProduct = {
  item: {}
}

export const initialState = {
  alerts: [],
  reads: false
}
