import { sagas as userData } from './userData'
import { sagas as userCycles } from './userCycles'
import { sagas as userCommercialInfo } from './userCommercialInfo'
import { sagas as userImpersonation } from './userImpersonation'
import { sagas as cartRecovery } from './cartRecovery'

export const sagas = (...args) => {
  return [
    ...userData(...args),
    ...userCycles(...args),
    ...userCommercialInfo(...args),
    ...userImpersonation(...args),
    ...cartRecovery(...args),
  ]
}
