import { GRAPHQL_SET_CLIENT } from '../../actions/constants'

const INITIAL_STATE = {
  client: null,
}

const graphqlReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GRAPHQL_SET_CLIENT:
      return {
        ...state,
        client: action.payload.client,
      };
    default:
      return state;
  }
}

export default graphqlReducer
