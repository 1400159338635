import React, { Component } from 'react'

import Translate from 'natura-commons/translate'

import Routes from './Routes'
import GTMScript from 'dataLayer/GTMScript'

export default class App extends Component {
  constructor(props) {
    super(props)
    global.Translate = new Translate()
    global.DaysShowTime = 5
  }

  render() {
    return (
      <>
        <GTMScript />
        <Routes />
      </>
    )
  }
}
