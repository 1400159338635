import React from 'react'
import { useSelector } from 'react-redux'

import { AuthContext } from '../contexts'

export const useAuth = () => {
  const [isAuthenticating, setIsAuthenticating] = React.useState(false)
  const { authProvider: Provider, params, navigate } = React.useContext(AuthContext)
  const service = React.useMemo(() => new Provider(
    Provider.addDefaultParams({
      ...params, navigate
    },
    `${window.location.protocol}//${window.location.host}`)
  ), [Provider, params, navigate])

  /** very wrong but needed */
  global.authService = service

  const isAuthenticated = useSelector(state => state.userSession.logged)
  const login = onSuccess => service.authorize(onSuccess)
  const logout = onSuccess => service.logout(onSuccess)
  const handleAuthentication = React.useCallback(
    async () => {
      if (!service || !navigate) {
        console.warn('authProvider not configured yet')

        return
      }

      if (typeof window !== 'undefined') {
        setIsAuthenticating(true)

        const loggedIn = await service.handleLoginCallback()

        if (loggedIn) {
          setIsAuthenticating(false)
        }
      }
    },
    [service, navigate]
  )

  return {
    handleAuthentication,
    isAuthenticating,
    isAuthenticated,
    login,
    logout,
  }
}
