import { SHOW_LOAD, LOAD_AVAILABILITY } from '../constants'

export function showLoad(bool, discrete = false) {
  return {
    type: SHOW_LOAD,
    payload: bool,
    discrete
  }
}

export function loadAvailability(bool) {
  return {
    type: LOAD_AVAILABILITY,
    payload: bool
  }
}
