import React, { lazy } from 'react'
import { useDispatch } from 'react-redux'

import { login as loginDL, logout as logoutDL, siteLoad } from 'dataLayer/dataLayerPush'
import { getSiteLoadData } from 'redux/actions/dataLayer/dataLayerAction'

import { useAuth } from '../hooks'

import { getCountry, setCountry } from 'natura-commons/utils/country/country'

import './SelectConsultant/styles.css'
import './Order/Search/styles.css'
import './Order/Home/styles.css'

import componentLoader from '../commons/helpers/componentLoader'

const PrivateRoutes = lazy(() => componentLoader(() => import('./PrivateRoutes'), 3))
const PublicRoutes = lazy(() => componentLoader(() => import('./PublicRoutes'), 3))

const Routes = () => {
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    isAuthenticating,
    login,
    logout,
    handleAuthentication
  } = useAuth()

  React.useEffect(() => {
    handleAuthentication()
  }, [handleAuthentication])

  React.useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      login(loginDL)
    }
  }, [isAuthenticating, isAuthenticated, login, loginDL])

  React.useEffect(() => {
    siteLoad(dispatch(getSiteLoadData()))
  }, [])

  const handleLogout = () => {
    const country = getCountry()

    sessionStorage.clear()
    setCountry(country)

    logout(logoutDL)
  }

  return (
    <>
      <PublicRoutes handleLogout={handleLogout} />
      <PrivateRoutes
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      />
    </>
  )
}

export default Routes
