import { useCallback, useEffect } from 'react';

export const useDebouncedEffect = (effect, delay, items) => {
  const callback = useCallback(effect, items);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
}