import types from './split.types';

const initialState = {
  fields: {
    searchInput: '',
    selectQuantity: '',
    enableSplit: false,
    isPointError: false,
    isProductError: false,
    selectedCustomer: null,
    showSplitErrors: false,
    showCustomerAddress: {
      name: '',
      addresses: []
    },
    openSelectCustomer: false,
    openDeleteCustomer: false,
    isItemRemovalNotAllowedOpen: false,
  },
  lists: {
    validateErrors: {
      points: [],
      productsQuantity: [],
    },
    errors: [],
    customers: [],
    cartItems: [],
    searchResults: [],
  },
};

const getSplit = (state, splits, minValue, products) => {
  const parentSplits = splits.filter(split => !split.items.find(item => item.itemTypeId === 2 || item.itemTypeId === 4)); 
  const customerSplit = parentSplits.map(spl => {
    const formattedItems = formatItems(splits, spl.personId);
    return {
      ...spl,
      productSelected: {},
      name: spl.customerName,
      personId: spl.personId,
      customerUid: spl.customerUid,
      productList: formattedItems,
      splitTotalPoints: setSplitTotalPoints(spl.items),
    };
  });

  return validateSplit({ ...state, lists: { ...state.lists, customers: customerSplit } }, minValue, products);
};

const addCustomer = (customerPersonId, deliveryModes, lists) => {
  const newCustomer = lists.searchResults.find(c => c.personId === customerPersonId);

  return [
    ...lists.customers,
    {
      ...newCustomer,
      productSelected: {},
      productList: [],
      splitTotalPoints: 0,
      isValid: false,
      deliveryModes,
    },
  ];
};

const setSelectedProduct = (customers, customerPersonId, productSelected) => {
  return customers.map(c => {
    if (c.personId === customerPersonId) {
      return {
        ...c,
        productSelected: { ...productSelected, splitQuantity: 1 }
      }
    }

    return c;
  })
};

const validateSplit = (state, minValue, products = []) => {
  const { lists, fields } = state;
  const pointsError = [];
  const productsError = [];

  const pointValidate = (customer) => {
    const validate = customer.splitTotalPoints >= minValue;

    if (!validate) pointsError.push(customer);

    return validate;
  };

  products.map(item => {
    const itemList = [];
   
    lists.customers.map(customer => {
      customer.productList.filter((product) => 
        product.productCode === +(item.code) 
        && product.itemTypeId === item.itemTypeId 
        && itemList.push(product)
      )
    });

    const totalSplitQuantity = itemList.reduce((a, b) => +a + +b.quantity, 0);

    totalSplitQuantity > item.quantity && productsError.push(item);
  });

  const validatedCustomers = lists.customers.map(item => ({
    ...item,
    isValid: pointValidate(item),
  }));

  return {
    ...state,
    fields: {
      ...fields,
      isPointError: pointsError.length > 0,
      isProductError: productsError.length > 0,
      showSplitErrors: productsError.length > 0,
    },
    lists: {
      ...lists,
      validateErrors: {
        points: pointsError,
        productsQuantity: productsError,
      },
      customers: validatedCustomers
    },
  };
};

const setSplitTotalPoints = (productList) => {
  return productList.reduce((a, b) => +a + +(b.quantity * b.unitPoints), 0);
};

const formatItems = (splits, personId) => {
  const items = [];

    splits.map((spl)=> {
      if (spl.personId === personId) items.push(...spl.items)
    })

    const formattedItems = items.map(item => item.itemTypeId === 2 || item.itemTypeId === 4 ? { ...item, unitPoints: 0, totalPoints: 0} : item);
  return formattedItems;
};

const updateSplit = (state, splits, minValue, products) => {
  const updatedCustomerList = state.lists.customers.map(customer => {
    const customerSplit = splits.find(spl => spl.personId === customer.personId);
    const formattedItems = formatItems(splits, customer.personId);

    return {
      ...customer,
      deliveryModes: customerSplit?.deliveryModes ? customerSplit.deliveryModes : customer.deliveryModes,
      productSelected: {},
      productList: customerSplit ? formattedItems : [],
      splitTotalPoints: customerSplit ? setSplitTotalPoints(customerSplit.items) : 0,
    };
  });

  return validateSplit({ ...state, lists: { ...state.lists, customers: updatedCustomerList } }, minValue, products);
};

const splitReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case types.SET_FIELD:
      return { ...state, fields: { ...state.fields, [payload.field]: payload.value } };

    case types.FETCH_CUSTOMERS:
      return {
        ...state,
        lists: {
          ...state.lists,
          searchResults: payload.customers
        },
        fields: {
          ...state.fields,
          openSelectCustomer: !state.fields.openSelectCustomer,
          searchInput: initialState.fields.initialState,
        }
      };

    case types.ADD_CUSTOMER:
      return {
        ...state,
        lists: {
          ...state.lists,
          searchResults: initialState.lists.searchResults,
          customers: addCustomer(payload.customerPersonId, payload.deliveryModes, state.lists)
        },
        fields: {
          ...state.fields,
          searchInput: initialState.fields.searchInput,
          selectedCustomer: initialState.fields.selectedCustomer,
          openSelectCustomer: !state.fields.openSelectCustomer,
        }
      }

    case types.SET_SELECTED_PRODUCT:
      return {
        ...state,
        lists: {
          ...state.lists,
          customers: setSelectedProduct([...state.lists.customers], payload.customerPersonId, payload.productSelected)
        }
      }


    case types.GET_SPLIT:
      return getSplit(state, payload.splitItems, payload.minValue, payload.products);

    case types.ADD_PRODUCT:
    case types.REMOVE_PRODUCT:
    case types.UPDATE_PRODUCT_QUANTITY:
      return updateSplit(state, payload.splitItems, payload.minValue, payload.products);

    case types.DELETE_CUSTOMER:
      return updateSplit({
        ...state,
        lists: {
          ...state.lists,
          customers: [...state.lists.customers.filter(customer => customer.personId !== payload.customerPersonId)]
        }
      },
        payload.splitItems,
        payload.minValue
      );
    case types.DELETE_EMPTY_CUSTOMER:
      return {
        ...state,
        lists: {
          ...state.lists,
          customers: [...state.lists.customers.filter(customer => customer.personId !== payload.customerPersonId)]
        }
      };
    case types.ENABLE_SPLIT:
      return { ...state, fields: { ...state.fields, enableSplit: payload.enable } };

    case types.SET_SPLIT_ERROR:
      return {
        ...state,
        lists: {
          ...state.lists,
          errors: state.lists.errors.find(({ type }) => type === payload.errorName)
            ? state.lists.errors
            : state.lists.errors.concat({ type: payload.errorName })
        }
      };

    case types.READ_SPLIT_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          openSelectCustomer: false,
        },
        lists: {
          ...state.lists,
          errors: state.lists.errors.filter(({ type }) => type !== payload.errorName)
        }
      };

    default:
      return { ...state };
  }
};

export default splitReducer;
