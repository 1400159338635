import types from './orderSummary.types';

const initialState = {
  fields: {
    searchInput: '',
    selectQuantity: '',
    enableSplit: false,
    isPointError: false,
    isProductError: false,
    selectedCustomer: null,
    showSplitErrors: false,
    showCustomerAddress: {
      name: '',
      addresses: []
    },
    openSelectCustomer: false,
  },
  lists: {
    validateErrors: {
      points: [],
      products: [],
    },
    errors: [],
    customers: [],
    searchResults: [],
  },
};

const createCnItems = (payload) => {
  return [ 
    ...payload.purchasedItems.itemsCn, 
    ...payload.giftItems.itemsCn 
  ]
}

const createCfItems = (payload) => {
  const { itemsCf : purchasedItemsCf } = { ...payload.purchasedItems }
  const { itemsCf : giftItemsCf } = { ...payload.giftItems }

  giftItemsCf.map(gift => {
    gift.isProcessed = false

    purchasedItemsCf.map(purchased => {
      if (gift.personId === purchased.personId) {
        gift.isProcessed = true
        purchased.items = [
          ...purchased.items,
          ...gift.items
        ]
      }
    })
  })

  const notProcessedGifts = giftItemsCf.filter(
    (gift) => !gift.isProcessed
  )

  if (notProcessedGifts.length > 0) {
    purchasedItemsCf = [
      ...purchasedItemsCf,
      ...notProcessedGifts,
    ]
  }

  return purchasedItemsCf
}

const orderSummaryReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ORDER_SUMMARY:
      return {
        ...state,
        lists: {
          ...state.lists,
          searchResults: {
            itemsCf: createCfItems(payload),
            itemsCn: createCnItems(payload),
          }
        }
      };

    default:
      return { ...state };
  }
};

export default orderSummaryReducer;
