import { get } from 'lodash'

import { takeLatest, put, select } from 'redux-saga/effects'
import {
  executeMutation,
  executeQuery,
  setLoading
} from 'redux/sagas/custom-effects'
import {
  getBag,
  refreshCartContent,
  addItems,
  dropBag
} from 'redux/actions/bag/bagAction'
import {
  navigateCart,
  navigateTo,
  navigateHome
} from 'redux/actions/router/routerAction'
import { verifyError } from 'redux/actions/alerts/alertsAction'
import { SELECT_CONSULTANT } from 'redux/actions/router/constants'
import { getSplit, enableSplit } from 'scenes/Split/actions/split.actions'

import { CHECK_BAG_ITEMS } from 'redux/actions/constants'

import { setPreSalesData } from 'redux/actions/dataLayer/dataLayerAction'

import { FAIL_CART_RECOVERY, FAIL_CART_RECOVERY_PRE_SALE } from 'commons/constants/error'
import { CART_CHOICE_PRE_SALES } from 'commons/constants/cart'

import channel from '../../../../commons/enums/channel'
import system from '../../../../commons/enums/system'

import Unavailability from 'natura-commons/service/Unavailability'
import {
  getCartUidFromStorage,
  setCartUidInLocalStorage
} from 'natura-commons/service/Cart/helpers/cartUid'
import { draftAccept, draftCart, draftRefuse } from 'dataLayer/dataLayerPush'
import { CART } from 'natura-commons/graphql/queries'

export const sagas = ({ types, creators }) => {
  function* mutationRecoverCart() {
    const cartUid = yield select(state => state.userSession.cartUid)

    return yield executeMutation({
      mutation: CART.recoverCart,
      variables: { cartUid }
    })
  }

  function* queryGetCartIsRecoverable(cartUid) {
    return yield executeQuery({
      query: CART.getCartIsRecoverable,
      variables: { cartUid }
    })
  }

  function* validateSellOut({ summary: { channelId, systemId } }) {
    const isSellOutParams = channelId === channel.WEB && systemId === system.MINEGOCIO

    if (isSellOutParams) {
      const unavailabilities = yield new Unavailability().getHistory()

      if (unavailabilities) {
        yield put(addItems(unavailabilities))
      }
    }
  }

  function* onRecoverCartRequest() {
    yield takeLatest(types.RECOVER_CART_REQUEST, function* (action) {
      draftCart()
      const currentCartUid = getCartUidFromStorage() || action.cartUid

      const { data, errors } = yield mutationRecoverCart(currentCartUid)

      if (!errors) {
        const { recoverCart } = data

        yield onRecoverCartSuccess(recoverCart)
      } else {
        yield onRecoverCartFailure(action.method, errors)
      }
    })
  }

  function* onRecoverCartFailure(method, error) {
    if (method === CART_CHOICE_PRE_SALES) {
      yield put(dispatch =>
        verifyError(dispatch, error, false, FAIL_CART_RECOVERY_PRE_SALE)
      )
    } else {
      yield put(dispatch =>
        verifyError(dispatch, error, false, FAIL_CART_RECOVERY)
      )
    }
  }

  function* onRecoverCartSuccess(recoverCart) {
    setCartUidInLocalStorage(recoverCart.cartUid)

    yield put((dispatch, getState) =>
      refreshCartContent(
        recoverCart.cart,
        dispatch,
        getState,
        undefined,
        undefined,
        false
      )
    )

    yield validateSellOut(recoverCart.cart)

    if (recoverCart.cart.hasSplit) {
      yield put(getSplit())
      yield put(enableSplit(true))
    }
    yield put(navigateCart())
    draftAccept()
  }

  function* onCartIsRecoverableRequest() {
    yield takeLatest(types.POST_COMMERCIAL_INFO_SUCCESS, function* () {
      yield setLoading(true)

      const cartUid = getCartUidFromStorage()
      const { data, errors } = yield queryGetCartIsRecoverable(cartUid)

      if (!errors) {
        const { cartIsRecoverable } = data

        yield onCartIsRecoverableSuccess(cartIsRecoverable)
      } else {
        yield onCartIsRecoverableFailure(errors)
      }

      yield setLoading(false)
    })
  }

  function* onCartIsRecoverableSuccess(response) {
    const { isImperson, isTestProfile, cartRecovered } = yield select(state => ({
      isImperson: !!get(state, 'userSession.impersonation.isImperson'),
      isTestProfile: !!get(state, 'userSession.commercialInfo.isTestProfile'),
      cartRecovered: !!get(state, 'userSession.commercialInfo.cartRecovered'),
    }))

    yield put(creators.setCartUid(response.cartUid))

    setCartUidInLocalStorage(response.cartUid)

    yield put({
      type: CHECK_BAG_ITEMS,
      payload: {
        hasPreSales: !!response.hasPreSales,
        hasBag: !!response.isRecoverable,
        showRecoveryBagModal: (!!response.isRecoverable || !!response.hasPreSales) && !cartRecovered,
        orderDate: response.orderDate || '',
        cycleCode: response.cycleCode || ''
      }
    })

    if (isTestProfile && response.cartUid) {
      yield put(dropBag(response.cartUid))
    }

    if (
      !isTestProfile &&
      !response.isRecoverable &&
      !response.hasPreSales
    ) {
      yield put(getBag())
    }

    if (response) yield put(setPreSalesData(response))
    if (isTestProfile || isImperson) yield put(navigateHome())
  }

  function* onCartIsRecoverableFailure(error) {
    const isImperson = yield select(
      state => !!get(state, 'userSession.impersonation.isImperson')
    )

    if (error) {
      if (isImperson) {
        yield put(navigateTo(SELECT_CONSULTANT))
      }
      yield put(dispatch =>
        verifyError(dispatch, error, !isImperson, FAIL_CART_RECOVERY)
      )
    }
  }

  return [
    onRecoverCartRequest,
    onCartIsRecoverableRequest,
  ]
}
