export const MOBILE = 'mobile'
export const TABLET = 'tablet'
export const COMPUTER = 'computer'

// min width
export const breakpoints = {
  xs: 0, // extra small
  sm: 480, // small
  md: 768, // medium
  lg: 992, // large
  xl: 1200, // extra large
};
