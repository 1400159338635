export const setAcceptanceTerms = (state, { pendingAcceptanceTerms, personId, response }) => ({
  ...state,
  pendingAcceptanceTerms: pendingAcceptanceTerms,
  personDataResponse: response,
  personId,
})

export const showAcceptanceTerm = (state, showAcceptanceTerm) => ({
  ...state,
  showAcceptanceTerm,
})
