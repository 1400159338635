import {
  SET_TAXES_PARAMS,
} from '../../actions/constants'
  
import { initialState } from '../../../commons/interactor/taxes/mapper'

const taxesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAXES_PARAMS:
      return { ...state, taxParams: action.payload }
    default:
      return state
  }
}

export default taxesReducer
