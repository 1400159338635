import { AVON, NATURA, FASHIONHOME } from 'commons/enums/company/companyBrands'

import themes from '../../../commons/assets/theme'

const themesExtension = (themeDS) => {
  const addDefaultTheme = {
    ...themeDS,
    default: {
      light: { ...themes.eloTheme }
    },
    [AVON]: {
      light: { ...themes.avonTheme }
    },
    [NATURA]: {
      light: { ...themes.naturaTheme }
    },
    [FASHIONHOME]: {
      light: { ...themes.fashionHomeTheme }
    }
  }

  return addDefaultTheme
}

export default themesExtension
