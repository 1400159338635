import {
  TYPE_CONFIRM_IMPERSONATION,
  ACTION_MULTIPLES_BUTTON,
  ACTION_BUTTON,
  TYPE_ALERT_MESSAGE,
  TYPE_MODAL_ERROR
} from 'redux/actions/constants'

import Translate from 'natura-commons/translate'

const translate = new Translate()

export const getModalConsultant = (contact) => [
  {
    id: 1,
    type: TYPE_CONFIRM_IMPERSONATION,
    typeAction: ACTION_MULTIPLES_BUTTON,
    typeModal: TYPE_CONFIRM_IMPERSONATION,
    contact,
    title: `<div class="mt-2">${translate.byKey('confirmYourchoice')}</div>`,
    show: true,
    titleButtonOne: translate.byKey('backButton'),
    titleButtonTwo: translate.byKey('confirm')
  }
]

export const showInvalidAddress = () => [
  {
    id: 9999,
    typeAction: ACTION_BUTTON,
    type: TYPE_ALERT_MESSAGE,
    typeModal: TYPE_MODAL_ERROR,
    title: translate.byKey('warningTitle'),
    show: true,
    titleButtonConfirm: translate.byKey('okUnderstand'),
    handleConfirm: () => {},
    body: translate.byKey('noAddressSelected')
  }
]

export const showInvalidAgency = () => [
  {
    id: 9998,
    typeAction: ACTION_BUTTON,
    type: TYPE_ALERT_MESSAGE,
    typeModal: TYPE_MODAL_ERROR,
    title: translate.byKey('incompleteAgency'),
    show: true,
    titleButtonConfirm: translate.byKey('okUnderstand'),
    handleConfirm: () => {},
    body: translate.byKey('incompleteAgencyInfo')
  }
]
