import React from 'react'
import { connect } from 'react-redux'
import { setError } from 'redux/actions/errors/errorAction'
import { setAlerts } from 'redux/actions/alerts/alertsAction'
import { getAlertTitleBody } from 'commons/presenter/order/home/presenter'
class ErrorCatcherBase extends React.Component {
  static getDerivedStateFromError() {
    /**
     * erros tratados via redux
     * implementado apenas para evitar warning
     **/
    return {}
  }

  componentDidCatch(error) {
    this.props.setError(error)
  }

  render() {
    return this.props.children
  }
}

export const ErrorCatcher = connect(null, { setError })(ErrorCatcherBase)

export const ErrorProvider = connect(state => ({
  hasError: state.errors.hasError,
  error: state.errors.error,
}), { setAlerts })(({ hasError, error, ...props }) => {
  if (hasError) {
    setAlerts([getAlertTitleBody(null, 'warningTitle', false)])

    return null
  }

  return <ErrorCatcher {...props} />
})
