import { filter, orderBy } from 'lodash'

import { syncProductOnBag } from './mapper'

export const filterSearchReducer = (state, action) => {
  const result = state.listSearch.slice().filter(item => {
    item.name &&
      item.name.toUpperCase().includes(action.key.trim().toUpperCase())
  })

  return result
}

export const filterCustomSearch = (items, searchLabel) => {
  const regex = new RegExp(searchLabel.trim(), 'i')
  const result = filter(items, item => regex.test(item.name))
  return result
}

export const allProductsAction = (list, bag) =>
  orderBy(
    bag.products.length > 0 ? syncProductOnBag(list, bag) : list,
    ['isPromotion', 'index'],
    ['desc', 'asc']
  )

export const productOnSelectionAction = (list, item, bag) => {
  let newList = filter(list, it => it.code === item.code)
  newList = bag.products.length > 0 ? syncProductOnBag(newList, bag) : newList
  return newList
}
