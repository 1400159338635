import { CLEAR_CART, CLEAR_REDURCERS } from 'redux/actions/constants'

const selectConsultantReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_REDURCERS:
    case CLEAR_CART:
      return state
    default:
      return state
  }
}

export default selectConsultantReducer
