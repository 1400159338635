import { filter } from 'lodash'

import { mapProductNextCicle } from './mapper'

export const productAddedInBagReducer = (state, action) => {
  let list = []
  const filtered = state.list.find(item => item.code === action.item.code)
  if (filtered) {
    filtered.inBag = true
    filtered.quantity = action.item.quantity
    list = [
      ...filter(
        state.list,
        item => item !== null && item.code !== action.item.code
      ),
      filtered
    ]
  }

  return {
    ...state,
    list
  }
}

export const productRemovedInBagReducer = (state, action) => {
  let list = []
  const filtered = state.list.find(item => item.code === action.item.code)
  if (filtered) {
    filtered.quantity = action.item.quantity
    if (filtered.quantity === 0) {
      filtered.inBag = false
    }
    list = [
      ...filter(
        state.list,
        item => item !== null && item.code !== action.item.code
      ),
      filtered
    ]
  }
  return {
    ...state,
    list
  }
}

export const productListReducer = (state, list) => ({
  ...state,
  list
})

export const clearProductListReducer = state => ({
  ...state,
  list: [],
  listPagined: []
})

export const productsNextCicleReducer = (state, productsNextCicle) => {
  return { ...state, productsNextCicle }
}
export const productsSimilarReducer = (state, list) => ({
  ...state,
  productsSimilar: list
})

export const productListPaginedReducer = (state, action) => {
  const { listPagined } = action
  return { ...state, listPagined }
}

export const productListPaginedPromotionReducer = (state, action) => {
  const { listPagined, index } = action
  let newVitrine = [...state.vitrine]
  newVitrine[index].listPagined = listPagined

  return {
    ...state,
    vitrine: [...newVitrine]
  }
}

export const setPromotionsTitles = (state, { titles }) => {
  const withExclusives = titles.some(title => title.idPromocaoExclusiva)

  return { 
    ...state, 
    titlesPromotion: titles,
    withExclusives
  }
}

export const setPromotionsTitlesExclusive = (state, { titles, onlySarrafos, lastCartSize }) => {
  const { exclusivePromotions = [], sarrafosPromotion = [] } = titles

  return { 
    ...state, 
    exclusivePromotions: onlySarrafos ? state.exclusivePromotions : exclusivePromotions,
    lastCartSize,
    sarrafosPromotion,
    withExclusives: exclusivePromotions.length >= 0,
  }
}

export const setSearchingExclusive = (state, { searchingExclusives }) => {
  return { 
    ...state, 
    exclusivePromotions: searchingExclusives ? [] : state.exclusivePromotions,
    searchingExclusives,
    sarrafosPromotion: searchingExclusives ? [] : state.sarrafosPromotion,
  }
}

export const productsNextCicleAction = getState => {
  const {
    userSession: { commercialInfo }
  } = getState()
  const { incorporations } = commercialInfo
  const listResult = []
  incorporations &&
    incorporations.map(incorporation => {
      const item = incorporation.productItem

      if (item.code !== null && item.code !== 0) {
        const itemObject = {
          ...item,
          incorporationUid: incorporation.incorporationUid,
          itemTypeId: incorporation.itemTypeId,
          promotionId: incorporation.promotionId,
          promotionStepNumber: incorporation.promotionStepNumber,
          promotionVersionId: incorporation.promotionVersionId,
        }
        listResult.push(itemObject)
      }
    })
  return mapProductNextCicle(listResult)
}
export const getInputTextSearch = item => item && `${item.code} - ${item.name}`

export const getArrayCodeProducts = item => {
  const listResult = []
  item.unavailabilityFix.map(product => {
    listResult.push(product.productCode)
  })
  return listResult
}

export const getItemQuantityBag = (item, bag) => {
  const { products } = bag
  const _item = products.find(f => item && f.code === item.code)
  if (!_item) return 0
  return _item.quantity
}

export const getPriority = (item, products) => {
  const { unavailabilityFix } = item
  if (unavailabilityFix && products) {
    const listResult = []
    products.map((product, index) => {
      const order = unavailabilityFix.find(
        una => parseInt(una.productCode) === parseInt(product.code)
      )
      const itemObject = {
        ...product,
        priority: order ? order.priority : index
      }
      listResult.push(itemObject)
    })
    return listResult
  }
  return products
}

export const isAllUnavailable = products => {
  const unavailableProducts = products.filter(product => !product.isAvailable)
  return unavailableProducts.length === products.length
}

export const syncAvailability = (state, action) => {
  const { productsCheck } = action
  if (!productsCheck) return { ...state }
  let { list, listPagined } = state
  const mList = managerList(productsCheck, list)
  const mPagined = managerList(productsCheck, listPagined)
  return { ...state, list: mList, listPagined: mPagined }
}

export const managerList = (productsCheck, list) => {
  productsCheck.map(item => {
    let product = list.find(
      result => parseInt(result.code) === parseInt(item.productCode)
    )
    if (product) {
      product.isAvailable = item.availability
      product.productUnavailability = item.productUnavailability
      product.unavailabilityFix = item.unavailabilityFix
      product.isAvailableChecked = true

      product.isPlanB =
        !item.availability &&
        item.productUnavailability &&
        item.productUnavailability.productsFix &&
        item.productUnavailability.productsFix.length > 0

      product.unavailabilityMessage =
        item.unavailabilityFix &&
        item.unavailabilityFix.productsFix &&
        item.unavailabilityFix.productsFix.length > 0
          ? item.unavailabilityFix.productsFix[0].message
          : ''

      product.unavailabilityFix =
        !item.availability &&
        item.productUnavailability &&
        item.productUnavailability.productsFix &&
        item.productUnavailability.productsFix.length > 0
          ? item.productUnavailability.productsFix
          : null
    }
  })
  return list
}

export const syncAvailabilityPromotion = (state, action) => {
  const { productsCheck, index } = action
  if (!productsCheck) return { ...state }
  const { vitrine } = state
  let { products, listPagined } = vitrine[index]
  const mList = managerList(productsCheck, products)
  const mPagined = managerList(productsCheck, listPagined)

  let newVitrine = [...vitrine]
  newVitrine[index].products = mList
  newVitrine[index].listPagined = mPagined

  return {
    ...state,
    vitrine: [...newVitrine]
  }
}

export const syncAvailabilityHighlighted = (state, action) => {
  const { productsCheck, index } = action
  if (!productsCheck) return { ...state }
  const { highlighted } = state
  let { products } = highlighted[index]
  const mList = managerList(productsCheck, products)

  let newHighlighted = [...highlighted]
  newHighlighted[index].products = mList

  return {
    ...state,
    highlighted: [...newHighlighted]
  }
}
