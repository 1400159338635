import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import componentLoader from '../../commons/helpers/componentLoader'

const AcceptanceTerm = React.lazy(() => componentLoader(() => import('../../components/acceptanceTerm'), 3))

export const AcceptanceTermProvider = React.memo(({ children }) => {
  const acceptanceTerm = useSelector(state => state.acceptanceTermReducer)

  return (
    <>
      { acceptanceTerm.showAcceptanceTerm && <AcceptanceTerm/> }
      { children }
    </>
  )
})
