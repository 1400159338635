import { orderBy, filter, nth } from 'lodash'

import Translate from 'natura-commons/translate'

import {
  TYPE_ALERT_INCREMENT,
  ACTION_BUTTON,
  TYPE_MODAL_INCREMENT
} from 'redux/actions/constants.js'

const translate = new Translate()

export const sorterProducts = (
  products,
  sorterSearch,
  filterSearch = [],
  isSimilar = false,
  isVitrine = false,
  usingSorter = true
) => {
  if (!usingSorter) return products
  if (!products) return []
  if (filterSearch.length) {
    const result = []
    filterSearch.map(filter => {
      const arr = products.filter(
        product =>
          product.brand === filter.value || product.subBrand === filter.value
      )
      result.push(...arr)
    })
    products = [...new Set(result)]
  }
  if (!sorterSearch && !isVitrine && isSimilar && products) {
    return orderBy(products, ['priority'], ['asc'])
  }

  if (!sorterSearch && isVitrine) {
    return orderBy(products, ['isAvailable'], ['desc'])
  }

  if (!sorterSearch && !isVitrine) return orderBy(products, ['code'], ['asc'])

  const { columnSorter, typeOrder } = sorterSearch
  if (!isVitrine) return orderBy(products, [columnSorter], [typeOrder])
  return orderBy(products, [columnSorter, typeOrder], ['isAvailable', 'desc'])
}

export const filterSearch = (products, filterSearch = []) => {
  if (filterSearch.length) {
    const result = []
    filterSearch.map(filter => {
      const arr = products.filter(
        product =>
          product.brand === filter.value || product.subBrand === filter.value
      )
      result.push(...arr)
    })
    products = [...new Set(result)]
  }
  return products
}

export const sorterVitrine = vitrine =>
  orderBy(vitrine, ['promotionCode'], ['asc'])

export const filterOptions = (list = []) => {
  let brand = list.map(item => ({ name: item.brand, value: item.brand }))
  brand = brand.filter(
    (item, index, self) => index === self.findIndex(t => t.name === item.name)
  )
  let subBrand = list.map(item => ({
    name: item.subBrand,
    value: item.subBrand
  }))
  subBrand = subBrand.filter(
    (item, index, self) => index === self.findIndex(t => t.name === item.name)
  )
  return {
    [translate.byKey('brand')]: {
      options: brand
    },
    [translate.byKey('subBrand')]: {
      options: subBrand
    }
  }
}

export const hasProductInPromotion = products => {
  const result = filter(products, item => item.isPromotion)
  return result.length > 0
}

export const firstProductPopover = products => {
  const result = filter(
    products,
    item => item.isPlanB && item.isGuaranteedSale
  )
  return result ? nth(result, 0) : null
}

export const filterProductsSearch = (products, stateSearch) => {
  const { list, listPagined } = products
  const { isSearchByCode, searchLabel } = stateSearch
  let result = isSearchByCode ? list : listPagined
  if (isSearchByCode && list.length > 1) {
    result = filter(result, item => item.code !== searchLabel)
  }

  return result
}

export const showModalIncrementGuaranteedSale = (
  item,
  setAlerts,
  functionOnChangeValue
) => {
  const arrayIncrement = [
    {
      id: 0,
      type: TYPE_ALERT_INCREMENT,
      typeAction: ACTION_BUTTON,
      typeModal: TYPE_MODAL_INCREMENT,
      title: `<span class="header-warn-me">${translate.byKey(
        'alertMe'
      )}</span>`,
      show: true,
      titleButtonConfirm: translate.byKey('confirm'),
      body: `<div><span>${translate.byKey(
        'modalIncrementGuaranteedSaleBody'
      )}</span>
      <div class='w-100 mt-3'/>
      <span class='text-warn-me'> ${translate.byKey(
        'modalIncrementGuaranteedSaleSubBody'
      )} </span></div>`,
      increment: {
        quantity: item.quantity,
        onValueChangeIncrement: value => functionOnChangeValue(value)
      }
    }
  ]
  setAlerts(arrayIncrement)
}
