import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import createStorage from 'redux-persist/lib/storage/createWebStorage'
import { routerMiddleware } from 'connected-react-router'

import { history } from './history'

import rootReducer from './redux/reducers'
import rootSaga from './redux/sagas'

const nextRootReducer = require('./redux/reducers')

export default function configureStore(initialState) {
  const persistConfig = {
    key: '2023-12-13',
    storage: createStorage('session'),
    blacklist: ['errors', 'graphql', 'orderShippingReducer', 'bagReducer', 'cartReducer', 'acceptanceTermReducer', 'dataLayerReducer']
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const router = routerMiddleware(history)
  const sagasManager = createSagaMiddleware()
  let store = null
  if (process.env.NODE_ENV === 'production') {
    store = createStore(
      persistedReducer,
      initialState,
      compose(applyMiddleware(thunk, sagasManager, router))
    )
  } else {
    // eslint-disable-next-line global-require
    const { createLogger } = require('redux-logger')
    const composeStore =
      window.__REDUX_DEVTOOLS_EXTENSION__ === undefined
        ? compose(applyMiddleware(thunk, sagasManager, router))
        : compose(
          applyMiddleware(thunk, sagasManager, createLogger(), router),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
              window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    store = createStore(persistedReducer, initialState, composeStore)
  }
  const persistor = persistStore(store)

  sagasManager.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./redux/reducers', () => {
      store.replaceReducer(nextRootReducer)
    })
  }

  return { store, sagasManager, persistor }
}
