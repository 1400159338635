import { find } from 'lodash'

export function syncProductOnBag(listItem, bag) {
  if (!listItem) {
    return []
  }
  const listProducts = []
  for (const item of listItem) {
    const itembag = find(bag.items, (it) => it.code === item.code)
    const quantity = itembag ? itembag.quantity : 0
    listProducts.push({ ...item, quantity })
  }
  return listProducts
}
