import { STEP_EXCLUSIVE } from "commons/constants/cart"
import { stepExclusiveProps } from 'commons/interactor/cart/mapper'

export const updateCartList = (state, action) => {
  const { bag } = action.payload
  return {
    ...state,
    items: bag.items,
    totalPrice: bag.totalPrice,
    quantityTotal: bag.quantityTotal
  }
}

export const goToNextStepReducer = (state, action) => ({
  ...state,
  steps: {
    list: state.steps.list,
    stepSelected: parseInt(state.steps.stepSelected) + 1
  }
})

export const goToPrevStepReducer = (state, action) => {
  const stepSelectedNumber = parseInt(state.steps.stepSelected)
  const stepSelected = stepSelectedNumber ? stepSelectedNumber - 1 : stepSelectedNumber

  return {
    ...state,
    steps: {
      list: state.steps.list,
      stepSelected
    }
  }
}

export const updateSteps = (state, action) => {
  const { list } = state.steps
  const { hasPromotions } = action.payload

  let cloneList = [...list]
  const isExclusiveStepInList = cloneList.some(item => item.type === STEP_EXCLUSIVE)

  if (hasPromotions && !isExclusiveStepInList) {
    cloneList.splice(1, 0, stepExclusiveProps)
  } else if (!hasPromotions && isExclusiveStepInList) {
    cloneList = cloneList.filter(item => item.type !== STEP_EXCLUSIVE)
  }

  return {
    ...state,
    steps: {
      ...state.steps,
      list: cloneList,
    }
  }
}

export const goToSpecificStepReducer = (state, action) => {
  const { step } = action.payload

  return {
    ...state,
    steps: {
      list: state.steps.list,
      stepSelected: step
    }
  }
}

export const getOrderShipping = (state, action) => {
  const { orderShippingReducer } = action.payload
  return {
    ...state,
    resume: {
      ...state.resume,
      shippingSelected: orderShippingReducer.selected,
      selected: orderShippingReducer.selected
    },
    shipping: orderShippingReducer
  }
}
export const setMinimumOrderPrice = (state, action) => {
  const { minimumOrderPrice } = action.payload
  return {
    ...state,
    minimumOrderPrice
  }
}

export const enableDisableNext = (state, action) => {
  const { disbaleNextButton } = action.payload
  return {
    ...state,
    disbaleNextButton
  }
}

export const updateCartResume = (state, action) => {
  const { resume } = action.payload
  if (action.payload.objTypeAndPrice != null) {
    resume.shippingSelected = action.payload.objTypeAndPrice
    if (action.payload.addressPickUp != null) {
      resume.shippingSelected.addressPickUp = action.payload.addressPickUp
    }
  }
  return {
    ...state,
    resume: {
      ...state.resume,
      ...resume
    }
  }
}

export const updateCartPromotions = (state, action) => {
  const { promotions } = action.payload

  return {
    ...state,
    promotions
  }
}

export const updateStatusIdCart = (state, action) => {
  const { resume } = state
  resume.statusId = action.statusId
  return {
    ...state,
    resume: {
      ...resume
    }
  }
}

const updateGift = (gift, code, image, name, quantity, selected) => {
  return {
    ...gift,
    code,
    image,
    name,
    quantity,
    selected
  }
}

const getBenefitQuantity = (gift, quantity) => gift.quantityBenefit > 0 ? gift.quantityBenefit * gift.quantity : quantity

export const selectGift = (state, action) => {
  const { promotionId, code, gifts, giftIndex } = action.payload
  const giftFindedIndex = gifts.findIndex(gift => gift.chooseFromList && gift.promotionId === promotionId)

  const newGifts = gifts.map(gift => ({ ...gift }))
  if (giftFindedIndex > -1) {
    const gift = newGifts[giftFindedIndex]

    const optionIndex = gift.options.findIndex(option => option.code === code && option.parentIndex === giftIndex)
    const item = gift.options[optionIndex]
    if (item) {
      const benefitQuantity = getBenefitQuantity(gift, item.quantity)

      newGifts[giftFindedIndex] = updateGift(gift, item.code, item.image, item.name, benefitQuantity, optionIndex)
    }
  }

  return {
    ...state,
    bagReducer: {
      gifts: newGifts,
      quantityDone: quantityDone(gifts)
    }
  }
}
export const selectGiftOption = (state, action) => {
  const { optionCode, gifts, giftIndex } = action.payload
  const optionIndex = gifts[giftIndex].options.findIndex(
    option => option.code === optionCode
  )

  const selected = gifts[giftIndex].options[optionIndex].selected
  gifts[giftIndex].options[optionIndex].selected = !selected

  if (!gifts[giftIndex].options[optionIndex].selected) {
    gifts[giftIndex].options[optionIndex].quantity = 0
  } else {
    gifts[giftIndex].options[optionIndex].quantity = 1
  }
  return {
    ...state,
    bagReducer: {
      gifts,
      quantityDone: quantityDone(gifts)
    }
  }
}
export const quantityDone = gifts => {
  const chooseGifts = gifts.filter(
    gift => gift.chooseFromList && !gift.distincts
  )
  const totalQuantity = chooseGifts.reduce(
    (sum, gift) => sum + gift.quantity,
    0
  )
  const selectedQuantity = chooseGifts.reduce(
    (acc, gift) => countSelectedOptions(gift.options) + acc,
    0
  )
  const allOpUnavailable = allOptionsAreUnavailable(chooseGifts)
  if (allOpUnavailable) {
    return true
  }
  return totalQuantity === selectedQuantity
}
const countSelectedOptions = options => options
  .filter(op => op.selected || (op.alternateProducts && op.alternateProducts.some(item => item.selected)))
  .reduce((acc, op) => {
    if (op.alternateProducts && op.alternateProducts.length > 0) {
      const { quantity } = op.alternateProducts.find(alternateProduct => alternateProduct.selected) || {}

      if (quantity) {
        return acc + quantity
      }
    }

    return acc + op.quantity
  }, 0)

const allOptionsAreUnavailable = gifts => {
  let allUnavailable = true
  gifts.forEach(gift => {
    const availableOptions = gift.options.filter(op => op.isAvailable)
    if (availableOptions.length > 0) {
      allUnavailable = false
    }
  })
  return allUnavailable
}

export const updateGiftQuantity = (state, action) => {
  const { code, quantity, gifts, giftIndex, promotionId, promotionStepNumber, promotionVersionId } = action.payload
  let newGifts = [...gifts]
  const optionIndex = newGifts[giftIndex].options.findIndex(
    option => option.code === code && option.promotionId === promotionId
      && option.promotionStepNumber === promotionStepNumber && option.promotionVersionId === promotionVersionId
  )
  newGifts[giftIndex].options[optionIndex].quantity = quantity < 0 ? 0 : quantity

  if (
    newGifts[giftIndex].options[optionIndex].quantity > 0 &&
    !newGifts[giftIndex].options[optionIndex].selected
  )
    newGifts[giftIndex].options[optionIndex].selected = true
  else if (
    newGifts[giftIndex].options[optionIndex].quantity === 0 &&
    newGifts[giftIndex].options[optionIndex].selected
  )
    newGifts[giftIndex].options[optionIndex].selected = false
  return {
    ...state,
    gifts: [...newGifts],
    quantityDone: quantityDone(gifts)
  }
}

export const setMine = (state, action) => {
  const { mineStatusId } = action.mine
  return {
    ...state,
    mine: { mineStatusId }
  }
}

const cleanGift = (gift) => ({
  ...gift,
  options: gift.options.map(op => ({
    ...op,
    included: false,
    quantity: 0,
    selected: false
  }))
})

export const renewGifts = (state) => {
  const { bagReducer } = state
  if (bagReducer) {
    let { gifts } = bagReducer
    for (const gift of gifts) {
      if (gift.chooseFromList) {
        gift.options.map((item, index) => {
          gift.selected = index
          gift.code = item.code
          gift.image = item.image
          gift.name = item.name
          gift.isAvailable = item.isAvailable
          gift.quantity = 0
        })
      }
    }

    gifts = gifts.map(cleanGift);

    return {
      ...state,
      bagReducer: {
        gifts,
        quantityDone: false,
        selectedGifts: undefined
      }
    }
  }
  return {
    ...state
  }
}

export const setPaymentMethod = (state, action) => {
  const { methodPayment, returnURL } = action.payload;
  state.methodPayment = methodPayment
  state.returnURL = returnURL
  return {
    ...state
  }
}

export const setConfirmPaymentIntent = (state, action) => {
  const { bank } = action.payload;
  state.bank = bank || ''
  return {
    ...state
  }
}

export const setErrorStripe = (state, action) => {
  const { errorStripe } = action.payload;
  state.errorStripe = errorStripe
  return {
    ...state
  }
}

export const setMinSplitValueCN = (state, action) => {
  return {
    ...state,
    split: {
      ...state.split,
      settings: {
        minEnable: {
          ...action.payload,
        }
      }
    }
  }
}
