import {
  PERSON_DATA,
  COMMERCIAL_DATA,
  VIRTUAL_PAGE_VIEW,
  CART_CONTENT,
  CLEAR_REDURCERS,
  PRE_SALES,
  CHECKOUT_DATA,
  PURCHASE_DATA,
  CART_ADDITION_ORIGIN,
  VTR_NAVIGATION,
  CURRENT_LIST,
  CALL_GTM_SCRIPT
} from 'redux/actions/constants'

import { initialState } from 'commons/interactor/dataLayer/mapper'

import {
  eraseCartData,
  updateCartContent,
  updatePreSales,
  updateCheckoutEvent,
  structurePurchaseEvent,
  updateCartAdditionOrigin,
  resetOriginInfo
} from 'dataLayer/structuring/cartData'

import {
  structureAddToCart,
  structureRemoveFromCart
} from 'dataLayer/structuring/productData'

import {
  updatePersonData,
  updateCommercialData
} from 'dataLayer/structuring/profileData'

import { setVtrNavigationOrigin } from 'dataLayer/structuring/listData'

import { updateCurrentPage } from 'dataLayer/structuring/virtualPageViewData'

import {
  addToCart,
  removeFromCart,
  siteLoad,
  purchase
} from 'dataLayer/dataLayerPush'

import { clearDuplicatedLists } from 'dataLayer/structuring/helper'

const dataLayerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CALL_GTM_SCRIPT:
      return{
        ...state,
        callGTM: payload,
      }
    case PERSON_DATA: {
      if (state.callGTM) {
        return updatePersonData(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case COMMERCIAL_DATA: {
      if (state.callGTM) {
        const newState = updateCommercialData(state, payload)
        if (differs(state.siteLoad, newState.siteLoad)) {
          siteLoad(newState.siteLoad)
        }
        return newState
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case VIRTUAL_PAGE_VIEW: {
      if (state.callGTM) {
        return updateCurrentPage(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case CART_CONTENT: {
      if (state.callGTM) {
        const didAddToCart = addToCart(structureAddToCart(state, payload))
        removeFromCart(structureRemoveFromCart(state, payload))
        if (didAddToCart) {
          const newState = resetOriginInfo(state)
          return updateCartContent(newState, payload)
        }
        return updateCartContent(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case CHECKOUT_DATA: {
      if (state.callGTM) {
        return updateCheckoutEvent(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case PURCHASE_DATA: {
      if (state.callGTM) {
        purchase(structurePurchaseEvent(state, payload))
        return eraseCartData(state)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case CLEAR_REDURCERS: {
      if (state.callGTM) {
        clearDuplicatedLists(["home", "bestOffer", "promotions", "vtr", "banner"])
        return initialState
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case PRE_SALES: {
      if (state.callGTM) {
        return updatePreSales(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case CART_ADDITION_ORIGIN: {
      if (state.callGTM) {
        return updateCartAdditionOrigin(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case VTR_NAVIGATION: {
      if (state.callGTM) {
        return setVtrNavigationOrigin(state, payload)
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    case CURRENT_LIST: {
      if (state.callGTM) {
        return {
          ...state,
          currentList: payload
        }
      }
      return {
        ...state,
        pendingDataLayerActions: [...state.pendingDataLayerActions, { type, payload }]
      }
    }
    default:
      return state
  }
}

const differs = (oldState, newState) =>
  JSON.stringify(oldState) !== JSON.stringify(newState)

export default dataLayerReducer
