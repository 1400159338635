import {
  CLEAR_CART,
  SET_DELIVERY_OPTION,
  CLEAR_DELIVERY_MODES,
  UPDATE_STEPS,
} from 'redux/actions/constants'


import Cart from 'natura-commons/service/Cart'


import {
  clearGifts,
  verifyUnavailableGiftsAndAddToCart,
  setMine
} from 'redux/actions/bag/bagAction'

import { verifyError, errorStripe } from 'redux/actions/alerts/alertsAction'
import { showLoad } from 'redux/actions/load/loadAction'

import { getCartWindow } from 'redux/actions/parameters/parameterAction'

import {
  cartIsClear,
  nextStepIsGift
} from 'commons/interactor/bag/interactor'

import Translate from 'natura-commons/translate'

import { TIMEOUT_ALERT_TOAST } from 'commons/constants/cart'

import {
  addItem,
  removeItem,
  removeAllItems,
  updateItem,
  updateAddressAction
} from '../bag/bagAction'
import {
  START_FETCHING_ITEMS,
  REMOVE_ITEM,
  UPDATE_ITEM,
  GO_TO_NEXT_STEP,
  GO_TO_PREV_STEP,
  GO_TO_SPECIFIC_STEP,
  CLEAR_STEP,
  UPDATE_CART_RESUME,
  GET_ORDER_SHIPPING,
  SET_PICK_UP_ADDRESS,
  SET_STATUS_ID_CART,
  SET_MINIMUM_ORDER_PRICE,
  REMOVE_ITEM_FROM_CART_STATE,
  CLEAR_UNAVAILABLE_PRODUCTS,
  ENABLE_DISABLE_NEXT,
  SET_CLEAR,
  CART_FINISH,
  CART_SUBSCRIBE,
  PAYMENT_INTENT,
  PAYMENT_CONFIRM_INTENT,
  SET_METHOD_PAYMENT,
  SET_ERROR_STRIPE,
  SET_CHANGE_PAYMENT_ERROR
} from '../constants'

import minValueType from '../../../commons/enums/split/minValueType'
import { showToast, hideToast } from '../toast/toastAction'

import {
  checkoutCart,
  updateDeliveryModes
} from './orderShippingAction'


const TYPE_VALUE = Object.freeze({
  POINTS: 1,
  PRICE: 0,
})

export const updateCart = item => (dispatch) => {
  dispatch({
    type: UPDATE_ITEM,
    item
  })
  dispatch(updateItem(item))
}

export const setSubcriber = subscribe => (dispatch) => {
  dispatch({ type: CART_SUBSCRIBE, subscribe })
}

export const setClear = clear => (dispatch) => {
  dispatch({ type: SET_CLEAR, clear })
  if (!clear) dispatch({ type: CART_FINISH, finish: true })
}

export const renewCart = () => (dispatch) => {
  dispatch({ type: CLEAR_CART })
}

export const updateStatusIdCart = statusId => (dispatch) => {
  dispatch({ type: SET_STATUS_ID_CART, statusId })
  dispatch(setMine({ mineStatusId: statusId }))
}

export const addItemCart = item => (dispatch) => {
  dispatch({ type: START_FETCHING_ITEMS, item })
  dispatch(addItem(item))
}

export const removeItemCart = item => (dispatch) => {
  dispatch({ type: REMOVE_ITEM, item })
  dispatch(removeItem(item))
}

export const removeAllItemsCart = () => (dispatch) => {
  dispatch({ type: REMOVE_ITEM })
  dispatch(removeAllItems())
}

export const removeProductFromCartState = item => (dispatch) => {
  dispatch({ type: REMOVE_ITEM_FROM_CART_STATE, item })
}
export const clearUnavailableProducts = () => (dispatch) => {
  dispatch({ type: CLEAR_UNAVAILABLE_PRODUCTS })
}

export const clearCart = () => ({ type: CLEAR_CART })

export const clearStep = () => (dispatch) => {
  dispatch({ type: CLEAR_STEP })
  dispatch(disbaleNextButton(false))
}

export const redirectOrder = () => (dispatch, getState) => {
  if (cartIsClear(getState)) {
    dispatch(setClear(false))
  }
  dispatch(showLoad(false))
}

export const updateSteps = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_STEPS, payload })
}

export const goToNextStep = payload => (dispatch, getState) => {
  dispatch({ type: GO_TO_NEXT_STEP, payload })
  dispatch(disbaleNextButton(false))
  if (nextStepIsGift(getState())) {
    dispatch(clearGifts())
    dispatch(verifyUnavailableGiftsAndAddToCart(getState()))
  }
}

export const goToPrevStep = payload => (dispatch, getState) => {
  dispatch({ type: GO_TO_PREV_STEP, payload })
  dispatch(disbaleNextButton(false))
  if (nextStepIsGift(getState())) {
    dispatch(clearGifts())
  }
}

export const goToSpecificStep = payload => (dispatch, getState) => {
  dispatch({ type: GO_TO_SPECIFIC_STEP, payload })
  dispatch(disbaleNextButton(false))
  if (nextStepIsGift(getState())) {
    dispatch(clearGifts())
  }
}

export const setMinimumOrderPrice = payload => (dispatch) => {
  dispatch({
    type: SET_MINIMUM_ORDER_PRICE,
    payload: {
      minimumOrderPrice: payload
    }
  })
}
export const updateCartResume = payload => (dispatch, getState) => {
  const { orderShippingReducer } = getState()
  dispatch({
    type: UPDATE_CART_RESUME,
    payload,
    orderShippingReducer
  })
}

export const updateOrderShipping = objAddress => (dispatch, getState) => {
  const { addressShipping, addresses } = objAddress

  if (addressShipping) {
    const { deliveryModes } = addresses.find(address => address.id === objAddress.addressShipping.id)

    dispatch(updateDeliveryModes(deliveryModes, getState))
  }

  updateAddressAction(objAddress, dispatch, getState)
}

export const updateOrderPickUp = objAddress => (dispatch) => {
  dispatch({
    type: SET_PICK_UP_ADDRESS,
    payload: objAddress
  })
}

export const getOrderShipping = () => (dispatch, getState) => {
  const { cartReducer, orderShippingReducer } = getState()
  dispatch({
    type: GET_ORDER_SHIPPING,
    payload: {
      cartReducer,
      orderShippingReducer
    }
  })
}

export const disbaleNextButton = disable => (dispatch) => {
  dispatch({
    type: ENABLE_DISABLE_NEXT,
    payload: { disbaleNextButton: disable }
  })
}

export const subscribeCart = cartid => (dispatch, getState) => {
  const {
    cartReducer: {
      subscribe,
      resume: { cartUid }
    }
  } = getState()
  const uid = cartid || cartUid
  if (!subscribe && uid) {
    const cart = new Cart()
    cart.resolver(
      (response) => {
        response.subscribe()
        dispatch(setSubcriber(true))
      },
      (error) => {
        dispatch(showLoad(false))
        verifyError(dispatch, error, false)
      },
      cart.subscribeCartCalc(uid)
    )
  }
}

export const getObjectsCart = (displayTaxes, taxContext) => dispatch => (
  dispatch(getCartWindow(displayTaxes, taxContext))
)

export const getPaymentConfig = (companyId, countryCode) => () => {
  const cart = new Cart()
  return new Promise(((resolve, reject) => {
    cart.getPaymentConfig(companyId, countryCode)
      .then(response => resolve(response))
      .catch(fail => reject(fail))
  }))
}

export const getAlertStripeError = payload => (dispatch) => {
  dispatch({
    type: SET_ERROR_STRIPE,
    payload: {
      errorStripe: true
    }
  })
  errorStripe(dispatch, [{ message: payload.payload }])
}

export const createPaymentIntent = payload => (dispatch) => {
  const data = payload.payload
  const { stripe } = payload
  dispatch(disbaleNextButton(true))
  const cart = new Cart()
  dispatch(showLoad(true))
  cart.stripeResolver(
    (response) => {
      if (!response.errors && response.data) {
        dispatch({
          type: PAYMENT_INTENT,
          payload: data
        })
        dispatch(showLoad(false))
        const confirmData = Object.assign(response.data.paymentCreatePaymentIntent, data)
        dispatch(confirmPaymentIntent(confirmData, stripe))
        dispatch(disbaleNextButton(false))
      } else {
        if (response.errors) {
          dispatch({
            type: SET_ERROR_STRIPE,
            payload: {
              errorStripe: true
            }
          })
          errorStripe(dispatch, response.errors)
          dispatch(disbaleNextButton(false))
        }
        dispatch(showLoad(false))
        dispatch(disbaleNextButton(false))
      }
    },
    (error) => {
      dispatch({
        type: SET_ERROR_STRIPE,
        payload: {
          errorStripe: true
        }
      })
      dispatch(showLoad(false))
      errorStripe(dispatch, error)
      dispatch(disbaleNextButton(false))
    },
    cart.createPaymentIntent({ data })
  )
}

export const confirmPaymentIntent = (data, stripe) => (dispatch) => {
  const cart = new Cart()
  const methodPayment = data.method
  dispatch(showLoad(true))
  cart.stripeResolver(
    async (response) => {
      if (!response.errors && response.data) {
        const responseData = response.data.paymentConfirmPaymentIntent
        dispatch(setErrorChangePayment(false))
        if (responseData.nextAction && responseData.nextAction.type === 'use_stripe_sdk') {
          const { error: errorAction } =
            await stripe.handleCardAction(responseData.clientSecret)
          dispatch(showLoad(false))
          if (errorAction) {
            dispatch({
              type: SET_ERROR_STRIPE,
              payload: {
                errorStripe: true
              }
            })
            errorStripe(dispatch, [errorAction])
            data.changePayment && dispatch(setErrorChangePayment(data.orderNumber))
            data.changePayment && data.changePaymentInfo &&
            data.changePaymentInfo(true)
            dispatch({
              type: SET_METHOD_PAYMENT,
              payload: {
                methodPayment,
                returnURL: ''
              }
            })
            dispatch(showLoad(false))
            dispatch(disbaleNextButton(false))
          }
          if (!errorAction) {
            dispatch(showLoad(true))
            dispatch(disbaleNextButton(true))
            dispatch(confirmPaymentIntent(data, stripe))
          }
          return
        }
        dispatch({
          type: PAYMENT_CONFIRM_INTENT,
          payload: data
        })
        dispatch({
          type: SET_METHOD_PAYMENT,
          payload: {
            methodPayment,
            returnURL: (response.data.paymentConfirmPaymentIntent.redirectUrl && methodPayment == 'fpx') ?
              response.data.paymentConfirmPaymentIntent.redirectUrl : ''
          }
        })
        data.changePayment ? showPaymentChangedToast(dispatch, data.orderNumber, 'warning') : dispatch(checkoutCart(data))
        dispatch(showLoad(false))
        dispatch(disbaleNextButton(false))
        data.changePayment && data.changePaymentInfo &&
          data.changePaymentInfo(false, data.bank)
      } else {
        dispatch({
          type: SET_ERROR_STRIPE,
          payload: {
            errorStripe: true
          }
        })
        if (response.errors) {
          errorStripe(dispatch, response.errors)
          data.changePayment && dispatch(setErrorChangePayment(data.orderNumber))
          data.changePayment && data.changePaymentInfo &&
            data.changePaymentInfo(true)
          dispatch({
            type: SET_METHOD_PAYMENT,
            payload: {
              methodPayment,
              returnURL: ''
            }
          })
        }
        dispatch(showLoad(false))
        dispatch(disbaleNextButton(false))
      }
    },
    (error) => {
      dispatch({
        type: SET_ERROR_STRIPE,
        payload: {
          errorStripe: true
        }
      })
      dispatch(showLoad(false))
      errorStripe(dispatch, error)
      dispatch(disbaleNextButton(false))
    },
    cart.confirmPaymentIntent({ data })
  )
}

export const isEnabledSplit = () => (dispatch, getState) => {
  const { TOTAL_POINTS, TOTAL_PRICE } = minValueType
  const { cartReducer: { resume, split: { settings: { minEnable } = {} } = {} } } = getState()
  const { totalPoints, totalMinOrderSize } = { ...resume }
  const { minValue, typeValue } = { ...minEnable }

  if (!minValue || !typeValue) return false

  const enableSplit = {
    [TOTAL_POINTS]: totalPoints >= minValue,
    [TOTAL_PRICE]: totalMinOrderSize >= minValue
  }

  return enableSplit[typeValue]
}

export const setDeliveryOption = deliveryOption => (dispatch, getState) => {
  dispatch({ type: SET_DELIVERY_OPTION, deliveryOption })
}

export const clearDeliveryModes = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_DELIVERY_MODES })
}

const showPaymentChangedToast = (dispatch, orderNumber) => {
  const translate = new Translate()
  dispatch(
    showToast({
      text: translate.byKeyAndParamValue('changePaymentSuccess', orderNumber || '0'),
      type: 'warning',
      show: true,
      autoHide: true,
      timeout: TIMEOUT_ALERT_TOAST,
      hideIcon: true
    })
  )
}

export const setErrorChangePayment = bool => ({
  type: SET_CHANGE_PAYMENT_ERROR,
  payload: bool
})
