import { creators as userSession } from 'redux/modules/userSession'
import { setTokenLocalStorage } from 'natura-commons/utils/profile'
// https://useauth.dev/docs/auth-providers/
export class CognitoAuthService {
  static addDefaultParams(options, uri) {
    return {
      ...options,
      params: {
        ...options.params,
        redirect_uri: uri,
      }
    }
  }

  constructor({ store, domain, country, language, callback, xApiKey, params }) {
    this.redux = store
    this.domain = domain
    this.callback = callback
    this.xApiKey = xApiKey
    this.params = {
      ...params,
      country,
      language,
    }
  }

  authorize() {
    if (this.getSSOToken()) {
      return
    }

    const params = encodeURI(Object.entries(this.params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&'))

    const url = `${this.domain}?${params}`

    window.location.assign(url)
  }

  async logout(onComplete = () => {}) {
    const { userSession: session } = this.redux.getState()
    const payload = JSON.stringify({ token: session.ssoToken })
    const response = await this.fetch('authentication-api/signout', { body: payload })

    this.redux.dispatch(userSession.clear())

    await onComplete(response)

    window.location.reload()
  }

  async handleLoginCallback() {
    if (this.isLogged()) {
      return true
    }

    try {
      const token = this.getSSOToken()

      if (token) {
        const payload = JSON.stringify({ token })
        const response = await this.fetch('authentication-api/recover-session', { body: payload })

        if (response.status >= 400) {
          return false
        }

        const body = await response.json()

        this.redux.dispatch(userSession.authenticateUserRequest(body.accessToken, body.expiresIn * 1000, token))

        return true
      }

      return false
    } catch (error) {
      console.error(error)

      return false
    }
  }

  /**
   * 
   * @returns {boolean}
   */
  async refresh() {
    try {
      const token = this.getSSOToken()
      if (token) {
        const response = await this.fetch(
          'authentication-api/recover-session',
          { body: JSON.stringify({ token }) },
        )
        if (response.status >= 400) {
          return false
        }
        const body = await response.json()
        this.redux.dispatch(userSession.setSession(body.accessToken, body.expiresIn * 1000))
        setTokenLocalStorage(body.accessToken)
        return true
      }
      return false
    } catch (error) {
      console.error(error)
      return false
    }
  }

  isLogged() {
    const { userSession } = this.redux.getState()

    return !!userSession.ssoToken && !!userSession.authenticationToken
  }

  getSSOToken() {
    if (typeof window !== 'undefined') {
      const params = Object.fromEntries(new URLSearchParams(window.location.search))

      if (params.sso_token) {
        return params.sso_token
      }
    }

    const { userSession } = this.redux.getState()

    return userSession.ssoToken
  }

  fetch(path, options = {}) {
    const url = [this.callback, path].join('/')

    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': this.xApiKey,
      },
      ...options,
    })
  }
}
