export const config = {
  // TODO: usar mesmo autenticador de dev, quando estiver estável
  //
  // local: {
  //   domain: 'https://natura-auth.dev.naturacloud.com',
  //   callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
  //   params: {
  //     company: 'natura',
  //     client_id: '3ovftt6ua8ig3v2okm7cg5j299'
  //   }
  // },

  local: {
    domain: 'https://natura-auth.qa.naturacloud.com',
    callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
    xApiKey: '1c4869ea-3ffb-41b6-87f1-c1c758440868',
    params: {
      company: 'natura',
      client_id: '48sej7f48eg946b7e9gbapg8q7'
    }
  },

  // development: {
  //   domain: 'https://natura-auth.dev.naturacloud.com',
  //   callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
  //   params: {
  //     company: 'natura',
  //     client_id: '3ovftt6ua8ig3v2okm7cg5j299'
  //   }
  // },

  development: {
    domain: 'https://natura-auth.qa.naturacloud.com',
    callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
    xApiKey: '1c4869ea-3ffb-41b6-87f1-c1c758440868',
    params: {
      company: 'natura',
      client_id: '48sej7f48eg946b7e9gbapg8q7'
    }
  },

  quality: {
    domain: 'https://natura-auth.qa.naturacloud.com',
    callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
    xApiKey: '1c4869ea-3ffb-41b6-87f1-c1c758440868',
    params: {
      company: 'natura',
      client_id: '48sej7f48eg946b7e9gbapg8q7'
    }
  },

  performance: {
    domain: 'https://natura-auth.qa.naturacloud.com',
    callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
    xApiKey: '1c4869ea-3ffb-41b6-87f1-c1c758440868',
    params: {
      company: 'natura',
      client_id: '48sej7f48eg946b7e9gbapg8q7'
    }
  },

  // homolog: {
  //   domain: 'https://natura-auth.hml.naturacloud.com',
  //   callback: 'https://authenticator-cognito-apigw.hml.naturacloud.com',
  //   xApiKey: 'd99a465f-e8f4-4cda-a53f-ce6fc4849fad',
  //   params: {
  //     company: 'natura',
  //     client_id: 'u62u141gfut8etaat5m6n9pso'
  //   }
  // },

  homolog: {
    domain: 'https://natura-auth.qa.naturacloud.com',
    callback: 'https://authenticator-cognito-apigw.qa.naturacloud.com',
    xApiKey: '1c4869ea-3ffb-41b6-87f1-c1c758440868',
    params: {
      company: 'natura',
      client_id: '48sej7f48eg946b7e9gbapg8q7'
    }
  },

  production: {
    domain: 'https://natura-auth.prd.naturacloud.com',
    callback: 'https://authenticator-cognito-apigw.prd.naturacloud.com',
    xApiKey: '2aa3706e-93b1-4b36-bb93-c76f5076d576',
    params: {
      company: 'natura',
      client_id: '3ec6rhfe52b2k78h32kv7ml6ti'
    }
  }
}[process.env.REACT_APP_NODE_ENV]
