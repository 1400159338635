import { toastMaxItemQuantity } from 'commons/presenter/toast/presenter'
import { STEP_GIFT, STEP_BAG } from 'commons/constants/cart'

export const diffAddItem = (
  dispatch,
  finalList,
  prevProducts,
  item,
  maxItemQuantity,
  isUpdate
) => {
  const prevItem = prevProducts.find(product => product.code === item.code)
  if (prevItem) {
    if (isUpdate && item.quantity < prevItem.quantity) return

    if (item.quantity > maxItemQuantity) {
      toastMaxItemQuantity(
        dispatch,
        item.quantity,
        isUpdate ? maxItemQuantity : maxItemQuantity - prevItem.quantity,
        prevItem
      )
    }
    return
  }

  const itemAdd = finalList.find(product => product.code === item.code)
  if (itemAdd && itemAdd.quantity < item.quantity) {
    toastMaxItemQuantity(dispatch, item.quantity, itemAdd.quantity, itemAdd)
  }
}

export const getArrayItemsUIDs = state => {
  const listResult = []
  const { bagReducer } = state
  let { products } = bagReducer
  products = products.filter(item => !item.isStarterKit && item.cartItemUid)
  products.map(product => listResult.push(product.cartItemUid))
  return listResult
}

export const checkStock = state => {
  const {
    cartReducer: {
      steps: { stepSelected, list }
    }
  } = state
  return [STEP_BAG, STEP_GIFT].includes(list[stepSelected].type)
}
