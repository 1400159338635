import { get, isEmpty } from 'lodash'
import { call, takeLatest, put, select } from 'redux-saga/effects'
import { commercialInfoResponsePush, personDataResponsePush } from 'dataLayer/dataLayerPush'
import { structureCommercialInfoResponse, structurePersonDataResponse } from 'dataLayer/structuring/profileData'
import { executeQuery, getSelectedRole, setLoading, executeMutation } from 'redux/sagas/custom-effects'
import { verifyError } from 'redux/actions/alerts/alertsAction'
import { activeSearchByDescription, getMinimumSplitValueCN, getHomeParameters, getChangePaymentMethod } from 'redux/actions/parameters/parameterAction'
import { INFORMATION_NOT_FOUND, TEST_PROFILE_ERROR } from 'commons/constants/error'
import { CHANNEL_WEB, CHANNEL_APP, GDN_CHANNEL_APP, GDN_CHANNEL_WEB, CHANNEL_ATTENDANCE, SYSTEM_ID_DEFAULT } from 'commons/constants/profile/index'
import { orderNavigateManagerAction } from 'commons/interactor/router/interactor'
import { HOME } from 'redux/actions/router/constants'
import { listProductsNextCicle } from 'redux/actions/products/productsActions'
import { isMobile } from 'commons/helpers/device'
import { SET_REFRESH_TOKEN } from 'redux/actions/constants'

import { setLevel, setSessionIdentifierStorage, setCommercialInfoLocalStorage } from 'natura-commons/utils/profile'
import { PROFILE } from 'natura-commons/graphql/queries'
export const sagas = ({ types, creators }) => {
  function* queryCommercialInfo(variables = null) {
    const xAppToken = yield select(state => state.userSession.xAppTokenPersonDataImpersonate)

    if (!variables) {
      variables = yield getCommercialInfoParameters()
    }

    return yield executeQuery({
      query: PROFILE.getCommercialInfo,
      variables,
      context: {
        headers: {
          xAppToken,
        }
      }
    })
  }

  function* mutationCommercialInfo() {
    const { incorporations, starterKit } = yield select(state => ({
      incorporations: state.userSession.commercialInfo.incorporations,
      starterKit: {
        products: (state.userSession.commercialInfo.orderProfile.starterKit.products || [])
          .map(({ list, position, ...product }) => product)
      }
    }))

    return yield executeMutation({
      mutation: PROFILE.postCommercialInfo,
      variables: {
        incorporations,
        starterKit,
      },
    })
  }

  function* getCommercialInfoParameters() {
    const {
      isAttendant,
      isGdn,
      isImperson,
      selectedRole,
      country,
    } = yield select(state => ({
      isAttendant: state.userSession.detailsPerson.isAttendant,
      isGdn: state.userSession.detailsPerson.isGdn,
      isImperson: state.userSession.impersonation.isImperson,
      selectedRole: state.userSession.selectedRole,
      country: state.i18n.countryCode,
    }))

    const isMob = isMobile()

    const systemOrigin = Number(sessionStorage.getItem('systemOrigin'))
    let channelId = isMob ? CHANNEL_APP : CHANNEL_WEB
    if (isGdn && isImperson) {
      channelId = isMob ? GDN_CHANNEL_APP : GDN_CHANNEL_WEB
    } else if (isAttendant && isImperson) {
      channelId = CHANNEL_ATTENDANCE
    } else if (
      !country &&
        !systemOrigin &&
        isMob &&
        !isAttendant &&
        !isImperson
    ) {
      channelId = CHANNEL_APP
    } else if (
      country &&
        systemOrigin &&
        isMob &&
        !isAttendant &&
        !isImperson
    ) {
      channelId = CHANNEL_APP
    }

    return yield {
      channelId,
      businessModelId: selectedRole.businessModelId,
      systemId: SYSTEM_ID_DEFAULT
    }
  }

  function* onCommercialInfoRequest() {
    yield takeLatest(types.COMMERCIAL_INFO_REQUEST, function* ({ variables }) {
      const commercialInfo = yield select(state => state.userSession.commercialInfo.personId)

      if (!isEmpty(commercialInfo)) {
        return
      }

      yield setLoading(true)

      const { data, errors } = yield queryCommercialInfo(variables)

      if (!errors) {
        const { payload, xAppToken } = get(data, 'profileGetCommercialInfo', {})

        yield put(creators.commercialInfoSuccess(payload, xAppToken))
      } else {
        yield onCommercialInfoRequestFailure(errors)
      }

      yield setLoading(false)
    })
  }

  function* onCommercialInfoRequestFailure(errors) {
    const role = yield getSelectedRole()

    if (role.isTestProfile) {
      yield put(dispatch => verifyError(dispatch, { errors }, false, TEST_PROFILE_ERROR))
    } else {
      yield put(dispatch => verifyError(dispatch, { errors }, true, INFORMATION_NOT_FOUND))
    }
  }

  function* onCommercialInfoRequestSuccess() {
    yield takeLatest(types.COMMERCIAL_INFO_SUCCESS, function* () {
      const {
        isImperson,
        commercialInfo
      } = yield select(state => ({
        isImperson: state.userSession.impersonation.isImperson,
        commercialInfo: state.userSession.commercialInfo,
      }))
      yield put(activeSearchByDescription())
      yield put(getHomeParameters(commercialInfo))
      yield put(getChangePaymentMethod())

      if (isImperson) {
        yield put((dispatch, getState) => orderNavigateManagerAction(getState, HOME)(dispatch))
      }
    })
  }

  function* onCommercialInfoSuccessDataLayer() {
    yield takeLatest(types.COMMERCIAL_INFO_SUCCESS, function* (action) {
      commercialInfoResponsePush(structureCommercialInfoResponse(action.commercialInfo))
      const personData = yield select(state => state.userSession)
      personDataResponsePush(structurePersonDataResponse(personData))
    })
  }

  /**
   * @deprecated This is just fallback for graphql queries inside commons folder
   * which still uses localStorage for headers
   */
  function* onCommercialInfoSuccessLocalStorage() {
    yield takeLatest(types.COMMERCIAL_INFO_SUCCESS, (action) => {
      setSessionIdentifierStorage(action.commercialInfo.sessionIdentifier)
      setCommercialInfoLocalStorage(action.xAppTokenCommercialInfo)
      setLevel(action.commercialInfo.orderProfile)
    })
  }

  function* onPostCommercialInfoSuccess() {
    yield takeLatest(types.POST_COMMERCIAL_INFO_SUCCESS, function*() {
      yield put(listProductsNextCicle())
    })
  }

  function* onPostCommercialInfoRequest() {
    yield takeLatest(types.POST_COMMERCIAL_INFO_REQUEST, function* () {
      const hasIncorporationsOrStarterKit = yield select(
        state =>
          !isEmpty(
            get(state, 'userSession.commercialInfo.incorporations')
          ) ||
          !isEmpty(
            get(state, 'userSession.commercialInfo.orderProfile.starterKit.products')
          )
      )

      if (!hasIncorporationsOrStarterKit) {
        yield put(creators.postCommercialInfoSuccess(
          [],
          { products: [] },
          false
        ))

        return
      }

      const { data, errors } = yield mutationCommercialInfo()

      if (!errors) {
        const { incorporations, starterKit, cartRecovered } = get(data, 'profilePostCommercialInfo', {})

        yield put(creators.postCommercialInfoSuccess(incorporations, starterKit, cartRecovered))
      } else {
        yield onCommercialInfoRequestFailure(errors)
      }
    })
  }

  function* onCommercialInfoRefreshToken() {
    yield takeLatest(SET_REFRESH_TOKEN, function* () {
      const { data } = yield executeQuery({
        query: PROFILE.getRefreshToken
      })

      const { payload, xAppToken } = get(data, 'profileGetRefreshToken', {})
      const {
        approvalReason,
        blockReason,
        credit,
        orderProfile: { starterKit },
        isBlocked
      } = payload

      yield put(creators.setRefreshToken(approvalReason, blockReason, credit, starterKit, xAppToken, isBlocked))
      yield setCommercialInfoLocalStorage(xAppToken)
    })
  }

  return [
    onCommercialInfoRefreshToken,
    onCommercialInfoRequest,
    onCommercialInfoRequestSuccess,
    onCommercialInfoSuccessDataLayer,
    onCommercialInfoSuccessLocalStorage,
    onPostCommercialInfoRequest,
    onPostCommercialInfoSuccess,
  ]
}
