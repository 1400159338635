import LOCALES from '../enums/locale'

const formatEsCountryCurrency = (numberToFormat, decimalPlaces, groupSeparator) => 
  Number(numberToFormat).toFixed(decimalPlaces).replace(/./g, (digit, index, value) =>
    index > 0 && digit !== "." && (value.length - index) % 3 === 0 ? 
      groupSeparator + digit :
      digit
  )

export const formatterValue = (value, decimalPlaces = 2, countryCode = 'PE') => {
  if (LOCALES[countryCode].groupSeparator) {
    return formatEsCountryCurrency(value, decimalPlaces, LOCALES[countryCode].groupSeparator)
  }

  return Math.abs(value).toLocaleString(LOCALES[countryCode].locale, {
    localeMatcher: 'best fit',
    style: 'decimal',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  })
}

export const formatterValueNoDigits = (value, locale = 'es-pe') => formatterValue(value, 0, locale)

export const onlynumber = event => {
  var theEvent = event || window.event;
  var key = theEvent.keyCode || theEvent.which;
  key = String.fromCharCode( key );
  var regex = /^[0-9.]+$/;
  if(!regex.test(key) ) {
     theEvent.returnValue = false;
     if(theEvent.preventDefault) theEvent.preventDefault();
  }
}

export const maskToCountry = (value) => {
  switch (value) {
    case 'MY':
      return 'MM/dd/yyyy'
    default:
      return 'dd/MM/yyyy'
  }
}

export const installmentValueFormatter = (totalValue, installmentNumber, currency, decimalPlaces, countryCode) => {
	const valueToShow = parseFloat(totalValue / installmentNumber)
  return `${installmentNumber}x ${currency}${formatterValue(valueToShow, decimalPlaces, countryCode)}`
}

export const formatBankName = (bank) => {
  if(!bank) {
    return null
  }
  
  const banksName = {
    affin_bank: "Affin Bank",
    alliance_bank: "Alliance Bank",
    ambank: "AmBank",
    bank_islam: "Bank Islan",
    bank_muamalat: "Bank Muamalat",
    bank_rakyat: "Bank Rakyat",
    bsn: "BSN",
    cimb: "CIMB Clicks",
    hong_leong_bank: "Hong Leong Bank",
    hsbc: "HSBC Bank",
    kfh: "KFH",
    maybank2u: "Maybank2U",
    ocbc: "OCBC Bank",
    public_bank: "Public Bank",
    rhb: "RHB Bank",
    standard_chartered: "Standard Chartered",
    uob: "UOB",
    maybank2e: "Maybank2E",
  }

  return banksName[bank] || bank.split('_').map( item => {
    return item.charAt(0).toUpperCase() + item.slice(1)
  }).join(' ')
}

export const getInitials = (fullName) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if(index === 0 || index === allNames.length - 1){
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}
