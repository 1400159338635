import { get, isEmpty } from 'lodash'
import { TEST_PROFILE_ID } from 'commons/constants/profile'

import { createModule } from 'natura-commons/redux'

import { sagas } from './sagas'

const initialState = {
  ssoToken: null,
  authenticationToken: null,
  commercialInfo: {
    orderProfile: {
      decimalPlaces: 2,
      growthPlan: [{
        rowthPlanId: null,
        growthPlanLevelId: null,
        growthPlanColor: null,
        accumulatedValue: null,
        nextLevelName: null,
        levelGrowthValue: null,
        nextLevelCode: null,
        nextPlanColor: null,
      }]
    },
  },
  cycles: [],
  detailsPerson: {},
  errorLogin: false,
  errorMessage: '',
  expiresIn: null,
  goHome: false,
  impersonation: {
    isImperson: false,
    person: {
      code: '',
      name: '',
      personData: {},
    },
    roleSelected: null,
  },
  logged: false,
  selectedRole: {},
  testProfileParameters: {},
  xAppTokenCommercialInfo: null,
  xAppTokenOtherCycles: null,
  xAppTokenPersonData: null,
  xAppTokenPersonDataImpersonate: null,
}

export const { reducer, saga, types, creators } = createModule({
  options: {
    id: 'user_session',
  },

  state: initialState,

  actions: {
    authenticateUserRequest: [
      ['token', 'expiresIn', 'ssoToken'],
      (state, { token, expiresIn, ssoToken }) => ({
        ...state,
        goHome: false,
        ssoToken,
        authenticationToken: token,
        expiresIn,
      }),
    ],

    setSession: [
      ['token', 'expiresIn'],
      (state, { token, expiresIn }) => ({
        ...state,
        authenticationToken: token,
        expiresIn,
      }),
    ],

    authenticateUserSuccess: [
      ['detailsPerson', 'xAppTokenPersonData'],
      (state, { detailsPerson, xAppTokenPersonData }) => ({
        ...state,
        detailsPerson,
        logged: true,
        xAppTokenPersonData,
        xAppTokenPersonDataImpersonate: xAppTokenPersonData,
      })
    ],

    authenticateUserFailure: [
      ['errorMessage'],
      (state, { errorMessage }) => ({
        ...state,
        logged: false,
        goHome: false,
        errorLogin: true,
        errorMessage,
      })
    ],

    profileCyclesRequest: [
      null,
      state => state
    ],

    profileCyclesSuccess: [
      ['cyclesInfo', 'xAppTokenOtherCycles'],
      (state, { cyclesInfo, xAppTokenOtherCycles }) => ({
        ...state,
        cycles: cyclesInfo.otherCycles,
        xAppTokenOtherCycles
      })
    ],

    setCycleData: [
      ['cyclesInfo'],
      (state, { cyclesInfo }) => ({
        ...state,
        detailsPerson: {
          ...state.detailsPerson,
          currentCycle: get(cyclesInfo, 'personData.currentCycle'),
          orderCycle: get(cyclesInfo, 'personData.orderCycle'),
        },
      }),
    ],

    setImpersonCycleData: [
      ['cyclesInfo'],
      (state, { cyclesInfo }) => ({
        ...state,
        impersonation: {
          ...state.impersonation,
          person: {
            ...state.impersonation.person,
            personData: {
              ...state.impersonation.person.personData,
              currentCycle: get(cyclesInfo, 'personData.currentCycle'),
              orderCycle: get(cyclesInfo, 'personData.orderCycle'),
            }
          }
        },
      })
    ],

    setSelectedRole: [
      ['roleId'],
      (state, { roleId }) => {
        const { roles = [] } = state.impersonation.isImperson ? state.impersonation.person.personData : state.detailsPerson
        const selectedRole = roles.find(role => role.businessModelId === roleId)

        return {
          ...state,
          selectedRole,
          impersonation: {
            ...state.impersonation,
            roleSelected: roleId,
          }
        }
      }
    ],

    setImpersonationType: [
      ['impersonationId'],
      state => state,
    ],

    setCycle: [
      ['cycleCode', 'subcycleCode', 'orderDate'],
      state => state
    ],

    changeCycleRequest: [
      ['cycleCode', 'subcycleCode', 'personId', 'orderDate'],
      state => state
    ],

    changeCycleSuccess: [
      ['changeCycleInfo', 'xAppTokenPersonDataImpersonate'],
      (state, { xAppTokenPersonDataImpersonate }) => ({
        ...state,
        xAppTokenPersonDataImpersonate
      })
    ],

    commercialInfoRequest: [
      ['variables', 'isTestProfile'],
      (state, { isTestProfile }) => ({
        ...state,
        commercialInfo: {
          ...state.commercialInfo,
          isTestProfile,
        }
      })
    ],

    commercialInfoSuccess: [
      ['commercialInfo', 'xAppTokenCommercialInfo'],
      (state, { commercialInfo, xAppTokenCommercialInfo }) => ({
        ...state,
        goHome: true,
        commercialInfo: {
          ...commercialInfo,
          isTestProfile: state.commercialInfo.isTestProfile,
          orderProfile: {
            ...commercialInfo.orderProfile,
            isLeader: state.impersonation.isImperson ? state.impersonation.personData : state.detailsPerson,
            showStepPayment: true
          }
        },
        xAppTokenCommercialInfo,
      })
    ],

    postCommercialInfoRequest: [
      null,
      state => state,
    ],

    setRefreshToken: [
      ['approvalReason', 'blockReason', 'credit', 'starterKit', 'xapptoken', 'isBlocked'],
      (state, { approvalReason, blockReason, credit, starterKit, xapptoken, isBlocked }) => ({
        ...state,
        commercialInfo: {
          ...state.commercialInfo,
          isBlocked,
          approvalReason,
          blockReason,
          credit,
          orderProfile: {
            ...state.commercialInfo.orderProfile,
            starterKit: !starterKit.products
              ? { ...starterKit }
              : { ...state.commercialInfo.orderProfile.starterKit }
          },
        },
        xAppTokenCommercialInfo: xapptoken
      })
    ],

    postCommercialInfoSuccess: [
      ['incorporations', 'starterKit', 'cartRecovered'],
      (state, { incorporations, starterKit, cartRecovered }) => ({
        ...state,
        commercialInfo: {
          ...state.commercialInfo,
          incorporations,
          cartRecovered: !!cartRecovered,
          orderProfile: {
            ...state.commercialInfo.orderProfile,
            starterKit: {
              ...state.commercialInfo.orderProfile.starterKit,
              ...starterKit,
              show: !isEmpty(starterKit?.products),
            },
          },
          isComplete: true,
        }
      })
    ],

    setImpersonationRequest: [
      ['contact'],
      (state, { contact }) => ({
        ...state,
        impersonation: {
          ...state.impersonation,
          person: {
            ...state.impersonation.person,
            code: contact.code,
            name: contact.name,
          },
        },
      })
    ],

    setImpersonationSuccess: [
      ['personData', 'xAppTokenPersonDataImpersonate'],
      (state, { personData, xAppTokenPersonDataImpersonate }) => ({
        ...state,
        xAppTokenPersonDataImpersonate,
        impersonation: {
          ...state.impersonation,
          isImperson: true,
          person: {
            ...state.impersonation.person,
            personData: {
              ...personData,
              roles: (personData.roles || []).filter(role => role.functionId !== TEST_PROFILE_ID)
            },
          }
        },
      })
    ],

    testProfileParametersRequest: [null, state => state],

    testProfileParametersSuccess: [
      ['testProfileParameters'],
      (state, { testProfileParameters }) => ({
        ...state,
        testProfileParameters,
      }),
    ],

    segmentationGroupRequest: [
      ['businessModelId', 'segmentId'],
      state => state
    ],

    segmentationGroupSuccess: [
      ['businessModels'],
      (state, { businessModels }) => ({
        ...state,
        testProfileParameters: {
          ...state.testProfileParameters,
          businessModels
        }
      })
    ],

    setAvailableStarterKit: [
      ['show'],
      (state, { show }) => ({
        ...state,
        commercialInfo: {
          ...state.commercialInfo,
          orderProfile: {
            ...state.commercialInfo.orderProfile,
            starterKit: {
              ...state.commercialInfo.orderProfile.starterKit,
              show
            }
          }
        }
      })
    ],

    recoverCartRequest: [null, state => state],

    setCartUid: [
      ['cartUid'],
      (state, { cartUid }) => ({
        ...state,
        cartUid,
      })
    ],

    findUserForImpersonationRequest: [
      ['personCode'],
      state => state
    ],

    resetLogin: [
      null,
      state => ({
        ...initialState,
        ssoToken: state.ssoToken,
        authenticationToken: state.authenticationToken,
        expiresIn: state.expiresIn,
      })
    ],

    clear: [null, () => ({ ...initialState })],

    clearImpersonation: [null, state => ({
      ...state,
      impersonation: initialState.impersonation,
      cycles: initialState.cycles,
      commercialInfo: initialState.commercialInfo,
      xAppTokenPersonDataImpersonate: state.xAppTokenPersonData,
      xAppTokenOtherCycles: initialState.xAppTokenOtherCycles,
      xAppTokenCommercialInfo: initialState.xAppTokenCommercialInfo,
    })]
  },

  sagas,
})
