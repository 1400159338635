
import {
	SET_MENU,
	SET_COMPANY_BRANDS
} from '../../actions/constants'

import {
  initialState
} from '../../../commons/interactor/companyBrand/mapper'

const routerReducer = (state = initialState, action) => {
  switch (action.type) {
		case SET_COMPANY_BRANDS:
      return { ...state, companyBrands: action.companyBrands }
    case SET_MENU:
			return { ...state, menu: action.menu }
  
    default:
      return state
  }
}

export default routerReducer
