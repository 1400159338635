import { get } from 'lodash'
import { isDuplicated } from './helper'

export const updatePersonData = (state, payload) => {
  try {
    let { currentCycle, orderCycle } = payload
    const siteLoad = {
      ...state.siteLoad,
      countryName: payload.countryCode,
      companyId: payload.companyId,
      user: {
        ...state.siteLoad.user,
        cycle: {
          cycleCode: currentCycle.cycleCode,
          subcycleCode: get(currentCycle, 'subcycle.subCycleCode'),
          orderCycleCode: orderCycle.cycleCode,
          orderSubcycleCode: get(orderCycle, 'subcycle.subCycleCode')
        },
        addresses: payload.addresses,
        personId: payload.personId,
        personCode: payload.personCode,
        personName: payload.name,
        isBlocked: payload.isBlocked,
        blockReason: payload.blockReason,
        canTest: payload.canTest,
        canImpersonate: payload.canImpersonate
      }
    }

    const userRoles = payload.roles

    return {
      ...state,
      siteLoad,
      userRoles
    }
  } catch (exception) {
    console.error('Error structuring person data: ', exception)
    return state
  }
}

export const updateCommercialData = (state, payload) => {
  try {
    const { orderProfile } = payload
    const [growthPlan] = orderProfile.growthPlan || []
    const { userPersonCode, personaPersonCode } = getUserAndPersonaCodes(
      payload.sessionIdentifier
    )
    const { isLeader = false } = orderProfile

    const levelName =
      growthPlan && growthPlan.planLevel && growthPlan.planLevel.levelName
    const businessModel = getBusinessModel(state, payload)
    const isImpersonation = payload.userId !== payload.personId
    const siteLoad = {
      ...state.siteLoad,
      isImpersonation,
      systemId: payload.orderProfile.systemId,
      channelId: payload.orderProfile.channelId,
      user: {
        ...state.siteLoad.user,
        businessModel,
        personCode: userPersonCode,
        growthPlanLevelCN: !isImpersonation && !isLeader ? levelName : null,
        growthPlanLevelLeader: !isImpersonation && isLeader ? levelName : null
      },
      persona: {
        ...state.siteLoad.persona,
        businessModel,
        cycle: {
          cycleCode: payload.orderProfile.currentCycle.cycleCode,
          subcycleCode: payload.orderProfile.currentCycle.subcycle.subcycleCode,
          orderCycleCode: payload.orderProfile.orderCycle.cycleCode,
          orderSubcycleCode:
            payload.orderProfile.orderCycle.subcycle.subcycleCode
        },
        addresses: payload.addresses,
        growthPlanLevelCN: isImpersonation && !isLeader ? levelName : null,
        growthPlanLevelLeader: isImpersonation && isLeader ? levelName : null,
        personId: payload.personId,
        isBlocked: payload.isBlocked,
        blockReason: payload.blockReason,
        segmentation: payload.segmentation,
        personCode: personaPersonCode
      }
    }

    return {
      ...state,
      siteLoad
    }
  } catch (exception) {
    console.error('Error structuring commercial data: ', exception)
    return state
  }
}

export const getUserAndPersonaCodes = sessionIdentifier => {
  try {
    const [personaRawCode, userCode] = sessionIdentifier.split('-i:')
    const [, personaCode] = personaRawCode.split('-u:')
    return {
      userPersonCode: userCode,
      personaPersonCode: personaCode
    }
  } catch (exception) {
    console.error('Error getting impersonator and user codes')
    return {
      userPersonCode: '',
      personaPersonCode: ''
    }
  }
}

export const getBusinessModel = (state, payload) => ({
  businessModelId: payload.orderProfile.businessModelId,
  businessModelName: getBusinessModelName(
    state.userRoles,
    payload.orderProfile.businessModelId
  ),
  functionsAndRoles: getFunctionsAndRoles(payload.orderProfile.roles),
  commercialStructures: getCommercialStructures(payload.orderProfile.structures)
})

export const getFunctionsAndRoles = roles =>
  roles.map(role => ({
    functionId: role.functionId,
    functionName: undefined,
    roleId: role.roleId,
    roleName: undefined
  }))

export const getCommercialStructures = structures =>
  structures.map(structure => ({
    structureTypeId: structure.structureTypeId,
    structureTypeName: structure.structureTypeName,
    structureId: structure.structureCode,
    structureName: structure.structureName
  }))

export const getBusinessModelName = (roles, businessModelId) => {
  const selectedRole = roles.find(
    role => role.businessModelId === businessModelId
  )
  return selectedRole && selectedRole.businessModelName
}

export const structurePersonDataResponse = payload => ({
  event: 'personDataResponse',
  response: payload
})

export const structureCommercialInfoResponse = payload => ({
  event: 'commercialInfoResponse',
  response: payload
})

export const structureProfileClick = () => {
  const eventName = 'clickProfile';

  if(!isDuplicated(eventName, 'profileClick')){
    return {
      event: eventName
    }
  }
}
