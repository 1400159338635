import UAParser from 'ua-parser-js'

import { MOBILE, TABLET } from '../constants/device'

const parser = new UAParser()

const configParser = () => {
  if (typeof window === 'undefined') {
    return null
  }
  parser.setUA(window && window.navigator.userAgent)
}

export const isMobile = () => {
  configParser();
  return parser.getDevice().type === MOBILE
}

export const isTablet = () => {
  configParser();
  return parser.getDevice().type === TABLET
}

export const isMobileOrTablet = () => {
  return isMobile() || isTablet();
}