export const structureAddToCart = (state, nextCart) => {
  try {
    const { cartContent: currentCart, cartAdditionOrigin } = state
    const diff = compareAddToCart(currentCart, nextCart)

    if (Object.entries(diff).length === 0) return undefined

    const addToCart = {
      event: 'addToCart',
      addProductsResponse: nextCart.response,
      origin: cartAdditionOrigin.origin,
      listName: cartAdditionOrigin.listName,
      listPosition: cartAdditionOrigin.listPosition,
      alteredItems: structureAlteredItems(nextCart.finalList, diff)
    }

    return addToCart
  } catch (exception) {
    console.error('Error structuring addToCart data: ', exception)
    return undefined
  }
}

export const compareAddToCart = (currentCart, nextCart) => {
  if (!currentCart) return {}

  const currentItems = {}
  const nextItems = {}
  const itemDifference = {}

  currentCart.finalList.forEach((item) => {
    currentItems[item.code] = item.quantity
  })

  nextCart.finalList.forEach((item) => {
    nextItems[item.code] = item.quantity
  })

  Object.keys(nextItems).forEach((key) => {
    if (!(key in currentItems)) {
      itemDifference[key] = nextItems[key]
    } else if (nextItems[key] > currentItems[key]) {
      itemDifference[key] = nextItems[key] - currentItems[key]
    }
  })

  return itemDifference
}

export const structureRemoveFromCart = (state, nextCart) => {
  try {
    const { cartContent: currentCart } = state
    const diff = compareRemoveFromCart(currentCart, nextCart)

    if (Object.entries(diff).length === 0) return undefined

    const removeFromCart = {
      event: 'removeFromCart',
      removeProductsResponse: currentCart.response,
      alteredItems: structureAlteredItems(currentCart.finalList, diff)
    }

    return removeFromCart
  } catch (exception) {
    console.error('Error structuring removeFromCart data: ', exception)
    return undefined
  }
}

export const compareRemoveFromCart = (currentCart, nextCart) => {
  if (!currentCart) return {}

  const currentItems = {}
  const nextItems = {}
  const itemDifference = {}

  currentCart.finalList.forEach((item) => {
    currentItems[item.code] = item.quantity
  })

  nextCart.finalList.forEach((item) => {
    nextItems[item.code] = item.quantity
  })

  Object.keys(currentItems).forEach((key) => {
    if (!(key in nextItems)) {
      itemDifference[key] = currentItems[key]
    } else if (currentItems[key] > nextItems[key]) {
      itemDifference[key] = currentItems[key] - nextItems[key]
    }
  })

  return itemDifference
}

export const structureAlteredItems = (baseProducts, difference) =>
  baseProducts
    .filter(item => item.code in difference)
    .map(item => ({
      code: item.code,
      discount: item.discount,
      image: item.image,
      inBag: item.inBag,
      isAvailable: item.isAvailable,
      companyBrand: item.companyBrand,
      isDiamondDiscount: item.isDiamondDiscount,
      isGuaranteedSale: item.isGuaranteedSale,
      isPlanB: item.isPlanB,
      isPromotion: item.isPromotion,
      isStarterKit: item.isStarterKit,
      isOptionalKit: item.isStarterKitOptional,
      name: item.name,
      points: item.points,
      showOriginalPrice: item.showOriginalPrice,
      unavailabilityFix: item.unavailabilityFix,
      value: {
        buyingPrice: item.value.purchase / item.quantity,
        sellingPrice: item.value.sell / item.quantity,
        profit: item.value.gain / item.quantity,
        originalPrice: item.value.originalPrice / item.quantity
      },
      quantity: difference[item.code]
    }))
